import React from "react";
import styles from "../../../styles/style.module.css";
import Occupations from "../../../../../assets/images/occupations.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import storage from "../../../../../utils/storage";
import { RootState } from "../../../../../redux/store";
import { keyInfoValidationSchema } from "../../../validations";
import {
  setActiveStep,
  setKeyInformation,
} from "../../../../../redux/reducers/addContract";
import { ADD_CONTRACT_STEPS } from "../../../../../shared/enums";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import { SaveAsDraftButton } from "../../Components";
import Toltip from "../../../../common/toltip/page";
import tip from "../../../../../assets/images/tip.png";
import { CONSTANT_MANAGER } from "../../../../../utils/constans/constants";
const AddKeyInformation: React.FC = () => {
  const dispatch = useDispatch();
  const token = storage.getToken();
  const { keyInformation } = useSelector(
    (state: RootState) => state.addContract
  );

  const formik: any = useFormik({
    initialValues: {
      noOfPeople: keyInformation.noOfPeople || "",
      shift: keyInformation.shift || "",
      plannedHoursPerDay: keyInformation.plannedHoursPerDay || "",
      plannedDaysPerWeek: keyInformation.plannedDaysPerWeek || "",
    },
    validationSchema: keyInfoValidationSchema,
    onSubmit: (value) => {
      console.log(value);
      dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_LOCATION));
    },
  });

  const onChange = (value: string | number, key: string) => {
    formik.setFieldValue(key, value);

    dispatch(
      setKeyInformation({
        ...keyInformation,
        [key]: value,
      })
    );
  };
  const handleGoBack = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_TIMINGS));
  };
  // const handleGoToNextStep = () => {
  //   dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_LOCATION));
  // };

  return (
    <>

      <HeaderContent />
      <div className="py-4 heightautoscroll">
        <div className={styles.signuppage}>

          <form onSubmit={formik.handleSubmit}>
            <div
              className={styles.registerOuter}
              style={{ position: "relative", height: "100%" }}
            >
              <div className="container">
                <div className={styles.registerBord}>
                  <div className="row align-items-start">
                    <div className="col-lg-6">
                    <div className={styles.registerLeft}>
                            <h4 className="text-white text-32 fw-semibold">
                              {" "}
                              How Many People Are Needed And <br/>How Much Will
                              They Work?
                            </h4>
                            <p className="text-16 text-white opacity-75">
                              How many Professionals do you need?
                            </p>
                         
                      <div className="col-lg-11 col-12"
                      > 
                        <figure>
                          <img
                            src={Occupations}
                            alt=""
                            className="img-fluid"
                          />
                        </figure>
                      </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className={styles.signUPContainer}>
                        <div className={styles.registerRight}>
                          
                          <div >
                            <div className={styles.fromGroup} style={{ marginBottom: "30px" }}>
                              {" "}
                              <div className="topTipHover">
                                <label className={styles.starlabel}>
                                  # Of People Needed{" "}
                                </label>{" "}
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                                <Toltip
                                  slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                  labelId={
                                    CONSTANT_MANAGER.ADD_CONTRACT
                                      .PEOPLE_NEEDED
                                  }
                                />
                              </div>
                              <input
                                type="number"
                                placeholder="Enter the number"
                                name="noOfPeople"
                                className="form-control rounded-pill h-48"
                                value={formik.values.noOfPeople}
                                onChange={(e) =>
                                  onChange(
                                    Number(e.target.value),
                                    "noOfPeople"
                                  )
                                }
                                onBlur={formik.handleBlur}
                              />
                              {formik.touched.noOfPeople &&
                                formik.errors.noOfPeople ? (
                                <div className={styles.error}>
                                  {formik.errors.noOfPeople}
                                </div>
                              ) : null}
                            </div>
                            <div className={styles.fromGroup} style={{ marginBottom: "30px" }}>
                              {" "}
                              <div className="topTipHover">
                                <label className={styles.starlabel}>
                                  Shift
                                </label>{" "}
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                                <Toltip
                                  slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                  labelId={
                                    CONSTANT_MANAGER.ADD_CONTRACT.SHIFT
                                  }
                                />
                              </div>
                              <select
                                name="shift"
                                value={formik.values.shift}
                                className="form-control rounded-pill h-48"
                                onChange={(e) =>
                                  onChange(e.target.value, "shift")
                                }
                                onBlur={formik.handleBlur}
                              >
                                <option value="">Select shift</option>
                                <option value="days">Days</option>
                                <option value="afternoons">Afternoons</option>
                                <option value="nightshift">
                                  Night shift
                                </option>
                                <option value="variable">Variable</option>
                              </select>
                              {formik.touched.shift && formik.errors.shift ? (
                                <div className={styles.error}>
                                  {formik.errors.shift}
                                </div>
                              ) : null}
                              {/* <span>
                                    <img src={down} alt="" />
                                  </span> */}
                            </div>
                            <div className={styles.fromGroup} style={{ marginBottom: "30px" }}>
                              {" "}
                              <div className="topTipHover">
                                <label className={styles.starlabel}>
                                  Planned Hours Per Day
                                </label>{" "}
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                                <Toltip
                                  slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                  labelId={
                                    CONSTANT_MANAGER.ADD_CONTRACT
                                      .PLANNED_HOURS_PER_DAY
                                  }
                                />
                              </div>
                              <select
                                className="form-control rounded-pill h-48"
                                name="plannedHoursPerDay"
                                value={formik.values.plannedHoursPerDay}
                                onChange={(e) =>
                                  onChange(
                                    Number(e.target.value),
                                    "plannedHoursPerDay"
                                  )
                                }
                                onBlur={formik.handleBlur}
                              >
                                <option value="">
                                  Select planned Hours per day
                                </option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                              </select>
                              {formik.touched.plannedHoursPerDay &&
                                formik.errors.plannedHoursPerDay ? (
                                <div className={styles.error}>
                                  {formik.errors.plannedHoursPerDay}
                                </div>
                              ) : null}
                              {/* <span>
                                  <img src={down} alt="" />
                                </span> */}
                            </div>

                            <div className={styles.fromGroup} style={{ marginBottom: "30px" }}>
                              {" "}
                              <div className="topTipHover">
                                <label className={styles.starlabel}>
                                  Planned Days Per Week
                                </label>{" "}
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                                <Toltip
                                  slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                  labelId={
                                    CONSTANT_MANAGER.ADD_CONTRACT
                                      .PLANNED_DAYS_PER_WEEK
                                  }
                                />
                              </div>
                              <select
                                className="form-control rounded-pill h-48"
                                name="plannedDaysPerWeek"
                                value={formik.values.plannedDaysPerWeek}
                                onChange={(e) =>
                                  onChange(
                                    Number(e.target.value),
                                    "plannedDaysPerWeek"
                                  )
                                }
                                onBlur={formik.handleBlur}
                              >
                                <option value="">
                                  Select Planned Days Per Week
                                </option>
                                <option value="6">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                              </select>
                              {formik.touched.plannedDaysPerWeek &&
                                formik.errors.plannedDaysPerWeek ? (
                                <div className={styles.error}>
                                  {formik.errors.plannedDaysPerWeek}
                                </div>
                              ) : null}
                              {/* <span>
                                  <img src={down} alt="" />
                                </span> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-4 text-start my-3 btn-hover-effect">
                          <h6
                            onClick={() => handleGoBack()}
                            className="btn bg-white arrowyellow border-white border-1 rounded-pill position-relative py-lg-3 py-2 ps-5 backbutton"
                          >
                            <span className="px-lg-4 px-2 text-12 fw-semibold d-inline-block">
                              Back
                            </span>
                            <i className="bg-yellow rounded-pill arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                              <img src={blackarrow} alt="" />
                            </i>
                          </h6>
                        </div>

                        <div className="col-4">
                          <SaveAsDraftButton />
                        </div>

                        <div className="col-4 text-end my-3 btn-hover-effect">
                          <button
                            type="submit"
                            className="btn bg-white arrowblue border-white border-1 rounded-pill position-relative py-lg-3 py-2 pe-5"
                          >
                            <span className="px-lg-4 px-2 text-12 fw-semibold d-inline-block">
                              Next
                            </span>
                            <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                              <img src={whitearrow} alt="" />
                            </i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default AddKeyInformation;
