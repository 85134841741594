import React from "react";
import styles from "../../../../../components/work/styles/style.module.css";
import tip from "../../../../../assets/images/tip.png";
import Occupations from "../../../../../assets/images/occupations.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import insc from "../../../../../assets/images/health_inc.svg";
import vacation from "../../../../../assets/images/vacation.png";
import incentive from "../../../../../assets/images/incentive.png";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { RootState } from "../../../../../redux/store";
import {
  setActiveStep,
  setAdditionalFields,
} from "../../../../../redux/reducers/addDirectJob";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import { DIRECT_JOB_STEPS } from "../Enums";

const AddAditionalInfo: React.FC = () => {
  const dispatch = useDispatch();
  const { additionfiels } = useSelector((state: RootState) => state.directJob);
  const {
    isHealthInsurance,
    isSameCitizenship,
    isVacationAndTimeOff,
    workEnvironment,
  } = additionfiels;

  const formIk: any = useFormik({
    initialValues: {},
    onSubmit: () => {},
  });

  const handleGoBack = () => {
    dispatch(setActiveStep(DIRECT_JOB_STEPS.ADD_TECHNICAL_SKILLS));
  };
  const handleGoToNextStep = () => {
    dispatch(setActiveStep(DIRECT_JOB_STEPS.OVERVIEW_FORM));
  };

  const onInputChange = (value: string | boolean, key: string) => {
    dispatch(setAdditionalFields({ ...additionfiels, [key]: value }));
  };

  return (
    <>
      <HeaderContent />
      <div className="py-4 heightautoscroll">
        <div className={styles.signuppage}>
          <div className="contractpages">
            <form className="h-100" onSubmit={formIk.handleSubmit}>
              <div
                className={styles.registerOuter}
                style={{ position: "relative" }}
              >
                <div className="container">
                  <div className={styles.registerBord}>
                    <div className="row align-items-start">
                      <div className="col-md-6">
                        <div className={styles.registerLeft}>
                          <div className="newheadingstyle p-4">
                            <h4 className="text-white text-32 fw-semibold">
                              Additional Fields
                            </h4>
                            <p className="text-16 text-white opacity-75">
                              Add a Legal information of the work that you need
                            </p>
                          </div>
                          <div className="col-lg-11 col-12">
                            <figure className="mb-0">
                              <img
                                src={Occupations}
                                alt=""
                                className="img-fluid"
                              />
                            </figure>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className={styles.registerRight}>
                          <div className="col-12 mx-auto">
                            <div className="form-group">
                              <div className="topTipHover">
                                <label>
                                  Select Work Location{" "}
                                  <sup className="text-danger">*</sup>
                                </label>
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                              </div>

                              <div className="row align-items-center">
                                <div className="col-12">
                                  <div className={styles.dropdownContainer}>
                                    <select
                                      id="dropdown"
                                      className="form-select"
                                      value={workEnvironment}
                                      onChange={(e) =>
                                        onInputChange(
                                          e.target.value,
                                          "workEnvironment"
                                        )
                                      }
                                    >
                                      <option value="">Select...</option>
                                      <option value="remote">Remote</option>
                                      <option value="hybrid">Hybrid</option>
                                      <option value="onsite">Onsite</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="form-group mt-3">
                              <label>
                                <input type="checkbox" className="" />
                                Same citizenship is required for the job
                              </label>
                            </div>
                            <div className="form-group mt-3">
                              <div className="topTipHover">
                                <label>Health Insurance</label>
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                              </div>
                              <div className="row align-items-center bg-lightwhiteblue p-3 rounded-4">
                                <div className="col-1 text-center px-0">
                                  <i> 
                                    <img src={insc} alt="" />
                                  </i>
                                </div>
                                <div className="col-11">
                                  <label className="startlabel text-14">
                                  Comprehensive health insurance coverage available upon permanent employment.
                                  </label>                              
                                </div>
                              </div>
                            </div>
                            <div className="form-group mt-3">
                              <div className="topTipHover">
                                <label>Vacation and Time Off</label>
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                              </div>
                              <div className="row align-items-center bg-lightwhiteblue p-3 rounded-4">
                                <div className="col-1 text-center px-0">
                                  <i> 
                                    <img src={vacation} alt="" />
                                  </i>
                                </div>
                                <div className="col-11">
                                  <label className="startlabel text-14">
                                      Comprehensive health insurance coverage available upon permanent employment.
                                  </label>                              
                                </div>
                              </div>
                            </div>
                            <div className="form-group mt-3">
                              <div className="topTipHover">
                                <label>Other Benefits</label>
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                              </div>
                              <div className="row align-items-center bg-lightwhiteblue p-3 rounded-4">
                                <div className="col-1 text-center px-0">
                                  <i> 
                                    <img src={incentive} alt="" />
                                  </i>
                                </div>
                                <div className="col-11">
                                  <label className="startlabel text-14">
                                      Comprehensive health insurance coverage available upon permanent employment.
                                  </label>                              
                                </div>
                              </div>
                            </div>
                            {/* <div>
                              
                              <div className={styles.ratedRadio}>
                                <label>Same Citizenship Required ?</label>
                                <div className={styles.rateradio}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="isSameCitizenship"
                                      checked={isSameCitizenship}
                                      onChange={() =>
                                        onInputChange(true, "isSameCitizenship")
                                      }
                                    />
                                    <label className="form-check-label">
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="isSameCitizenship"
                                      checked={!isSameCitizenship}
                                      onChange={() =>
                                        onInputChange(
                                          false,
                                          "isSameCitizenship"
                                        )
                                      }
                                    />
                                    <label className="form-check-label">
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className={styles.ratedRadio}>
                                <label>Health Insurance</label>
                                <div className={styles.rateradio}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="isHealthInsurance"
                                      checked={isHealthInsurance}
                                      onChange={() =>
                                        onInputChange(true, "isHealthInsurance")
                                      }
                                    />
                                    <label className="form-check-label">
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="isHealthInsurance"
                                      checked={!isHealthInsurance}
                                      onChange={() =>
                                        onInputChange(
                                          false,
                                          "isHealthInsurance"
                                        )
                                      }
                                    />
                                    <label className="form-check-label">
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className={styles.ratedRadio}>
                                <label>Vacation and Time Off</label>
                                <div className={styles.rateradio}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="isVacationAndTimeOff"
                                      checked={isVacationAndTimeOff}
                                      onChange={() =>
                                        onInputChange(
                                          true,
                                          "isVacationAndTimeOff"
                                        )
                                      }
                                    />
                                    <label className="form-check-label">
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="isVacationAndTimeOff"
                                      checked={!isVacationAndTimeOff}
                                      onChange={() =>
                                        onInputChange(
                                          false,
                                          "isVacationAndTimeOff"
                                        )
                                      }
                                    />
                                    <label className="form-check-label">
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className={styles.ratedRadio}>
                                <label>Other Benefits</label>
                                <div className={styles.dropdownContainer}>
                                  <select id="dropdown" className="form-select">
                                    <option value="">Select...</option>
                                    <option value=" retirement plans">
                                      {" "}
                                      Retirement Plans
                                    </option>
                                    <option value="bonuses">Bonuses</option>
                                  </select>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                        <div className="row align-items-center justify-content-between mt-3">
                          <div className="col-4 text-start my-3 btn-hover-effect">
                            <h6
                              onClick={() => handleGoBack()}
                              className="btn bg-white arrowyellow border-grey border-1 rounded-pill position-relative py-lg-3 py-2 ps-5 backbutton"
                            >
                              <i className="bg-yellow rounded-pill arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                                <img src={blackarrow} alt="" />
                              </i>
                              <span className="px-lg-4 px-2 text-12 fw-semibold">
                                Back
                              </span>
                            </h6>
                          </div>
                          <div className="col-8">
                            <div className="row align-items-center jusify-content-end">
                              <div className="col-12 text-end my-3 btn-hover-effect">
                                <h6
                                  onClick={handleGoToNextStep}
                                  className="btn bg-white arrowblue border-grey border-1 rounded-pill position-relative py-lg-3 py-2 pe-5"
                                >
                                  <span className="px-lg-4 px-2 text-12 fw-semibold">
                                    Next
                                  </span>
                                  <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                                    <img src={whitearrow} alt="" />
                                  </i>
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAditionalInfo;
