import React, { useEffect, useState } from 'react'
import info from "../../../assets/images/info_icon.svg"
import styles from "../styles/style.module.css"
import logo from "../../../assets/images/logo.png"
import Header from '../../header/routes'
import { getMSAAgrement } from '../api'
import { useParams } from 'react-router-dom'
import moment from "moment";
import { toast } from 'react-toastify'

const MSAAggrement = () => {
    const { id, userId } = useParams();
    const [MSA, setMSA] = useState<any>();

    useEffect(() => {
        MSADataList();
    }, []);


    const MSADataList = async () => {
        try {
            const response: any = await getMSAAgrement(id, userId);
            if (response && response.data) {
                setMSA(response.data);
            } else {
                throw new Error('No data found');
            }
        } catch (error: any) {
            toast.error(error.data.message);

        }
    };
    return (
        <>
            <Header />
            <div className={styles.aggremtn}>
                <div className='container'>
                    <div className={styles.agreeHead}>
                        <h4>Master Service Agreement  <img src={info} alt="" /></h4>
                        <div className={styles.greBtn}>
                            <button className='cmn-tbn'>Download MSA</button>

                        </div>
                    </div>

                    <div className={styles.agrementUper}>
                        <div className={styles.AgreBrd}>
                            <div className={styles.AgreBrdHead}>
                                <img src={logo} alt="" />
                            </div>
                            <div className={styles.AgreBrdname}>
                                <h5>Master Service Agreement</h5>
                            </div>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className={styles.leftAgremnt}>
                                        <span className={styles.maindeHd}> Details</span>
                                        <ul>
                                            <li><span>Work Number: </span> <label>{MSA?.work?.workNumber}</label></li>
                                            <li><span>Account Manager: </span> <label>{"jachob"}</label></li>
                                            <li><span>Account Manager Phone: </span> <label>{"+91-8765434567"}</label></li>
                                            <li><span>Account Manager Email: </span> <label>{"jachob@gmail.com"}</label></li>
                                        </ul>
                                    </div>
                                    <div className={styles.leftAgremnt}>
                                        <span className={styles.maindeHd}> Job Site Locations</span>
                                        <ul>
                                            <li><span>State: </span> <label>{MSA?.work?.state}</label></li>
                                            <li><span>City: </span> <label>{MSA?.work?.city}</label></li>
                                            <li><span>Street Address: </span> <label>{MSA?.work?.address}</label></li>
                                            <li><span>Location Notes: </span> <label>10086478</label></li>
                                        </ul>
                                    </div>
                                    <div className={styles.leftAgremnt}>
                                        <span className={styles.maindeHd}> Professional Contact Info</span>
                                        <ul>
                                            <li><span>Professional Name: </span> <label>{MSA?.professional?.name}</label></li>
                                            <li><span>Professional Phone: </span> <label>{MSA?.professional?.phoneNumber}</label></li>
                                            <li><span>Professional Email: </span> <label>{MSA?.professional?.email}</label></li>
                                        </ul>
                                    </div>
                                    <div className={styles.leftAgremnt}>
                                        <span className={styles.maindeHd}> Customer Contact Info </span>
                                        <ul>
                                            <li><span>Company Name: </span> <label>{MSA?.Customer?.name}</label></li>
                                            <li><span>Company Group: </span> <label></label></li>
                                            <li><span>Customer Name: </span> <label></label></li>
                                            <li><span>Customer Phone: </span> <label></label></li>
                                            <li><span>Customer Email: </span> <label></label></li>
                                            <li><span>Account Payable Name: </span> <label></label></li>
                                            <li><span>Account Payable Phone: </span> <label></label></li>
                                            <li><span>Account Payable Email: </span> <label></label></li>
                                            <li><span>AP Name #2: </span> <label></label></li>
                                            <li><span>AP Phone #2:</span> <label></label></li>
                                            <li><span>AP Email #2:</span> <label></label></li>
                                        </ul>
                                    </div>
                                    <div className={styles.leftAgremnt}>
                                        <span className={styles.maindeHd}> Safety Equipment Required </span>
                                        <p>Standard PPE ( Safety glasses, Shoes, ear protection ) </p>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className={styles.leftAgremnt}>
                                        <span className={styles.maindeHd}> Application Date</span>
                                        <ul>
                                            <li><span>Application Start Date: </span> <label>{moment(MSA?.times?.startDate).format("MMM DD, YYYY")}</label></li>
                                            <li><span>Application End Date: </span> <label>{moment(MSA?.times?.endDate).format("MMM DD, YYYY")}</label></li>
                                            <li><span>Planned Days/week: </span> <label>{MSA?.work?.times?.plannedDaysPerWeek}</label></li>
                                            <li><span>Planned Hours/Day: </span> <label>{MSA?.work?.times?.plannedHoursPerDay}</label></li>
                                        </ul>
                                    </div>
                                    <div className={styles.leftAgremnt}>
                                        <span className={styles.maindeHd}> Customer Bill Rate</span>
                                        <ul>
                                            <li><span>Payment Term: </span> <label>{MSA?.hourlyPayment?.paymentTerm}</label></li>
                                            <li><span>Base Rate: </span> <label>${MSA?.work?.rates?.baseRate}</label></li>
                                            <li><span>OT Rate: </span> <label>${MSA?.work?.rates?.overTimeRate}</label></li>
                                            <li><span>DT Rate: </span> <label>${MSA?.work?.rates?.doubleTimeRate}</label></li>
                                            <li><span>Travel Time Rate: </span> <label>{MSA?.hourlyPayment?.travelTimeRate}</label></li>
                                            <li><span>Base Rate Hours: </span> <label>{MSA?.hourlyPayment?.baseRate}</label></li>
                                            <li><span>OT Hours: </span> <label>{MSA?.hourlyPayment?.overTimeRate}</label></li>
                                            <li><span>DT Hours: </span> <label>{MSA?.hourlyPayment?.doubleTimeRate}</label></li>
                                        </ul>
                                    </div>
                                    <div className={styles.leftAgremnt}>
                                        <span className={styles.maindeHd}>Expense Paid:</span>
                                        <ul>
                                            <li><span>Daily Per Diem: </span> <label>{MSA?.work?.expense?.dailyPerDiem}</label></li>
                                            <li><span>Travel Day Per Diem: </span> <label>{MSA?.work?.expense?.travelDayPerDiem}</label></li>
                                            {/* <li><span>Travel Trips Allowed(per month): </span> <label>{MSA?.work?.expense?.travelDayPerDiem}</label></li>
                                            <li><span>Travel Hours Per Round Trip: </span> <label>{MSA?.work?.expense?.travelDayPerDiem}</label></li> */}
                                            <li><span>Hotel Allowance/Day: </span> <label>{MSA?.work?.expense?.hotelAllowance}</label></li>
                                            <li><span>Rental Car Allowance/Day: </span> <label>{MSA?.work?.expense?.rentalCarAllowance}</label></li>
                                            <li><span>Fuel: </span> <label>{MSA?.work?.expense?.fuel}</label></li>
                                            <li><span>Airfare Allowance: </span> <label>{MSA?.work?.expense?.airfareAllowance}</label></li>
                                            <li><span>Mileage Rate/Mile: </span> <label>{MSA?.work?.expense?.mileage}</label></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {MSA?.htmlText ?
                            <div dangerouslySetInnerHTML={{
                                __html: MSA?.htmlText
                            }} />
                            :
                            <div className={styles.agreDisc}>
                                <h5>Master Services Agrement</h5>
                                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Est, soluta eum quae odio sunt voluptate, amet harum temporibus id rerum ex, alias debitis optio error enim architecto consectetur voluptatem laborum?</p>

                                <h6>1. Services to be Performed</h6>
                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore debitis ipsum maxime. Itaque quae, cumque asperiores eligendi ea, ex rem earum ad maiores officia dolore recusandae atque commodi ipsam aliquid!</p>

                                <h6>2. Tax Identification Number</h6>
                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore debitis ipsum maxime. Itaque quae, cumque asperiores eligendi ea, ex rem earum ad maiores officia dolore recusandae atque commodi ipsam aliquid!</p>

                                <h6>3. Terms of MSA</h6>
                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore debitis ipsum maxime. Itaque quae, cumque asperiores eligendi ea, ex rem earum ad maiores officia dolore recusandae atque commodi ipsam aliquid!</p>

                                <h6>4. Payment</h6>
                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore debitis ipsum maxime. Itaque quae, cumque asperiores eligendi ea, ex rem earum ad maiores officia dolore recusandae atque commodi ipsam aliquid!</p><p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Est, soluta eum quae odio sunt voluptate, amet harum temporibus id rerum ex, alias debitis optio error enim architecto consectetur voluptatem laborum?</p>

                                <h6>5. Terms of Payment</h6>
                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore debitis ipsum maxime. Itaque quae, cumque asperiores eligendi ea, ex rem earum ad maiores officia dolore recusandae atque commodi ipsam aliquid!</p>

                                <h6>6. Confidentiality of Payment Arrangements</h6>
                                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore debitis ipsum maxime. Itaque quae, cumque asperiores eligendi ea, ex rem earum ad maiores officia dolore recusandae atque commodi ipsam aliquid!</p><p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Est, soluta eum quae odio sunt voluptate, amet harum temporibus id rerum ex, alias debitis optio error enim architecto consectetur voluptatem laborum?</p>

                                {/* <h6>1. Services to be performed</h6>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore debitis ipsum maxime. Itaque quae, cumque asperiores eligendi ea, ex rem earum ad maiores officia dolore recusandae atque commodi ipsam aliquid!</p>

                            <h6>1. Services to be performed</h6>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Labore debitis ipsum maxime. Itaque quae, cumque asperiores eligendi ea, ex rem earum ad maiores officia dolore recusandae atque commodi ipsam aliquid!</p> */}

                            </div>}

                    </div>
                </div>
            </div>
        </>
    )
}

export default MSAAggrement