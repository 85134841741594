import React, { FC, useState } from 'react';
import HeaderContent from '../../shared/Components/HeaderContent/headerContent';
import styles from '../../components/work/styles/style.module.css';
import { useNavigate } from 'react-router-dom';
import oppertunitybanner from '../../assets/images/oppertunity_banner.png';
import blackarrow from '../../assets/images/blackarrow.svg';
import whitearrow from '../../assets/images/whitearrow.svg';
import tip from '../../assets/images/tip.png';
import { CONSTANT_MANAGER } from '../../utils/constans/constants';
import Toltip from '../../components/common/toltip/page';
import { APP_ROUTES } from '../../utils/routes/AppRoutes';
import storage from '../../utils/storage';

const ChoosePostType: FC = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState<string>(
    APP_ROUTES.ADD_HOURLY_CONTRACT
  );

  const onInputChange = (step: string): void => setActiveStep(step);

  const goToNextStep = (): void => {
    if (activeStep === APP_ROUTES.ADD_HOURLY_CONTRACT) {
      storage.clearSavedContract();
    }

    navigate(activeStep);
  };

  const handleGoBack = () => {
    navigate(APP_ROUTES.DASHBOARD);
  };

  return (
    <>
      <HeaderContent />

      <div className="heightautoscroll contractradio py-4">
        <div className={styles.signuppage}>
          {/* <div className="bgbluegradient oppertunitybanner"> */}
          <div className="col-lg-11 col-12 mx-auto">
            <div className="contractpages ">
              <form className="h-100">
                <div className={styles.registerOuter}>
                  <div className="container">
                    <div className={styles.registerBord}>
                      <div className="row pt-5 h-100 align-items-center">
                        <div className="col-lg-5">
                          <div className={styles.registerLeft}>
                            <h4 className="text-white text-32 fw-semibold">
                              What Opportunity Do
                              <br /> You Want To Post?
                            </h4>
                            <p className="text-white text-14 opacity-75">
                              Choose the opportunity you need to post
                            </p>
                            <figure className="mb-0">
                              <img
                                src={oppertunitybanner}
                                alt=""
                                className="img-fluid"
                              />
                            </figure>
                          </div>
                        </div>
                        <div className="col-lg-7 col-12">
                          <div className={styles.signUPContainer}>
                            
                            <div className={styles.registerRight}>
                              <ul className={`customRadio ${styles.radioBTn}`}>
                                <li className="">
                                  <div className="form-check ps-0">
                                    <label className="form-check-label">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id={
                                          CONSTANT_MANAGER.ADD_CONTRACT
                                            .HOURLY_CONTRACT
                                        }
                                        onChange={() =>
                                          onInputChange(
                                            APP_ROUTES.ADD_HOURLY_CONTRACT
                                          )
                                        }
                                        checked={
                                          activeStep ===
                                          APP_ROUTES.ADD_HOURLY_CONTRACT
                                        }
                                      />{' '}
                                      <div className="topTipHover">
                                        <div className="d-flex align-items-center">
                                          <h4 className="form-check-label text-20 fw-bold text-white font-inter">
                                            Hourly Contract
                                          </h4>
                                          <p></p>
                                          <i>
                                            <img
                                              className="customeTolImg"
                                              src={tip}
                                              alt=""
                                            />
                                          </i>
                                          <Toltip
                                            slug={
                                              CONSTANT_MANAGER.SLUG.ADD_CONTRACT
                                            }
                                            labelId={
                                              CONSTANT_MANAGER.ADD_CONTRACT
                                                .HOURLY_CONTRACT
                                            }
                                          />
                                        </div>
                                      </div>
                                    </label>
                                  </div>
                                </li>
                                <li className="">
                                  <div className="form-check ps-0">
                                    <label className="form-check-label">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id={
                                          CONSTANT_MANAGER.ADD_CONTRACT.DIRECT_JOB
                                        }
                                        onChange={() =>
                                          onInputChange(
                                            APP_ROUTES.ADD_DIRECT_JOBS
                                          )
                                        }
                                        checked={
                                          activeStep ===
                                          APP_ROUTES.ADD_DIRECT_JOBS
                                        }
                                      />
                                      <div className="topTipHover">
                                        <div className="d-flex align-items-center">
                                          <h4 className="form-check-label text-20 fw-bold text-white font-inter">
                                            Direct Job
                                          </h4>

                                          <i>
                                            <img
                                              className="customeTolImg"
                                              src={tip}
                                              alt=""
                                            />
                                          </i>
                                          <Toltip
                                            slug={
                                              CONSTANT_MANAGER.SLUG.ADD_CONTRACT
                                            }
                                            labelId={
                                              CONSTANT_MANAGER.ADD_CONTRACT
                                                .DIRECT_JOB
                                            }
                                          />
                                        </div>
                                      </div>
                                      <p
                                        className="text-14 fw-normal text-white mb-0"
                                        style={{ zIndex: '0' }}
                                      >
                                        I am looking to hire a direct
                                        <br /> employee.
                                      </p>
                                    </label>
                                  </div>
                                </li>

                              <li className="">
                                <div className="form-check ps-0">
                                  <label className="form-check-label">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id={
                                        CONSTANT_MANAGER.ADD_CONTRACT.DIRECT_JOB
                                      }
                                      onChange={() =>
                                        onInputChange(APP_ROUTES.ADD_PAID_ADS)
                                      }
                                      checked={
                                        activeStep === APP_ROUTES.ADD_PAID_ADS
                                      }
                                    />
                                    <div className="topTipHover">
                                      <div className="d-flex align-items-center">
                                        <h4 className="form-check-label text-20 fw-bold text-white font-inter">
                                          Paid Ad
                                        </h4>
                                        <i>
                                          <img
                                            className="customeTolImg"
                                            src={tip}
                                            alt=""
                                          />
                                        </i>
                                        <Toltip
                                          slug={
                                            CONSTANT_MANAGER.SLUG.ADD_CONTRACT
                                          }
                                          labelId={
                                            CONSTANT_MANAGER.ADD_CONTRACT
                                              .PAID_AD.DATA.PAID_AD
                                          }
                                        />
                                      </div>
                                    </div>
                                    <p
                                      className="text-14 fw-normal text-white mb-0"
                                      style={{ zIndex: '0' }}
                                    >
                                      Post work that you want
                                      <br /> quoted
                                    </p>
                                  </label>
                                </div>
                              </li>
                              <li className="">
                                <div className="form-check ps-0">
                                  <label className="form-check-label">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id={
                                        CONSTANT_MANAGER.ADD_CONTRACT
                                          .SOCIAL_POST_FOR_FEED.DATA.SOCIAL_POST_FOR_FEED
                                      }
                                      onChange={() =>
                                        onInputChange(
                                          APP_ROUTES.ADD_SOCIAL_POST
                                        )
                                      }
                                      checked={
                                        activeStep ===
                                        APP_ROUTES.ADD_SOCIAL_POST
                                      }
                                    />
                                    <div className="topTipHover">
                                      <div className="d-flex align-items-center">
                                        <h4 className="form-check-label text-20 fw-bold text-white font-inter">
                                          Social Post For Feed
                                        </h4>
                                        <i>
                                          <img
                                            className="customeTolImg"
                                            src={tip}
                                            alt=""
                                          />
                                        </i>
                                        <Toltip
                                          slug={
                                            CONSTANT_MANAGER.SLUG.ADD_CONTRACT
                                          }
                                          labelId={
                                            CONSTANT_MANAGER.ADD_CONTRACT
                                              .SOCIAL_POST_FOR_FEED.DATA.SOCIAL_POST_FOR_FEED
                                          }
                                        />
                                      </div>
                                    </div>
                                    <p
                                      className="text-14 fw-normal text-white mb-0"
                                      style={{ zIndex: '0' }}
                                    >
                                      Post your Social Posts
                                      <br /> here!
                                    </p>
                                  </label>
                                </div>
                              </li>
                              {/* <li className="col-lg-6 col-12">
                                <div className="form-check ps-0">
                                  <label className="form-check-label">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="flexRadioDefault"
                                      id="flexRadioDefault1"
                                      // onChange={() => onInputChange("directJob")}
                                      // checked={contractType === "directJob"}
                                    />
                                    <h4 className="form-check-label text-20 fw-bold text-white font-inter">
                                      Post RFQ
                                    </h4>
                                    <p className="text-14 fw-normal text-white mb-0">
                                      Post work that you want
                                      <br /> quoted
                                    </p>
                                  </label>
                                </div>
                              </li> */}

                                <li className="">
                                  <div className="form-check ps-0">
                                    <label className="form-check-label">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="flexRadioDefault"
                                        id={
                                          CONSTANT_MANAGER.ADD_CONTRACT
                                            .POST_YOUR_AD
                                        }
                                        // onChange={() => onInputChange("directJob")}
                                        // checked={contractType === "directJob"}
                                      />
                                      <div className="topTipHover">
                                        <div className="d-flex align-items-center">
                                          <h4 className="form-check-label text-20 fw-bold text-white font-inter">
                                            Post your Ads
                                          </h4>
                                          <i>
                                            <img
                                              className="customeTolImg"
                                              src={tip}
                                              alt=""
                                            />
                                          </i>
                                          <Toltip
                                            slug={
                                              CONSTANT_MANAGER.SLUG.ADD_CONTRACT
                                            }
                                            labelId={
                                              CONSTANT_MANAGER.ADD_CONTRACT
                                                .POST_YOUR_AD
                                            }
                                          />
                                        </div>
                                      </div>
                                      <p
                                        className="text-14 fw-normal text-white mb-0"
                                        style={{ zIndex: '0' }}
                                      >
                                        Post work that you want
                                        <br /> quoted
                                      </p>
                                    </label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="container">
                            <div className="row align-items-center justify-content-bewteen g-0 mt-4">
                              {/* <div className="btn" onClick={() => handleGoBack()}>
                              <span>‹ Back</span>
                            </div> */}
                              <div className="col-6 my-3 btn-hover-effect">
                                <h6
                                  onClick={() => handleGoBack()}
                                  className="btn bg-white arrowyellow border-white border-1 rounded-pill position-relative py-lg-3 py-2 ps-5 backbutton"
                                >
                                  <i className="bg-yellow rounded-pill arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                                    <img src={blackarrow} alt="" />
                                  </i>
                                  <span className="px-lg-4 px-2 text-12 fw-semibold">
                                    Back
                                  </span>
                                </h6>
                              </div>
                              {/* <div className="btn"> */}
                              {/* <button onClick={() => goToNextStep()}>
                                Next ›
                              </button> */}
                              <div className="col-6 text-end my-3 btn-hover-effect">
                                <h6
                                  onClick={() => goToNextStep()}
                                  className="btn bg-white arrowblue border-white border-1 rounded-pill position-relative py-lg-3 py-2 pe-5"
                                >
                                  <span className="px-lg-4 px-2 text-12 fw-semibold">
                                    Next
                                  </span>
                                  <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                                    <img src={whitearrow} alt="" />
                                  </i>
                                </h6>
                              </div>
                              {/* </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChoosePostType;
