import { axios } from '../../../lib/axios';
import { AxiosResponse } from '../../../shared/models';
import { API_ROUTES } from '../../../utils/routes/apiRoutes';
import { PaymentTermResponse } from '../interface';

export const getPaymentTerms = async (): Promise<AxiosResponse<PaymentTermResponse>
> => {
  return axios.get(API_ROUTES.PAYMENT_TERM.GET_PAYMENT_TERMS);
};


export const getPaymentTermsByWorkId = async (id:string): Promise<AxiosResponse<PaymentTermResponse>
> => {
  return axios.get(`${API_ROUTES.PAYMENT_TERM.GET_PAYMENT_TERMS}/${id}`);
};