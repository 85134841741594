import React from "react";
import styles from "../styles/style.module.css";
import Plus from "../../../assets/images/plus.png";
import info from "../../../assets/images/info_icon.svg";
import Postads from "../../post&ads/routes";
import { getAdvertise } from "../api";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../../utils/routes/AppRoutes";
import Toltip from "../../common/toltip/page";
import tip from "../../../assets/images/tip.png";
import { CONSTANT_MANAGER } from "../../../utils/constans/constants";
const PlaceAndAds = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.postandads}>
      <div className="container">
        <div className={styles.new_feeds}>
          <div className="">
            <div className="row align-items-center justify-content-between mb-3">
              <div className="col-sm-6 col-12">
                <div className="newheadingstyle">
                  <div className="topTipHover">
                    <div className="d-flex align-items-center">
                      <h4 className="form-check-label text-20 fw-bold  font-inter">
                        Place & Ads
                      </h4>
                      <i>
                        <img className="customeTolImg" src={tip} alt="" />
                      </i>
                      <Toltip
                        slug={CONSTANT_MANAGER.SLUG_FEED.FEED}
                        labelId={CONSTANT_MANAGER.FEED_DATA.PLACE_ADS}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-12">
                <div className="text-end">
                  <div className="col-xl-4 col-md-11 col-6 ms-auto">
                    <button
                      className="btn btn-primary h-40 text-14"
                      onClick={() => navigate(APP_ROUTES.ADD_PAID_ADS)}
                    >
                      <i className="me-2">
                        <img src={Plus} alt="" width={10} height={10} />
                      </i>
                      Place an Ads
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <Postads />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlaceAndAds;
