export enum CONTRACT_Type {
  HOURLY_CONTRACT = "hourly_contract",
  DIRECT_JOB = "direct_job",
  APPLICATIONS = "applications",
  RFQ = "rfq",
}

export enum WORK_APPLY_TYPE {
  SHORTLISTED = 'shortlisted',
  REJECTED = "rejected",
  APPLIED = "applied",
  RECEIVED = "received",
  SELECTED = "selected",
}

export enum POST_TYPE {
  ADVERTISEMENT = "advertisement",
  FREE = "free",
  ADMIN = "admin",
}
export enum COUNT_TYPE {
  WORK_APPLY = "workapply",
  WORK_COUNT = "workcounts",
  REPORTS = "reports",
  FOR_ME = "forme",
  BY_ME = "byme",
}
export enum PLAN_TYPE {
  WHITEGLOVE = "whiteglove",
  STANDARD = "standard",
}

export enum EVENT_TYPE {
  INTERVIEW = "interview",
  OTHER = "other",
}

export enum INTERVIEW_TYPE {
  PHONE_CALL = 'phonecall',
  VIDEO = 'video',
  IN_PERSON = 'inPerson'
}

export enum TYPE {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  ALL = "all",
  UPCOMING = "upcoming",
  COMPLETED = "completed",
}

export enum AD_TYPE {
  TECH_SCHOOL_AD = 'techschooladvertisements',
}

export enum BOOST_TYPE {
  CONTRACT = "contract",
  POST = 'post',
  PROFILE = 'profile',
}

export enum PAYMENT_STATUS {
  PENDING = 'pending',
  SUCCESS = 'success',
  FAILED = 'failed',
}