import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styles from '../../css/styles/style.module.css';
import download from '../../../../assets/images/download.svg';
import print from '../../../../assets/images/print.svg';
import share from '../../../../assets/images/share.svg';
import { getWorkDetailWithCount } from '../api';
import { APP_ROUTES } from '../../../../utils/routes/AppRoutes';
import { COUNT_TYPE } from '../../../../utils/enums/types';
import WhiteGloveContractDetails from '../whiteGloveHourlyContract/whileGloveHourlyContract';

const OngoingContractDetails = () => {
  const [workDetails, setWorkDetails] = useState<any>();
  const location=useLocation();
  const ongoingContractsDetails=location?.state?.data
  const [currentOngoingData, setCurrentOngoingData] = useState<any>([])
  const { id } = useParams();
  const navigate = useNavigate();


  const getWorkDetials = async () => {
    const response: any = await getWorkDetailWithCount(  id, COUNT_TYPE.WORK_COUNT);
    setWorkDetails(response?.data);
  };

  useEffect(() => {
    getWorkDetials();
    setCurrentOngoingData(ongoingContractsDetails)
  }, []);


  const handlGoToTimesheetDetails = () => {
    if (location?.state?.professionalFlag === true) {
      navigate(`${APP_ROUTES.TIMESHEET_TABLE}/${workDetails?._id}`,{state:{currentOngoingData}});
    } else {
      navigate(`${APP_ROUTES.TIMESHEETDETAIL_TABLE}/${workDetails?._id}`);
    }
  };
  const handlGoToExpenseDetails = () => {
    if (location?.state?.professionalFlag === true) {
      navigate(`${APP_ROUTES.EXPENSE_TABLE}/${workDetails?._id}`);
    } else {
      navigate(`${APP_ROUTES.EXPENSE_STATUS_TABLE}/${workDetails?._id}`);
    }
  };
  const handlGoToInvoiceDetails = () => {
    if (location?.state?.professionalFlag === true) {
      navigate(`${APP_ROUTES.INVOICE_TABLE}/${workDetails?._id}`);
    } else {
      navigate(`${APP_ROUTES.INVOICE_TABLE}/${workDetails?._id}`);
    }
  };
  return (
    <div className="Contracts OngoingContractDetails">
      <div className="container">
        <div className={styles.OngoingContractDetails}>
          <div className={styles.ContractTabs}>
            <div className={styles.AllContract}>
              <h5>Contract ID : #{workDetails?.workNumber}</h5>
              <div className={styles.leftContract}>
                <button className={styles.contractbtn}>
                  <i className="me-2">
                    <img src={download} alt="" />
                  </i>
                  Download
                </button>
                <button className={styles.contractbtn}>
                  <i className="me-2">
                    <img src={print} alt="" />
                  </i>
                  Print
                </button>
                <button className={styles.contractbtn}>
                  <i className="me-2">
                    <img src={share} alt="" />
                  </i>
                  Share
                </button>
              </div>
            </div>
            <div className={styles.OuterSlides}>
              <div className="">
                <div className="col-12">
                  <div className={styles.ContarctOuter}>
                    <WhiteGloveContractDetails ongoingContractDetails={currentOngoingData} />
                    <div className={styles.timeSheets}>
                      <div className="row">
                        <div
                          className="col-md-3 col-6"
                        >
                          <div className={styles.timesheetData} onClick={() => handlGoToTimesheetDetails()}>
                            <h4 className="fw-bold">Timesheet</h4>
                            <ul>
                              <li>
                                <span>Current Timesheet</span>{' '}
                                <label>{workDetails?.timesheet?.current}</label>
                              </li>
                              <li className={styles.red}>
                                <span>Due Timesheet</span>{' '}
                                <label>{workDetails?.timesheet?.due}</label>
                              </li>
                              <li className={styles.yeloo}>
                                <span>Pending</span>{' '}
                                <label>{workDetails?.timesheet?.pending}</label>
                              </li>
                              <li className={styles.gren}>
                                <span>Approved</span>{' '}
                                <label>
                                  {workDetails?.timesheet?.approved}
                                </label>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-md-3 col-6">
                          <div className={styles.timesheetData} onClick={() => handlGoToExpenseDetails()}>
                            <h4 className="fw-bold">Expenses</h4>
                            <ul>
                              <li>
                                <span>Current Expense</span>{' '}
                                <label>{workDetails?.expense?.current}</label>
                              </li>
                              <li className={styles.red}>
                                <span>Due Expense</span>{' '}
                                <label>{workDetails?.expense?.current}</label>
                              </li>
                              <li className={styles.yeloo}>
                                <span>Pending</span>{' '}
                                <label>{workDetails?.expense?.current}</label>
                              </li>
                              <li className={styles.gren}>
                                <span>Approved</span>{' '}
                                <label>{workDetails?.expense?.current}</label>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-md-3 col-6">
                          <div className={styles.timesheetData} onClick={() => handlGoToInvoiceDetails()}>
                            <h4 className="fw-bold">Invoices</h4>
                            <ul>
                              <li className={styles.red}>
                                <span>Due </span>{' '}
                                <label>{workDetails?.invoice?.due}</label>
                              </li>
                              <li className={styles.yeloo}>
                                <span>Past Due</span>{' '}
                                <label>{workDetails?.invoice?.pastDue}</label>
                              </li>
                              <li className={styles.gren}>
                                <span>Paid</span>{' '}
                                <label>{workDetails?.invoice?.isPaid}</label>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-md-3 col-6">
                          <div className={styles.timesheetData}>
                            <h4 className="fw-bold">Purchase Orders</h4>
                            <ul>
                              <li>
                                <span>Current Order</span>{' '}
                                <label>{workDetails?.invoice?.pastDue}</label>
                              </li>
                              <li className={styles.red}>
                                <span>Due Order</span>{' '}
                                <label>{workDetails?.invoice?.pastDue}</label>
                              </li>
                              <li className={styles.yeloo}>
                                <span>Pending</span>{' '}
                                <label>{workDetails?.invoice?.pastDue}</label>
                              </li>
                              <li className={styles.gren}>
                                <span>Approved</span>{' '}
                                <label>{workDetails?.invoice?.pastDue}</label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="text-center py-3">
                        {location?.state?.professionalFlag === true ? (
                          <button
                            onClick={() =>
                              navigate(
                                `${APP_ROUTES.MSAAGGREMENT}/${workDetails?._id}/${ongoingContractsDetails?.userInfo?._id}`
                              )
                            }
                            className="btn btn-primary w-50 mx-auto"
                          >
                            View MSA
                          </button>
                        ) : (
                          <button
                            onClick={() =>
                              navigate(
                                `${APP_ROUTES.SSAAGGREMENT}/${workDetails?._id}`
                              )
                            }
                            className="btn btn-primary w-50 mx-auto"
                          >
                            View SSA
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OngoingContractDetails;
