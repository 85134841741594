import React from 'react';
import styles from "../styles/style.module.css";
import HeaderContent from "../../../shared/Components/HeaderContent/headerContent";
import { Link } from 'react-router-dom';
import profile from "../../../assets/images/pro.png";


const MyDashboards = () => {
  return (
    <div>
        <HeaderContent />
      <section className={styles.dashboard_sec}>
      <div className="container">
      <div className="row align-items-center mb-4">
        <div className="col-lg-6">
          <h1>Onging Contracts</h1>
        </div>

        <div className="col-lg-6">
          <ul className={`nav ${styles.nav_short}`}>
            <li>
            <div className="dropdown">
              <button type="button" className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown">
              <i className="fas fa-sort-amount-down-alt pe-1"></i> Sort
              </button>
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" href="#">Link 1</a></li>
                <li><a className="dropdown-item" href="#">Link 2</a></li>
                <li><a className="dropdown-item" href="#">Link 3</a></li>
              </ul>
            </div>
            </li>

            <li>
            <div className="dropdown">
              <button type="button" className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown">
              <i className="fas fa-sort-amount-down-alt pe-1"></i> Newest
              </button>
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" href="#">Link 1</a></li>
                <li><a className="dropdown-item" href="#">Link 2</a></li>
                <li><a className="dropdown-item" href="#">Link 3</a></li>
              </ul>
            </div>
            </li>

          </ul>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6">
          <div className={styles.card_contract}>
            <div className={styles.card_headers}>
              <div className={styles.card_left_c}>
                <h3>Contract ID : #457</h3>
                <p>Start Date : 12 Jan 2024</p>
              </div>
              <div className={styles.card_ongoing}>
                <button type="button" className={`btn ${styles.ongoing}`}>Ongoing</button>
              </div>
            </div>
            <div className={styles.contract_details}>
              <div className={styles.flex_details}>
                <p>Contractor Details</p>
                <p>Working: Johns Yani</p>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <div className={`d-flex align-items-center ${styles.border_rr}`}>
                  <div className={styles.flex_imageProfile}>
                     <img src={profile} alt="" className="img-fluid"/>
                  </div>
                  <div className={styles.flex_cintent_ic}>
                    <h4>Bradely Innes</h4>
                    <p>Pia Automation . inc</p>
                    <h6> <i className="fas fa-map-marker-alt pe-1"></i> Evansville. IN</h6>
                  </div>
                  </div>
                </div>
                <div className="col-lg-6">
                <div className={styles.flex_cintent}>
                <p>Start Date: 10/18/2023</p>
                <p> End Date: 12/18/2023</p>
                <p> Rate Per Hour : $70.84(net 15)</p>
                <p>Expense Terms : Net 15</p>
                </div>
                </div>
              </div>
            </div>

            <div className={styles.card_body}>
              <div className="row">
                <div className="col-lg-6">
                  <div className={styles.timesheet_card}> 
                  <h3>Timesheet</h3>
                  <p className={styles.clr_black}>Current Timesheet  <span>20</span></p>
                  <p className={styles.clr_red}>Due Timesheet  <span>9</span></p>
                  <p className={styles.clr_yellow}>Pending  <span>9</span></p>
                  <p className={styles.clr_green}>Approved  <span>19</span></p>

                  </div>
                </div>

                <div className="col-lg-6">
                  <div className={styles.timesheet_card}> 
                  <h3>Expese</h3>
                  <p className={styles.clr_black}>Current Timesheet  <span>20</span></p>
                  <p className={styles.clr_red}>Due Timesheet  <span>9</span></p>
                  <p className={styles.clr_yellow}>Pending  <span>9</span></p>
                  <p className={styles.clr_green}>Approved  <span>19</span></p>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className={styles.timesheet_card}> 
                  <h3>Invoices</h3>
                  <p className={styles.clr_black}>Current Timesheet  <span>20</span></p>
                  <p className={styles.clr_red}>Due Timesheet  <span>9</span></p>
                  <p className={styles.clr_yellow}>Pending  <span>9</span></p>
                  <p className={styles.clr_green}>Approved  <span>19</span></p>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className={styles.timesheet_card}> 
                  <h3>Purchase Orders</h3>
                  <p className={styles.clr_black}>Current Timesheet  <span>20</span></p>
                  <p className={styles.clr_red}>Due Timesheet  <span>9</span></p>
                  <p className={styles.clr_yellow}>Pending  <span>9</span></p>
                  <p className={styles.clr_green}>Approved  <span>19</span></p>
                  </div>
                </div>

                <div className="col-lg-6"> 
                <div className={styles.rec_card}> 
                <p>Recieved For Hours Worked This Contract $10,000</p>
                </div>
                </div>

                <div className="col-lg-6"> 
                <div className={styles.rec_card}> 
                <p>Recieved For Hours Worked This Contract $10,000</p>
                </div>
                </div>

                <div className="col-lg-6"> 
                <div className={styles.rec_card}> 
                <p>Recieved For Hours Worked This Contract $10,000</p>
                </div>
                </div>

                <div className="col-lg-6"> 
                <div className={styles.rec_card}> 
                <p>Recieved For Hours Worked This Contract $10,000</p>
                </div>
                </div>

                <div className="col-lg-12">
                  <button type="button" className={`btn ${styles.viewssa}`}>View SSA</button>
                </div>

              </div>
            </div>

          </div>
        </div>

        <div className="col-lg-6">
          <div className={styles.card_contract}>
            <div className={styles.card_headers}>
              <div className={styles.card_left_c}>
                <h3>Contract ID : #457</h3>
                <p>Start Date : 12 Jan 2024</p>
              </div>
              <div className={styles.card_ongoing}>
                <button type="button" className={`btn ${styles.ongoing}`}>Ongoing</button>
              </div>
            </div>
            <div className={styles.contract_details}>
              <div className={styles.flex_details}>
                <p>Contractor Details</p>
                <p>Working: Johns Yani</p>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <div className={`d-flex align-items-center ${styles.border_rr}`}>
                  <div className={styles.flex_imageProfile}>
                     <img src={profile} alt="" className="img-fluid"/>
                  </div>
                  <div className={styles.flex_cintent_ic}>
                    <h4>Bradely Innes</h4>
                    <p>Pia Automation . inc</p>
                    <h6> <i className="fas fa-map-marker-alt pe-1"></i> Evansville. IN</h6>
                  </div>
                  </div>
                </div>
                <div className="col-lg-6">
                <div className={styles.flex_cintent}>
                <p>Start Date: 10/18/2023</p>
                <p> End Date: 12/18/2023</p>
                <p> Rate Per Hour : $70.84(net 15)</p>
                <p>Expense Terms : Net 15</p>
                </div>
                </div>
              </div>
            </div>

            <div className={styles.card_body}>
              <div className="row">
                <div className="col-lg-6">
                  <div className={styles.timesheet_card}> 
                  <h3>Timesheet</h3>
                  <p className={styles.clr_black}>Current Timesheet  <span>20</span></p>
                  <p className={styles.clr_red}>Due Timesheet  <span>9</span></p>
                  <p className={styles.clr_yellow}>Pending  <span>9</span></p>
                  <p className={styles.clr_green}>Approved  <span>19</span></p>

                  </div>
                </div>

                <div className="col-lg-6">
                  <div className={styles.timesheet_card}> 
                  <h3>Expese</h3>
                  <p className={styles.clr_black}>Current Timesheet  <span>20</span></p>
                  <p className={styles.clr_red}>Due Timesheet  <span>9</span></p>
                  <p className={styles.clr_yellow}>Pending  <span>9</span></p>
                  <p className={styles.clr_green}>Approved  <span>19</span></p>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className={styles.timesheet_card}> 
                  <h3>Invoices</h3>
                  <p className={styles.clr_black}>Current Timesheet  <span>20</span></p>
                  <p className={styles.clr_red}>Due Timesheet  <span>9</span></p>
                  <p className={styles.clr_yellow}>Pending  <span>9</span></p>
                  <p className={styles.clr_green}>Approved  <span>19</span></p>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className={styles.timesheet_card}> 
                  <h3>Purchase Orders</h3>
                  <p className={styles.clr_black}>Current Timesheet  <span>20</span></p>
                  <p className={styles.clr_red}>Due Timesheet  <span>9</span></p>
                  <p className={styles.clr_yellow}>Pending  <span>9</span></p>
                  <p className={styles.clr_green}>Approved  <span>19</span></p>
                  </div>
                </div>

                <div className="col-lg-6"> 
                <div className={styles.rec_card}> 
                <p>Recieved For Hours Worked This Contract $10,000</p>
                </div>
                </div>

                <div className="col-lg-6"> 
                <div className={styles.rec_card}> 
                <p>Recieved For Hours Worked This Contract $10,000</p>
                </div>
                </div>

                <div className="col-lg-6"> 
                <div className={styles.rec_card}> 
                <p>Recieved For Hours Worked This Contract $10,000</p>
                </div>
                </div>

                <div className="col-lg-6"> 
                <div className={styles.rec_card}> 
                <p>Recieved For Hours Worked This Contract $10,000</p>
                </div>
                </div>

                <div className="col-lg-12">
                  <button type="button" className={`btn ${styles.viewssa}`}>View SSA</button>
                </div>

              </div>
            </div>

          </div>
        </div>


        <div className="col-lg-6">
          <div className={styles.card_contract}>
            <div className={styles.card_headers}>
              <div className={styles.card_left_c}>
                <h3>Contract ID : #457</h3>
                <p>Start Date : 12 Jan 2024</p>
              </div>
              <div className={styles.card_ongoing}>
                <button type="button" className={`btn ${styles.ongoing}`}>Ongoing</button>
              </div>
            </div>
            <div className={styles.contract_details}>
              <div className={styles.flex_details}>
                <p>Contractor Details</p>
                <p>Working: Johns Yani</p>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <div className={`d-flex align-items-center ${styles.border_rr}`}>
                  <div className={styles.flex_imageProfile}>
                     <img src={profile} alt="" className="img-fluid"/>
                  </div>
                  <div className={styles.flex_cintent_ic}>
                    <h4>Bradely Innes</h4>
                    <p>Pia Automation . inc</p>
                    <h6> <i className="fas fa-map-marker-alt pe-1"></i> Evansville. IN</h6>
                  </div>
                  </div>
                </div>
                <div className="col-lg-6">
                <div className={styles.flex_cintent}>
                <p>Start Date: 10/18/2023</p>
                <p> End Date: 12/18/2023</p>
                <p> Rate Per Hour : $70.84(net 15)</p>
                <p>Expense Terms : Net 15</p>
                </div>
                </div>
              </div>
            </div>

            <div className={styles.card_body}>
              <div className="row">
                <div className="col-lg-6">
                  <div className={styles.timesheet_card}> 
                  <h3>Timesheet</h3>
                  <p className={styles.clr_black}>Current Timesheet  <span>20</span></p>
                  <p className={styles.clr_red}>Due Timesheet  <span>9</span></p>
                  <p className={styles.clr_yellow}>Pending  <span>9</span></p>
                  <p className={styles.clr_green}>Approved  <span>19</span></p>

                  </div>
                </div>

                <div className="col-lg-6">
                  <div className={styles.timesheet_card}> 
                  <h3>Expese</h3>
                  <p className={styles.clr_black}>Current Timesheet  <span>20</span></p>
                  <p className={styles.clr_red}>Due Timesheet  <span>9</span></p>
                  <p className={styles.clr_yellow}>Pending  <span>9</span></p>
                  <p className={styles.clr_green}>Approved  <span>19</span></p>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className={styles.timesheet_card}> 
                  <h3>Invoices</h3>
                  <p className={styles.clr_black}>Current Timesheet  <span>20</span></p>
                  <p className={styles.clr_red}>Due Timesheet  <span>9</span></p>
                  <p className={styles.clr_yellow}>Pending  <span>9</span></p>
                  <p className={styles.clr_green}>Approved  <span>19</span></p>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className={styles.timesheet_card}> 
                  <h3>Purchase Orders</h3>
                  <p className={styles.clr_black}>Current Timesheet  <span>20</span></p>
                  <p className={styles.clr_red}>Due Timesheet  <span>9</span></p>
                  <p className={styles.clr_yellow}>Pending  <span>9</span></p>
                  <p className={styles.clr_green}>Approved  <span>19</span></p>
                  </div>
                </div>

                <div className="col-lg-6"> 
                <div className={styles.rec_card}> 
                <p>Recieved For Hours Worked This Contract $10,000</p>
                </div>
                </div>

                <div className="col-lg-6"> 
                <div className={styles.rec_card}> 
                <p>Recieved For Hours Worked This Contract $10,000</p>
                </div>
                </div>

                <div className="col-lg-6"> 
                <div className={styles.rec_card}> 
                <p>Recieved For Hours Worked This Contract $10,000</p>
                </div>
                </div>

                <div className="col-lg-6"> 
                <div className={styles.rec_card}> 
                <p>Recieved For Hours Worked This Contract $10,000</p>
                </div>
                </div>

                <div className="col-lg-12">
                  <button type="button" className={`btn ${styles.viewssa}`}>View SSA</button>
                </div>

              </div>
            </div>

          </div>
        </div>

        <div className="col-lg-6">
          <div className={styles.card_contract}>
            <div className={styles.card_headers}>
              <div className={styles.card_left_c}>
                <h3>Contract ID : #457</h3>
                <p>Start Date : 12 Jan 2024</p>
              </div>
              <div className={styles.card_ongoing}>
                <button type="button" className={`btn ${styles.ongoing}`}>Ongoing</button>
              </div>
            </div>
            <div className={styles.contract_details}>
              <div className={styles.flex_details}>
                <p>Contractor Details</p>
                <p>Working: Johns Yani</p>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <div className={`d-flex align-items-center ${styles.border_rr}`}>
                  <div className={styles.flex_imageProfile}>
                     <img src={profile} alt="" className="img-fluid"/>
                  </div>
                  <div className={styles.flex_cintent_ic}>
                    <h4>Bradely Innes</h4>
                    <p>Pia Automation . inc</p>
                    <h6> <i className="fas fa-map-marker-alt pe-1"></i> Evansville. IN</h6>
                  </div>
                  </div>
                </div>
                <div className="col-lg-6">
                <div className={styles.flex_cintent}>
                <p>Start Date: 10/18/2023</p>
                <p> End Date: 12/18/2023</p>
                <p> Rate Per Hour : $70.84(net 15)</p>
                <p>Expense Terms : Net 15</p>
                </div>
                </div>
              </div>
            </div>

            <div className={styles.card_body}>
              <div className="row">
                <div className="col-lg-6">
                  <div className={styles.timesheet_card}> 
                  <h3>Timesheet</h3>
                  <p className={styles.clr_black}>Current Timesheet  <span>20</span></p>
                  <p className={styles.clr_red}>Due Timesheet  <span>9</span></p>
                  <p className={styles.clr_yellow}>Pending  <span>9</span></p>
                  <p className={styles.clr_green}>Approved  <span>19</span></p>

                  </div>
                </div>

                <div className="col-lg-6">
                  <div className={styles.timesheet_card}> 
                  <h3>Expese</h3>
                  <p className={styles.clr_black}>Current Timesheet  <span>20</span></p>
                  <p className={styles.clr_red}>Due Timesheet  <span>9</span></p>
                  <p className={styles.clr_yellow}>Pending  <span>9</span></p>
                  <p className={styles.clr_green}>Approved  <span>19</span></p>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className={styles.timesheet_card}> 
                  <h3>Invoices</h3>
                  <p className={styles.clr_black}>Current Timesheet  <span>20</span></p>
                  <p className={styles.clr_red}>Due Timesheet  <span>9</span></p>
                  <p className={styles.clr_yellow}>Pending  <span>9</span></p>
                  <p className={styles.clr_green}>Approved  <span>19</span></p>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className={styles.timesheet_card}> 
                  <h3>Purchase Orders</h3>
                  <p className={styles.clr_black}>Current Timesheet  <span>20</span></p>
                  <p className={styles.clr_red}>Due Timesheet  <span>9</span></p>
                  <p className={styles.clr_yellow}>Pending  <span>9</span></p>
                  <p className={styles.clr_green}>Approved  <span>19</span></p>
                  </div>
                </div>

                <div className="col-lg-6"> 
                <div className={styles.rec_card}> 
                <p>Recieved For Hours Worked This Contract $10,000</p>
                </div>
                </div>

                <div className="col-lg-6"> 
                <div className={styles.rec_card}> 
                <p>Recieved For Hours Worked This Contract $10,000</p>
                </div>
                </div>

                <div className="col-lg-6"> 
                <div className={styles.rec_card}> 
                <p>Recieved For Hours Worked This Contract $10,000</p>
                </div>
                </div>

                <div className="col-lg-6"> 
                <div className={styles.rec_card}> 
                <p>Recieved For Hours Worked This Contract $10,000</p>
                </div>
                </div>

                <div className="col-lg-12">
                  <button type="button" className={`btn ${styles.viewssa}`}>View SSA</button>
                </div>

              </div>
            </div>

          </div>
        </div>


        <div className="col-lg-6">
          <div className={styles.card_contract}>
            <div className={styles.card_headers}>
              <div className={styles.card_left_c}>
                <h3>Contract ID : #457</h3>
                <p>Start Date : 12 Jan 2024</p>
              </div>
              <div className={styles.card_ongoing}>
                <button type="button" className={`btn ${styles.ongoing}`}>Ongoing</button>
              </div>
            </div>
            <div className={styles.contract_details}>
              <div className={styles.flex_details}>
                <p>Contractor Details</p>
                <p>Working: Johns Yani</p>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <div className={`d-flex align-items-center ${styles.border_rr}`}>
                  <div className={styles.flex_imageProfile}>
                     <img src={profile} alt="" className="img-fluid"/>
                  </div>
                  <div className={styles.flex_cintent_ic}>
                    <h4>Bradely Innes</h4>
                    <p>Pia Automation . inc</p>
                    <h6> <i className="fas fa-map-marker-alt pe-1"></i> Evansville. IN</h6>
                  </div>
                  </div>
                </div>
                <div className="col-lg-6">
                <div className={styles.flex_cintent}>
                <p>Start Date: 10/18/2023</p>
                <p> End Date: 12/18/2023</p>
                <p> Rate Per Hour : $70.84(net 15)</p>
                <p>Expense Terms : Net 15</p>
                </div>
                </div>
              </div>
            </div>

            <div className={styles.card_body}>
              <div className="row">
                <div className="col-lg-6">
                  <div className={styles.timesheet_card}> 
                  <h3>Timesheet</h3>
                  <p className={styles.clr_black}>Current Timesheet  <span>20</span></p>
                  <p className={styles.clr_red}>Due Timesheet  <span>9</span></p>
                  <p className={styles.clr_yellow}>Pending  <span>9</span></p>
                  <p className={styles.clr_green}>Approved  <span>19</span></p>

                  </div>
                </div>

                <div className="col-lg-6">
                  <div className={styles.timesheet_card}> 
                  <h3>Expese</h3>
                  <p className={styles.clr_black}>Current Timesheet  <span>20</span></p>
                  <p className={styles.clr_red}>Due Timesheet  <span>9</span></p>
                  <p className={styles.clr_yellow}>Pending  <span>9</span></p>
                  <p className={styles.clr_green}>Approved  <span>19</span></p>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className={styles.timesheet_card}> 
                  <h3>Invoices</h3>
                  <p className={styles.clr_black}>Current Timesheet  <span>20</span></p>
                  <p className={styles.clr_red}>Due Timesheet  <span>9</span></p>
                  <p className={styles.clr_yellow}>Pending  <span>9</span></p>
                  <p className={styles.clr_green}>Approved  <span>19</span></p>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className={styles.timesheet_card}> 
                  <h3>Purchase Orders</h3>
                  <p className={styles.clr_black}>Current Timesheet  <span>20</span></p>
                  <p className={styles.clr_red}>Due Timesheet  <span>9</span></p>
                  <p className={styles.clr_yellow}>Pending  <span>9</span></p>
                  <p className={styles.clr_green}>Approved  <span>19</span></p>
                  </div>
                </div>

                <div className="col-lg-6"> 
                <div className={styles.rec_card}> 
                <p>Recieved For Hours Worked This Contract $10,000</p>
                </div>
                </div>

                <div className="col-lg-6"> 
                <div className={styles.rec_card}> 
                <p>Recieved For Hours Worked This Contract $10,000</p>
                </div>
                </div>

                <div className="col-lg-6"> 
                <div className={styles.rec_card}> 
                <p>Recieved For Hours Worked This Contract $10,000</p>
                </div>
                </div>

                <div className="col-lg-6"> 
                <div className={styles.rec_card}> 
                <p>Recieved For Hours Worked This Contract $10,000</p>
                </div>
                </div>

                <div className="col-lg-12">
                  <button type="button" className={`btn ${styles.viewssa}`}>View SSA</button>
                </div>

              </div>
            </div>

          </div>
        </div>


        <div className="col-lg-6">
          <div className={styles.card_contract}>
            <div className={styles.card_headers}>
              <div className={styles.card_left_c}>
                <h3>Contract ID : #457</h3>
                <p>Start Date : 12 Jan 2024</p>
              </div>
              <div className={styles.card_ongoing}>
                <button type="button" className={`btn ${styles.ongoing}`}>Ongoing</button>
              </div>
            </div>
            <div className={styles.contract_details}>
              <div className={styles.flex_details}>
                <p>Contractor Details</p>
                <p>Working: Johns Yani</p>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <div className={`d-flex align-items-center ${styles.border_rr}`}>
                  <div className={styles.flex_imageProfile}>
                     <img src={profile} alt="" className="img-fluid"/>
                  </div>
                  <div className={styles.flex_cintent_ic}>
                    <h4>Bradely Innes</h4>
                    <p>Pia Automation . inc</p>
                    <h6> <i className="fas fa-map-marker-alt pe-1"></i> Evansville. IN</h6>
                  </div>
                  </div>
                </div>
                <div className="col-lg-6">
                <div className={styles.flex_cintent}>
                <p>Start Date: 10/18/2023</p>
                <p> End Date: 12/18/2023</p>
                <p> Rate Per Hour : $70.84(net 15)</p>
                <p>Expense Terms : Net 15</p>
                </div>
                </div>
              </div>
            </div>

            <div className={styles.card_body}>
              <div className="row">
                <div className="col-lg-6">
                  <div className={styles.timesheet_card}> 
                  <h3>Timesheet</h3>
                  <p className={styles.clr_black}>Current Timesheet  <span>20</span></p>
                  <p className={styles.clr_red}>Due Timesheet  <span>9</span></p>
                  <p className={styles.clr_yellow}>Pending  <span>9</span></p>
                  <p className={styles.clr_green}>Approved  <span>19</span></p>

                  </div>
                </div>

                <div className="col-lg-6">
                  <div className={styles.timesheet_card}> 
                  <h3>Expese</h3>
                  <p className={styles.clr_black}>Current Timesheet  <span>20</span></p>
                  <p className={styles.clr_red}>Due Timesheet  <span>9</span></p>
                  <p className={styles.clr_yellow}>Pending  <span>9</span></p>
                  <p className={styles.clr_green}>Approved  <span>19</span></p>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className={styles.timesheet_card}> 
                  <h3>Invoices</h3>
                  <p className={styles.clr_black}>Current Timesheet  <span>20</span></p>
                  <p className={styles.clr_red}>Due Timesheet  <span>9</span></p>
                  <p className={styles.clr_yellow}>Pending  <span>9</span></p>
                  <p className={styles.clr_green}>Approved  <span>19</span></p>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className={styles.timesheet_card}> 
                  <h3>Purchase Orders</h3>
                  <p className={styles.clr_black}>Current Timesheet  <span>20</span></p>
                  <p className={styles.clr_red}>Due Timesheet  <span>9</span></p>
                  <p className={styles.clr_yellow}>Pending  <span>9</span></p>
                  <p className={styles.clr_green}>Approved  <span>19</span></p>
                  </div>
                </div>

                <div className="col-lg-6"> 
                <div className={styles.rec_card}> 
                <p>Recieved For Hours Worked This Contract $10,000</p>
                </div>
                </div>

                <div className="col-lg-6"> 
                <div className={styles.rec_card}> 
                <p>Recieved For Hours Worked This Contract $10,000</p>
                </div>
                </div>

                <div className="col-lg-6"> 
                <div className={styles.rec_card}> 
                <p>Recieved For Hours Worked This Contract $10,000</p>
                </div>
                </div>

                <div className="col-lg-6"> 
                <div className={styles.rec_card}> 
                <p>Recieved For Hours Worked This Contract $10,000</p>
                </div>
                </div>

                <div className="col-lg-12">
                  <button type="button" className={`btn ${styles.viewssa}`}>View SSA</button>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>


     </div>
      </section>
    

    </div>
  )
}

export default MyDashboards