import React, { useState } from "react";
import { Button } from "react-bootstrap";
import styles from "../../components/common/css/styles/style.module.css";
import "./calendar.module.style.css";
import moment from "moment";
import { addEvents } from "./api";
import { toast } from "react-toastify";
import { MESSAGES } from "../../utils/messages";
import DatePicker from "react-datepicker";
import storage from "../../utils/storage";
import { EVENT_TYPE } from "../../utils/enums/types";
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  addEvent: (event: { title: string; start: Date }) => void;
}
const AddEvent: React.FC<ModalProps> = ({ isOpen, onClose, addEvent }) => {
  if (!isOpen) return null;
  const userId = storage.getAuthUserId() || "";
  const [title, setTitle] = useState("");
  const [date, setDate] = useState<Date | null>(null);
  const [time, setTime] = useState("");

  const handleDateChange = (selectedDate: Date | null) => {
    setDate(selectedDate);
  };

  const handleAddEvents = async () => {
    if (title && date && time) {
      const eventDate = moment(date).startOf('day').valueOf();
      const combinedDateTime = moment(date).set({
        hour: moment(time, 'HH:mm').hour(),
        minute: moment(time, 'HH:mm').minute(),
        second: 0,
        millisecond: 0
      }).toDate();
      
      const payload = {
        userId: userId,
        title: title,
        eventType: EVENT_TYPE.OTHER,
        eventDate: combinedDateTime.valueOf(),
        eventTime: combinedDateTime.valueOf(),
        // additionalNotes: title,
      };
      try{
        await toast.promise(addEvents(payload), {
          pending: MESSAGES.CALENDAR.PENDING,
          success: MESSAGES.CALENDAR.SUCCESS,
          error: MESSAGES.CALENDAR.ERROR,
        });
        addEvent({ title, start: date });
        onClose();
      }catch(err){console.log(err);
      }
    } else {
      alert("Please fill in all fields (Title, Start Date, End Date)");
    }
  };

  return (
    <div
      className="AddEventsModal"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="item">
        <h4>Add Events</h4>
        <div className="form-group">
          <label>Add Title:</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Add Date:</label>
          <DatePicker
            selected={date}
            onChange={handleDateChange}
            placeholderText="Start Date"
            minDate={new Date()}
            dateFormat="MM-dd-yyyy"
          />
        </div>
        <div className="form-group">
          <label>Add Time:</label>
          <input
            type="time"
            value={time}
            onChange={(e) => setTime(e.target.value)}
          />
        </div>
        <Button type="button" className="btn" onClick={handleAddEvents}>
          Add Events
        </Button>
        <Button type="button" className="btn" onClick={onClose}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default AddEvent;