import React from "react";
import styles from "../styles/style.module.css";
import Callingicon from "../../../assets/images/calling_icon.svg";
import emailicon from "../../../assets/images/email_icon.svg";
import locationicon from "../../../assets/images/location_icon.svg";
import Sendmessageform from "../../Sendmessageform/routes";

const LetsTalkWithUs = () => {
  return (
    <div className={styles.letsconnect}>
      <div className="container">
        <div className={styles.new_feeds}>
          <div className="row align-items-center justify-content-between g-lg-5 g-3">
            <div className="col-lg-6 col-12">
              <div className={styles.formblock}>
                <div className="py-lg-5 py-3 px-lg-4 px-0">
                  <div className={styles.newheadingstyle}>
                    <h2 className="fw-bolder">Lets talk with Us</h2>
                    <p className="text-14 mb-4">
                      Have a project in mind that you think we’d be a great fit
                      for it? We’d love to know what you’re thinking
                    </p>
                  </div>
                  <div className="">
                    <h4 className="text-20 fw-bolder">Contact Info:</h4>
                    <ul>
                      <li className="text-14">
                        <i className="pe-2">
                          <img src={Callingicon} alt="" />
                        </i>
                        Phone Number: <a href="tell:621829017">(248) 951-2434</a>
                      </li>
                      <li className="text-14">
                        <i className="pe-2">
                          <img src={emailicon} alt="" />
                        </i>
                        Email:{" "}
                        <a href="tell:621829017"> info@AutomateAmerica.com</a>
                      </li>
                      <li className="text-14">
                        <i className="pe-2">
                          <img src={locationicon} alt="" />
                        </i>
                        Map Street:{" "}
                        <a href="tell:621829017">1409 Allen Drive, Suite A, Troy, MI 48083</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-12">
              <div className={styles.formdetailsform}>
                <div className="px-lg-5 px-3 py-lg-0 py-3">
                  <Sendmessageform />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LetsTalkWithUs;
