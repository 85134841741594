import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface VideoJoinConfigInterface {
    token: string;
    channelName: string;
    uid: number;
};

const videoJoinConfigSlice = createSlice({
    name: "videoJoinConfig",
    initialState: {
        token: "",
        channelName: "",
        uid: 0,
    },
    reducers: {
        setVideoJoinConfig: (state, action: PayloadAction<VideoJoinConfigInterface>) => {
            state.token = action.payload.token;
            state.channelName = action.payload.channelName;
            state.uid = action.payload.uid;
        },
    },
})

export const { setVideoJoinConfig } = videoJoinConfigSlice.actions;


export default videoJoinConfigSlice.reducer;