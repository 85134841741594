import React, { useEffect, useState } from 'react';
import InterViewQuestions from '../interviewQuestion/page';
import { EligibilityQuestions } from './eligibilityQuestions';
import { APP_ROUTES } from '../../../../utils/routes/AppRoutes';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { setAnswers, setInterviewAns } from '../../../../redux/reducers/dashboard';
import { COUNT_TYPE } from '../../../../utils/enums/types';
import { InterviewQus, Question } from '../../Contracts/interface';
import { getWorkDetailWithCount } from '../../Contracts/api';


enum APPLY_JOB_TABS {
  ELIGIBILITY_QUESTIONS,
  INTERVIEW_QUESTIONS,
}

interface EligibilityProps {
  workId: string;
  onClose: () => void;
}
const EligibiltyModal: React.FC<EligibilityProps> = ({ onClose, workId }) => {
  const [activeTab, setActiveTab] = useState<APPLY_JOB_TABS>(
    APPLY_JOB_TABS.ELIGIBILITY_QUESTIONS
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [interviewQuestion, setInterviewQuestions] = useState<InterviewQus[]>([]);
  const { eligibilityAnswers, interviewAnswers } = useSelector((state: RootState) => state.dashboard);


  const [showAlertText, setShowAlertText] = useState<boolean>(false);
  const [showSaveBtn, setShowSaveBtn] = useState<boolean>(true);
  const [showSaveInterviewBtn, setShowInterviewBtn] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();


  useEffect(() => {
    const getPostedContracts = async () => {
      const response = await getWorkDetailWithCount(workId, COUNT_TYPE.WORK_APPLY);
      setQuestions(response.data.eligibilityQuestions);
      setInterviewQuestions(response?.data?.interviewQuestions);
      setLoading(false);
    };
    getPostedContracts();
  }, []);

  // Swal.fire({
  //   title: "Warning",
  //   text: "We cannot proceed with your request at this time,Please review the provided information and try again.",
  //   icon: "warning",
  //   confirmButtonText: "Go Back",
  // });
  // onClose()

  const onAnsChange = (value: boolean, index: number, id: string, key?: string) => {
    const updatedQuestions = [...eligibilityAnswers];
    updatedQuestions[index] = { ...updatedQuestions[index], Answers: value, questionId: id };
    dispatch(setAnswers(updatedQuestions));
    if (key === "No") {
      setShowAlertText(true);
      setShowSaveBtn(false);
      return false;
    } else {
      setShowAlertText(false);
    }
    const allAnswersValid = updatedQuestions.every((question) => question.Answers !== false);
    setShowSaveBtn(allAnswersValid);
  };



  const answerChange = (value: string, index: number, id: string) => {
    const updatedInterQuestions = [...interviewAnswers];
    const updatedAns = { ...updatedInterQuestions[index], Answers: value, questionId: id };
    updatedInterQuestions[index] = updatedAns;
    dispatch(setInterviewAns(updatedInterQuestions));
    const hasData = updatedInterQuestions.some((item) => item.Answers.trim() !== "");
    setShowInterviewBtn(hasData);
  };
  

  const navigateToPaymentTerms = () => {
    navigate(`${APP_ROUTES.JOBDETAIL}/${workId}`);
  };

  return (
    <>
      {activeTab === APPLY_JOB_TABS.ELIGIBILITY_QUESTIONS ? (
        <EligibilityQuestions
          questions={questions}
          loading={loading}
          onAnsChange={onAnsChange}
          onNext={() => setActiveTab(APPLY_JOB_TABS.INTERVIEW_QUESTIONS)}
          onClose={onClose}
          showAlertText={showAlertText}
          showSaveBtn={showSaveBtn}
        />
      ) : (
        <InterViewQuestions
          onClose={() => onClose()}
          onNext={navigateToPaymentTerms}
          interviewQus={interviewQuestion}
          answerChange={answerChange}
          showSaveInterviewBtn={showSaveInterviewBtn}
        />
      )}

    </>
  );
};

export default EligibiltyModal;
