import { axios } from "../../../../lib/axios";
import { AxiosResponse } from "../../../../shared/models";
import { API_ROUTES } from "../../../../utils/routes/apiRoutes";
import { GetHelpText, HelpTextItem, TooltipResponse } from "../interface";

export const getHelpText = async (): Promise<GetHelpText> => {
    return axios.get(API_ROUTES.TOOTTIP.GET_TOOTL_TIP);

};
export const getHelpTextByFilter = async (
  slug: string,
  labelId: string
  ): Promise<{ data?: HelpTextItem;error?: string}> => {
    try {
      const response = await axios.get<HelpTextItem> (
        `${API_ROUTES.TOOTTIP.GET_TOOTL_TIP_FILTER}?slug=${slug}&labelId=${labelId}`
      );
      return response;
    } catch (error) {
      return { error: 'Failed to fetch tooltip data' };
    }
  };
  