import React from "react";
import styles from "../styles/style.module.css";
import penbl from "../../../assets/images/penbl.png";
import tip from "../../../assets/images/tip.png";
import { BioInterface } from "../interface";

const About: React.FC<BioInterface> = ({ bio, onChange, bioValue }) => {
  return (
    <div className={styles.AboutEdit}>
       <div className="row align-items-center justify-content-between mb-3">
        <div className="col-6">
        <div className="topTipHover">
            <div className="d-flex align-items-center">
              <h4 className="text-20 fw-bold  font-inter mb-0">
              Short Bio
              </h4>
              <i>
                <img className="customeTolImg" src={tip} alt="" />
              </i>
              {/* <Toltip
                slug={CONSTANT_MANAGER.SLUG_FEED.FEED}
                labelId={
                  CONSTANT_MANAGER.FEED_DATA
                    .ADD_MISSING_PROFILE_INFORMATION
                }
              /> */}
            </div>
          </div>
          
        </div>
        <div className="col-6 text-end">
          <span>
            <img src={penbl} alt="" />
          </span>
        </div>
      </div>
      {/* <input type="text" name={bio} value={bioValue} onChange={onChange} /> */}
      <p className="o">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed luctus, augue eget scelerisque efficitur.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed luctus, augue eget scelerisque efficitur.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed luctus, augue eget scelerisque efficitur.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed luctus, augue eget scelerisque efficitur.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed luctus, augue eget scelerisque efficitur.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed luctus, augue eget scelerisque efficitur.
      </p>
    </div>
  );
};

export default About;
