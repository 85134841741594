import React from "react";
import styles from "../styles/style.module.css";
import infoicon from "../../../../assets/images/infoicon.svg";
import { InterviewQus } from "../../Contracts/interface";



interface InterViewQusProps {
  onClose: () => void;
  onNext: () => void;
  interviewQus: InterviewQus[],
  answerChange: (value: string, index: number, id: string) => void;
  showSaveInterviewBtn: boolean
}
const InterViewQuestions: React.FC<InterViewQusProps> = ({
  onClose,
  onNext,
  interviewQus,
  answerChange,
  showSaveInterviewBtn

}) => {

  return (
    <>
      <div className="modal" style={{ display: "block" }}>
        <div className="modal-dialog ElibalModal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">InterView Questions</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              ></button>
            </div>
            <div className="modal-body">
              <div className={styles.EligibalModal}>
                <div className="row">
                  <div className="col-md-8">
                    <div className={styles.leftEligibal}>
                      <form>
                        {interviewQus?.map((data, idx) => {
                          return (
                            <div key={idx} className={styles.formGroup}>
                              <h5>
                                {idx + 1}.{data?.question}
                              </h5>
                              <textarea placeholder="Enter Answer here...." onChange={(e) => answerChange(e.target.value, idx, data?._id)}></textarea>
                            </div>
                          )
                        })}


                      </form>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className={styles.rightEligibal}>
                      <img src={infoicon} alt="" />
                      <p>
                        These questions can help gauge your experience and
                        skills in various aspects of project management and
                        technical tasks. Be prepared to provide specific
                        examples from your past work to support your answers.
                        These questions can help gauge your experience and
                        skills in various aspects of project management and
                        technical tasks. Be prepared to provide specific
                        examples from your past work to support your answers.
                        These questions can help gauge your experience and
                        skills in various aspects of project{" "}
                      </p>
                    </div>
                  </div>
                </div>
                {showSaveInterviewBtn &&
                  <div className="eligibalBtn">
                    <button onClick={onNext} className="cmn-tbn">
                      Save & Next{" "}
                    </button>
                  </div>
                }

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InterViewQuestions;
