import React from "react";
import styles from "../styles/style.module.css";
import advertisebanner from "../../../assets/images/advertisebanner.png";
import arrowwithoutcircle_blue from "../../../assets/images/arrow_withoutcircle_blue.svg";

const AdvertiseYourSchool = () => {
  return (
    <div className={styles.advertiseyourschool}>
      <div className="container">
        <div className={styles.advertiseblock}>
          <div className="row align-items-center g-0 justify-content-between mx-0 flex-row-reverse">
            <div className="col-lg-5 offset-lg-1 text-center d-none d-lg-block">
              <figure className="mb-0">
                <img src={advertisebanner} alt="" className="img-fluid w-100" />
              </figure>
            </div>

            <div className="col-lg-5 offset-lg-1 col-12">
              <div className="newheadingstyle p-3">
                <h4 className="text-uppercase">Grow Your business</h4>
                <h2 className="text-uppercase">
                  Advertise
                  <br /> your
                  <br /> school for
                  <br /> free
                </h2>

                <button className="btn btn-white text-blue text-20">
                  Start free Ad{" "}
                  <i className="ps-2">
                    <img
                      src={arrowwithoutcircle_blue}
                      alt=""
                      width={15}
                      height={15}
                    />
                  </i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvertiseYourSchool;
