import React from "react";
import Login from "../feature/auth/login/routes/Login";
import About from "../feature/auth/signup/routes/Aboutus";
import Citizenship from "../feature/auth/signup/routes/Citizenship";
import Emailverify from "../feature/auth/signup/routes/EmailVerification";
import Occupation from "../feature/auth/signup/routes/Occupation";
import OtpVerify from "../feature/auth/signup/routes/OtpVerification";
import PersonalInfo from "../feature/auth/signup/routes/PersonalInfo";
import PhoneVerify from "../feature/auth/signup/routes/PhoneVerification";

import { AUTH_ROUTES } from "../utils/routes/AuthRoutes";
import ForgotPassword from "../feature/auth/login/routes/ForgotPassword";
import OtpVerification from "../feature/auth/login/routes/OTPverifications";
import RecoverPassword from "../feature/auth/login/routes/RecoverPassword";
import Home from "../components/home/routes";
import Feeds from "../components/Feeds/routes/feed";
import Password from "../feature/auth/signup/routes/Password";
import Findopportunity from "../components/common/findopportunity/routes";
import Editprofile from "../components/editprofile/routes/page";
import { APP_ROUTES } from "../utils/routes/AppRoutes";
import AddCalendar from "../components/Calendar/AddCalendar";
import OnGoingContracts from "../components/common/Contracts/OngoingContracts/viewAllOngoingContracts";
import Addtimesheet from "../components/common/timesheets/routes/addTimesheet";
import PaymentTerms from "../components/paymentTerms/routes/page";
import Aggrement from "../components/agrement/routes/MSA";
import SSAAggrement from "../components/agrement/routes/SSA";
import MSAAggrement from "../components/agrement/routes/MSA";
import TimesheetTable from "../components/common/timesheets/routes/timesheetTable";
import ExpenseDetails from "../components/common/expense/expenseDetails";
import DetailOpenContract from "../components/common/Contracts/contractDetails/detailOpenContract";
import TimesheetDetails from "../components/common/timesheets/routes/detailstimesheet";
import TimesheetDetailTable from "../components/common/timesheets/routes/timesheetDetailTable";
import TimesheetStatus from "../components/common/timesheets/routes/statusTimesheet";
import Expensereport from "../components/common/expense/expensereport";
import ExpenseTable from "../components/common/expense/expenseTable";
import ExpenseStatusTable from "../components/common/expense/routes/expenseStatusTable";
import InvoiceTable from "../components/common/invoice/routes/invoiceTable";
import Invoice from "../components/common/expense/invoice";
import UserProfile from "../components/userprofile/userProfiles";
import FindProfessionals from "../components/findProfessionals/pages";
import Congratulation from "../components/congratulations/routes";
import ConnectForm from "../components/VideoCall/ConnectForm";
import BoostProfile from "../components/bostprofile/routes/BoostProfile";
import { RouteParams } from ".";
import WorkDetails from "../components/Feeds/routes/jobDetails/jobDetails";
import ChatModule from "../components/ChatModule/routes";
import ViewProfile from "../components/profileview/routes/page";
import Chat from "../components/chat/routes/page";
import Dashboard from "../components/dashboard/routes/newDashboard";
import ContractData from "../components/common/Contracts/contractDetails/fullDetailsContract";
import OngoingContractDetails from "../components/common/Contracts/OngoingContracts/OngoingDetails";
// import PaidAdds from "../components/PaidAds/routes";
import UploadImage from "../components/UploadImage/routes";
// import AddPromotion from "../components/AddPromotion/routes";
// import BoostAdds from "../components/BoostAdds/routes";

import MyDashboards from "../components/MyDashboards/routes";
import LoginVerifications from "../feature/auth/login/routes/LoginVerifications";
import ContractOpen from "../components/common/contractsopen/routes/page";
import {
  AddDirectJob,
  AddHourlyContract,
  AddPaidAdds,
  ChoosePostType,
} from "../pages";
import Success from "../components/PaymentStatus/Success";
import LiveVideo from "../components/VideoCall/LiveVideo";
import AgoraRTC, { AgoraRTCProvider } from 'agora-rtc-react';
import Cancel from "../components/PaymentStatus/Cancel";
import AddSocialPost from "../pages/ChoosePostType/Components/AddSocialPost/AddSocialPost";
import CompanyInfoModal from "../components/work/contractSteps/Components/BusinessdetailsModal/businessdetailsModal";
import PrivacyPolicy from "../feature/auth/signup/routes/PrivacyPolicy";
import SignUp from "../feature/auth/signup/routes/SignUp";
import Test from "../shared/Test";
import { useAppSelector } from "../redux/hooks";
import LiveVideoClient from "../components/common/Contracts/contractDetails/connectVideoCall";
// import LiveVideoClient from "../components/common/Contracts/contractDetails/connectVideoCall";
const agoraClient = AgoraRTC.createClient({ codec: 'vp8', mode: 'rtc' });

// const { channelName, uid, token } = useAppSelector((state) => state.videoJoinConfig);

export const publicRoutes: RouteParams[] = [

  { path: APP_ROUTES.TEST, element: <Test /> },
  { path: AUTH_ROUTES.PERSONAL_INFO, element: <PersonalInfo /> },
  { path: AUTH_ROUTES.PHONE_VERIFY, element: <PhoneVerify /> },
  { path: AUTH_ROUTES.PASSWORD, element: <Password /> },
  { path: AUTH_ROUTES.OTP_VERIFY, element: <OtpVerify /> },
  { path: AUTH_ROUTES.OCCUPATION, element: <Occupation /> },
  { path: AUTH_ROUTES.EMAIL_VERIFY, element: <Emailverify /> },
  { path: AUTH_ROUTES.CITIZENSHIP, element: <Citizenship /> },
  { path: AUTH_ROUTES.ABOUT_US, element: <About /> },
  { path: AUTH_ROUTES.PRIVACY_POLICY, element: <PrivacyPolicy /> },
  { path: AUTH_ROUTES.LOGIN, element: <Login /> },
  {
    path: AUTH_ROUTES.LOGIN_VERIFICATIONS,
    element: <LoginVerifications />,
    isPublicAccessible: true,
  },
  {
    path: APP_ROUTES.DASHBOARD,
    element: <Dashboard />,
    isPublicAccessible: true,
  },
  { path: AUTH_ROUTES.FORGOT_PASSWORD, element: <ForgotPassword /> },
  { path: AUTH_ROUTES.OTP_VERIFICATION, element: <OtpVerification /> },
  { path: AUTH_ROUTES.RECOVER_PASSWORD, element: <RecoverPassword /> },
  {
    path: APP_ROUTES.HOME,
    element: <Home />,
    isPublicAccessible: true,
  },
  { path: APP_ROUTES.FEEDS, element: <Feeds />, isPublicAccessible: true },
  {
    path: APP_ROUTES.WORK_DETAILS,
    element: <WorkDetails />,
    isPublicAccessible: true,
  },
  {
    path: APP_ROUTES.FIND_PROFESSIONALS,
    element: <FindProfessionals />,
    isPublicAccessible: true,
  },
  //{ path: APP_ROUTES.CONGRATULATION_ApplyJob, element: <Congratulation />, isPublicAccessible: true, },
  {
    path: APP_ROUTES.ONGOING_CONTRACTS,
    element: <OnGoingContracts />,
    isPublicAccessible: true,
  },
  {
    path: APP_ROUTES.CONGRATULATIION,
    element: <Congratulation />,
    isPublicAccessible: true,
  },
  {
    path: `${APP_ROUTES.CONTRACT_DETAILS}/:id`,
    element: <ContractData />,
    isPublicAccessible: true,
  },
  {
    path: `${APP_ROUTES.ONGOING_CONTRACT_DETAIL}/:id`,
    element: <OngoingContractDetails />,
    isPublicAccessible: true,
  },
  {
    path: APP_ROUTES.OPPRTUNITY,
    element: <Findopportunity />,
    isPublicAccessible: true,
  },
  {
    path: `${APP_ROUTES.OPENCONTRACT_DETAILS}/:id`,
    element: <DetailOpenContract />,
    isPublicAccessible: true,
  },
  {
    path: APP_ROUTES.EDITPROFILE,
    element: <Editprofile />,
    isPublicAccessible: true,
  },
  {
    path: `${APP_ROUTES.ADDTIMESHEET}/:id`,
    element: <Addtimesheet />,
    isPublicAccessible: true,
  },
  {
    path: `${APP_ROUTES.EDITTIMESHEET}/:id/:timesheetId`,
    element: <Addtimesheet isEditMode={true} />,
    isPublicAccessible: true,
  },
  {
    path: `${APP_ROUTES.JOBDETAIL}/:id`,
    element: <PaymentTerms />,
    isPublicAccessible: true,
  },
  {
    path: `${APP_ROUTES.SSAAGGREMENT}/:id`,
    element: <SSAAggrement />,
    isPublicAccessible: true,
  },
  {
    path: `${APP_ROUTES.MSAAGGREMENT}/:id/:userId`,
    element: <MSAAggrement />,
    isPublicAccessible: true,
  },
  {
    path: APP_ROUTES.AGGREMENT,
    element: <Aggrement />,
    isPublicAccessible: true,
  },
  {
    path: `${APP_ROUTES.TIMESHEET_TABLE}/:id`,
    element: <TimesheetTable />,
    isPublicAccessible: true,
  },
  {
    path: `${APP_ROUTES.EXPENSE_TABLE}/:id`,
    element: <ExpenseTable />,
    isPublicAccessible: true,
  },
  {
    path: `${APP_ROUTES.TIMESHEET_DETAILS}/:id`,
    element: <TimesheetDetails />,
    isPublicAccessible: true,
  },
  {
    path: `${APP_ROUTES.EXPENSE_DETAILS}/:id`,
    element: <ExpenseDetails />,
    isPublicAccessible: true,
  },
  {
    path: `${APP_ROUTES.TIMESHEETDETAIL_TABLE}/:id`,
    element: <TimesheetDetailTable />,
    isPublicAccessible: true,
  },
  {
    path: `${APP_ROUTES.TIMESHEET_STATUS}/:id`,
    element: <TimesheetStatus />,
    isPublicAccessible: true,
  },
  {
    path: `${APP_ROUTES.EXPENSE_REPORT}/:id`,
    element: <Expensereport />,
    isPublicAccessible: true,
  },
  {
    path: `${APP_ROUTES.EXPENSE_STATUS_TABLE}/:id`,
    element: <ExpenseStatusTable />,
    isPublicAccessible: true,
  },
  {
    path: `${APP_ROUTES.INVOICE_TABLE}/:id`,
    element: <InvoiceTable />,
    isPublicAccessible: true,
  },
  {
    path: `${APP_ROUTES.INVOICE_MAIN}/:id`,
    element: <Invoice />,
    isPublicAccessible: true,
  },
  {
    path: `${APP_ROUTES.CHAT_MODULE}`,
    element: <ChatModule onClose={function (): void {
      throw new Error("Function not implemented.");
    }} />,
    isPublicAccessible: true,
  },
  {
    path: `${APP_ROUTES.ADD_CONTRACT}`,
    element: <ChoosePostType />,
    isPublicAccessible: true,
  },
  {
    path: `${APP_ROUTES.ADD_HOURLY_CONTRACT}`,
    element: <AddHourlyContract />,
    isPublicAccessible: true,
  },
  {
    path: `${APP_ROUTES.ADD_DIRECT_JOBS}`,
    element: <AddDirectJob />,
    isPublicAccessible: true,
  },
  {
    path: `${APP_ROUTES.ADD_PAID_ADS}`,
    element: <AddPaidAdds />,
    isPublicAccessible: true,
  },
  {
    path: `${APP_ROUTES.USER_PROFILE}/:id`,
    element: <UserProfile />,
    isPublicAccessible: true,
  },
  {
    path: APP_ROUTES.VIEW_PROFILE,
    element: <ViewProfile />,
    isPublicAccessible: true,
  },
  // {
  //   path: `${APP_ROUTES.CHAT_MESSAGE}/:userId?`,
  //   element: <ChatMessage />,

  //   isPublicAccessible: true,
  // },
  {
    path: `${APP_ROUTES.CHAT_PAGE}/:userId?`,
    element: <Chat />,
    isPublicAccessible: true,
  },
  { path: APP_ROUTES.CHAT_PAGE, element: <Chat />, isPublicAccessible: true },

  {
    path: APP_ROUTES.UPLOAD_IMAGE,
    element: <UploadImage />,
    isPublicAccessible: true,
  },

  {
    path: APP_ROUTES.MY_DASHBOARDS,
    element: <MyDashboards />,
    isPublicAccessible: true,
  },
  {
    path: APP_ROUTES.MY_CONTRACT_OPEN,
    element: <ContractOpen />,
    isPublicAccessible: true,
  },
  {
    path: APP_ROUTES.ADD_CALENDAR,
    element: <AddCalendar />,
    isPublicAccessible: true,
  },
  {
    path: APP_ROUTES.VIDEO_CALL.HOST,
    element: <ConnectForm />,
    isPublicAccessible: true,
  },
  // {
  //   path: APP_ROUTES.VIDEO_CALL.AUDIENCE,
  //   element: <ConnectForm isCandidate />,
  //   isPublicAccessible: true,
  // },
  {
    path: APP_ROUTES.VIDEO_CALL.INTERVIEW_CABIN,
    element: (
      <AgoraRTCProvider client={agoraClient}>
        <LiveVideoClient />
        {/* <LiveVideo /> */}
      </AgoraRTCProvider>)
  },
  {
    path: APP_ROUTES.VIDEO_CALL.AUDIENCE,
    element: (
      <AgoraRTCProvider client={agoraClient}>
        {/* <LiveVideoClient /> */}
        <LiveVideo />
      </AgoraRTCProvider>)
  },
  {
    path: APP_ROUTES.BOOST_PROFILE,
    element: <BoostProfile />,
    isPublicAccessible: true,
  },
  {
    path: APP_ROUTES.SUCCESS,
    element: <Success />,
    isPublicAccessible: true,
  },
  {
    path: APP_ROUTES.CANCEL,
    element: <Cancel />,
    isPublicAccessible: true,
  },
  {
    path: APP_ROUTES.ADD_SOCIAL_POST,
    element: <AddSocialPost />,
    isPublicAccessible: true,
  },
  {
    path: APP_ROUTES.BUSINESS,
    element: <CompanyInfoModal />,
    isPublicAccessible: true,
  },
  {
    path: APP_ROUTES.SIGNUP,
    element: <SignUp />,
    isPublicAccessible: true,
  }
];

export default publicRoutes;
