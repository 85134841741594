import { axios } from "../../../lib/axios";
import { AxiosResponse } from "../../../shared/models";
import { API_ROUTES } from "../../../utils/routes/apiRoutes";
import { PostsResponse, AdData } from "../interface";


export const getPostsData = async (type: string): Promise<AxiosResponse<PostsResponse[]>> => {
    return axios.get(`${API_ROUTES.FEED.POSTS}?type=${type}`);
};

export const postAdData = async (type:string, payload: AdData): Promise<any> => {
    return axios.post(`${API_ROUTES.FEED.POSTS}?type=${type}`, payload)
}

export const uploadFileImg = async (payload:any): Promise<any> => {
    return axios.post(`${API_ROUTES.COMMON.UPLOAD_POST}`, payload, 
        {headers: {"Content-Type":"multipart/form-data"}}
    );
}