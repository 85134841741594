import React from "react";

const RequiredIcon: React.FC = () => {
  return (
    <i className="fa fa-asterisk  " aria-hidden="true" style={{
      color: '#ff0000',        
      verticalAlign: 'super',  
      marginLeft: '4px',  
      fontSize:"8px"     
    }}></i>
  );
};

export default RequiredIcon;
