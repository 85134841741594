import React, { useEffect, useState } from 'react'
import styles from "../../css/styles/style.module.css"
import loc from "../../../../assets/images/loc.png"
import timeiing from "../../../../assets/images/timeiing.png"
import man from "../../../../assets/images/man.png"
import clocl from "../../../../assets/images/clocl.png"
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { getContractDetail } from '../api'
import Header from '../../../header/routes'


const ContractData = () => {
    const { id } = useParams();
    const [contractDetails, setContractDetails] = useState<any>();
    const [loading, setLoading] = useState<boolean>(true);

    const contractData = async () => {
        const response: any = await getContractDetail(id);
        setContractDetails(response?.data);
        setLoading(false)
    }

    useEffect(() => {
        contractData()
    }, []);

    return (
        <>                                        
    {/* <Header/> */}
            <div className='OpenContarct'>
                {loading ? <div className="spinner-grow text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div> :
                    <div className='container'>
                        <div className={styles.contractPast}>
                            <div className={styles.opprBox}>
                                <div className={styles.jobDetails}>
                                    <img className={styles.worklogo} src={contractDetails?.workLogo} alt='' />
                                    <div className={styles.PenigSkil}>
                                        <h5>Work Number</h5>
                                        <h6>#{contractDetails?.workNumber}</h6>
                                    </div>
                                    <div className={styles.PenigSkilRate}>
                                        <div className={styles.PenigSkil}>
                                            <h5>Contract Type</h5>
                                            <span className={styles.Hrrate}>  {contractDetails?.workType === "hourly_contract" &&
                                                "Hourly Contract"}
                                            </span>
                                        </div>
                                    </div>
                                    <div className={styles.PenigSkilRate}>
                                        <div className={styles.PenigSkil}>
                                            <h5>Occupation</h5>
                                            <h6>{contractDetails?.occupation?.name}</h6>
                                        </div>
                                    </div>
                                    <div className={styles.PenigSkilRate}>
                                        <div className={styles.PenigSkil}>
                                            <h5>Address</h5>
                                            <div className={styles.Loca}>
                                                <p><img src={loc} alt="" /> {contractDetails?.address}</p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className={styles.PenigSkilRate}>
                                        <h5>Applications</h5>
                                        <div className={styles.SoftWareLicenece}>
                                            {contractDetails?.application?.length > 0 ? contractDetails?.application?.map((data: any, index: number) => {
                                                return (
                                                    <div key={index}>
                                                        <p>{data.name}</p>
                                                    </div>
                                                )
                                            }) : <p>There are no application added</p>}
                                        </div>
                                    </div> */}
                                    <div className={styles.PenigSkilRate}>
                                        <div className={styles.PenigSkil}>
                                            <h5>Job Description</h5>
                                            <p>
                                                {contractDetails?.description ? (
                                                    <div dangerouslySetInnerHTML={{ __html: contractDetails?.description }} />
                                                ) : (
                                                    <p>There is No Procedure and Policies added</p>
                                                )}
                                            </p>


                                        </div>
                                    </div>

                                    <div className={styles.PenigSkilRate}>
                                        <h5>TECHNICAL SKILLS</h5>
                                        <div className={styles.SoftWareLicenece}>
                                            {contractDetails?.technicalSkills?.length >
                                                0 ? (
                                                contractDetails?.technicalSkills?.map(
                                                    (data: any, index: number) => {
                                                        return (
                                                            <>
                                                                <div className={styles.keyMap} key={index}>
                                                                    <span className={styles.softarName}>
                                                                        {data?.manufacturerDetails?.name}
                                                                    </span>
                                                                    <div className={styles.Tagline}>
                                                                        <p>
                                                                            {" "}
                                                                            {data.isGoodToHave === true
                                                                                ? "(Good to have)"
                                                                                : ""}
                                                                        </p>
                                                                        <p>
                                                                            {" "}
                                                                            {data.isMandatory === true
                                                                                ? "(Mandatory)"
                                                                                : ""}
                                                                        </p>
                                                                    </div>
                                                                  
                                                                    <label className={styles.softarName}>
                                                                        {data?.manufacturerModelDetails?.name}
                                                                    </label>
                                                                </div>
                                                            </>
                                                        );
                                                    }
                                                )
                                            ) : (
                                                <p>There are no skills added</p>
                                            )}
                                        </div>
                                    </div>


                                    {/* <div className={`${styles.borderadd} ${styles.PenigSkil}`}>
                                        <h5>Skills</h5>
                                        <ul>
                                            {contractDetails?.skilledTradesInfo?.length > 0 ? contractDetails?.skilledTradesInfo?.map((data: any, idx: number) => {
                                                return (
                                                    <li key={idx} >{data?.name}</li>
                                                )
                                            }) : <p>There are no Skills added</p>}


                                        </ul>
                                    </div> */}

                                    <div className={styles.PenigSkilRate}>
                                        <div className={styles.ExtraPadding}>
                                            <h5>SOFTWARE LICENSES </h5>
                                            <div className={styles.SoftWareLicenece}>
                                                {contractDetails?.softwareLicence?.length > 0 ? contractDetails?.softwareLicence?.map((data: any, index: number) => {
                                                    return (
                                                        <>
                                                            <div className={styles.keyMap} key={index}>
                                                                <span>{data?.softwareLicenceDetails?.name}</span>

                                                                <div className={styles.Tagline}>
                                                                    <p> {data?.isGoodToHave === true ? "(Good to have)" : ""}</p>
                                                                    <p> {data?.isMandatory === true ? "(Mandatory)" : ""}</p>
                                                                </div>
                                                            </div>

                                                        </>
                                                    )
                                                }) : <p>There are no Software Licenses added</p>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.PenigSkilRate}>
                                        <h5>Pay Rates</h5>
                                        <ul>
                                            <li>
                                                <span>Base</span>
                                                <label>${contractDetails?.rates?.baseRate}</label>
                                            </li>
                                            <li>
                                                <span>Overtime</span>
                                                <label>${contractDetails?.rates?.overTimeRate}</label>
                                            </li>
                                            <li>
                                                <span>Sun/Holidays</span>
                                                <label>${contractDetails?.rates?.doubleTimeRate}</label>
                                            </li>
                                        </ul>
                                    </div>


                                    <div className={styles.OtherDetails}>
                                        <h5>Key Information</h5>
                                        <ul>
                                            <li>
                                                <span>
                                                    <img src={timeiing} alt="" />
                                                </span>
                                                <div>
                                                    <span>Start date </span>
                                                    <label>{moment(contractDetails?.times?.startDate).format("DD MMM YYYY")}</label>
                                                </div>
                                            </li>
                                            <li>
                                                <span>
                                                    <img src={timeiing} alt="" />
                                                </span>
                                                <div>
                                                    <span>Duration </span>
                                                    <label>{contractDetails?.times?.duration}</label>
                                                </div>
                                            </li>
                                            <li>
                                                <span>
                                                    <img src={man} alt="" />
                                                </span>
                                                <div>
                                                    <span>People Needed </span>
                                                    <label>{contractDetails?.times?.noOfPepole}</label>
                                                </div>
                                            </li>
                                            <li>
                                                <span>
                                                    <img src={clocl} alt="" />
                                                </span>
                                                <div>
                                                    <span>Day Shift </span>
                                                    <label>{contractDetails?.times?.shift}</label>
                                                </div>
                                            </li>
                                            <li>
                                                <span>
                                                    <img src={clocl} alt="" />
                                                </span>
                                                <div>
                                                    <span>Planned Hrs Per Day </span>
                                                    <label>{contractDetails?.times?.plannedHoursPerDay}hrs</label>
                                                </div>
                                            </li>
                                            <li>
                                                <span>
                                                    <img src={clocl} alt="" />
                                                </span>
                                                <div>
                                                    <span>Planned Days per week </span>
                                                    <label>{contractDetails?.times?.plannedDaysPerWeek}week</label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>



                                    <div className={`${styles.OtherDetailsOption} ${styles.PenigSkilRate}`}>  
                                        <h5>Expenses</h5>
                                        <ul>
                                            <li>
                                                <div>
                                                    <span>Daily Per Diem</span>
                                                    <label>${contractDetails?.expense?.dailyPerDiem}</label>
                                                </div>
                                            </li>
                                            <li>

                                                <div>
                                                    <span>Airfare Allowance</span>
                                                    <label>${contractDetails?.expense?.airfareAllowance}</label>
                                                </div>
                                            </li>
                                            <li>

                                                <div>
                                                    <span>Fuel</span>
                                                    <label>${contractDetails?.expense?.fuel}</label>
                                                </div>
                                            </li>
                                            <li>

                                                <div>
                                                    <span>Mileage Reimbursement Rate</span>
                                                    <label>${contractDetails?.expense?.mileage}</label>
                                                </div>
                                            </li>
                                            <li>

                                                <div>

                                                    <span>Rental Car Allowance</span>
                                                    <label>${contractDetails?.expense?.rentalCarAllowance}</label>
                                                </div>
                                            </li>
                                            <li>
                                                <div><span>Travel Per Diem</span>
                                                    <label>${contractDetails?.expense?.travelDayPerDiem}</label>
                                                </div>

                                            </li>
                                            <li>
                                                <div>
                                                    <span>Hotel Allowance</span>
                                                    <label>${contractDetails?.expense?.hotelAllowance}</label>
                                                </div>
                                            </li>
                                        </ul>

                                    </div>


                                    <div className={styles.OtherDetails}>
                                        <h5>Travel Expense</h5>
                                        <ul className='mt-4'>
                                            <li>

                                                <div>
                                                    <span>Travel Time Rate </span>
                                                    <label>{contractDetails?.travels?.travelTimeRate}</label>
                                                </div>
                                            </li>
                                            <li>

                                                <div>
                                                    <span>Tip Allowed </span>
                                                    <label>{contractDetails?.travels?.tipAllowed}</label>
                                                </div>
                                            </li>
                                            <li>

                                                <div>
                                                    <span>Per Round Trip </span>
                                                    <label>{contractDetails?.travels?.perRoundTrip}</label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>


                                    <div className={styles.PenigSkilRate}>
                                        <h5>PROCEDURES AND POLICIES </h5>
                                        <div className={styles.SoftWareLicenece}>
                                            <p>
                                                {contractDetails?.proceduresAndPolicies?.description ? (
                                                    <div dangerouslySetInnerHTML={{ __html: contractDetails?.proceduresAndPolicies?.description }} />
                                                ) : (
                                                    <p>There is No Procedure and Policies added</p>
                                                )}
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                }
            </div>
        </>
    )
}
export default ContractData

