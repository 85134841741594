import React from 'react'

import Header from '../../header/routes'
import { useNavigate } from 'react-router-dom'
import arrow from '../../../assets/images/arrow_withoutcircle_blue.svg'
import Linkdin from '../../../assets/images/linkdin.svg'
import tictok from '../../../assets/images/tictok.svg'
import twitter from '../../../assets/images/twitter.svg'
import facebook from '../../../assets/images/facebook.svg'
import instagram from '../../../assets/images/insta.svg'
import styles from "../styles/style.module.css"
import Slider from 'react-slick'


function FollowUs() {

    const navigate = useNavigate()
    const handleGoToNext = () => {
        navigate("/feeds")
    }
    var settings = {
        dots: false,        
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        infinite: false,
        arrows: false,
        responsive: [
          {
            breakpoint: 1400,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              infinite: true,
              arrows: true,
            }
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2.2,
              slidesToScroll: 1,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1.2,
              slidesToScroll: 1
            }
          }
        ]
      };
    return (
      <>
        <div className='recommendedjobs share_connect_friends'>
          <div className="row align-items-center justify-content-between">
          <Slider {...settings}>
              <div className='col border rounded-3 p-4 text-center bg-white'>
                <div className=' mb-2 mx-auto'>                          
                      <div className='newheadingstyle text-center'>
                        <figure><img src={Linkdin} alt='' /></figure>
                        <h3 className='mb-0 text-16'>LinkDin</h3>
                    </div>
                </div>
                <div className='mt-3'>
                  <div className='row align-items-center justify-content-center g-2'>
                      <div className='col'><button type='button' className='btn btn-white w-50 text-center border text-14 d-flex align-items-center justify-content-center text-blue mx-auto'>Follow<i  className='ms-1'><img src={arrow} alt='' width={14} height={12}/></i></button></div>
                  </div>
                </div>
              </div>
              <div className='col border rounded-3 p-4 text-center bg-white'>
                <div className=' mb-2 mx-auto'>                          
                      <div className='newheadingstyle text-center'>
                        <figure><img src={tictok} alt='' /></figure>
                        <h3 className='mb-0 text-16'>Tic Tok</h3>
                    </div>
                </div>
                <div className='mt-3'>
                  <div className='row align-items-center g-2'>
                  <div className='col'><button type='button' className='btn btn-white w-50 text-center border text-14 d-flex align-items-center justify-content-center text-blue mx-auto'>Follow<i  className='ms-1'><img src={arrow} alt='' width={14} height={12}/></i></button></div>
                  </div>
                </div>
              </div>
              <div className='col border rounded-3 p-4 text-center bg-white'>
                <div className=' mb-2 mx-auto'>                          
                      <div className='newheadingstyle text-center'>
                        <figure><img src={facebook} alt='' /></figure>
                        <h3 className='mb-0 text-16'>Facebook</h3>
                    </div>
                </div>
                <div className='mt-3'>
                  <div className='row align-items-center g-2'>
                  <div className='col'><button type='button' className='btn btn-white w-50 text-center border text-14 d-flex align-items-center justify-content-center text-blue mx-auto'>Follow<i  className='ms-1'><img src={arrow} alt='' width={14} height={12}/></i></button></div>
                  </div>
                </div>
              </div>
              <div className='col border rounded-3 p-4 text-center bg-white'>
                <div className=' mb-2 mx-auto'>                          
                      <div className='newheadingstyle text-center'>
                        <figure><img src={instagram} alt='' /></figure>
                        <h3 className='mb-0 text-16'>Instagram</h3>
                    </div>
                </div>
                <div className='mt-3'>
                  <div className='row align-items-center g-2'>
                  <div className='col'><button type='button' className='btn btn-white w-50 text-center border text-14 d-flex align-items-center justify-content-center text-blue mx-auto'>Follow<i  className='ms-1'><img src={arrow} alt='' width={14} height={12}/></i></button></div>
                  </div>
                </div>
              </div>
              <div className='col border rounded-3 p-4 text-center bg-white'>
                <div className=' mb-2 mx-auto'>                          
                      <div className='newheadingstyle text-center'>
                        <figure><img src={twitter} alt='' /></figure>
                        <h3 className='mb-0 text-16'>Twitter</h3>
                    </div>
                </div>
                <div className='mt-3'>
                  <div className='row align-items-center g-2'>
                  <div className='col'><button type='button' className='btn btn-white w-50 text-center border text-14 d-flex align-items-center justify-content-center text-blue mx-auto'>Follow<i  className='ms-1'><img src={arrow} alt='' width={14} height={12}/></i></button></div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </>
    );
}

export default FollowUs