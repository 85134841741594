import React from "react";
import styles from "../styles/style.module.css";
import info from "../../../assets/images/info_icon.svg";
import Plus from "../../../assets/images/plus.png";
import Techschool from "../../Techschool/routes";
import { CONSTANT_MANAGER } from "../../../utils/constans/constants";
import Toltip from "../../common/toltip/page";
import tip from "../../../assets/images/tip.png";
const TechSchoolAds = () => {
  return (
    <div className={styles.techschool}>
      <div className="container">
        <div className={styles.new_feeds}>
          <div className="">
            <div className="row align-items-center justify-content-between mb-3">
              <div className="col-lg-6 col-12">
                <div className="newheadingstyle">
                  <div className="topTipHover">
                    <div className="d-flex align-items-center">
                      <h4 className="form-check-label text-20 fw-bold  font-inter">
                        Tech School Advertisements
                      </h4>
                      <i>
                        <img className="customeTolImg" src={tip} alt="" />
                      </i>
                      <Toltip
                        slug={CONSTANT_MANAGER.SLUG_FEED.FEED}
                        labelId={
                          CONSTANT_MANAGER.FEED_DATA.TECH_SCHOOL_ADVERTISEMENTS
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="text-end">
                  <div className="col-xl-5 col-md-11 col-6 ms-auto">
                    <button className="btn btn-primary h-40 text-14">
                      <i className="me-2">
                        <img src={Plus} alt="" width={10} height={10} />
                      </i>
                      Post New ads
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <Techschool />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechSchoolAds;
