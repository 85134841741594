import React, { useState } from "react";
import styles from "../styles/style.module.css";
import penbl from "../../../assets/images/penbl.png";

const Experience = () => {
  return (
    <div className={styles.AboutEdit}>
      
      <div className="row align-items-center justify-content-between mb-3">
        <div className="col-6">
          <h5 className="text-20 fw-semibold mb-0 ">Professional Experience{" "} </h5>
        </div>
        <div className="col-6">
          <div className=" text-end">            
            <span>
              <img src={penbl} alt="" />
            </span>
          </div>
        </div>
        <div className="col-12 mt-3">
          <h4 className="text-16 fw-medium mb-0">Work Experience{" "} </h4>
        </div>
      </div>
      <ul className={styles.experienceSet}>
        <li>
          <span className={styles.expUi}></span>
          <div>
            <h4>Freelance UX/UI designer</h4>
            <h5>Lorem ipsum Lorem ipsum</h5>
            <h6>
              Jun 2022 — Present <span>1 yrs 02 mos</span>
            </h6>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
              dapibus eros eu vehicula interdum. Cras nec ultricies massa.
              Curabitur rutrum, diam id consequat consequat
            </p>
          </div>
        </li>
        <li>
          <span className={styles.expUi}></span>
          <div>
            <h4>Freelance UX/UI designer</h4>
            <h5>Lorem ipsum Lorem ipsum</h5>
            <h6>
              Jun 2022 — Present <span>1 yrs 02 mos</span>
            </h6>
            <h4 className="text-12 fw-semibold">Responsibilities</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
              dapibus eros eu vehicula interdum. Cras nec ultricies massa.
              Curabitur rutrum, diam id consequat consequat
            </p>
          </div>
        </li>
        <li >
          <h4 className="text-14 fw-semibold text-blue">+ Add more</h4>
        </li>
      </ul>
    </div>
  );
};

export default Experience;
