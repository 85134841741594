import React from 'react';
import group from "../../assets/images/Group1686558045.png";
import style from "../css/congratulationsModal.module.css";

const LoginSucessModal = (props: any) => {
    return (
        <div className="py-3">
            <div className="text-center">
                <img src={group} alt="" />
                <h5 className="font-28" >
                    Congratulations!
                </h5>
                <div className={style.modal}>
                    <p>Login sucessfully</p>

                </div>
            </div>

            <div className={`${style.viewDashButtonStyle} row mt-4`}>
                <div className={`${style.viewDashButtonStyle}  text-center`}>
                    <button
                        className={`${style.viewDashButton} btn border btn-light rounded-pill primaryBtn submitBtn m-0`}
                        type="button"
                    // onClick={handleGoToMoreDetails}
                    >
                        <span> View Dashboard
                        </span>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default LoginSucessModal;