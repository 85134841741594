import { axios } from "../../../../lib/axios";
import { AxiosResponse } from "../../../../shared/models";
import { API_ROUTES } from "../../../../utils/routes/apiRoutes";
import { AcceptReq, ConnectWithFriendsResponse } from "../interface/interface";

export const ConnectionRequest = async (): Promise<
    AxiosResponse<ConnectWithFriendsResponse[]>
> => {
    return axios.get(API_ROUTES.FEED.CONNECT_REQUEST);
};

export const AcceptRequest = async (payload: AcceptReq, id: string): Promise<any> => {
    return axios.patch(`${API_ROUTES.FEED.REQUEST}/${id}`, payload);
};
