import React from "react";
import styles from "../styles/sigup.module.css";
import { useNavigate } from "react-router-dom";
import left from "../../../../assets/images/left.png";
import down from "../../../../assets/images/down.png";
import { dropdownValidationSchema } from "../validations";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { setAboutData } from "../../../../redux/reducers/auth";
import whitearrow from "../../../../assets/images/whitearrow.svg";
import { AUTH_ROUTES } from "../../../../utils/routes/AuthRoutes";
import HeaderContent from "../../../../shared/Components/HeaderContent/headerContent";
import { RootState } from "../../../../redux/store";

const About: React.FC = () => {
  const userDetail = useSelector((state: RootState) => state.userData);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const signupFormik: any = useFormik({
    initialValues: {
      selectedOption: userDetail ? userDetail?.aboutData?.About : "",
    },
    validationSchema: dropdownValidationSchema,
    onSubmit: (values) => {
      const selectedOption: any = {
        selectedOption: values.selectedOption,
      };
      dispatch(setAboutData(selectedOption));
      navigate(AUTH_ROUTES.PRIVACY_POLICY);
    },
  });
  return (
    <>
      <HeaderContent />
      <div className="py-4 heightautoscroll">
      <div className={styles.occupationdetails}>
        <div className={styles.signuppage}>
          {/* <div className={styles.signupHead}>
        <div className={styles.leftSign}>
          <img src={logo} alt="" />
        </div>
        <div className={styles.rightSign}>
          <p className="mb-0">
            Already have an account? <a href="/">Sign in</a>
          </p>
          <button>Need Help?</button>
        </div>
      </div> */}
          <div className={styles.signUPContainer} style={{padding: "15px"}}>
            <form onSubmit={signupFormik.handleSubmit}>
              <h5> How did you hear about us </h5>
              <span className={styles.Leftntm}>
                <a href="#">
                  <img src={left} alt="" onClick={() => navigate(-1)} />
                </a>
              </span>
              <p>From where you heard about us</p>
              <div className={styles.fromGroup}>
                <select
                  id="selectedOption"
                  name="selectedOption"
                  onChange={signupFormik.handleChange}
                  onBlur={signupFormik.handleBlur}
                  value={signupFormik.values.selectedOption}
                >
                  <option value="">Please select an option</option>
                  <option value="Google">Google </option>
                  <option value="Facebook">Facebook</option>
                  <option value="Linkdin">Linkdin</option>
                  <option value="Instagram">Instagram </option>
                  <option value="YouTube">YouTube </option>
                </select>
                {signupFormik.touched.selectedOption &&
                  signupFormik.errors.selectedOption && (
                    <div className={styles.error}>
                      {signupFormik.errors.selectedOption}
                    </div>
                  )}
                <span>
                  <img src={down} alt="" />
                </span>
              </div>

              {/* <div className={styles.fromGroup}>
            <input type="submit" value="Next ›" />
          </div> */}
              <div className="col-lg-3 col-6 mx-auto">
                <div className="text-center my-3 btn-hover-effect border rounded-pill">
                  {/* <input type="submit"/> */}
                  <button
                    type="submit"
                    className="btn bg-white arrowblue border-white border-1 rounded-pill position-relative py-lg-3 py-2 w-100 pe-5 text-center"
                  >
                    <span className="px-lg-4 px-2 text-12 fw-semibold w-100">Next</span>
                    <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                      <img src={whitearrow} alt="" />
                    </i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};
export default About;
