import React, { useEffect, useState } from 'react';
import styles from '../../css/styles/style.module.css';
import info from '../../../../assets/images/info.png';
import bags from '../../../../assets/images/bags.png';
import Slider from 'react-slick';
import moment from 'moment';
import { ContractResponse } from '../interface';
import { getContractList } from '../api';
import { TYPE, WORK_STATUS_TYPE } from '../../../../utils/enums/status';
import { CONTRACT_Type } from '../../../../utils/enums/types';
import { useDispatch } from 'react-redux';
import {
  setActiveStep,
} from '../../../../redux/reducers/addContract';
import { ADD_CONTRACT_STEPS } from '../../../../shared/enums';
import { AddContractRootState } from '../../../../models';
import { sendForApproval } from '../../../work/api';
import { APIResponseErrors } from '../../../work/interface';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { APP_ROUTES } from '../../../../utils/routes/AppRoutes';
import storage from '../../../../utils/storage';

const DraftContracts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [draftContarcts, setDraftContracts] = useState<ContractResponse[]>([]);


  const [loading, setLoading] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  }
  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 2,
    centerPadding: '60px',
    slidesToScroll: 2,
    infinite: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getDraftContracts = async () => {
    const response: any = await getContractList(
      CONTRACT_Type.HOURLY_CONTRACT,
      TYPE.DONEFORME,
      WORK_STATUS_TYPE.DRAFT
    );
    setDraftContracts(response?.data);
    setLoading(false);
  };

  useEffect(() => {
    getDraftContracts();
  }, []);

  const handleGoToEdit = (contract: ContractResponse) => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.OVERVIEW_FORM));
    // dispatch(
    //   initContract({
    //     ...(AddContractRootState.deserialize(contract) as any),
    //     activeStep: ADD_CONTRACT_STEPS.OVERVIEW_FORM,
    //   })
    // );
    storage.saveContract({
      ...AddContractRootState.deserialize(contract),
      activeStep: ADD_CONTRACT_STEPS.OVERVIEW_FORM,
    });
    navigate(APP_ROUTES.ADD_HOURLY_CONTRACT);
  };

  const sendForApprovalButton = async (contractId: string) => {
    try {
      const response = await sendForApproval(contractId);
      Swal.fire({
        title: 'Success',
        text: response.message as string,
        icon: 'success',
        confirmButtonText: 'OK',
      });
      getDraftContracts();
    } catch (error) {
      const apiError = error as APIResponseErrors;
      const errorMessage = apiError?.data?.message as string;

      if (apiError.data.status == 400) {
        Swal.fire({
          title: 'Please Review the form and add all the mandatory details!',
          html: `<span style="color: red;">${errorMessage}</span>`,
          icon: 'error',
          confirmButtonText: 'Complete Details',
          cancelButtonText: 'Cancel',
          showCancelButton: true,
          customClass: {
            confirmButton: 'confirmBtn',
            cancelButton: 'cancelBtn',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            // handleGoToEdit(data);
          }
        });
      } else {
        Swal.fire({
          title: 'Unexpected Error',
          text: errorMessage,
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    }
  };

  return (
    <>
      {draftContarcts?.length > 0 && (
        <div className="custom-ongoing">
          <div className={styles.OuterSlides}>
            <div className={styles.AllContract}>
              <h5>
                Contracts Saved in Draft <img src={info} alt="" />
              </h5>
              <div className={styles.rightFilter}>
                <div className={styles.formGroup}>
                  <span>Date and time</span>
                  <select>
                    <option>Date and time</option>
                    <option>Date and time</option>
                  </select>
                </div>
                <div className={styles.formGroup}>
                  <span>Sort</span>
                  <select>
                    <option>This Week</option>
                    <option>This Week</option>
                  </select>
                </div>
              </div>
            </div>
            {loading ? (
              <div className="spinner-grow text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <Slider {...settings}>
                {draftContarcts?.map((data, idx) => (
                  <div key={idx} className={styles.ContarctOuter}>
                    <div className={styles.ContarctHead}>
                      <div className={styles.leftContract}>
                        <h5>{data.occupation?.name}</h5>
                        {data.times.startDate ? (
                          <span>
                            Start Date :{' '}
                            {moment(data.times.startDate).format('DD-MM-YYYY')}{' '}
                            End Date :{' '}
                            {moment(data.times.endDate).format('DD-MM-YYYY')}
                          </span>
                        ) : (
                          ''
                        )}
                      </div>
                      <div
                        className={`${styles.RightContract} ${styles.leftContract}`}
                      >
                        <button className={styles.CoptText}>
                          <i className="far fa-copy"></i> Copy
                        </button>

                        <button>
                          {data.workStatus === 'draft' ? 'Draft' : ''}
                        </button>
                      </div>
                    </div>
                    <div className={styles.draftCity}>
                      <div className={styles.gratCtyLoc}>
                        <div>
                          <p>{data?.address ? data.address : data?.address == undefined ? "---" : ""}</p>
                          {data.createdAt ? (
                            <p>
                              Initiated{' '}
                              {moment(data.createdAt).format('DD-MM-YYYY')}
                            </p>
                          ) : (
                            ''
                          )}
                        </div>
                        <span>
                          <img src={bags} alt="White Glove Contract" />
                          {data.planType === 'whiteglove'
                            ? 'White Glove Contract'
                            : data.planType === 'Standard'
                              ? 'Standard'
                              : ''}
                        </span>
                      </div>
                      <div dangerouslySetInnerHTML={{
                        __html:  isExpanded && data.description ? data?.description : data?.description?.slice(0, 150)
                      }} />
                      <span onClick={toggleReadMore} className={styles.readMore}>
                        {isExpanded ? 'Show Less' : 'Read More'}
                      </span>
                      {data?.technicalSkills?.length && (
                        <div className={styles.draftSkills}>
                          <h5>Technical Skills</h5>
                          <ul>
                            {data.technicalSkills?.map((skill: any) => {
                              return (
                                <>
                                  <li>{skill.manufacturerDetails?.name ? skill.manufacturerDetails.name : "---"}</li>
                                  <li>
                                    {skill.manufacturerModelDetails?.name ? skill.manufacturerModelDetails?.name : "---"}
                                  </li>
                                </>
                              );
                            })}
                          </ul>
                        </div>
                      )}
                      <div className={styles.draftPayrate}>
                        <h5>Pay Rates</h5>
                        <ul>
                          <li>
                            <span>Base</span>
                            <label>${data.rates.baseRate}</label>
                          </li>
                          <li>
                            <span>Overtime</span>
                            <label>${data.rates.overTimeRate}</label>
                          </li>
                          <li>
                            <span>Sun/Holidays</span>
                            <label>${data.rates.doubleTimeRate}</label>
                          </li>
                        </ul>
                      </div>
                      <div className={styles.MainLink}>
                        {/* <Link to="#">Save</Link> */}
                        <button
                          onClick={() => handleGoToEdit(data)}
                          className={styles.edit}
                        >
                          Edit
                        </button>
                        <button
                          type="button"
                          onClick={() => sendForApprovalButton(data._id)}
                          className="btn btn-primary"
                        >
                          Send For Approval
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default DraftContracts;
