import React from "react";
import styles from "../../styles/styles.module.css";
import "react-markdown-editor-lite/lib/index.css";
import { useDispatch, useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useFormik } from "formik";
import storage from "../../../../../utils/storage";
import { RootState } from "../../../../../redux/store";
import {
  setActiveStep,
  setProceduresAndPolicies,
} from "../../../../../redux/reducers/addContract";
import { ADD_CONTRACT_STEPS } from "../../../../../shared/enums";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import Occupations from "../../../../../assets/images/occupations.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";

const ProcessorAndPolicies: React.FC = () => {
  const dispatch = useDispatch();
  const { proceduresAndPolicies } = useSelector(
    (state: RootState) => state.addContract
  );

  const formIk = useFormik({
    initialValues: {
      ...proceduresAndPolicies,
    },
    onSubmit: () => {
      dispatch(setActiveStep(ADD_CONTRACT_STEPS.OVERVIEW_FORM));
    },
  });

  const handleGoBack = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_SECURITY_REQUIREMENT));
  };

  const handleSkip = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.COMPANY_INFORMATION));
  };
  const handleGoToNextStep = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.OVERVIEW_FORM));
  };

  const onValueChange = (text: string) => {
    dispatch(
      setProceduresAndPolicies({
        ...proceduresAndPolicies,
        proceduresAndPolicies: text,
      })
    );
  };

  const handleFileChange = () => { };

  return (
    <>
      <HeaderContent />
      <div className="py-4 heightautoscroll">
      <div className={styles.signuppage}>
        <div className="contractpages">
          
            <form onSubmit={formIk.handleSubmit}>
              <div className={styles.registerOuter}>
                <div className="container">
                  <div className={styles.registerBord}>
                    <div className="row">
                      <div className="col-md-6">
                      <div className={styles.registerLeft}>
                              <h4 className="text-black text-38 fw-semibold">
                                Procedures and Policies
                              </h4>
                              <p className="text-16 text-white opacity-75">
                                You can provide all the information here
                                regarding the contract; you just need to fill in
                                all the details here.
                              </p>
                           
                        <div
                          className="col-lg-11 col-12"
                        >
                          {/* <p>Add a Legal information of the work that you need</p> */}
                          <figure>
                            <img
                              src={Occupations}
                              alt=""
                              className="img-fluid"
                            />
                          </figure>
                        </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className={styles.signUPContaine}>
                          <div
                            className={`${styles.registerRight} ${styles.editors}`}
                          >
                            
                            <div className={styles.fromGroup}>
                              <label>
                                Procedures and Policies
                              </label>
                              <CKEditor
                                editor={ClassicEditor}
                                data={
                                  proceduresAndPolicies.proceduresAndPolicies
                                }
                                onChange={(event, editor) =>
                                  onValueChange(editor.getData())
                                }
                              />
                            </div>
                          
                          </div>
                        </div>
                        <div className="row align-items-center justify-content-between mt-4">
                          <div className="col-4 text-start my-3 btn-hover-effect">
                            <h6
                              onClick={() => handleGoBack()}
                              className="btn bg-white arrowyellow border-white border-1 rounded-pill position-relative py-lg-3 py-2 ps-5 backbutton"
                            >
                              <i className="bg-yellow rounded-pill arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                                <img src={blackarrow} alt="" />
                              </i>
                              <span className="px-lg-4 px-2 text-12 fw-semibold">
                                Back
                              </span>
                              
                            </h6>
                          </div>
                          <div className="col-8">
                            <div
                              className="d-flex align-items-center jusify-content-end"
                             
                            >
                              <div className="col-6 text-end">
                                {/* <SaveAsDraftButton /> */}
                                <button
                                  className="btn bg-secondary border-grey border-1 rounded-pill position-relative py-lg-3 py-2 px-4"
                                  onClick={handleGoToNextStep}
                                >
                                  <span  className="px-lg-4 px-2 text-12 fw-semibold text-black">Skip</span>
                                </button>
                              </div>
                              <div className="col-6 text-end my-3 btn-hover-effect">
                                <h6
                                  onClick={handleGoToNextStep}
                                  className="btn bg-white arrowblue border-white border-1 rounded-pill position-relative py-lg-3 py-2 pe-5"
                                >
                                  <span className="px-lg-4 px-2 text-12 fw-semibold">
                                    Next
                                  </span>
                                  <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                                    <img src={whitearrow} alt="" />
                                  </i>
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProcessorAndPolicies;
