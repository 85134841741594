
import React, { FC } from "react";
import styles from "../../../../../components/work/styles/style.module.css"
import Occupations from "../../../../../assets/images/occupations.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useAppSelector } from "../../../../../redux/hooks";
import { RootState } from "../../../../../redux/store";
import {
  setActiveStep,
  setWorkDescription,
} from "../../../../../redux/reducers/addDirectJob";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import tip from "../../../../../assets/images/tip.png";
import { CONSTANT_MANAGER } from "../../../../../utils/constans/constants";
import { descriptionSchema } from "../../../../../components/work/validations";
import { DIRECT_JOB_STEPS } from "../Enums";
import Toltip from "../../../../../components/common/toltip/page";


const AddWorkDescriptionForDirectJob: FC = () => {
  const dispatch = useDispatch();
  const contract = useAppSelector((state: RootState) => state.directJob);

  const formIk: any = useFormik({
    initialValues: {
      description: contract.workDescription,
    },
    validationSchema: descriptionSchema,
    onSubmit: (values) => {
      console.log(values);

      dispatch(setActiveStep(DIRECT_JOB_STEPS.ADD_LOCATIONS));
    },
  });

  const handleGoBack = () => {
    dispatch(setActiveStep(DIRECT_JOB_STEPS.ADD_OCCUPATION));
  };

  const onValueChange = (value: string) => {
    formIk.setFieldValue("description", value);
    dispatch(setWorkDescription(value));
  };
  const editorConfiguration = {
    toolbar: [
      "heading",
      "|",
      "undo",
      "redo",
      "|",
      "bold",
      "italic",
      "|",
      "bulletedList",
      "numberedList",
    ],
    height: "150px",
  };

  return (
    <>
      <HeaderContent />
      <div className="py-4 heightautoscroll">
        <div className={styles.signuppage}>
          <div className="contractpages">
            <form onSubmit={formIk.handleSubmit} className="h-100">
              <div className={styles.registerOuter}>
                <div className="container h-100">
                  <div className={styles.registerBord}>
                    <div className="row pt-2 h-100 align-items-start">
                      <div className="col-lg-6">
                      <div className={styles.registerLeft}>
                              <h4 className="text-white text-32 fw-semibold">
                                What Do You Need Done?
                              </h4>
                              <p className="text-16 text-white opacity-75">
                                Tell us what needs to be done. 10 Words or More
                                Please.
                              </p>
                          
                        <div className="col-lg-11 col-12"
                        >
                          <figure>
                            <img
                              src={Occupations}
                              alt=""
                              className="img-fluid"
                            />
                          </figure>
                        </div>
                      </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className={styles.signUPContainer}>
                          <div
                            className={`${styles.registerRight} ${styles.editors}`}
                          >
                            
                            <div className={styles.fromGroup} style={{ marginBottom: "30px" }}>
                              <div className="topTipHover">
                                <label className={styles.starlabel}>
                                  Description
                                </label>
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                                <Toltip
                                  slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                  labelId={
                                    CONSTANT_MANAGER.ADD_CONTRACT.DESCRIPTION
                                  }
                                />
                              </div>
                              <div
                                className={`${styles.desw_editor} custom-editor`}
                              >
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={contract.workDescription}
                                  config={editorConfiguration}
                                  onChange={(event, editor) =>
                                    onValueChange(editor.getData())
                                  }
                                />
                              </div>

                              {formIk.touched.description &&
                                formIk.errors.description ? (
                                <div className={styles.error}>
                                  {formIk.errors.description}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="row align-items-center justify-content-between">
                          <div className="col-4 text-start my-3 btn-hover-effect">
                            <h6
                              onClick={() => handleGoBack()}
                              className="btn bg-white arrowyellow border-white border-1 rounded-pill position-relative py-lg-3 py-2 ps-5 backbutton mb-0"
                            >
                              <i className="bg-yellow rounded-pill arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                                <img src={blackarrow} alt="" />
                              </i>
                              <span className="px-lg-4 px-2 ps-3 text-12 fw-semibold d-block">
                                Back
                              </span>

                            </h6>
                          </div>
                          <div className="col-8">
                            <div
                              className="d-flex align-items-center jusify-content-end"
                              style={{ gap: "10px" }}
                            >
                              {/* <div className="col-6">
                                <SaveAsDraftButton />
                              </div> */}
                              <div className="col-12 text-end my-3 btn-hover-effect">
                                <button
                                  type="submit"
                                  // onClick={handleGoToNextStep}
                                  className="btn bg-white arrowblue border-white border-1 rounded-pill position-relative py-lg-3 py-2 pe-5 mb-0"
                                >
                                  <span className="px-lg-4 px-2 pe-lg-3 text-12 fw-semibold d-block">
                                    Next
                                  </span>
                                  <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                                    <img src={whitearrow} alt="" />
                                  </i>
                                </button>
                              </div>

                              {/* <button  > Next</button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddWorkDescriptionForDirectJob;
