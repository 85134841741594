import React from "react";
import { setActiveStep } from "../../redux/reducers/addContract";
import { ADD_CONTRACT_STEPS } from "../enums";
import { useDispatch } from "react-redux";
import group from "../../assets/images/Group1686558045.png";

const ConfirmModal = (props: any) => {
    const dispatch = useDispatch()

    const handleGoToMoreDetails = () => {
        dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_BASIC_INFO));
    }

    return (
        <div className="py-3">
            <div className="text-center">
                <img src={group} alt="" />
            </div>
            <h5 className="font-28 text-center" >
            Would you like to Add More Details?
            </h5>

            <div className="row justify-content-center mt-4">
                <div className="d-grid gap-1 col-lg-3 col-md-6">
                    <button
                        className="btn border btn-primary rounded-pill primaryBtn submitBtn m-0 py-3"
                        type="button"
                        onClick={handleGoToMoreDetails}
                    >
                        <span> Add Now
                        </span>
                    </button>
                </div>
                <div className="d-grid gap-1 col-lg-3 col-md-6">
                    <button
                        className="btn border btn-light rounded-pill border primaryBtn cancelBtn m-0 py-3"
                        type="button"
                        onClick={() => props.onCloseModal()}
                    >
                        <span>Not Now</span>
                    </button>
                </div>
            </div>
        </div>
    );
};
export default ConfirmModal;
