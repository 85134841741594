import { axios } from "../../../../lib/axios";
import { API_ROUTES } from "../../../../utils/routes/apiRoutes";
import { ChnageStatusTimesheet } from "../../timesheets/interface";
import { ExpensePayload } from "../interface";

export const getExpense = async (workId: any, type: string): Promise<any> => {
    return axios.get(`${API_ROUTES.EXPENSE.EXPENSE}?workId=${workId}&type=${type}`);
};

export const getexpenseById = async (id: any): Promise<any> => {
    return axios.get(`${API_ROUTES.EXPENSE.GET_EXPENSE_BY_ID}/${id}`);
};
export const addexpense = async (payload: ExpensePayload): Promise<ExpensePayload> => {
    return axios.post(API_ROUTES.EXPENSE.EXPENSE, payload);
};
export const updateExpense = async (payload: ExpensePayload): Promise<ExpensePayload> => {
    return axios.post(API_ROUTES.EXPENSE.EXPENSE, payload);
};
export const expenseSendforApproval = (expenseId: string,) => {
    return axios.put(`${API_ROUTES.EXPENSE.SEND_FOR_APPROVAL}/${expenseId}`,)
  }
  export const changeExpenseStatus = async (id: any, status: any): Promise<ChnageStatusTimesheet> => {
    return axios.put(`${API_ROUTES.EXPENSE.CHANGE_STATUS}${id}`, { status: status });
  };
  export const getInvoice = async (workId: any, type: string,isBusiness:boolean): Promise<any> => {
    return axios.get(`${API_ROUTES.INVOICE.GET_INVOICE}?workId=${workId}&type=${type}&isBusiness=${isBusiness}`);
};
