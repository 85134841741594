import "bootstrap/dist/css/bootstrap.min.css";
// import { format } from "date-fns";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import chatdot from "../../../assets/images/chatdot.png";
import chatone from "../../../assets/images/chatone.png";
import Header from "../../header/routes";
import { getUserProfileById } from "../../userprofile/api";
import styles from "../styles/style.module.css";

import moment from "moment";
import io, { Socket } from "socket.io-client";
import storage from "../../../utils/storage";
import { fetchUserChatLists } from "../api";
import BlockModal from "./blockModal";
import ReportModal from "./reportModal";
import {
  format,
  isToday,
  isYesterday,
  differenceInDays,
  formatDistanceToNow,
} from "date-fns";
import { getuserProfile } from "../../home/api";
import { userProfileResponse } from "../../home/interface";
const serverURL = "https://dev-api.automateamerica.com";

export interface User {
  id: string;
  name: string;
  profile_image: string;
  status?: boolean;
}

export interface Message {
  id: number;
  senderId: any;
  lastMessage: string;
  createdAt: string;
  timestamp: string;
  status: "unread" | "read" | "blocked" | "reported";
}

function Chat() {
  const location = useLocation();
  const navigate = useNavigate();
  const [socket, setSocket] = useState<Socket | null>(null);
  const [otherUserInfo, setOtherUserInfo] = useState<User | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [filteredMessages, setFilteredMessages] = useState<Message[]>([]);
  const [userChatInfo, setUserChatInfo] = useState<any[]>([]);
  const [messagess, setAllMessages] = useState<any[]>([]);
  const [message, setMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [filterType, setFilterType] = useState<
    "all" | "unread" | "blocked" | "reported"
  >("all");
  const [chatUsers, setChatUsers] = useState<User[]>([]);
  const scrollToBottomRef = useRef<HTMLDivElement>(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showBlockModal, setShowBlockModal] = useState(false);
  const [isBlocked, setIsBlocked] = useState<boolean>(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [profilePicture, setProfilePicture] = useState<string | null>(null);

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const userId: string | null = location.pathname.split("/")[2] ?? null;
  useEffect(() => {
    const socketConnection = io(serverURL);
    setSocket(socketConnection);

    return () => {
      socketConnection.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!socket) return;

    socket.on("connect", () => {
      console.log("Connected to server");
    });

    socket.on("disconnect", () => {
      console.log("Disconnected from server");
    });

    socket.on("getChats", (data: { data: Message[]; pagination: any }) => {
      const history = data.data;
      console.log("Chat history received:", data);
      setMessages(history);
      filterMessages(history);
      scrollToBottom();
    });

    socket.on("receiveMessage", (newMessage: Message) => {
      console.log("New message received:", newMessage);
      let newMessagecreatedAt = { createdAt: newMessage?.timestamp };
      let newMessageN = {
        ...newMessage,
        ...newMessagecreatedAt,
      };
      setAllMessages((prevMessages) => {
        console.log("prevMessages", prevMessages.length);
        const updatedMessages = [...prevMessages, newMessageN];
        filterMessages(updatedMessages);
    
        if (userId) {
          // fetchUserData(userId);
          socket.emit("markAsSeen", {
            senderId: storage.getAuthUserId(),
            receiverId: userId,
          });
        }
        return updatedMessages;
      });
      scrollToBottom();
    });

    socket.on("messageHistory", (history: Message[]) => {
      console.log("Message history:", history);
      setMessages(history);
      filterMessages(history);
      scrollToBottom();
      setAllMessages(history);
    });
    socket.on("messagesSeen", ({ roomId, receiverId, isSeen }) => {
      console.log(`Messages seen for room ${roomId} by receiver ${receiverId}:`, isSeen);
      // Handle UI updates if necessary, e.g., marking messages as seen in the UI
    });
    return () => {
      socket.off("getChats");
      socket.off("receiveMessage");
      socket.off("messageHistory");
      socket.off("messagesSeen");
    };
  }, [socket,userId]);

  useEffect(() => {
  
    if (userId) {
      fetchUserData(userId);

      if (socket) {
        socket.emit("join", { senderId: storage.getAuthUserId() });
        socket.emit("getAllChats", {
          senderId: storage.getAuthUserId(),
          limit: 10,
          page: 1,
          type: "all",
        });
        socket.emit("joinChat", {
          senderId: storage.getAuthUserId(),
          receiverId: userId,
        });
      }
    }

    fetchChatUsers();
  }, [location.pathname, socket]);

  useEffect(() => {
    const fetchProfilePicture = async () => {
      try {
        const response: any = await getuserProfile();
        console.log("response profile", response);

        // Ensure that data is not empty and profilePicture exists
        if (response.data?.profilePicture) {
          setProfilePicture(response.data.profilePicture);
        }
        console.log(
          "jgegegjkegfgfgrfsaasdasdasd",
          response.data.profilePicture
        );
      } catch (error) {
        console.error("Failed to fetch profile picture:", error);
      }
    };

    fetchProfilePicture();
  }, []);

  const fetchUserData = (userId: string) => {
    getUserProfileById(userId, "chats")
      .then((response) => {
        const userData = response.data;
        console.log("userData", userData);
        const user: User = {
          id: userData._id.toString(),
          name: userData.name,
          profile_image: userData.profilePicture,
          status: userData?.status,
        };
        setOtherUserInfo(user);
        setIsBlocked(userData?.isBlocked);
      })
      .catch((error) => {
        console.error("Error fetching user profile:", error);
      });

    console.log("isBlocked", isBlocked);
  };

  const filterMessages = (msgs: Message[]) => {
    let filtered: Message[] = [];
    switch (filterType) {
      case "unread":
        filtered = msgs.filter((msg) => msg.status === "unread");
        break;
      case "blocked":
        filtered = msgs.filter((msg) => msg.status === "blocked");
        break;
      case "reported":
        filtered = msgs.filter((msg) => msg.status === "reported");
        break;
      case "all":
      default:
        filtered = msgs;
    }

    setFilteredMessages(filtered);
  };

  const handleFilterChange = (
    filter: "all" | "unread" | "blocked" | "reported"
  ) => {
    setFilterType(filter);
    filterMessages(messages);
    fetchChatUsers(filter);
  };

  const handleMessageSend = () => {
    if (message && socket && otherUserInfo) {
      socket.emit("sendMessage", {
        senderId: storage.getAuthUserId(),
        receiverId: otherUserInfo.id,
        message: message,
        created_at: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
        status: "read",
      });
      setMessage("");
      fetchChatUsers();
    }
  };

  const handleMessageSendOnEnter = (event: any) => {
    if (event.key === "Enter") {
      if (message && socket && otherUserInfo) {
        socket.emit("sendMessage", {
          senderId: storage.getAuthUserId(),
          receiverId: otherUserInfo.id,
          message: message,
          created_at: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
          status: "read",
        });
        setMessage("");
        fetchChatUsers();
      }
    }
  };

  const handleUserClick = (user: any) => {
    console.log(user);
    fetchUserData(user._id);
    navigate(`/chat-page/${user._id}`);
  };
  const loadMoreMessages = () => {
    setPage((prevPage) => prevPage + 1);
    if (socket) {
      socket.emit("getAllChats", {
        senderId: storage.getAuthUserId(),
        limit: 10,
        page: page + 1,
        type: filterType,
      });
    }
  };

  const scrollToBottom = () => {
    if (messages?.length <= 10)
      scrollToBottomRef.current?.scrollIntoView({
        block: "end",
        inline: "nearest",
      });
  };

  function debounce<T extends (...args: any[]) => void>(
    func: T,
    delay: number
  ): (...args: Parameters<T>) => void {
    let timer: ReturnType<typeof setTimeout>;
    return (...args: Parameters<T>) => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }

  const fetchChatUsers = useCallback(
    (filter: string = filterType, searchValue: string = "") => {
      console.log(
        "fetchChatUsers called with filter:",
        filter,
        "searchValue:",
        searchValue
      ); // Debugging line
      fetchUserChatLists(filter, searchValue)
        .then((users) => {
          setChatUsers(users); // Update state with fetched user list
        })
        .catch((error) => {
          console.error("Error fetching user chat list:", error);
        });
    },
    [filterType]
  );

  const debouncedFetchChatUsers = useCallback(debounce(fetchChatUsers, 300), [
    filterType,
  ]);

  const fetchChatUsersSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    let searchValue = event.target.value;
    console.log("fetchChatUsersSearch called with searchValue:", searchValue); // Debugging line
    debouncedFetchChatUsers(filterType, searchValue);
  };
  useEffect(() => {
    scrollToBottom();
  }, [filteredMessages]);

  const handleBlockSuccess = () => {
    setIsBlocked(!isBlocked); // Update blocked status on success
  };

  const toggleDropdown = () => {
    // setShowBlockModal(true);
    setShowDropdown(true);
  };

  const openBlockModal = () => {
    setShowBlockModal(true);

    setShowDropdown(false); // Close the dropdown when opening the modal
  };

  const openReportModal = () => {
    setShowReportModal(true);

    setShowDropdown(false); // Close the dropdown when opening the modal
  };
  const closeBlockModal = () => {
    setShowBlockModal(false);
  };
  const closeReportModal = () => {
    setShowReportModal(false);
  };
  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      <Header />
      <div className={styles.chatOuter}>
        <div className="container">
          <div className={styles.chatMain}>
            <div className={styles.chatLeft}>
              {/* <div className={styles.leftchathead}>
                <div>
                  <h3>
                    Messages <span>angle-down</span>
                  </h3>
                  <h4>16 Free Connects Available</h4>
                </div>
                <button>Buy Connects</button>
              </div> */}
              <div className={styles.seahChat}>
                <input
                  type="search"
                  onChange={fetchChatUsersSearch}
                  placeholder="Search messages"
                  //   onKeyUp={fetchChatUsersSearch}
                />
              </div>
              <div className={styles.userList}>
                <ul className={styles.memberList}>
                  <li
                    className={filterType === "all" ? styles.active : ""}
                    onClick={() => handleFilterChange("all")}
                  >
                    <span>All</span>
                  </li>
                  <li
                    className={filterType === "unread" ? styles.active : ""}
                    onClick={() => handleFilterChange("unread")}
                  >
                    <span>Unread</span>
                  </li>
                  <li
                    className={filterType === "blocked" ? styles.active : ""}
                    onClick={() => handleFilterChange("blocked")}
                  >
                    <span>Blocked</span>
                  </li>
                  <li
                    className={filterType === "reported" ? styles.active : ""}
                    onClick={() => handleFilterChange("reported")}
                  >
                    <span>Reported</span>
                  </li>
                </ul>

                <ul className={styles.userList}>
                  {chatUsers.length ? (
                    chatUsers.map((user: any) => (
                      <li
                        key={user.id}
                        className={styles.userListItem}
                        onClick={() => handleUserClick(user?.user)}
                      >
                        <span>
                          <img
                            height={48}
                            width={48}
                            className={styles?.objectFitContain}
                            src={
                              user?.user?.profilePicture &&
                              user?.user?.profilePicture.includes("https://")
                                ? user?.user?.profilePicture
                                : chatone
                            }
                            alt={user.name}
                          />
                        </span>
                        <div>
                          <h5>
                            {user?.user?.name}
                            {/* <span>{formatDate(user?.user?.createdAt)}</span> */}
                          </h5>
                          <h6>{user?.lastMessage}</h6>
                        </div>
                      </li>
                    ))
                  ) : (
                    <li>No record found!</li>
                  )}
                </ul>
              </div>
            </div>
            <div className={styles.chatRight}>
              <div className={styles.chatLOgin}>
                <span>Admin is monitoring these messages</span>
              </div>
              {otherUserInfo && (
                <div className={styles.chatRightHead}>
                  <div className={styles.leftHead}>
                    <span>
                      <img
                        height={48}
                        width={48}
                        className={styles?.objectFitContain}
                        src={
                          otherUserInfo?.profile_image &&
                          otherUserInfo?.profile_image.includes("https://")
                            ? otherUserInfo?.profile_image
                            : chatone
                        }
                        alt=""
                      />
                    </span>
                    <div>
                      <h5>{otherUserInfo.name}</h5>
                      <span>
                        {otherUserInfo?.status ? "Online" : "offline"}
                      </span>
                    </div>
                  </div>
                  <div
                    className={styles.chatDots}
                    onClick={toggleDropdown}
                    ref={dropdownRef}
                  >
                    <img src={chatdot} alt="" />
                    {showDropdown && (
                      <ul
                        className="dropdown-menu"
                        style={{ display: "block" }}
                      >
                        <li>
                          <button
                            className="dropdown-item"
                            onClick={() => openBlockModal()}
                          >
                            {isBlocked ? "UnBlock" : "Block"}
                          </button>
                        </li>
                        <li>
                          <button
                            className="dropdown-item"
                            onClick={() => openReportModal()}
                          >
                            Report
                          </button>
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              )}
              <div className={styles.pageChat}>
                {messagess.map((msg) => (
                  <div
                    key={msg.id}
                    className={`${styles.usrChat} ${
                      msg.senderId === storage.getAuthUserId()
                        ? styles.clientchta
                        : ""
                    }`}
                  >
                    {msg.senderId !== storage.getAuthUserId() && (
                      <span>
                        <img
                          height={48}
                          width={48}
                          className={styles?.objectFitContain}
                          src={
                            otherUserInfo?.profile_image &&
                            otherUserInfo?.profile_image.includes("https://")
                              ? otherUserInfo?.profile_image
                              : chatone
                          }
                          alt={otherUserInfo?.name}
                        />
                      </span>
                    )}
                    <div>
                      <label>{msg.message}</label>
                      <span style={{ fontSize: "10px" }}>
                        {formatDistanceToNow(new Date(msg.createdAt), {
                          addSuffix: true,
                        })}
                      </span>
                    </div>
                    {msg.senderId === storage.getAuthUserId() && (
                      <span>
                        {profilePicture ? (
                          <img
                            src={profilePicture}
                            alt="Profile"
                            height={48}
                            width={48}
                          />
                        ) : (
                          <img src={chatone} alt="" />
                        )}
                      </span>
                    )}
                  </div>
                ))}
                <div ref={scrollToBottomRef}></div>
              </div>
              {!isBlocked ? (
                <div className={styles.sentCaht}>
                  {/* <span>paperclip</span> */}
                  <div className={styles.formGroup}>
                    <input
                      type="text"
                      placeholder="Type a message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      onKeyPress={handleMessageSendOnEnter}
                    />
                  </div>
                  <label onClick={handleMessageSend}>Send</label>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      {showBlockModal && (
        <BlockModal
          onClose={closeBlockModal}
          userInfo={otherUserInfo}
          onBlockSuccess={handleBlockSuccess}
          // onUnblockSuccess={handleUnBlockSuccess}
          isBlocked={isBlocked}
        />
      )}
      {showReportModal && (
        <ReportModal onClose={closeReportModal} userInfo={otherUserInfo} />
      )}
    </>
  );
}

export default Chat;