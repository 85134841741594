import React, { FC, useEffect, useState } from "react";
import Header from "../../../header/routes";
import styles from "../styles/style.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { WORK_STATUS_TYPE } from "../../../../utils/enums/status";
import moment from "moment";
import Swal from 'sweetalert2';
import { toast } from "react-toastify";
import {
  addTimesheet,

  getTimesheetById,
  timesheetSendforApproval,
  updateTimesheet,
} from "../api";
import { MESSAGES } from "../../../../utils/messages";
import { APP_ROUTES } from "../../../../utils/routes/AppRoutes";
import {
  getDaysOfWeek,
  getMondayOfCurrentWeek,
} from "../../../../utils/daysMap";
import { COUNT_TYPE } from "../../../../utils/enums/types";
import { APIResponseError, WorkCountResponse } from "../../Contracts/interface";
import { getWorkDetailWithCount } from "../../Contracts/api";

interface TimeSheetFields {
  date: Date;
  hours: number;
  overTimeHours?: number;
  doubleTime?: number;
  travelHours?: number;
  travelTime: number;
  statusReport: string;
  isHoliday: boolean,
  total: number
}

interface Props {
  isEditMode?: boolean;
}

const Addtimesheet: FC<Props> = ({ isEditMode }) => {

  const { id, timesheetId } = useParams();
  const navigate = useNavigate();
  const mondayDate = getMondayOfCurrentWeek();
  const [workDetails, setWorkDetails] = useState<WorkCountResponse>();
  const [showApprovalBtn, setShowApprovalBtn] = useState<boolean>(false);
  const [showAddBtn, setShowAddBtn] = useState<boolean>(true);
  const [timeSheetDays, setTimeSheetDays] = useState<any>([]);


  useEffect(() => {
    if (isEditMode) {
      initWithEdit();
    } else {
      initWithAddMode();
    }
  }, []);

  const days: TimeSheetFields[] = [];
  const daysOfWeek = getDaysOfWeek();

  daysOfWeek.forEach((day) => {
    days.push({
      date: day,
      hours: 0,
      doubleTime: 0,
      isHoliday: false,
      travelHours: 0,
      statusReport: "",
      travelTime: 0,
      total: 0,
    });
  });
  const initWithEdit = async () => {
    const response = await getTimesheetById(timesheetId);
    if (response?.data?.timesheetDetails?.length > 0) {
      const updatedA = days?.map(itemA => {
        const matchingItem: any = response.data.timesheetDetails.find(itemB => new Date(itemB.workedDate).toISOString() === itemA.date.toISOString());
        return {
          ...itemA,
          hours: matchingItem?.hourPerDay ? Number(matchingItem?.hourPerDay) : 0,
          total: matchingItem?.total ? Number(matchingItem?.total) : 0,
          doubleTime: matchingItem?.isHoliday ? matchingItem?.isHoliday : false,
          isHoliday: matchingItem?.isHoliday ? matchingItem?.isHoliday : false,
          travelTime: matchingItem?.travelTime ? matchingItem?.travelTime : 0,
          statusReport: matchingItem?.dailyStatus ? matchingItem?.dailyStatus : "",
          overTimeHours: 0,
          travelHours: 0,
        };
      });
      setTimeSheetDays(updatedA);
    }
  };

  const initWithAddMode = () => {
    setTimeSheetDays(days);
  };

  useEffect(() => {
    const getWorkDetials = async () => {
      const response: any = await getWorkDetailWithCount(
        id,
        COUNT_TYPE.REPORTS
      );
      setWorkDetails(response?.data);
    };
    getWorkDetials();
  }, []);

  const handleAddTimesheet = async () => {
    const payload: any = {
      workId: id,
      totalStraightTime: 40,
      overTime: totalOverTime,
      doubleTime: totalResuls.totalDoubleTime,
      totalTravelTime: totalResuls.totalTravel,
      totalHours: totalResuls.totalHours,
      ssaRates: {
        baseRate: 48.5,
        overTimeRate: 60.0,
        doubleTimeRate: 78,
        travelTimeAllowed: 0,
      },
      status: WORK_STATUS_TYPE.DRAFT,
      timesheetDetails: timeSheetDays
        .filter((x: any) => x.date < new Date())
        .map((x: any) => ({
          workedDate: x.date.toISOString(),
          hourPerDay: x.hours.toString(),
          dailyStatus: x.statusReport,
          isHoliday: x.isHoliday,
          travelTime: x.travelTime,
          total: (
            totalResuls.totalHours +
            totalResuls.totalDoubleTime +
            totalResuls.totalTravel
          ).toString(),
        })),
    };
  
    // Show a loading toast while processing
    const loadingToastId = toast.loading(MESSAGES.POSTING_CONTRACTS.VERIFYING);
  
    try {
      if (isEditMode) {
        await updateTimesheet(timesheetId, payload);
        toast.dismiss(loadingToastId);
        setShowApprovalBtn(true);
        setShowAddBtn(false);
        toast.success("Timesheet updated successfully!");
      } else {
        await addTimesheet(payload);
        toast.dismiss(loadingToastId); 
        setShowAddBtn(false);
        navigate(`${APP_ROUTES.TIMESHEETDETAIL_TABLE}/${id}`);
        toast.success("Timesheet added successfully!");
      }
    } catch (error) {
      toast.dismiss(loadingToastId);
      const errorMessage = (error as any)?.data?.message;
      Swal.fire({
        title: 'Error!',
        text: errorMessage,
        icon: 'error',
        confirmButtonText: 'Try Again',
      });
    }
  };
  const handleStatusChange = () => {
    toast.promise(timesheetSendforApproval(timesheetId as string), {
      pending: {
        render() {
          return MESSAGES.WORK.APPLY;
        },
      },
      success: {
        render() {
          Swal.fire({
            title: 'Congratulations!',
            text: MESSAGES.TIMESHEET.SENTFORPPROVAL,
            icon: 'success',
            confirmButtonText: 'OK',
          });
          return MESSAGES.TIMESHEET.SENTFORPPROVAL;
        },
      },
      error: {
        render(error) {
          const errorMessage = (error as any)?.data?.message ;
          
          Swal.fire({
            title: 'Error!',
            text: errorMessage,
            icon: 'error',
            confirmButtonText: 'Try Again',
          });
          return errorMessage; 
        },
      },
    });
  };
  const onTimesheetValueChange = (
    value: number | string | boolean,
    key: string,
    idx: number
  ) => {
    const updatedValues = [...timeSheetDays];
    const updatedDay = { ...updatedValues[idx], [key]: value };
    updatedValues[idx] = updatedDay;
    setTimeSheetDays(updatedValues);
  };

  const totalResuls = timeSheetDays.reduce(
    (prev: any, curr: any) => {
      prev.totalTravel += curr.travelTime ?? 0;

      if (curr.isHoliday) {
        prev.totalDoubleTime += curr.hours;
      }
      prev.totalHours += curr.hours + curr.travelTime;

      return prev;
    },
    { totalHours: 0, totalDoubleTime: 0, totalTravel: 0 }
  );

  const totalOverTime =
    (totalResuls.totalHours - totalResuls.totalDoubleTime - totalResuls.totalTravel) > 40 ? (totalResuls.totalHours - totalResuls.totalDoubleTime - totalResuls.totalTravel) - 40 : 0;

  const totalStraight =
    (totalResuls.totalHours - totalResuls.totalDoubleTime - totalResuls.totalTravel) <= 40 ? (totalResuls.totalHours - totalResuls.totalDoubleTime - totalResuls.totalTravel) : 40;
  const currentdate: any = new Date();

  return (
    <>
      <Header />
      <div className={styles.addroutes}>
        <div className="container">
          <h3>Add new Timesheet</h3>
          <div className={styles.timeshettrd}>
            <div className={styles.addroutesouter}>
              <div className={styles.timeshethead}>
                <h5>Timesheet details Details</h5>
                <div>
                  <button>Print</button>
                  <button>Export data</button>
                </div>
              </div>
              <div className={styles.tabeltata}>
                <div className={styles.tabeltatabrd}>
                  <span>Engineers :</span>
                </div>
                <div className={styles.tabeltatabrd}>
                  <span>{workDetails?.applyUser?.name}</span>
                </div>
                <div className={styles.tabeltatabrd}>
                  <span>Monday / Week Start Date</span>
                </div>
              </div>

              <div className={styles.tabeltata}>
                <div className={styles.tabeltatabrd}>
                  <span>Customer Company Name :</span>
                </div>
                <div className={styles.tabeltatabrd}>
                  <span>{workDetails?.companyDetails?.name}</span>
                </div>
                <div className={styles.tabeltatabrd}>
                  <span>{mondayDate}</span>
                </div>
              </div>

              <div className={styles.tabeltata}>
                <div className={styles.tabeltatabrd}>
                  <span>Customer # :</span>
                </div>
                <div className={styles.tabeltatabrd}>
                  <span>47110</span>
                </div>
                <div className={styles.tabeltatabrd}>
                  <span></span>
                </div>
              </div>
              <div className={styles.tabeltata}>
                <div className={styles.tabeltatabrd}>
                  <span>Customer Contact : </span>
                </div>
                <div className={styles.tabeltatabrd}>
                  <span>{workDetails?.userInfo?.name}</span>
                </div>
                <div className={styles.tabeltatabrd}>
                  <span>
                    <b>Customer Email : </b>
                  </span>
                </div>
                <div className={styles.tabeltatabrd}>
                  <span>{workDetails?.userInfo?.email}</span>
                </div>
              </div>
              <div className={styles.tabeltata}>
                <div className={styles.tabeltatabrd}>
                  <span>Work Number: </span>
                </div>
                <div className={styles.tabeltatabrd}>
                  <span>#{workDetails?.workNumber}</span>
                </div>
              </div>
              {/* <div className={styles.tabeltata}>
                                <div className={styles.tabeltatabrd}>
                                    <span>End Customer :  </span>
                                </div>
                                <div className={styles.tabeltatabrd}>
                                    <span>#10179800</span>
                                </div>

                            </div> */}
            </div>
            <div className={styles.timeshettdatatable}>
              <ul>
                <li>Day</li>
                <li>Date</li>
                <li>Hours Worked Per Day</li>
                <li>Sunday / Holidays</li>
                <li>Travel Time Hours</li>
                <li>Total</li>
              </ul>
              {timeSheetDays?.map((x: any, idx: any) => {
                const momentObj = moment(x.date);
                const isFutureDate = x.date > new Date();

                return (
                  <>
                    <ul key={idx}>
                      <>
                        <li>
                          <span>{momentObj.format("dddd")}</span>
                        </li>
                        <li>{momentObj.format("YYYY-MM-DD")}</li>
                        <li>
                          <input
                            disabled={isFutureDate}
                            type="number"
                            value={x.hours}
                            onChange={({ target: { value } }) =>
                              onTimesheetValueChange(
                                Number(value),
                                "hours",
                                idx
                              )
                            }
                          />
                        </li>

                        <li>
                          <input
                            // disabled={isFutureDate}
                            type="checkbox"
                            checked={x.isHoliday}
                            onChange={({ target: { checked } }) =>
                              onTimesheetValueChange(checked, "isHoliday", idx)
                            }
                          />
                        </li>
                        <li>
                          <input
                            disabled={isFutureDate}
                            type="number"
                            value={x.travelTime}
                            onChange={({ target: { value } }) =>
                              onTimesheetValueChange(
                                Number(value),
                                "travelTime",
                                idx
                              )
                            }
                          />
                        </li>
                        <li>
                          {(x?.hours ? x?.hours : 0) +
                            (x?.travelTime ? x?.travelTime : 0)
                          }
                        </li>
                      </>
                    </ul>

                    <div className={styles.AddMonth}>
                      <textarea
                        disabled={isFutureDate}
                        placeholder="Add Service Report...."
                        value={x.statusReport}
                        onChange={({ target: { value } }) =>
                          onTimesheetValueChange(value, "statusReport", idx)
                        }
                      ></textarea>
                    </div>
                  </>
                );
              })}
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className={styles.innerTotalleft}>
                  <ul>
                    <li>
                      <span>Total Straight Time</span>
                      <label>{totalStraight}</label>{" "}
                    </li>
                    <li>
                      <span>Total Over Time</span>{" "}
                      <label>{totalOverTime}</label>
                    </li>
                    <li>
                      <span>Total Double Time</span>{" "}
                      <label>{totalResuls.totalDoubleTime}</label>
                    </li>
                    <li>
                      <span>Total Travel Time</span>{" "}
                      <label>{totalResuls.totalTravel}</label>
                    </li>
                    <li>
                      <span>Total Hours</span>{" "}
                      <label>{totalResuls.totalHours}</label>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className={styles.innerTotalleft}>
                  <ul>
                    <li>
                      <span>Bill Rates</span>{" "}
                    </li>
                    <li>
                      <span>Base Rate $(per hour)</span>{" "}
                      <label>
                        $ {workDetails?.rates?.baseRate
                          ? workDetails?.rates?.baseRate
                          : "0.00"}
                      </label>
                    </li>
                    <li>
                      <span>OT Rate $(per hour)</span>{" "}
                      <label>
                        $ {workDetails?.rates?.overTimeRate
                          ? workDetails?.rates?.overTimeRate
                          : "0.00"}
                      </label>
                    </li>
                    <li>
                      <span>DT Rate $(per hour)</span>{" "}
                      <label>
                        $ {workDetails?.rates?.doubleTimeRate
                          ? workDetails?.rates?.doubleTimeRate
                          : "0.00"}
                      </label>
                    </li>
                    <li>
                      <span>Travel Time Rate</span>{" "}
                      <label>
                        {workDetails?.travels?.travelTimeRate
                          ? workDetails?.travels?.travelTimeRate
                          : "0.00"}
                      </label>
                    </li>
                    <li>
                      <span>Travel Trips Allowed (per month)</span>{" "}
                      <label>
                        {workDetails?.travels?.tipAllowed
                          ? workDetails?.travels?.tipAllowed
                          : "0.00"}
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* <div className={styles.finalDta}>
              <p>Approved By : Elinrew Johns</p>
              <p>IP Address : 89 : 99 :88</p>
              <p className={styles.Red}>Pending for Approval</p>
              <p>Approval Signature</p>
            </div> */}
          </div>
          {!isEditMode || showAddBtn ?
            <div
              className={styles.addshetbtn}
              onClick={() => handleAddTimesheet()}
            >
              <button type="button">Save Timesheet</button>
            </div>
            : ""}
          {isEditMode || showApprovalBtn ?
            <div
              className={styles.addshetbtn}
              onClick={() => handleStatusChange()}
            >
              <button type="button">Send for Approval</button>
            </div>
            : ""
          }

        </div>
      </div>
    </>
  );
};

export default Addtimesheet;
