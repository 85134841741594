import React, { useEffect, useState } from "react";
import arrowleft from "../../assets/images/arrow_withoutcircle_blue.svg";
import styles from "./styles/style.module.css";
import { getBoostContractPlan, postBoostContract } from "../api";
import { PLAN_RESPONSE } from "../../components/bostprofile/interface";
import { BOOST_TYPE } from "../../utils/enums/types";
import { loadStripe } from "@stripe/stripe-js";
import storage from "../../utils/storage";

const stripePromise = loadStripe(
  `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`
);

const BoostContract = (workId: any) => {
  const [planData, setPlanData] = useState<PLAN_RESPONSE[]>([]);
  const [planID, setPlanID] = useState<string | null>("");

  const fetchPlans = async () => {
    try {
      const data = await getBoostContractPlan();
      console.log("Boost Plans: ", data.data);
      setPlanData(data.data);
      if (data.data.length > 0) {
        setPlanID(data.data[0]._id);
      }
    } catch (err) {
      console.log(err, "Error while fetching plans");
    }
  };

  const handleCheckboxChange = (planID: string) => {
    setPlanID((prevID) => (prevID === planID ? null : planID));
  };

  useEffect(() => {
    fetchPlans();
  }, []);

  const handleCheckOut = async () => {
    if (planData) {
      const payload = {
        boostType: BOOST_TYPE.CONTRACT,
        boostPlanId: planID,
        workId: workId.workId,
      };
      try {
        const response = await postBoostContract(payload);
        const sessionId = response.data.sessionId;
        console.log(sessionId, "SessionId");
        storage.setSessionId(sessionId);
        const stripe = await stripePromise;
        if (stripe) {
          const { error } = await stripe.redirectToCheckout({
            sessionId: sessionId,
          });
          if (error) {
            console.error("Stripe Checkout error:", error);
          }
        }
      } catch (err) {
        console.log("Error redirecting to checkout:", err);
      }
    }
  };

  return (
    <div className="py-3">
      <div className="text-center">
        <h5 className="text-20 text-center fw-semibold">
          Boost your Direct Job/Contract
        </h5>
        <p className="text-12 opacity-75">
          If everything is fine then click the publish button to post your
          Contract
        </p>
      </div>
      <div className="p-3">
        <div className="bg-light">
          <div className="text-start p-3 border-bottom mb-2">
            <p className="text-14 mb-0">
              Bid for one of 4 boosted spaces at the top of the client’s RFQ
              list.
            </p>
            <h5 className="text-16 text-start mb-0 fw-semibold">Direct Jobs</h5>
          </div>
          <div className="text-start p-3 border-bottom mb-2">
            <p className="text-14 mb-0">
              Bid for one of 4 boosted spaces at the top of the client’s RFQ
              list.
            </p>
            <h5 className="text-16 text-start mb-0 fw-semibold">Direct Jobs</h5>
          </div>
        </div>
        <div className={styles.boosttable}>
          <table className="w-100">
            <tr>
              <th>Boosts</th>
              <th>Durations</th>
              <th>Amount</th>
            </tr>
            {planData && planData?.length > 0 ? (
              planData?.map((plan, ind) => {
                return (
                  <tr key={ind}>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className={styles.checkboxwrapper}>
                          <div>
                            <input
                              type="checkbox"
                              name="planType"
                              checked={planID === plan._id}
                              onChange={() => handleCheckboxChange(plan._id)}
                            />
                            {/* <label htmlFor="checkbox-18"></label> */}
                          </div>
                        </div>
                        <span>Contract/Job</span>
                      </div>
                    </td>
                    <td>
                      {plan.duration} {plan.durationType}
                    </td>
                    <td>${plan.price}</td>
                  </tr>
                );
              })
            ) : (
              <p>No Plans Available</p>
            )}
          </table>
        </div>
      </div>
      <div className="row justify-content-between mt-4 mx-0">
        <div className="col-md-4 col-6">
          <button
            className="btn border btn-primary rounded-2 primaryBtn submitBtn m-0 my-2"
            type="button"
          >
            <span>50 Free Connects Availabe</span>
          </button>
        </div>
        <div className="col-md-4 col-6">
          <button
            className="btn btn-transparent rounded-pill primaryBtn submitBtn m-0 my-2"
            type="button"
          >
            <span>
              Buy More Connects
              <i className="ms-3">
                <img src={arrowleft} alt="" />
              </i>
            </span>
          </button>
        </div>
        <div className="col-md-4 col-6">
          <button
            className="btn border btn-primary rounded-2 border primaryBtn cancelBtn m-0 my-2"
            type="button"
            onClick={handleCheckOut}
          >
            Checkout Summary
          </button>
        </div>
      </div>
    </div>
  );
};
export default BoostContract;