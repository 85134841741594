import { IOption } from "../../../../Interfaces";


export const states: IOption[] = [
  { value: '', label: 'Select an option' },
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New' },
  { value: 'NJ', label: 'New' },
  { value: 'NM', label: 'New' },
  { value: 'NY', label: 'New' },
  { value: 'NC', label: 'North' },
  { value: 'ND', label: 'North' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode' },
  { value: 'SC', label: 'South' },
  { value: 'SD', label: 'South' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
];

export const cities: IOption[] = [
  { value: '', label: 'Select an option' },
  { value: 'Tokyo', label: 'Tokyo, Japan' },
  { value: 'New York City', label: 'New York City, USA' },
  { value: 'London', label: 'London, United Kingdom' },
  { value: 'Paris', label: 'Paris, France' },
  { value: 'Beijing', label: 'Beijing, China' },
  { value: 'Los Angeles', label: 'Los Angeles, USA' },
  { value: 'Shanghai', label: 'Shanghai, China' },
  { value: 'Moscow', label: 'Moscow, Russia' },
  { value: 'São Paulo', label: 'São Paulo, Brazil' },
  { value: 'Delhi', label: 'Delhi, India' },
];

export const streets: IOption[] = [
  { value: '', label: 'Select an option' },
  { value: '123 Main St', label: '123 Main St' },
  { value: '456 Elm St', label: '456 Elm St' },
  { value: '789 Oak St', label: '789 Oak St' },
  { value: '101 Maple Ave', label: '101 Maple Ave' },
  { value: '555 Pine St', label: '555 Pine St' },
  { value: '777 Birch St', label: '777 Birch St' },
  { value: '888 Cedar Ave', label: '888 Cedar Ave' },
  { value: '246 Walnut St', label: '246 Walnut St' },
  { value: '369 Cherry Ln', label: '369 Cherry Ln' },
  { value: '808 Willow Ave', label: '808 Willow Ave' },
];
