
import { axios } from "../../../lib/axios";
import { API_ROUTES } from "../../../utils/routes/apiRoutes";
import { UpdateProfileParams, UpdateProfileResponse, userProfileResponse } from "../interface";

export const getuserProfile = async (): Promise<userProfileResponse> => {
    return axios.get(API_ROUTES.PROFILE.GET_USERPROFILE);
};

export const updateUserProfile = async (params: UpdateProfileParams): Promise<UpdateProfileResponse> => {
    return axios.put(API_ROUTES.PROFILE.UPDATE_PROFILE, params)
}

export const deactivateAccount = async (params: object): Promise<any> => {
    return axios.delete(`${API_ROUTES.PROFILE.DELETE}`,  {data: params});
};