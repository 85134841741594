import React from 'react'
// import Header from '../../header/routes'
import { useNavigate } from 'react-router-dom'
import { connect } from 'http2'
import styles from "../styles/style.module.css";

function Profileinfo() {

    const navigate = useNavigate()
    const handleGoToNext = () => {
        navigate("/feeds")
    }
 
    return (
      <>
        <div className="recommendedjobs share_connect_friends">
          <div className={styles.bggrey}>
            <div className='newheadingstyle pb-2'>
              <h3 className='text-18'>1. Have bought a robot in the last year?  </h3>
              <form>
                <div className='form-group'>
                  <textarea placeholder='Type you ans here......' rows={5} style={{height: "100px"}} className='form-control text-14'></textarea>
              </div>
              </form>
              
            </div>
            <div className="row align-items-center justify-content-between g-3 mt-3 mb-3">
            <button className='btn btn-white rounded-2 p-2 col-lg-2 col-4 text-14 border' type='button'>Back</button>
            <button className='btn btn-primary rounded-2 p-2 col-lg-2 col-4 text-14 border' type='button'>Save & Next</button>
          </div>
          </div>
         
        </div>
      </>
    );
}

export default Profileinfo