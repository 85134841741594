//client's cred

//my cred priyanka
// const appId = '5af97c686c594d0986b8d33933c295f7'
// Jishan Token
// const appId = '4367dacd4572477a872a8f95bc014036'
// const primaryAppCertificate = '72e9397263e543229ab511bffd12abe0'

// minejish
const appId = '592d4bbe24794426aaff9dd3c025211f'
const token = '007eJxTYODbub553tF1olrKgbxKcqo3oozCP9Wp7H5+4YCwdq7NBAcFBkOLFFMDE3NzsxQzcxMDc7NEw2SzNDMTMyNTIzOjFOO0+nv30hoCGRk0X65hZmSAQBCfg8ErszgjJLW4hIEBAPrnHsM=';
const channel = 'JishTest';

import React, { useEffect, useState } from "react";
import AgoraRTC, { ICameraVideoTrack, IMicrophoneAudioTrack, ILocalTrack } from "agora-rtc-sdk-ng";
import { VideoPlayer } from "./videoPlayer"; // Import your video player component

const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

const LiveVideoClient: React.FC = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [localTracks, setLocalTracks] = useState<(ICameraVideoTrack | IMicrophoneAudioTrack)[]>([]);

    interface User {
        uid: number;
        videoTrack: any;
        audioTrack: any;
    }

    const handleUserJoined = async (user: User, mediaType: string) => {
        await client.subscribe(user.uid, mediaType as "video" | "audio");

        if (mediaType === "video") {
            setUsers((prevUsers: User[]) => [...prevUsers, user]);
        }

        if (mediaType === "audio") {
            user.audioTrack.play();
        }
    };

    const handleUserLeft = (user: User) => {
        setUsers((prevUsers) => prevUsers.filter((u) => u.uid !== user.uid));
    };

    useEffect(() => {
        client.on("user-published", handleUserJoined);
        client.on("user-left", handleUserLeft);

        client
            .join(appId, channel, token, null)
            .then((uid) => {
                return AgoraRTC.createMicrophoneAndCameraTracks();
            })
            .then((tracks) => {
                const [audioTrack, videoTrack] = tracks;
                setLocalTracks(tracks);
                setUsers((prevUsers) => [
                    ...prevUsers,
                    { uid: client.uid as number, videoTrack, audioTrack },
                ]);
                client.publish(tracks);
            });

        return () => {
            for (let track of localTracks) {
                track.stop();
                track.close();
            }
            client.off("user-published", handleUserJoined);
            client.off("user-left", handleUserLeft);
            client.unpublish(localTracks as ILocalTrack[]).then(() => client.leave());
        };
    }, [localTracks]);

    return (
        <div className="video-call-container">
            <div className="video-grid">
                {users.map((user) => (
                    <VideoPlayer key={user.uid} user={user} />
                ))}
            </div>
        </div>
    );
};

export default LiveVideoClient;




// //client's cred

// //my cred priyanka
// // const appId = '5af97c686c594d0986b8d33933c295f7'
// // Jishan Token
// // const appId = '4367dacd4572477a872a8f95bc014036'
// // const primaryAppCertificate = '72e9397263e543229ab511bffd12abe0'


// // minejish
// const appId = '592d4bbe24794426aaff9dd3c025211f'
// const token = '007eJxTYODbub553tF1olrKgbxKcqo3oozCP9Wp7H5+4YCwdq7NBAcFBkOLFFMDE3NzsxQzcxMDc7NEw2SzNDMTMyNTIzOjFOO0+nv30hoCGRk0X65hZmSAQBCfg8ErszgjJLW4hIEBAPrnHsM=';
// const channel = 'JishTest';


// import React, { useCallback, useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// // import AgoraRTC, {
// //     LocalUser,
// //     RemoteUser,
// //     useLocalCameraTrack,
// //     useLocalMicrophoneTrack,
// //     useRemoteAudioTracks,
// //     useRemoteUsers,
// // } from "agora-rtc-react";
// import moment from "moment";
// import storage from "../../../../utils/storage";
// import AgoraRTC, { ICameraVideoTrack, ILocalTrack, IMicrophoneAudioTrack } from "agora-rtc-sdk-ng";
// import { VideoPlayer } from "./videoPlayer";

// const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });


// // const LiveVideoClient: React.FC = () => {
// // const navigate = useNavigate();
// // const currentDay = moment().format('YYYY-MM-DD');
// // const userId = JSON.parse(storage.getInterviewUserId());

// // // Function to get video information for the user
// // const getUserVideoInfo = useCallback(() => {
// //     const videoInfo = storage.getInterviewData(currentDay, userId?.userId, "");

// //     if (!Array.isArray(videoInfo)) {
// //         return {
// //             token: videoInfo?.token,
// //             channelName: videoInfo?.channelName,
// //             uid: videoInfo?.uid,
// //         };
// //     }
// //     return videoInfo[0];
// // }, []);

// // const { token, channelName, uid } = getUserVideoInfo() || {};

// // const [micOn, setMic] = useState(true);
// // const [cameraOn, setCamera] = useState(true);
// // const [loading, setLoading] = useState(true);
// // const [error, setError] = useState<string | null>(null);
// // const [activeConnection, setActiveConnection] = useState(true);
// // const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn);
// // const { localCameraTrack } = useLocalCameraTrack(cameraOn);

// // const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

// // useEffect(() => {
// //     const joinAndPublishTracks = async () => {
// //         try {
// //             console.log('Requesting permissions...');
// //             await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
// //             console.log('Permissions granted');

// //             console.log('Joining channel...');
// //             await client.join(appId, channelName, token, uid);

// //             console.log('Creating and publishing tracks...');
// //             const [microphoneTrack, cameraTrack] = await AgoraRTC.createMicrophoneAndCameraTracks();
// //             await client.publish([microphoneTrack, cameraTrack]);

// //             console.log('Joined channel and published tracks');
// //             setLoading(false);
// //         } catch (err) {
// //             console.error('Error joining or publishing tracks:', err);
// //             setError(Error: ${err});
// //         }
// //     };

// //     if (token && channelName) {
// //         joinAndPublishTracks();
// //     }

// //     return () => {
// //         client.leave();
// //     };
// // }, [token, channelName, appId, uid, client]);

// // const remoteUsers = useRemoteUsers();
// // const { audioTracks } = useRemoteAudioTracks(remoteUsers);

// // useEffect(() => {
// //     audioTracks.forEach(track => track.play());
// // }, [audioTracks]);

// // const handleToggleMic = () => setMic(prevState => !prevState);

// // const handleToggleCamera = async () => {
// //     try {
// //         if (cameraOn && localCameraTrack) {
// //             localCameraTrack.stop();
// //             await client.unpublish([localCameraTrack]);
// //         } else if (!cameraOn) {
// //             const newCameraTrack = await AgoraRTC.createCameraVideoTrack();
// //             await client.publish([newCameraTrack]);
// //         }
// //         setCamera(prevState => !prevState);
// //     } catch (err) {
// //         console.error('Error toggling camera:', err);
// //         setError(Error: ${err});
// //     }
// // };

// // const handleDisconnect = () => {
// //     storage.deleteInterviewUserId();
// //     storage.clearInterviewData(currentDay, userId)
// //     setActiveConnection(false);
// //     navigate('/');
// // };

// // const handleMuteAllRemote = () => {
// //     audioTracks.forEach(track => track.stop());
// // };

// // if (loading) {
// //     return <div>Loading your video stream... Please wait</div>;
// // }

// // if (error) {
// //     return <div>Error: {error}</div>;
// // }

// // return (
// //     <div id="videoContainer">
// //         <div id="remoteVideoGrid">
// //             {remoteUsers.map(user => (
// //                 <div key={user.uid} className="remote-video-container">
// //                     <RemoteUser user={user} />
// //                 </div>
// //             ))}
// //         </div>

// //         <div id="localVideo">
// //             <LocalUser
// //                 audioTrack={localMicrophoneTrack}
// //                 videoTrack={localCameraTrack}
// //                 cameraOn={cameraOn}
// //                 micOn={micOn}
// //                 playAudio={micOn}
// //                 playVideo={cameraOn}
// //             />
// //             <div id="controlsToolbar">
// //                 <div id="mediaControls">
// //                     <button className="btn" onClick={handleToggleMic}>
// //                         {micOn ? 'Mute Mic' : 'Unmute Mic'}
// //                     </button>
// //                     <button className="btn" onClick={handleToggleCamera}>
// //                         {cameraOn ? 'Turn Off Camera' : 'Turn On Camera'}
// //                     </button>
// //                     <button className="btn" onClick={handleMuteAllRemote}>
// //                         Mute All Remote Users
// //                     </button>
// //                 </div>
// //                 <button id="endConnection" onClick={handleDisconnect}>
// //                     Disconnect
// //                 </button>
// //             </div>
// //         </div>
// //     </div>
// // );
// // };

// const LiveVideoClient: React.FC = () => {
//     const [users, setUsers] = useState<User[]>([]);
//     const [localTracks, setLocalTracks] = useState<(ICameraVideoTrack | IMicrophoneAudioTrack)[]>([]);

//     interface User {
//         uid: number;
//         videoTrack: any;
//         audioTrack: any;
//     }

//     const handleUserJoined = async (user: User, mediaType: string) => {
//         await client.subscribe(user.uid.toString(), mediaType as 'video' | 'audio');

//         if (mediaType === 'video') {
//             setUsers((previousUsers: User[]) => [...previousUsers, user]);
//         }

//         if (mediaType === 'audio') {
//             user.audioTrack.play()
//         }
//     };

//     const handleUserLeft = (user: User) => {
//         setUsers((previousUsers: User[]) =>
//             previousUsers.filter((u) => u.uid !== user.uid)
//         );
//     };

//     useEffect(() => {
//         client.on('user-published', handleUserJoined);
//         client.on('user-left', handleUserLeft);

//         client
//             .join(appId, channel, token, null)
//             .then((uid) => {
//                 console.log(🚀 JISHH 🤦‍♂️😒 --  ~file: connectVideoCall.tsx: 243 ~useEffect ~uid:, uid);
//                 return Promise.all([
//                     AgoraRTC.createMicrophoneAndCameraTracks(),
//                     uid,
//                 ])
//             })
//             .then(([tracks, uid]) => {
//                 const [audioTrack, videoTrack] = tracks;
//                 setLocalTracks(tracks);
//                 setUsers((previousUsers) => [...previousUsers, { uid: Number(uid), videoTrack, audioTrack, },]);
//                 client.publish(tracks);
//             });

//         return () => {
//             for (let localTrack of localTracks) {
//                 localTrack.stop();
//                 localTrack.close();
//             }
//             client.off('user-published', handleUserJoined);
//             client.off('user-left', handleUserLeft);
//             const tracks = localTracks.map(track => {
//                 if ('track' in track) {
//                     return track.track;
//                 }
//                 return track;
//             });
//             client.unpublish(tracks as ILocalTrack[]).then(() => client.leave());
//         };
//     }, []);

//     return (
//         <div
//             style={{ display: 'flex', justifyContent: 'center' }}
//         >
//             <div
//                 style={{
//                     display: 'grid',
//                     gridTemplateColumns: 'repeat(2, 200px)',
//                 }}
//             >
//                 {users.map((user) => (
//                     <VideoPlayer key={user.uid} user={user} />
//                 ))}
//             </div>
//         </div>
//     );
// }