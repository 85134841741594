import React, { FC, useEffect, useState } from "react";
import styles from "../styles/style.module.css";
import infoicon from "../../../../assets/images/infoicon.svg";
import { Question } from "../../Contracts/interface";

interface Props {
  loading: boolean;
  questions: Question[];
  onClose: () => void;
  onAnsChange: (value: boolean, index: number, id: string, key?: "No") => void;
  onNext: () => void;
  showAlertText: boolean
  showSaveBtn:boolean
}

export const EligibilityQuestions: FC<Props> = ({
  questions,
  loading,
  onClose,
  onAnsChange,
  onNext,
  showAlertText,
  showSaveBtn
}) => {


  return (
    <div className="modal" style={{ display: "block" }}>
      <div className="modal-dialog ElibalModal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Eligibility Criteria</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            ></button>
          </div>
          <div className="modal-body">
            {loading ? (
              <div className="spinner-grow text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <div className={styles.EligibalModal}>
                <div className="row">
                  <div className="col-md-8">
                    <div className={styles.leftEligibal}>
                      <form>
                        {questions?.map((data: any, idx: number) => {
                          return (
                            <div key={idx} className={styles.formGroup}>
                              <h5>
                                1.
                                {data?.question
                                  ? data?.question
                                  : ""}
                              </h5>
                              <div>
                                <label>
                                  <input
                                    type="radio"
                                    name={`answer-${idx}`}
                                    value="yes"
                                    onChange={(e) =>
                                      onAnsChange(true, idx, data?._id)
                                    }
                                  />
                                  Yes
                                </label>
                                <label>
                                  <input
                                    type="radio"
                                    name={`answer-${idx}`}
                                    value="no"
                                    onChange={(e) =>
                                      onAnsChange(false, idx, data?._id, "No")
                                    }
                                  />
                                  No

                                </label>
                                {showAlertText && <p className="unique-text"><em>(If you choose No, you will not be able to proceed ahead)</em></p>}
                              </div>
                            </div>
                          );
                        })}
                      </form>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className={styles.rightEligibal}>
                      <img src={infoicon} alt="" />
                      <p>
                        These questions can help gauge your experience and
                        skills in various aspects of project management and
                        technical tasks. Be prepared to provide specific
                        examples from your past work to support your answers.
                        These questions can help gauge your experience and
                        skills in various aspects of project management and
                        technical tasks. Be prepared to provide specific
                        examples from your past work to support your answers.
                        These questions can help gauge your experience and
                        skills in various aspects of project{" "}
                      </p>
                    </div>
                  </div>
                </div>
                {showSaveBtn && 
                <div className="eligibalBtn">
                  <button onClick={onNext} className="cmn-tbn">
                    Save & Next{" "}
                  </button>
                </div>
}
              </div>
            )}
          </div>

        </div>
      </div>
    </div>
  );
};
