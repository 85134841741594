import React, { useEffect, useState } from "react";
import { updateUserProfile } from "../../../home/api";
import { getMasterTypes } from "../../../../feature/auth/signup/api";
import { MASTER_TYPE } from "../../../../utils/enums/masterTypes.enum";
import { IOption } from "../../../../Interfaces";
import { Dropdown, FormControl } from "react-bootstrap";
import { postMasterType } from "../../../work/api";
import { toast } from "react-toastify";
import { MESSAGES } from "../../../../utils/messages";
import { EducationDetails } from "../../../editprofile/interface";
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  educationDetails: EducationDetails[];
  refresh: () => void;
  handleEdit: (updatedEducation: EducationDetails) => void;
  initialData: EducationDetails | null;
  indexNumber: number | null;
}

const EducationModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  educationDetails = [],
  refresh,
  handleEdit,
  initialData,
  indexNumber,
}) => {
  if (!isOpen) return null;
  const [school, setSchool] = useState("");
  const [schoolId, setSchoolId] = useState("");
  const [schoolData, setSchoolData] = useState<IOption[]>([]);
  const [degree, setDegree] = useState("");
  const [degreeId, setDegreeId] = useState("");
  const [degreeData, setDegreeData] = useState<IOption[]>([]);
  const [year, setYear] = useState<number | null>(null);
  const [specialize, setSpecialize] = useState("");
  const [specializeId, setSpecializeId] = useState("");
  const [specializeData, setSpecializeData] = useState<IOption[]>([]);
  const [comment, setComment] = useState("");
  const [filterValue, setFilterValue] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  console.log("Education", educationDetails);

  useEffect(() => {
    if (initialData) {
      // If there is initial data, pre-fill the form fields
      setSchool(initialData.schoolDetails?.name || "");
      setSchoolId(initialData.schoolDetails?._id || "");
      setDegree(initialData.degreeDetails?.name || "");
      setDegreeId(initialData.degreeDetails?._id || "");
      setYear(initialData.completionYear || null);
      setSpecialize(initialData.specializationDetails?.name || "");
      setSpecializeId(initialData.specializationDetails?._id || "");
      setComment(initialData.comment || "");
    } else {
      // Clear fields if no initial data
      setSchool("");
      setSchoolId("");
      setDegree("");
      setDegreeId("");
      setYear(null);
      setSpecialize("");
      setSpecializeId("");
      setComment("");
    }
  }, [initialData]);

  const loadDropDowns = async () => {
    try {
      const schoolResponse = await getMasterTypes(MASTER_TYPE.SCHOOL);
      const degreeRes = await getMasterTypes(MASTER_TYPE.DEGREE);
      const specializationRes = await getMasterTypes(
        MASTER_TYPE.SPECIALIZATIONS
      );
      if (schoolResponse.data && degreeRes.data && specializationRes.data) {
        setSchoolData(
          schoolResponse.data.map((x: any) => ({
            label: x.name,
            value: x._id,
          }))
        );
        setDegreeData(
          degreeRes.data.map((x: any) => ({
            label: x.name,
            value: x._id,
          }))
        );
        setSpecializeData(
          specializationRes.data.map((x: any) => ({
            label: x.name,
            value: x._id,
          }))
        );
      }
    } catch (error) {
      console.error("Failed to load school data", error);
    }
  };

  useEffect(() => {
    loadDropDowns();
  }, []);

  const handleSchoolChange = (label: string, value: string) => {
    setSchool(label);
    setSchoolId(value);
  };

  const handleDegreeChange = (label: string, value: string) => {
    setDegree(label);
    setDegreeId(value);
  };

  const handleSplecializeChange = (label: string, value: string) => {
    setSpecialize(label);
    setSpecializeId(value);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const numericValue = Number(value);
    if (value === "" || !isNaN(numericValue)) {
      setYear(value === "" ? null : numericValue);
    }
  };

  const handleSubmitCustomSchool = async () => {
    const payload = {
      type: MASTER_TYPE.SCHOOL,
      name: searchTerm,
      alias: searchTerm,
    };
    toast.promise(postMasterType(payload), {
      pending: MESSAGES.SCHOOL_ADDED.PENDING,
      success: MESSAGES.SCHOOL_ADDED.SUCCESS,
      error: MESSAGES.SCHOOL_ADDED.ERROR,
    });
  };

  const handleSave = async () => {
    if (schoolId && degreeId && year && specializeId) {
      console.log(school, degree, year, specialize);
      const newEducation = {
        completionYear: year,
        comment: comment,
        degreeId: degreeId,
        schoolId: schoolId,
        specializationId: specializeId,
      };
      try {
        if (
          indexNumber !== null &&
          indexNumber !== undefined &&
          indexNumber >= 0
        ) {
          console.log("if", indexNumber);
          let educationsData = educationDetails.map((data, ind) => {
            if (indexNumber === ind) {
              return newEducation;
            } else {
              return data;
            }
          });
          let updatedProfile = {
            educations: [...educationsData],
          };
          console.log("updatedProfile", updatedProfile);
          // await updateUserProfile(updatedProfile);
        } else {
          console.log("else", indexNumber);
          let existingData: any = [];
          if (educationDetails?.length) {
            existingData = educationDetails.map((data, ind) => {
              return {
                completionYear: data.completionYear,
                comment: data.comment,
                degreeId: data.degreeDetails._id,
                schoolId: data.schoolDetails._id,
                specializationId: data.specializationDetails._id,
              };
            });
          }
          let updatedProfile = {
            educations: [...existingData, newEducation],
          };
          console.log("updatedProfile", updatedProfile);
          await updateUserProfile(updatedProfile);
        }

        refresh();
      } catch (err) {
        console.log("Occuring error while updating user information", err);
      }
    }
  };

  const filteredSchoolData =
    filterValue && filterValue !== ""
      ? schoolData.filter((x) =>
          x.label.toLowerCase().includes(filterValue.toLowerCase())
        )
      : schoolData;

  const filteredDegreeData =
    filterValue && filterValue !== ""
      ? degreeData.filter((x) =>
          x.label.toLowerCase().includes(filterValue.toLowerCase())
        )
      : degreeData;

  const filteredSpecializeData =
    filterValue && filterValue !== ""
      ? specializeData.filter((x) =>
          x.label.toLowerCase().includes(filterValue.toLowerCase())
        )
      : specializeData;

  return (
    <div className="modal" style={{ display: "block" }}>
      <div className="modal-dialog addModal educationModal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            ></button>
          </div>
          <div className="modal-body">
            <div className="addModalPro">
              <form>
                <div className="formGroup">
                  <label>School Name</label>
                </div>
                <div style={{ marginBottom: "30px" }}>
                  <Dropdown>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                      {school || "Please select an option"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <div className="">
                        <FormControl
                          autoFocus
                          className="mx-3 my-2 w-auto"
                          placeholder="Search..."
                          onChange={(e) => setFilterValue(e.target.value)}
                          value={filterValue}
                        />
                      </div>

                      <div
                        style={{
                          maxHeight: "300px",
                          overflowY: "scroll",
                        }}
                      >
                        {filteredSchoolData.length === 0 ? (
                          <Dropdown.Item disabled>
                            No options found
                          </Dropdown.Item>
                        ) : (
                          filteredSchoolData.map((option, index: number) => (
                            <>
                              <Dropdown.Item
                                key={index}
                                onClick={() =>
                                  handleSchoolChange(option.label, option.value)
                                }
                              >
                                {option.label}
                              </Dropdown.Item>
                            </>
                          ))
                        )}
                        <div>
                          <div className="col-8">
                            <input
                              type="text"
                              placeholder="Type school here you want to add..."
                              name="school"
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handleSubmitCustomSchool();
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <div className="formGroup">
                  <label>Degree/Certificate Name</label>
                  <div style={{ marginBottom: "30px" }}>
                    <Dropdown>
                      <Dropdown.Toggle variant="primary" id="dropdown-basic">
                        {degree || "Please select an option"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <div className="">
                          <FormControl
                            autoFocus
                            className="mx-3 my-2 w-auto"
                            placeholder="Search..."
                            onChange={(e) => setFilterValue(e.target.value)}
                            value={filterValue}
                          />
                        </div>

                        <div
                          style={{
                            maxHeight: "300px",
                            overflowY: "scroll",
                          }}
                        >
                          {filteredDegreeData.length === 0 ? (
                            <Dropdown.Item disabled>
                              No options found
                            </Dropdown.Item>
                          ) : (
                            filteredDegreeData.map((option, index: number) => (
                              <>
                                <Dropdown.Item
                                  key={index}
                                  onClick={() =>
                                    handleDegreeChange(
                                      option.label,
                                      option.value
                                    )
                                  }
                                >
                                  {option.label}
                                </Dropdown.Item>
                              </>
                            ))
                          )}
                          <div>
                            <div className="col-8">
                              <input
                                type="text"
                                placeholder="Type degree/certificate here you want to add..."
                                name="degreeCertificate"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    handleSubmitCustomSchool();
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="formGroup">
                  <label>Year of Completion</label>
                  <input
                    type="text"
                    placeholder="Enter year"
                    value={year === null ? "" : year}
                    onChange={handleChange}
                  />
                </div>
                <div className="formGroup">
                  <label>Specialization</label>
                  <div style={{ marginBottom: "30px" }}>
                    <Dropdown>
                      <Dropdown.Toggle variant="primary" id="dropdown-basic">
                        {specialize || "Please select an option"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <div className="">
                          <FormControl
                            autoFocus
                            className="mx-3 my-2 w-auto"
                            placeholder="Search..."
                            onChange={(e) => setFilterValue(e.target.value)}
                            value={filterValue}
                          />
                        </div>

                        <div
                          style={{
                            maxHeight: "300px",
                            overflowY: "scroll",
                          }}
                        >
                          {filteredSpecializeData.length === 0 ? (
                            <Dropdown.Item disabled>
                              No options found
                            </Dropdown.Item>
                          ) : (
                            filteredSpecializeData.map(
                              (option, index: number) => (
                                <>
                                  <Dropdown.Item
                                    key={index}
                                    onClick={() =>
                                      handleSplecializeChange(
                                        option.label,
                                        option.value
                                      )
                                    }
                                  >
                                    {option.label}
                                  </Dropdown.Item>
                                </>
                              )
                            )
                          )}
                          <div>
                            <div className="col-8">
                              <input
                                type="text"
                                placeholder="Type degree/certificate here you want to add..."
                                name="degreeCertificate"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    handleSubmitCustomSchool();
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <div className="formGroup">
                  <label>Description</label>
                  <input
                    type="textarea"
                    placeholder="Enter Description"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                </div>
                <div className="formGroup">
                  <button type="button" onClick={handleSave}>
                    Save & Close ›
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationModal;
