import { API_ROUTES } from "../../../utils/routes/apiRoutes";
import { axios } from "../../../lib/axios";
import { AxiosResponse } from "axios";

interface User {
  id: string;
  name: string;
  profile_image: string;
  // Add other properties as per your API response
}

// Assuming your response data is directly an array of users
interface ApiResponse extends Array<User> {}

export const fetchUserChatLists = async (filter:any = "all",searchValue:string = ""): Promise<User[]> => {
  try {
    const response: AxiosResponse<ApiResponse> = await axios.get(`${API_ROUTES.CHAT.USER_CHAT}?limit=10&page=1&type=${filter}&search=${searchValue}`);
    console.log("responseresponseresponse", response.data);
    return response.data; // Directly return the array of users
  } catch (error) {
    console.error('Error fetching chat users:', error);
    throw error; // Handle or rethrow the error as needed
  }
};
// export const userAvailable = async (filter:any = "all",searchValue:string = ""): Promise<User[]> => {
//   try {
//     const response: AxiosResponse<ApiResponse> = await axios.get(API_ROUTES.CHAT.USER_CHAT.USER_AVAILABLE,{userid,});
//     console.log("responseresponseresponse", response.data);
//     return response.data; // Directly return the array of users
//   } catch (error) {
//     console.error('Error fetching chat users:', error);
//     throw error; // Handle or rethrow the error as needed
//   }
// };