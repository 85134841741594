import React, { useEffect, useState } from "react";
import styles from "../../../styles/style.module.css";
import Occupations from "../../../../../assets/images/occupations.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import tip from "../../../../../assets/images/tip.png";
import storage from "../../../../../utils/storage";
import { RootState } from "../../../../../redux/store";
import { getuserProfile } from "../../../../home/api";
import {
  setActiveStep,
  setCustomerDetails,
} from "../../../../../redux/reducers/addContract";
import { ADD_CONTRACT_STEPS } from "../../../../../shared/enums";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import {
  SaveAsDraftAuthErrorDialog,
  SaveAsDraftButton,
} from "../../Components";
import Toltip from "../../../../common/toltip/page";
import { CONSTANT_MANAGER } from "../../../../../utils/constans/constants";
import CustomModal from "../../../../../shared/CustomModal/customModal";
import ConfirmModal from "../../../../../shared/CustomModal/confirmationModal";

const AddCustomerDetails: React.FC = () => {
  const dispatch = useDispatch();
  const token = storage.getToken();
  const { authentication } = useSelector((state: RootState) => state);
  const { isAuthenticated } = authentication;
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);

  const [key, setKey] = useState(Math.random());
  const { customerDetails } = useSelector(
    (state: RootState) => state.addContract
  );
  const [userData, setUserData] = useState<any>();
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
  };

  const getuserData = () => {
    if (token) {
      getuserProfile().then((res) => {
        setUserData(res.data);
      });
    }
  };

  useEffect(() => {
    getuserData();
  }, []);

  const {
    sameAsCustomer,
    primaryName,
    primaryEmail,
    primaryPhoneNumber,
    secondaryName,
    secondaryEmail,
    secondaryPhoneNumber,
  } = customerDetails;

  const formIk = useFormik({
    initialValues: {
      sameAsCustomer,
      primaryName,
      primaryEmail,
      primaryPhoneNumber,
      secondaryName,
      secondaryEmail,
      secondaryPhoneNumber,
    },
    //validationSchema: onSiteCustomervalidationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: () => {
      //
      dispatch(setActiveStep(ADD_CONTRACT_STEPS.OVERVIEW_FORM));
    },
  });

  const handleGoBack = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_EXPENSES));
  };

  const handleAddMoreDetails = () => {
    setModalDetail({
      show: true,
      title: "addMore",
      flag: "addMore",
    });
    setKey(Math.random());
  };

  // const handleGoToReviewPage = () => {
  //   dispatch(setActiveStep(ADD_CONTRACT_STEPS.OVERVIEW_FORM));
  // };

  const onDetailChange = async (
    value: string | boolean | number,
    key: string
  ) => {
    formIk.setFieldValue(key, value);
    await formIk.validateField(key);

    if (key === "sameAsCustomer") {
      if (!isAuthenticated && !token) {
        setShowErrorDialog(true);
        return;
      }
      if (value) {
        // No need to explicitly check for true, since value is boolean
        dispatch(
          setCustomerDetails({
            ...customerDetails,
            sameAsCustomer: true,
            primaryName: userData?.name || "", // Use optional chaining to prevent potential undefined errors
            primaryEmail: userData?.email || "", // Provide default values if userData[0] is undefined
            primaryPhoneNumber: String(userData?.phoneNumber || ""), // Ensure phoneNumber is always a string
          })
        );
      } else {
        // Clear primary fields when "No" is selected
        dispatch(
          setCustomerDetails({
            ...customerDetails,
            sameAsCustomer: false,
            primaryName: "",
            primaryEmail: "",
            primaryPhoneNumber: "",
          })
        );
      }
      return;
    }

    dispatch(setCustomerDetails({ ...customerDetails, [key]: value }));
  };

  return (
    <>
      <HeaderContent />
      {/* <ProgressBar now={100} /> */}
      <div className="py-4 heightautoscroll">
        <SaveAsDraftAuthErrorDialog
          show={showErrorDialog}
          onHide={() => setShowErrorDialog(false)}
        />
        <div className={styles.signuppage}>
          <form onSubmit={formIk.handleSubmit}>
            <div
              className={styles.registerOuter}
              style={{ position: "relative" }}
            >
              <div className="container">
                <div className={styles.registerBord}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className={styles.registerLeft}>
                        <h4 className="text-white text-32 fw-semibold">
                          ON-SITE Customer Contact
                        </h4>
                        <p className="text-16 text-white opacity-75">
                          Add a basic information of the Customer
                        </p>

                        <div className="col-lg-11 col-12">
                          <figure>
                            <img
                              src={Occupations}
                              alt=""
                              className="img-fluid"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className={styles.signUPContainer}>
                        <div
                          className={`${styles.registerRight} ${styles.editors}`}
                        >
                          <div className={styles.ScrollDiv}>
                            <div
                              className={styles.fromGroup}
                              style={{ marginBottom: "30px" }}
                            >
                              <div className={styles.ratedRadio}>
                                {" "}
                                <div className="topTipHover">
                                  <label className={styles.starlabel}>
                                    {" "}
                                    I am the onsite customer?
                                  </label>
                                  <i>
                                    <img
                                      className="customeTolImg"
                                      src={tip}
                                      alt=""
                                    />
                                  </i>

                                  <Toltip
                                    slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                    labelId={
                                      CONSTANT_MANAGER.ADD_CONTRACT
                                        .ONSITE_CUSTOMER
                                    }
                                  />
                                </div>
                                <div className={styles.rateradio}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="sameAsCustomer"
                                      id="sameAsCustomer"
                                      checked={sameAsCustomer}
                                      onChange={() =>
                                        onDetailChange(true, "sameAsCustomer")
                                      }
                                    />
                                    <label className="form-check-label">
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="sameAsCustomer1"
                                      id="sameAsCustomer1"
                                      checked={!sameAsCustomer}
                                      onChange={() =>
                                        onDetailChange(false, "sameAsCustomer")
                                      }
                                    />
                                    <label className="form-check-label">
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className={styles.fromGroup}
                              style={{ marginBottom: "30px" }}
                            >
                              {" "}
                              <div className="topTipHover">
                                <label className={styles.starlabel}>
                                  Primary Name{" "}
                                </label>{" "}
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                                <Toltip
                                  slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                  labelId={
                                    CONSTANT_MANAGER.ADD_CONTRACT.PRIMARY_NAME
                                  }
                                />
                              </div>
                              <input
                                type="text"
                                name="primaryName"
                                className="form-control rounded-pill h-48"
                                placeholder="Primary Name "
                                value={primaryName}
                                onChange={(e) =>
                                  onDetailChange(e.target.value, "primaryName")
                                }
                                onBlur={formIk.handleBlur}
                              />
                              {formIk.touched.primaryName &&
                              formIk.errors.primaryName ? (
                                <div className={styles.error}>
                                  {formIk.errors.primaryName}
                                </div>
                              ) : null}
                            </div>
                            <div
                              className={styles.fromGroup}
                              style={{ marginBottom: "30px" }}
                            >
                              {" "}
                              <div className="topTipHover">
                                <label className={styles.starlabel}>
                                  Primary Phone
                                </label>{" "}
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                                <Toltip
                                  slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                  labelId={
                                    CONSTANT_MANAGER.ADD_CONTRACT.PRIMARY_PHONE
                                  }
                                />
                              </div>
                              <input
                                type="text" // Changed type to text to handle phone numbers as strings
                                name="primaryPhoneNumber"
                                className="form-control rounded-pill h-48"
                                placeholder="Primary Phone"
                                value={primaryPhoneNumber}
                                onChange={
                                  (e) =>
                                    onDetailChange(
                                      e.target.value,
                                      "primaryPhoneNumber"
                                    ) // Handle as a string
                                }
                              />
                              {formIk.touched.primaryPhoneNumber &&
                              formIk.errors.primaryPhoneNumber ? (
                                <div className={styles.error}>
                                  {formIk.errors.primaryPhoneNumber}
                                </div>
                              ) : null}
                            </div>

                            <div
                              className={styles.fromGroup}
                              style={{ marginBottom: "30px" }}
                            >
                              {" "}
                              <div className="topTipHover">
                                <label className={styles.starlabel}>
                                  Primary Email
                                </label>{" "}
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                                <Toltip
                                  slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                  labelId={
                                    CONSTANT_MANAGER.ADD_CONTRACT.PRIMARY_EMAIL
                                  }
                                />
                              </div>
                              <input
                                type="email"
                                name="primaryEmail"
                                className="form-control rounded-pill h-48"
                                placeholder="Primary Email"
                                value={primaryEmail}
                                onChange={(e) =>
                                  onDetailChange(e.target.value, "primaryEmail")
                                }
                                onBlur={formIk.handleBlur}
                              />
                              {formIk.touched.primaryEmail &&
                              formIk.errors.primaryEmail ? (
                                <div className={styles.error}>
                                  {formIk.errors.primaryEmail}
                                </div>
                              ) : null}
                            </div>

                            {!sameAsCustomer && (
                              <>
                                <div className={styles.fromGroup}>
                                  {" "}
                                  <div className="topTipHover">
                                    <label>Secondary Name</label>{" "}
                                    <i>
                                      <img
                                        className="customeTolImg"
                                        src={tip}
                                        alt=""
                                      />
                                    </i>
                                    <Toltip
                                      slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                      labelId={
                                        CONSTANT_MANAGER.ADD_CONTRACT
                                          .SECONDARY_NAME
                                      }
                                    />
                                  </div>
                                  <input
                                    type="text"
                                    name="secondaryName"
                                    className="form-control rounded-pill h-48"
                                    placeholder="Secondary Name"
                                    value={secondaryName}
                                    onChange={(e) =>
                                      onDetailChange(
                                        e.target.value,
                                        "secondaryName"
                                      )
                                    }
                                  />
                                </div>
                                <div className={styles.fromGroup}>
                                  {" "}
                                  <div className="topTipHover">
                                    <label>Secondary Phone Number </label>{" "}
                                    <i>
                                      <img
                                        className="customeTolImg"
                                        src={tip}
                                        alt=""
                                      />
                                    </i>
                                    <Toltip
                                      slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                      labelId={
                                        CONSTANT_MANAGER.ADD_CONTRACT
                                          .SECONDARY_PHONE_NUMBER
                                      }
                                    />
                                  </div>
                                  <input
                                    type="number"
                                    name="secondaryPhoneNumber"
                                    className="form-control rounded-pill h-48"
                                    placeholder="Secondary Phone Number "
                                    value={secondaryPhoneNumber}
                                    onChange={(e) =>
                                      onDetailChange(
                                        Number(e.target.value),
                                        "secondaryPhoneNumber"
                                      )
                                    }
                                  />
                                </div>

                                <div className={styles.fromGroup}>
                                  {" "}
                                  <div className="topTipHover">
                                    <label>Secondary Email</label>{" "}
                                    <i>
                                      <img
                                        className="customeTolImg"
                                        src={tip}
                                        alt=""
                                      />
                                    </i>
                                    <Toltip
                                      slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                      labelId={
                                        CONSTANT_MANAGER.ADD_CONTRACT
                                          .SECONDARY_EMAIL
                                      }
                                    />
                                  </div>
                                  <input
                                    type="email"
                                    name="secondaryEmail"
                                    className="form-control rounded-pill h-48"
                                    placeholder="Secondary Email"
                                    value={secondaryEmail}
                                    onChange={(e) =>
                                      onDetailChange(
                                        e.target.value,
                                        "secondaryEmail"
                                      )
                                    }
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row align-items-center justify-content-between mx-0">
                        <div className="col-lg-3 col-4 text-start my-3 btn-hover-effect">
                          <h6
                            onClick={() => handleGoBack()}
                            className="btn bg-white arrowyellow border-white border-1 rounded-pill position-relative py-lg-3 py-2 ps-5 backbutton"
                          >
                            <span className="px-lg-3 px-2 text-12 fw-semibold d-inline-block">
                              Back
                            </span>
                            <i className="bg-yellow rounded-pill arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                              <img src={blackarrow} alt="" />
                            </i>
                          </h6>
                        </div>

                        <div className="col-lg-3 col-4">
                          <SaveAsDraftButton />
                        </div>

                        <div className="col-lg-3 col-4 text-end my-3 btn-hover-effect">
                          <h6
                            onClick={handleAddMoreDetails}
                            className="btn bg-white arrowblue border-grey border-1 rounded-pill position-relative py-lg-3 py-2"
                          >
                            <span className="py-1 text-12 fw-semibold d-inline-block">
                              + Add More Details
                            </span>
                            {/* <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                                  <img src={whitearrow} alt="" />
                                </i> */}
                          </h6>
                        </div>

                        <div className="col-lg-3 col-12 text-end my-3 btn-hover-effect">
                          <button
                            type="submit"
                            className="btn bg-white arrowblue border-white border-1 rounded-pill position-relative py-lg-3 py-2 pe-5"
                          >
                            <span className="px-3 text-12 fw-semibold d-inline-block">
                              Next
                            </span>
                            <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                              <img src={whitearrow} alt="" />
                            </i>
                          </button>
                        </div>
                        {/* <button type="submit">next</button> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className={styles.regisFooter}>
                  <div className="" onClick={() => handleGoBack()}>
                    <span>‹ Back</span>
                  </div>
                  <div className="col-3 text-center">
                    <div
                      className={`${styles.draftBTn} ${styles.draftBlue}`}
                      onClick={() => handleAddMoreDetails()}
                    >
                      <button className={styles.addDetalBtn}>+ Add More Details</button>
                    </div>
                  </div>


                  <div className={styles.DraftBtns}>
                    <SaveAsDraftButton/>
                  </div>
                  <div className="" onClick={handleGoToReviewPage}>
                    <button>Review ›</button>
                  </div>
                </div> */}
              </div>
            </div>
          </form>
          {modalDetail.show && (
            <CustomModal
              key={key}
              show={modalDetail.show}
              backdrop="static"
              showCloseBtn={true}
              isRightSideModal={false}
              mediumWidth={false}
              className={modalDetail.flag === "addMore" ? "addMore" : ""}
              child={
                modalDetail.flag === "addMore" ? (
                  <ConfirmModal onCloseModal={() => handleOnCloseModal()} />
                ) : (
                  ""
                )
              }
              onCloseModal={() => handleOnCloseModal()}
            />
          )}
        </div>
      </div>
    </>
  );
};
export default AddCustomerDetails;
