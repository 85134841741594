import React from "react";
import styles from "../styles/style.module.css";

const AvailableForWork = () => {
  return (
    <div className={styles.availabework}>
      <div className="container my-4">
        <div className={styles.availabeforwork}>
          <div className="p-lg-5 p-3">
            <div className="newheadingstyle">
              <h2 className="text-64 text-white mb-0">Did you know?</h2>
              <p className="text-24 text-white fw-normal">
                by enabling our availability, we can attract numerous leads?
                <br />
                If expansion is our goal, then this step is crucial!
              </p>

              <p className="mb-0 btn btn-white d-inline-flex ">
                <span className=" text-blue text-17 me-1">
                  I am Available for work{" "}
                </span>
                <div className={styles.switchToggle}>
                  <input type="checkbox" id="switch2" />
                  <label htmlFor="switch2"></label>
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvailableForWork;
