import React from "react";
import PersonalInfo from "./PersonalInfo";
import EmailVerify from "./EmailVerification";

const SignUp = () => {
  return (
    <>
      <PersonalInfo />
      <EmailVerify />
    </>
  );
};

export default SignUp;
