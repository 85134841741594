import React, { useEffect, useState } from 'react'
import shareprofile from '../../../assets/images/contact_friend.png'
import share from '../../../assets/images/share_grey.svg'
import dots from '../../../assets/images/dots.svg'
import banner from '../../../assets/images/posts_details.jpg'
import Connect from '../../../assets/images/connect.svg'
import Comment from '../../../assets/images/comment.svg'
import styles from "../styles/style.module.css"
import Slider from 'react-slick'
import { PostsResponse } from '../interface'
import { getPostsData } from '../api'
import { POST_TYPE } from '../../../utils/enums/types'
import moment from 'moment'

const Posts = () => {
  const [posts, setPosts] = useState<PostsResponse[]>([])
  const getPosts = async () => {
    const response = await getPostsData(POST_TYPE.FREE);
    setPosts(response.data)
  }
  useEffect(() => {
    getPosts()
  }, []);

  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 3.2,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: false,
    nav: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3.2,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
      <div className='recommendedjobs share_connect_friends'>
        <div className="row align-items-center justify-content-between">
          <Slider {...settings}>
            {posts?.map((post, idx) => {
              return (
                <div key={idx} className='col border rounded-3 p-lg-3 p-2 text-center'>
                  <div className=' mb-2 text-start'>
                    <div className='d-flex align-items-start justify-content-between'>
                      <div className='col-8'>
                        <div className='newheadingstyle text-start d-flex align-items-center'>
                          <figure className={styles.profileCircle}><img src={post?.user?.profilePicture ? post?.user?.profilePicture : shareprofile} alt='' width={50} height={50} /></figure>
                          <div className='ps-3'>
                            <h3 className='mb-0 text-16'>{post?.user?.name}</h3>
                            <p className='text-12 mb-2'>
                            {moment.utc(post.createdAt).fromNow()}
                              </p>
                          </div>
                        </div>
                      </div>
                      <div className='col-4 text-end'>
                        <i><img src={dots} className='img-fluid' /></i>
                      </div>
                    </div>
                    <div className='col-12'>
                      <p className='text-12'>{post?.description?post?.description?.slice(0,500):""}</p>
                    </div>
                  </div>
                  {/* <div className='mt-3'>
                    {post?.files?.map((file, index) => (
                      <figure key={index}>
                        <img src={file} alt='' className='img-fluid' />
                      </figure>
                    ))}
                  </div> */}
                  <div className='mt-3 banner_img'>

                    <figure >
                      <img src={`${post?.files[0]}`} alt='' className='img-fluid' />
                    </figure>

                  </div>
                  <div className='mt-3'>
                    <div className='row align-items-start g-2'>
                      <div className='col'>
                        <button type='button' className='btn-white w-100 text-center border text-12 d-flex align-items-center justify-content-center text-grey flex-column fst-normal border-0'>
                          <i className='me-1'><img src={post?.user?.profilePicture ? post?.user?.profilePicture : shareprofile} alt='' width={15} height={14} /></i>
                          <span className='lh-sm text-10'>Posted By {post?.user?.name}</span>
                        </button>
                      </div>
                      <div className='col'><button type='button' className='btn btn-white w-100 text-center border text-12 d-flex align-items-center justify-content-center text-grey flex-column border-0'><i className='me-1'><img src={share} alt='' width={15} height={14} /></i><span className='lh-sm  text-10'>Share</span></button></div>
                      <div className='col'><button type='button' className='btn btn-white w-100 text-center border text-12 d-flex align-items-center justify-content-center text-grey flex-column border-0'><i className='me-1'><img src={Connect} alt='' width={15} height={14} /></i><span className='lh-sm  text-10'>Connect</span></button></div>
                      <div className='col'><button type='button' className='btn btn-white w-100 text-center border text-12 d-flex align-items-center justify-content-center text-grey flex-column border-0'><i className='me-1'><img src={Comment} alt='' width={15} height={14} /></i><span className='lh-sm  text-10'>Comment</span></button></div>
                      <div className='col'><button type='button' className='btn btn-white w-100 text-center border text-12 d-flex align-items-center justify-content-center text-grey flex-column border-0'><i className='me-1'><img src={share} alt='' width={15} height={14} /></i><span className='lh-sm  text-10'>Send Request</span></button></div>
                    </div>
                  </div>
                </div>
              )
            })}


          </Slider>
        </div>
      </div>
    </>
  );
}

export default Posts