import React from "react";
import NewFeeds from "../../newfeeds/routes";
import Circleplus from "../../../assets/images/plus_circle.svg";
import styles from "../styles/style.module.css";

const PeopleAvailable = () => {
  return (
    <div className={styles.new_feeds}>
      <div className="newheadingstyle">
        <h3 className="mb-3">People Available for work</h3>
      </div>
      <div className="row g-3">
        <div className="col-xl-1 col-md-2 col-sm-3 col-4">
          <div className={styles.openwork}>
            <i>
              <img src={Circleplus} alt="" />
            </i>
            <p>Open to work</p>
          </div>
        </div>
        <div className="col-xl-11 col-md-10 col-sm-9 col-8 slide_openwork">
          <NewFeeds />
        </div>
      </div>
    </div>
  );
};

export default PeopleAvailable;