import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import storage from '../../../utils/storage';
import {
  authenticateUser,
  logoutUser,
} from '../../../redux/reducers/authentication';

const Authorization: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const savedToken = storage.getToken();
    const userId = storage.getAuthUserId();

    if (savedToken && savedToken !== '' && userId && userId !== '') {
      dispatch(
        authenticateUser({
          isLoading: false,
          token: savedToken,
          userId: userId,
        })
      );

      setIsLoading(false);
    } else {
      dispatch(logoutUser());
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return (
      <div>
        <div className="spinner-grow text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return <>{children}</>;
};

export default Authorization;
