import React from "react";
import styles from "../styles/style.module.css";
import artifiimg from "../../../assets/images/arti_inte_rightimg.png";
import artifitext from "../../../assets/images/artificial_inte.png";

const GoogleAds = () => {
  return (
    <div className={styles.googleadsblock}>
      <div className="container">
        <div className={styles.googleads}>
          <div className="row align-items-center g-4 justify-content-between mt-3 mx-0">
            <div className="col-lg-4 col-6">
              <i>
                <img src={artifitext} alt="" className="img-fluid" />
              </i>
            </div>
            <div className="col-lg-4 col-6 text-center">
              <button
                type="button"
                className="btn btn-white px-lg-5 px-3 py-lg-3 py-2"
              >
                Google Ads
              </button>
            </div>
            <div className="col-lg-4 col-12 text-center">
              <i>
                <img src={artifiimg} alt="" className="img-fluid" />
              </i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleAds;
