import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../css/styles/style.module.css";
import info from "../../../../assets/images/info.png";
import loc from "../../../../assets/images/loc.png";
import grbag from "../../../../assets/images/grbag.png";
import chatic from "../../../../assets/images/chatic.png";
import Slider from "react-slick";
import moment from "moment";
import { ContractResponse } from "../interface";
import { getContractList } from "../api";
import { TYPE, WORK_STATUS_TYPE } from "../../../../utils/enums/status";
import { CONTRACT_Type } from "../../../../utils/enums/types";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { WORK_TABS } from "../../../../utils/enums/workTabs.enum";
import { APP_ROUTES } from "../../../../utils/routes/AppRoutes";
import ChatModule from "../../../ChatModule/routes";
import Toltip from "../../toltip/page";
import { CONSTANT_MANAGER } from "../../../../utils/constans/constants";
import tip from "../../../../../src/assets/images/tip.png";
const PendingContracts = () => {
  const navigate = useNavigate();
  const dashboardData = useSelector((state: RootState) => state.dashboard);
  const { activeTab } = dashboardData;
  const [isExpanded, setIsExpanded] = useState(false);
  const [pendingContarct, setPendingContract] = useState<ContractResponse[]>([]);
  const [showChatModal, setShowChatmodal] = useState<boolean>(false);
  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  }
  const [loading, setLoading] = useState<boolean>(false);

  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 2,
    centerPadding: "60px",
    slidesToScroll: 2,
    infinite: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.02,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // useEffect(() => {
  //   if (activeTab === WORK_TABS.DONEFORME) {
  //     getPendingContractsForMe();
  //     return;
  //   } else if (activeTab === WORK_TABS.DONEBYME) {
  //     getPendingContractsByMe();
  //     return;
  //   }
  // }, [activeTab]);

  // const getPendingContractsForMe = async () => {
  //   const response: any = await getContractList(
  //     CONTRACT_Type.HOURLY_CONTRACT,
  //     TYPE.DONEFORME,
  //     WORK_STATUS_TYPE.PENDING
  //   );
  //   setPendinContract(response?.data);
  //   setLoading(false);
  // };

  // const getPendingContractsByMe = async () => {
  //   const response: any = await getContractList(
  //     CONTRACT_Type.HOURLY_CONTRACT,
  //     TYPE.DONEBYME,
  //     WORK_STATUS_TYPE.PENDING
  //   );
  //   setPendinContract(response?.data);
  //   setLoading(false);
  // };

  useEffect(() => {
    const fetchContracts = async () => {
      setLoading(true);
      try {
        let response: any;
        if (activeTab === WORK_TABS.DONEFORME) {
          response = await getContractList(
            CONTRACT_Type.HOURLY_CONTRACT,
            TYPE.DONEFORME,
            WORK_STATUS_TYPE.PENDING
          );
        } else if (activeTab === WORK_TABS.DONEBYME) {
          response = await getContractList(
            CONTRACT_Type.HOURLY_CONTRACT,
            TYPE.DONEBYME,
            WORK_STATUS_TYPE.PENDING
          );
        } else {
          response = { data: [] };
        }
        setPendingContract(response.data);
      } catch (error) {
        console.error("Error fetching contracts:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchContracts();
  }, [activeTab]);

  const handleOpenAdminChat = () => {
    setShowChatmodal(true);
  };
  return (
    <>
      {pendingContarct?.length > 0 && (
        <div className={styles.AllContracta}>
          <div className="row align-items-center justify-content-between mx-0 mt-4">
            <div className="col-lg-6 col-12">
            
              <div className="topTipHover">
              <div className="d-flex align-items-center">
                <h4 className="form-check-label text-20 fw-bold  font-inter">
                Contracts Pending Approval 
                </h4>
                <i>
                  <img className="customeTolImg" src={tip} alt="" />
                </i>
                <Toltip
                  slug={CONSTANT_MANAGER.SLUG_HOME.HOME}
                  labelId={CONSTANT_MANAGER.HOME_DATA.MY_TEAMS}
                />
              </div>
            </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className={styles.RightFilyter}>
                <div className={styles.FormGroup}>
                  <span>Filter:</span>
                  <select>
                    <option>Date and time</option>
                  </select>
                </div>
                <div className={styles.FormGroup}>
                  <span>Sort:</span>
                  <select>
                    <option>This Week</option>
                  </select>
                </div>
                <button onClick={() => navigate("/add-contract")}>
                  +Post New Contract
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {loading ? (
        <div className="spinner-grow text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <div className={styles.OuterSlides}>
          <div className="custom-ongoing">
            <Slider {...settings}>
              {pendingContarct?.length > 0
                ? pendingContarct?.map((data, idx) => {
                  return (
                    <>
                      <div key={idx} className={styles.ContarctOuter}>
                        <div className={styles.ContarctHead}>
                          <div className="row align-item-center justify-content-between mx-0 w-100">
                            <div className="col-xl-5 col-lg-12 col-md-5 col-12">
                              <div className={styles.leftContract}>
                                <h5>Contract ID : #{data.workNumber}</h5>

                                <span>
                                  Start Date:{" "}
                                  {moment(data.times.startDate).format(
                                    "DD MMM YYYY"
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="col-xl-7 col-lg-12 col-md-7 col-12">
                              <div
                                className={styles.leftContract}
                                style={{ textAlign: "end" }}
                              >
                                <button className={styles.CoptText}>
                                  <i className="far fa-copy"></i> Copy
                                </button>

                                <button>
                                  {data.workStatus === "pending"
                                    ? "Pending"
                                    : data.workStatus === "approved"
                                      ? "Approved"
                                      : ""}
                                </button>
                                <button
                                  className={styles.CoptText}
                                  onClick={() =>
                                    navigate(
                                      `${APP_ROUTES.CONTRACT_DETAILS}/${data._id}`
                                    )
                                  }
                                >
                                  <i className="far fa-eye"></i> View
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.OuterPadd}>
                          <div className={styles.PendingCOnatctList}>
                            <div className={styles.Local}>
                              <div className={styles.adrssLoc}>
                                <p>
                                  <img src={loc} alt="" /> {data.address},
                                  {data.street}
                                </p>
                                <span className={styles.contarctBag}>
                                  <img src={grbag} alt="" />
                                  {data.planType === "whiteglove"
                                    ? "White Glove Contract"
                                    : "Standard Contract"}
                                </span>
                              </div>
                              <p>
                                <img src={loc} alt="" /> Initiated
                                {moment(data.times.startDate).format(
                                  "DD MMM YYYY"
                                )}
                              </p>
                            </div>
                            <div
                              className=""
                              style={{ minHeight: "100px" }}
                            >
                              <div dangerouslySetInnerHTML={{
                                __html: isExpanded && data.description ? data?.description : data?.description?.slice(0, 150)
                              }} />
                              <span onClick={toggleReadMore} className={styles.readMore}>
                                {isExpanded ? 'Show Less' : 'Read More'}
                              </span>
                            </div>

                            {data?.technicalSkills?.length && (
                              <div className={styles.PenigSkil}>
                                <h5>Technical Skills</h5>
                                <ul>
                                  {data.technicalSkills?.map(
                                    (skill: any) => {
                                      return (
                                        <>
                                          <li>
                                            {
                                              skill.manufacturerDetails
                                                ?.name
                                            }
                                          </li>
                                          <li>
                                            {
                                              skill.manufacturerModelDetails
                                                ?.name
                                            }
                                          </li>
                                        </>
                                      );
                                    }
                                  )}
                                </ul>
                              </div>
                            )}

                            <div className={styles.PenigSkilRate}>
                              <h5>Pay Rates</h5>
                              <ul>
                                <li>
                                  <span>Base</span>
                                  <label>${data?.rates?.baseRate}</label>
                                </li>
                                <li>
                                  <span>OverTime</span>
                                  <label>
                                    ${data?.rates?.overTimeRate}
                                  </label>
                                </li>
                                <li>
                                  <span>Double Time</span>
                                  <label>
                                    ${data?.rates?.doubleTimeRate}
                                  </label>
                                </li>
                              </ul>
                            </div>
                            <div
                              className={styles.chatAdmin}
                              onClick={handleOpenAdminChat}
                            >
                              <a href="#">
                                <img src={chatic} alt="" />
                                Chat with Admin
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })
                : ""}
            </Slider>
          </div>
        </div>
      )}
      {showChatModal && <ChatModule onClose={()=>setShowChatmodal(false)}  />}
    </>
  );
};

export default PendingContracts;
