import * as Yup from "yup";
import moment from "moment";

const today = moment().startOf("day").toDate();

const occupationDropdownSchema = Yup.object().shape({
  selectedOccupation: Yup.array()
    .of(Yup.string())
    .required("Occupation is required"),
});

const techSkillsSchema = Yup.object().shape({
  skills: Yup.array().of(
    Yup.object().shape({
      category: Yup.string()
        .required("Please Enter Hardware/Software name")
        .matches(/^[A-Za-z\s]+$/, "Name contains only alphabetic characters")
        .min(1),
      subCategory: Yup.string()
        .required("Please Enter Hardware/Software Model name")
        .min(1),
      skillType: Yup.string().required().min(1),
    })
  ),
});
const questionsSchema = Yup.object().shape({
  questions: Yup.array()
    .of(
      Yup.object().shape({
        question: Yup.string().required("Please Enter Question").min(1),

        required: Yup.string().required().min(1),
      })
    )
    .required(),
});

const durationSchema = Yup.object().shape({
  startDate: Yup.date()
    .required("Start date is required")
    .min(today, "Selected date cannot be in the past"),
  
  duration: Yup.number()
    .typeError("Duration must be a number") // Handle non-numeric values
    .integer("Duration must be an integer")
    .min(0, "Duration cannot be negative") // Ensures duration is not negative
    .required("Duration is required")
    .test("is-valid-duration", "Duration must be a positive integer", (value) =>
      Number.isInteger(value) && value >= 0
    ),

  endDate: Yup.date()
    .required("End date is required")
    .min(Yup.ref("startDate"), "End date should be greater than start date"),
});


const keyInfoValidationSchema = Yup.object().shape({
  noOfPeople: Yup.number()
    .required("Number of people is required")
    .positive("Must be a positive number")
    .integer("Must be an integer"),
  shift: Yup.string().required("Shift is required"),
  plannedHoursPerDay: Yup.number().required(
    "Planned Hours Per Day is required"
  ),
  plannedDaysPerWeek: Yup.number().required(
    "Planned Days Per Week is required"
  ),
});

const rateValidationSchema = Yup.object().shape({
  baseRate: Yup.number()

    .required("Base rate is required")
    .positive("Must be a positive number")
    .integer("Must be an integer"),
  // otRate: Yup.number()

  // .required('Base rate is required')
  // .positive('Must be a positive number')
  // .integer('Must be an integer'),
  // dtRate: Yup.number()

  // .required('Base rate is required')
  // .positive('Must be a positive number')
  // .integer('Must be an integer'),
  paymentTerms: Yup.object().shape({
    label: Yup.string().required("Select the payment terms"),
    value: Yup.string().required("Select the payment terms"),
  }),
});
const rateValidationDirectjob=Yup.object().shape({
  baseRate: Yup.number()

  .required("Base rate is required")
  .positive("Must be a positive number")
  .integer("Must be an integer"),
});

const validationSchemaForOccupation = Yup.object().shape({
  primaryOccupation: Yup.object().shape({
    label: Yup.string().required("Select the payment terms"),
    value: Yup.string().required("Select the payment terms"),
  }),
});
const skillTradesValidation = Yup.object().shape({
  selectedSkillsId: Yup.array()
    .of(Yup.string())
    .required("Skills are required"),
});

const descriptionSchema = Yup.object().shape({
  description: Yup.string()
    .required("Please Enter Description of your Work")
    .test(
      "min-words",
      "Description must be at least 10 words long",
      value => {
        if (!value) return false; // Required check will handle this
        const wordCount = value.trim().split(/\s+/).length;
        return wordCount >= 10;
      }
    ),
});

const onSiteCustomervalidationSchema = Yup.object().shape({
  primaryName: Yup.string().required("Primary Name is required"),

  primaryEmail: Yup.string()
    .email("Invalid email format")
    .required("Primary Email is required"),
  primaryPhoneNumber: Yup.number()
    .required("Primary Phone Number is required")
    .positive("Must be a positive number")
    .integer("Must be an integer"),
});
const locationSchema = Yup.object().shape({
  selectedCountry: Yup.string().required("Country is required"),
  selectedStreet: Yup.string().required("Street is required"),
  selectedState: Yup.string().required("State is required"),
  selectedCity: Yup.string().required("City is required"),
});
export {
  occupationDropdownSchema,
  techSkillsSchema,
  durationSchema,
  rateValidationSchema,
  validationSchemaForOccupation,
  descriptionSchema,
  questionsSchema,
  skillTradesValidation,
  keyInfoValidationSchema,
  onSiteCustomervalidationSchema,
  locationSchema,
  rateValidationDirectjob
};
