import React from 'react';
import styles from "../../../components/common/css/styles/style.module.css";
import pro from "../../../assets/images/pro.png";
import loc from "../../../assets/images/loc.png";
import rdinfo from "../../../assets/images/rdinfo.png";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../../utils/routes/AppRoutes';
import storage from '../../../utils/storage';

const GetAllTaskCalander = (props: any) => {
    const { getAllEventData } = props;
    const navigate = useNavigate();

    const handelVideoCall = (item: any) => {
        // setting local storage need to delete after video call done
        storage.setClientInterviewEventId({
            day: moment.utc(item?.eventDate).local().format("MM/DD/yyyy"),
            time: moment.utc(item?.eventTime, "hh:mm").local().format("hh:mm A"),
            videoCallDetails: item.videoDetails,
            userId: item?.userId._id,
        });
        storage.setClientInterviewUserId(item?.userId._id);
        navigate(APP_ROUTES.VIDEO_CALL.AUDIENCE);
    }

    return (
        <>
            {
                getAllEventData?.length ? getAllEventData?.map((elem: any) =>
                    <>
                        <div className="col-md-6 ">
                            <div className={styles.ContarctOuter}>
                                <div className={styles.ContarctHead}>
                                    <div className={styles.leftContract}>
                                        <h5>Contract ID : #457</h5>
                                    </div>
                                    <div className={styles.leftContract}>
                                        <button className={styles.shedule}>
                                            <i className="fas fa-video"></i> {elem?.interviewType}
                                        </button>
                                    </div>
                                </div>
                                <div className={styles.outerNameDetail}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className={styles.leftName}>
                                                <h4>Professional Details</h4>
                                                <div className={styles.PrileImg}>
                                                    <span>
                                                        <img src={pro} alt="" />
                                                    </span>
                                                    <div>
                                                        <h5>{elem?.workId?.primaryContact?.name}</h5>
                                                        <h6>Pia Automation . inc</h6>
                                                        <p>
                                                            <img src={loc} alt="" /> {elem?.workId?.address}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className={styles.rightName}>
                                                <p>Start Date: {moment.utc(elem?.workId?.times?.startDate).local().format("MM/DD/yyyy")}</p>
                                                <p>End Date: {moment.utc(elem?.workId?.times?.endDate).local().format("MM/DD/yyyy")}</p>
                                                <p>Rate Per Hour : ${elem?.workId?.rates?.baseRate}</p>
                                                <p>Expense Terms : Net {elem?.workId?.times?.noOfPepole}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.VideoCallBtn}>
                                    <button>
                                        <i className="far fa-times-circle"></i> Cancel Contract
                                    </button>
                                    <button className="videBtn"
                                        onClick={() => handelVideoCall(elem)}
                                    >
                                        <i className="fas fa-video"></i> Request for Video Call
                                    </button>
                                </div>
                                <div className={styles.disc}>
                                    <h4>Additional Notes </h4>
                                    <p>
                                        {elem.additionalNotes}
                                    </p>
                                </div>
                                <div className={styles.Infor}>
                                    <p>
                                        <img src={rdinfo} alt="" /> If you cancel a project that you
                                        committed to, your account will be subject to cancelation.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </>
                ) : <p>Data No Found </p>
            }
        </>
    );
}

export default GetAllTaskCalander;