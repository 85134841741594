export enum OVERVIEW_FORM_FIELDS {
  PRIMARY_OCCUPATION,
  SECONDARY_OCCUPATION,
  DESCRIPTION,
  START_DATE,
  ESTIMATED_DATE,
  END_DATE,
  NO_OF_PEOPLE,
  SHIFT,
  PLANNED_HOURS_PER_DAY,
  PLANNED_HOURS_PER_WEEK,
  COUNTRY,
  STATE,
  CITY,
  STREET,
  PAYMENT_TERMS,
  BASE_RATE,
  OT_RATE,
  DT_RATE,
  TRAVEL_ALLOWED,
  TRAVEL_TIME_RATE,
  TRAVEL_TIP_ALLOWED,
  TRAVEL_HOURS,
  IS_EXPENSE_REIMBURSED,
  DAILY_PER_DIEM,
  TRAVEL_PER_DIEM,
  HOTEL_ALLOWANCE,
  RENTAL_CAR_ALLOWANCE,
  FUEL,
  AIRFARE_ALLOWANCE,
  MILLAGE,
  PRIMARY_NAME,
  PRIMARY_PHONE,
  PRIMARY_EMAIL,
  SECONDARY_NAME,
  SECONDARY_PHONE,
  SECONDARY_EMAIL,
  IS_AUTOMATE_AMERICA,
  IS_LEGAL_RESIDENCE,
  IS_VISA,
  PROGRAMMING_SKILLS,
  MODEL_CONTROLLER,
  SKILLS_REQUIRED,
  SOFTWARE_LICENSES,
  SOFTWARE_LICENSES_REQUIRED,
  DRUG_TEST_REQUIRED,
  DRUG_REGENCY,
  BG_TEST_REQUIRED,
  BG_REGENCY,
  PROCEDURES_AND_POLICIES,
  INTERVIEW_QUESTION,
  ELIGIBILITY_QUESTION,
}
