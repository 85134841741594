import React, { useState } from "react";
import styles from "../styles/sigup.module.css";
import { useNavigate } from "react-router-dom";
import logo from "../../../../assets/images/logo.png";
import left from "../../../../assets/images/left.png";
import { useFormik } from "formik";
import { EmailSchema } from "../validations";
import { useDispatch, useSelector } from "react-redux";
import { setEmail } from "../../../../redux/reducers/auth";
import { AUTH_ROUTES } from "../../../../utils/routes/AuthRoutes";
import eye from "../../../../assets/images/eye.png";
import closeye from "../../../../assets/images/closeye.png";
import whitearrow from "../../../../assets/images/whitearrow.svg";
import HeaderContent from "../../../../shared/Components/HeaderContent/headerContent";

const EmailVerify: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetail = useSelector((state: any) => state.userData);

  const formik: any = useFormik({
    initialValues: {
      email: userDetail ? userDetail?.emailData?.email : "",
    },
    validationSchema: EmailSchema,
    onSubmit: (values) => {
      const emailObj: any = {
        email: values.email,
      };
      dispatch(setEmail(emailObj));
      navigate(AUTH_ROUTES.PHONE_VERIFY);
    },
  });

  return (
    <>
      <HeaderContent />
      <div className="py-4 heightautoscroll">
        <div className={styles.signuppage}>
          {/* <div className={styles.signupHead}>
				<div className={styles.leftSign}>
					<img src={logo} alt="" />
				</div>
				<div className={styles.rightSign}>
					<p className="mb-0">Already have an account? <a href="/">Sign in</a></p>
					<button>Need Help?</button>
				</div>
			</div> */}
          <div className={styles.signUPContainer} style={{padding: "15px"}}>
            <form onSubmit={formik.handleSubmit}>
              <h5>Enter email address</h5>
              <span className={styles.Leftntm}>
                <a href="#">
                  <img src={left} alt="" onClick={() => navigate(-1)} />
                </a>
              </span>
              <p>Please enter your email address to proceed. This helps us verify your<br /> 
              identity for a secure login experience.</p>
              <div className={styles.fromGroup}>
                <input
                  type="email"
                  placeholder="Eg. automateamerica@gmail.com"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  {...formik.getFieldProps("email")}
                />

                {formik.touched.email && (
                  <div className={styles.error}>{formik.errors.email}</div>
                )}
              </div>

              {/* <div className={styles.fromGroup} >
						<input type='submit' value="Next ›" />
					</div> */}
              <div className="col-lg-3 col-6 mx-auto">
                <div className="text-center my-3 btn-hover-effect border rounded-pill">
                  {/* <input type="submit"/> */}
                  <button
                    type="submit"
                    className="btn bg-white arrowblue border-white border-1 rounded-pill position-relative py-lg-3 py-2 w-100 pe-5 text-center"
                  >
                    <span className="px-lg-4 px-2 text-12 fw-semibold w-100">Next</span>
                    <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                      <img src={whitearrow} alt="" />
                    </i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default EmailVerify;
