import React, { FC, useEffect, useState } from 'react';
import styles from '../../../reviewcontract/styles/style.module.css';
import { Dropdown, FormControl } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DatePicker from 'react-datepicker';
import down from '../../../../../assets/images/down.png';
import Delete from '../../../../../assets/images/delete.svg';
import moment from 'moment';
import { useAppSelector } from '../../../../../redux/hooks';
import { RootState } from '../../../../../redux/store';
import {
  setBackgroundCheck,
  setCustomerDetails,
  setDrugTest,
  setDurations,
  setEligibilityQuestions,
  setExpenseData,
  setInterviewQuestions,
  setKeyInformation,
  setLegalDetails,
  setLocation,
  setOccupation,
  setProceduresAndPolicies,
  setRates,
  setSoftwareLicenseDetails,
  setTechnicalSkills,
  setTravelData,
  setWorkDescription,
} from '../../../../../redux/reducers/addContract';
import { IOption } from '../../../../../Interfaces';
import { getMasterTypes } from '../../../../../feature/auth/signup/api';
import { getManufacturerModelList } from '../../../api';
import { MASTER_TYPE } from '../../../../../utils/enums/masterTypes.enum';
import Header from '../../../../header/routes';
import CustomModal from '../../../../../shared/CustomModal/customModal';
import OverviewCollapseItem from '../../Components/OverviewCollapseItem/OverviewCollapseItem';
import { SaveAsDraftButton } from '../../Components';
import whitearrow from '../../../../../assets/images/whitearrow.svg';
import overviewpage from '../../../../../assets/images/overviewpage.svg';
import print from '../../../../../assets/images/print_white.svg';
import download from '../../../../../assets/images/download_white.svg';
import editicon from '../../../../../assets/images/edit_icon.svg';
import Subscription from '../../subscription/choosePlanModal/choosePlanModal';
import { OVERVIEW_FORM_FIELDS } from '../../../enums';

const OverviewContract: FC = () => {
  const dispatch = useDispatch();
  const [key, setKey] = useState(Math.random());
  const [occupationFilterValue, setOccupationFilterValue] =useState<string>('');
  const [activeItem, setActiveItem] = useState<any | null>();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filterValue, setFilterValue] = useState<string>('');
  const [inEditField, setInEditField] = useState<OVERVIEW_FORM_FIELDS | null>(null );
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: '',
    flag: '',
  });

  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: '',
      flag: '',
    });
  };
  const contract = useAppSelector((state: RootState) => state.addContract);
  const {
    occupation,
    occupationsList,
    durations,
    keyInformation,
    rates,
    travelData,
    expenseData,
    customerDetails,
    location,
    legalDetails,
    softwareLicenseDetails,
    drugTest,
    backgroundCheck,
    proceduresAndPolicies,
    interviewQuestions,
    eligibilityQuestions,
    technicalSkillsData,
  } = contract;
  const { startDate, endDate, duration } = durations;
  const { isDrugTestRequired, drugRegacy } = drugTest;
  const { softwareLicenses, options, isRequired } = softwareLicenseDetails;

  const { selectedCity, selectedCountry, selectedState, selectedStreet } =
    location;
  const {
    primaryName,
    primaryEmail,
    primaryPhoneNumber,
    secondaryName,
    secondaryEmail,
    secondaryPhoneNumber,
  } = customerDetails;

  const {
    isExpenseReimbursed,
    travelDayPerDiem,
    dailyPerDiem,
    hotelAllowance,
    fuel,
    rentalCarAllowance,
    airFareAllowance,
    mileage,
  } = expenseData;
  const { isAutomateAmerica, isLegalResidence, isVisa } = legalDetails;
  const { backgroundRegecy, isBackgroundCheckRequired } = backgroundCheck;

  const primaryOccOptions =
    occupationFilterValue && occupationFilterValue !== ''
      ? occupationsList.filter((x) =>
        x.label.toLowerCase().includes(occupationFilterValue.toLowerCase())
      )
      : occupationsList;

  const handleOccupationChange = (value: any, key: string) => {
    dispatch(setOccupation({ ...occupation, [key]: value }));
  };

  const toggleOption = (item: any) => {
    setActiveItem(item === activeItem ? null : item);
    setIsEditing(false);
  };

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  const onDescriptionChange = (value: string) => {
    dispatch(setWorkDescription(value));
  };

  const ondateChange = (value: Date | string | number | null, key: string) => {
    let updatedDuration = duration;
    // formik.setFieldValue(key, value);
    if (key === 'startDate' || key === 'endDate') {
      updatedDuration = moment(endDate).diff(moment(startDate), 'days');
      // formik.setFieldValue('duration', updatedDuration);
    }
    dispatch(
      setDurations({
        ...durations,
        duration: updatedDuration,
        [key]: value,
      })
    );
  };
  const onKeyInforChange = (value: string | number, key: string) => {
    dispatch(
      setKeyInformation({
        ...keyInformation,
        [key]: value,
      })
    );
  };
  const onRateChange = (value: any, key: string) => {
    dispatch(setRates({ ...rates, [key]: value }));
  };
  const onTravelDataChange = (
    value: string | number | boolean,
    key: string
  ) => {
    if (key === 'isTravelRequired' && value === false) {
      dispatch(
        setTravelData({
          isTravelRequired: false,
        })
      );
      return;
    }
    dispatch(
      setTravelData({
        ...travelData,
        [key]: value,
      })
    );
  };
  const onExpanceChange = (value: string | boolean | number, key: string) => {
    dispatch(
      setExpenseData({
        ...expenseData,
        [key]: value,
      })
    );
  };
  const onDetailChange = async (
    value: string | boolean | number,
    key: string
  ) => {
    dispatch(setCustomerDetails({ ...customerDetails, [key]: value }));
  };

  const onExpenseDataChange = (
    value: string | boolean | number,
    key: string
  ) => {
    dispatch(
      setExpenseData({
        ...expenseData,
        [key]: value,
      })
    );
  };

  const onInputChange = (value: IOption, key: string) => {
    if (value.label === '') {
      return;
    }
    dispatch(setLocation({ ...location, [key]: value }));
  };

  const filteredOptions = location.countries.filter((option: IOption) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const handleSearch = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const onLegalInputdata = (value: string | boolean, key: string) => {
    dispatch(setLegalDetails({ ...legalDetails, [key]: value }));
  };
  const onEditorValueChange = (text: string) => {
    dispatch(
      setProceduresAndPolicies({
        ...proceduresAndPolicies,
        proceduresAndPolicies: text,
      })
    );
  };

  useEffect(() => {
    getSoftwareLicense();
  }, []);

  const getSoftwareLicense = async () => {
    const response = await getMasterTypes(MASTER_TYPE.SOFTWARE_LICENCES);
    response.data.forEach((x) => {
      x.label = x.name;
      x.value = x._id;
    });

    dispatch(
      setSoftwareLicenseDetails({
        ...softwareLicenseDetails,
        options: response.data,
      })
    );
  };

  const onBackgroudCheck = (value: string | boolean, key: string): void => {
    const updatedData = {
      ...backgroundCheck,
      [key]: value,
    };
    dispatch(setBackgroundCheck(updatedData));
  };

  const onDrugTestValue = (value: string | boolean, key: string): void => {
    const updatedSafetyRequirements = {
      ...drugTest,
      [key]: value,
    };
    dispatch(setDrugTest(updatedSafetyRequirements));
  };
  const onSoftwareLicenseChange = (value: any, key: string) => {
    dispatch(
      setSoftwareLicenseDetails({
        ...softwareLicenseDetails,
        [key]: value,
      })
    );
  };

  const addNewQuestion = (): void => {
    const updatedQuestion = [...interviewQuestions];
    updatedQuestion.push('');
    dispatch(setInterviewQuestions([...updatedQuestion]));
  };

  const onInyterviewQuestionChange = (value: string, index: number): void => {
    const updatedQuestions = [...interviewQuestions];
    updatedQuestions[index] = value;
    dispatch(setInterviewQuestions(updatedQuestions));
  };

  const deleteQuestion = (index: number) => {
    const updatedQuestion = [...interviewQuestions];
    updatedQuestion.splice(index, 1);
    dispatch(setInterviewQuestions([...updatedQuestion]));
  };

  const addNewEligibilityQuestion = (): void => {
    const updatedQuestion = [...eligibilityQuestions];
    updatedQuestion.push('');
    dispatch(setEligibilityQuestions([...updatedQuestion]));
  };

  const onEligibilityQusChange = (value: string, index: number): void => {
    const updatedQuestions = [...eligibilityQuestions];
    updatedQuestions[index] = value;
    dispatch(setEligibilityQuestions(updatedQuestions));
  };

  const deleteEligibilityQuestion = (index: number) => {
    const updatedQuestion = [...eligibilityQuestions];
    updatedQuestion.splice(index, 1);

    dispatch(setEligibilityQuestions([...updatedQuestion]));
  };
  const deleteSkill = (i: number) => {
    const updatedSkills = [...technicalSkillsData.skills];
    updatedSkills.splice(i, 1);

    dispatch(
      setTechnicalSkills({ ...technicalSkillsData, skills: updatedSkills })
    );
  };
  useEffect(() => {
    const getManufacturerData = async () => {
      const response = await getMasterTypes(MASTER_TYPE.MANUFACTURERS);
      response.data.forEach((x) => {
        x.label = x.name;
        x.value = x._id;
      });

      dispatch(
        setTechnicalSkills({
          ...technicalSkillsData,
          skillTypes: response.data,
        })
      );
    };

    getManufacturerData();
  }, []);

  const onSkillsChange = (
    value: string | boolean | IOption[],
    key: string,
    index: number
  ): void => {
    const updatedSkills = [...technicalSkillsData.skills];
    const updatedSkill = { ...updatedSkills[index], [key]: value };
    updatedSkills[index] = updatedSkill;

    dispatch(
      setTechnicalSkills({ ...technicalSkillsData, skills: updatedSkills })
    );
  };
  const handleChangeManufacturer = async (
    option: any,
    index: number,
    key: string
  ) => {
    const updatedSkills = [...technicalSkillsData.skills];
    const updatedSkill = { ...updatedSkills[index] };

    if (key === 'category') {
      const response = await getManufacturerModelList(
        MASTER_TYPE.MANUFACTURERS_MODELS,
        option.value
      );
      response.data.forEach((x) => {
        x.label = x.name;
        x.value = x._id;
      });

      updatedSkill.subCategoriesList = response.data ?? [];
      updatedSkill.category = option;
      updatedSkill.subCategory = [];
    }

    if (key === 'subCategory') {
      updatedSkill.subCategory = option;
    }
    (updatedSkill as any)[key] = option;
    updatedSkills[index] = updatedSkill;
    dispatch(
      setTechnicalSkills({ ...technicalSkillsData, skills: updatedSkills })
    );
  };
  const filteredCategories = technicalSkillsData.skillTypes.filter((option) =>
    option?.label.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const handleSendForApproval = () => {
    setModalDetail({
      show: true,
      title: 'plan',
      flag: 'plan',
    });
    setKey(Math.random());
  };

  return (
    <>
      <Header />
      <div className={styles.Reviewcontarctdetail}>
        <div className={styles.Reviewcontarct}>
          <div className="container text-center">
            <figure>
              <img src={overviewpage} alt="" />
            </figure>
            <h4 className="text-white mb-2">
              Hey {primaryName ? primaryName : ''}!
            </h4>
            <h3 className="text-white mb-3">
              We are excited to see you reach your goal!
            </h3>
            <p className="text-white text-14">
              You have done a fantastic job so far. Please take a moment to
              review your preview. If there are any changes
              <br /> needed, feel free to edit your form. Once you are ready, we
              can move forward together to the next step.
            </p>
          </div>
        </div>
        <div className="p-3">
          <div className="col-lg-10 mx-auto col-12 reviewquestion">
            <div
              className="d-flex align-items-center justify-content-end mb-3"
              style={{ gap: '15px' }}
            >
              <button
                type="button"
                className="btn btn-transparent border border-2 d-inline-block rounded-pill"
              >
                <i className="pe-2">
                  <img src={print} alt="" />
                </i>
                <span className="text-white">Print</span>
              </button>
              <button
                type="button"
                className="btn btn-transparent border border-2 d-inline-block rounded-pill"
              >
                <i className="pe-2">
                  <img src={download} alt="" />
                </i>
                <span className="text-white">Export Data</span>
              </button>
            </div>
          </div>
          <div className="col-lg-10 mx-auto col-12 p-2">
            <div
              className="position-relative p-0 rounded-4"
              style={{ background: '#E7EFFB', border: '1px solid #E7EFFB' }}
            >
              <div className={styles.Reviewcontarctform}>
                <ul className="ps-0">
                  <OverviewCollapseItem
                    toggleEditing={toggleEditing}
                    toggle={(item) => toggleOption(item)}
                    name={'Who do you need?'}
                    isActive={activeItem == 'Who do you need?'}
                  >
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label> Primary Occupation</label>
                        </div>
                        <div className="col-11">
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="primary"
                              id="dropdown-basic"
                              className="ms-0"
                              disabled={
                                inEditField !==
                                OVERVIEW_FORM_FIELDS.PRIMARY_OCCUPATION
                              }
                            >
                              {occupation.primaryOccupation
                                ? occupation.primaryOccupation.label
                                : 'Please select an option'}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <div className="my-3">
                                <FormControl
                                  autoFocus
                                  className="mx-3 my-2 w-auto"
                                  placeholder="Search..."
                                  onChange={(e) =>
                                    setFilterValue(e.target.value)
                                  }
                                  disabled={
                                    inEditField !==
                                    OVERVIEW_FORM_FIELDS.PRIMARY_OCCUPATION
                                  }
                                  value={filterValue}
                                />
                              </div>

                              <div
                                style={{
                                  maxHeight: '300px',
                                  overflowY: 'scroll',
                                }}
                              >
                                {primaryOccOptions.length === 0 ? (
                                  <Dropdown.Item disabled>
                                    No options found
                                  </Dropdown.Item>
                                ) : (
                                  primaryOccOptions?.map(
                                    (option, index: number) => (
                                      <Dropdown.Item
                                        key={index}
                                        onClick={() =>
                                          handleOccupationChange(
                                            option,
                                            'primaryOccupation'
                                          )
                                        }
                                      >
                                        {option.label}
                                      </Dropdown.Item>
                                    )
                                  )
                                )}
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        {inEditField !==
                          OVERVIEW_FORM_FIELDS.PRIMARY_OCCUPATION && (
                            <div
                              className="col-1 text-center"
                              onClick={() =>
                                setInEditField(
                                  OVERVIEW_FORM_FIELDS.PRIMARY_OCCUPATION
                                )
                              }
                            >
                              <i className="pe-2">
                                <img src={editicon} alt="" />
                              </i>
                            </div>
                          )}
                      </div>
                    </div>

                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Secondary Occupation</label>
                        </div>

                        <div className="col-11 text-center">
                          <Select
                            isMulti
                            name="colors"
                            options={occupationsList as any}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(val) =>
                              handleOccupationChange(
                                val,
                                'secondaryOccupations'
                              )
                            }
                            value={occupation.secondaryOccupations}
                            isDisabled={
                              inEditField !==
                              OVERVIEW_FORM_FIELDS.SECONDARY_OCCUPATION
                            }
                          />
                        </div>

                        {inEditField !==
                          OVERVIEW_FORM_FIELDS.SECONDARY_OCCUPATION && (
                            <div
                              className="col-1 text-center"
                              onClick={() =>
                                setInEditField(
                                  OVERVIEW_FORM_FIELDS.SECONDARY_OCCUPATION
                                )
                              }
                            >
                              <i className="pe-2">
                                <img src={editicon} alt="" />
                              </i>
                            </div>
                          )}
                      </div>
                    </div>
                  </OverviewCollapseItem>

                  <OverviewCollapseItem
                    toggleEditing={toggleEditing}
                    toggle={(item) => toggleOption(item)}
                    name={'What do you need done?'}
                    isActive={activeItem == 'What do you need done?'}
                  >
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Description</label>
                        </div>

                        <div className="col-11 text-center">
                          <CKEditor
                            editor={ClassicEditor}
                            data={contract.workDescription}
                            disabled={
                              inEditField !== OVERVIEW_FORM_FIELDS.DESCRIPTION
                            }
                            onChange={(event, editor) =>
                              onDescriptionChange(editor.getData())
                            }
                          />
                        </div>

                        {inEditField !== OVERVIEW_FORM_FIELDS.DESCRIPTION && (
                          <div
                            className="col-1 text-center"
                            onClick={() =>
                              setInEditField(OVERVIEW_FORM_FIELDS.DESCRIPTION)
                            }
                          >
                            <i className="pe-2">
                              <img src={editicon} alt="" />
                            </i>
                          </div>
                        )}
                      </div>
                    </div>
                  </OverviewCollapseItem>

                  <OverviewCollapseItem
                    toggleEditing={toggleEditing}
                    toggle={(item) => toggleOption(item)}
                    name={'When is the work to be done?'}
                    isActive={activeItem == 'When is the work to be done?'}
                  >
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Start Date</label>
                        </div>

                        <div className="col-11 text-center">
                          <DatePicker
                            disabled={
                              inEditField !== OVERVIEW_FORM_FIELDS.START_DATE
                            }
                            selected={startDate}
                            onChange={(date) => ondateChange(date, 'startDate')}
                            placeholderText="StartDate"
                            minDate={new Date()}
                            dateFormat="MM-dd-yyyy"
                            className=""
                          />
                        </div>

                        {inEditField !== OVERVIEW_FORM_FIELDS.START_DATE && (
                          <div
                            className="col-1 text-center"
                            onClick={() =>
                              setInEditField(OVERVIEW_FORM_FIELDS.START_DATE)
                            }
                          >
                            <i className="pe-2">
                              <img src={editicon} alt="" />
                            </i>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Estimated End Date</label>
                        </div>

                        <div className="col-11 text-center">
                          <DatePicker
                            disabled={
                              inEditField !== OVERVIEW_FORM_FIELDS.END_DATE
                            }
                            selected={endDate}
                            onChange={(date) => ondateChange(date, 'endDate')}
                            placeholderText="EndDate"
                            minDate={new Date()}
                            dateFormat="MM-dd-yyyy"
                            className=""
                          />
                        </div>

                        {inEditField !== OVERVIEW_FORM_FIELDS.END_DATE && (
                          <div
                            className="col-1 text-center"
                            onClick={() =>
                              setInEditField(OVERVIEW_FORM_FIELDS.END_DATE)
                            }
                          >
                            <i className="pe-2">
                              <img src={editicon} alt="" />
                            </i>
                          </div>
                        )}
                      </div>
                    </div>
                  </OverviewCollapseItem>

                  <OverviewCollapseItem
                    toggleEditing={toggleEditing}
                    toggle={(item) => toggleOption(item)}
                    name={
                      'How many people are needed and how much will they work?'
                    }
                    isActive={
                      activeItem ==
                      'How many people are needed and how much will they work?'
                    }
                  >
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label># Of People Needed</label>
                        </div>

                        <div className="col-11 text-center">
                          <input
                            type="text"
                            placeholder="Enter the number"
                            name="noOfPeople"
                            value={keyInformation.noOfPeople}
                            disabled={
                              inEditField !== OVERVIEW_FORM_FIELDS.NO_OF_PEOPLE
                            }
                            onChange={(e) =>
                              onKeyInforChange(
                                Number(e.target.value),
                                'noOfPeople'
                              )
                            }
                          />
                        </div>

                        {inEditField !== OVERVIEW_FORM_FIELDS.NO_OF_PEOPLE && (
                          <div
                            className="col-1 text-center"
                            onClick={() =>
                              setInEditField(OVERVIEW_FORM_FIELDS.NO_OF_PEOPLE)
                            }
                          >
                            <i className="pe-2">
                              <img src={editicon} alt="" />
                            </i>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Shift</label>
                        </div>

                        <div className="col-11 text-center">
                          <select
                            name="shift"
                            value={keyInformation.shift}
                            disabled={
                              inEditField !== OVERVIEW_FORM_FIELDS.SHIFT
                            }
                            onChange={(e) =>
                              onKeyInforChange(e.target.value, 'shift')
                            }
                          >
                            <option value="">Select shift</option>
                            <option value="days">Days</option>
                            <option value="afternoons">Afternoons</option>
                            <option value="nightshift">Night shift</option>
                            <option value="variable">Variable</option>
                          </select>

                          <span>
                            <img src={down} alt="" />
                          </span>
                        </div>

                        {inEditField !== OVERVIEW_FORM_FIELDS.SHIFT && (
                          <div
                            className="col-1 text-center"
                            onClick={() =>
                              setInEditField(OVERVIEW_FORM_FIELDS.SHIFT)
                            }
                          >
                            <i className="pe-2">
                              <img src={editicon} alt="" />
                            </i>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Planned hours per Day</label>
                        </div>

                        <div className="col-11 text-center">
                          <select
                            name="plannedHoursPerDay"
                            value={keyInformation.plannedHoursPerDay}
                            disabled={
                              inEditField !==
                              OVERVIEW_FORM_FIELDS.PLANNED_HOURS_PER_DAY
                            }
                            onChange={(e) =>
                              onKeyInforChange(
                                Number(e.target.value),
                                'plannedHoursPerDay'
                              )
                            }
                          >
                            <option value="">
                              Select planned Hours per day
                            </option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                          </select>

                          <span>
                            <img src={down} alt="" />
                          </span>
                        </div>

                        {inEditField !==
                          OVERVIEW_FORM_FIELDS.PLANNED_HOURS_PER_DAY && (
                            <div
                              className="col-1 text-center"
                              onClick={() =>
                                setInEditField(
                                  OVERVIEW_FORM_FIELDS.PLANNED_HOURS_PER_DAY
                                )
                              }
                            >
                              <i className="pe-2">
                                <img src={editicon} alt="" />
                              </i>
                            </div>
                          )}
                      </div>
                    </div>

                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Planned Days per Week</label>
                        </div>

                        <div className="col-11 text-center">
                          <select
                            name="plannedDaysPerWeek"
                            disabled={
                              inEditField !==
                              OVERVIEW_FORM_FIELDS.PLANNED_HOURS_PER_WEEK
                            }
                            value={keyInformation.plannedDaysPerWeek}
                            onChange={(e) =>
                              onKeyInforChange(
                                Number(e.target.value),
                                'plannedDaysPerWeek'
                              )
                            }
                          >
                            <option value="">
                              Select planned days per week
                            </option>
                            <option value="6">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                          </select>

                          <span>
                            <img src={down} alt="" />
                          </span>
                        </div>

                        {inEditField !==
                          OVERVIEW_FORM_FIELDS.PLANNED_HOURS_PER_WEEK && (
                            <div
                              className="col-1 text-center"
                              onClick={() =>
                                setInEditField(
                                  OVERVIEW_FORM_FIELDS.PLANNED_HOURS_PER_WEEK
                                )
                              }
                            >
                              <i className="pe-2">
                                <img src={editicon} alt="" />
                              </i>
                            </div>
                          )}
                      </div>
                    </div>
                  </OverviewCollapseItem>

                  <OverviewCollapseItem
                    toggleEditing={toggleEditing}
                    toggle={(item) => toggleOption(item)}
                    name={'Where will the work take place?'}
                    isActive={activeItem == 'Where will the work take place?'}
                  >
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Country</label>
                        </div>

                        <div className="col-11 text-center">
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="primary"
                              id="dropdown-basic"
                              className="ms-0"
                            >
                              {selectedCountry
                                ? selectedCountry.label
                                : 'Please select an option'}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <FormControl
                                autoFocus
                                className="mx-3 my-2 w-auto"
                                placeholder="Search..."
                                disabled={
                                  inEditField !== OVERVIEW_FORM_FIELDS.COUNTRY
                                }
                                onChange={handleSearch}
                                value={searchTerm}
                              />

                              {filteredOptions.length === 0 && (
                                <Dropdown.Item disabled>
                                  No options found
                                </Dropdown.Item>
                              )}

                              {filteredOptions?.map((option, index: number) => (
                                <Dropdown.Item
                                  key={index}
                                  onClick={() =>
                                    onInputChange(option, 'selectedCountry')
                                  }
                                >
                                  {option.label}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>

                        {inEditField !== OVERVIEW_FORM_FIELDS.COUNTRY && (
                          <div
                            className="col-1 text-center"
                            onClick={() =>
                              setInEditField(OVERVIEW_FORM_FIELDS.COUNTRY)
                            }
                          >
                            <i className="pe-2">
                              <img src={editicon} alt="" />
                            </i>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>State</label>
                        </div>

                        <div className="col-11 text-center">
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="primary"
                              id="dropdown-basic"
                              className="ms-0"
                            >
                              {selectedState
                                ? selectedState.label
                                : 'Please select an option'}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {location.states?.map((option, index: number) => (
                                <Dropdown.Item
                                  key={index}
                                  disabled={
                                    inEditField !== OVERVIEW_FORM_FIELDS.STATE
                                  }
                                  onClick={() =>
                                    onInputChange(option, 'selectedState')
                                  }
                                >
                                  {option.label}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>

                        {inEditField !== OVERVIEW_FORM_FIELDS.STATE && (
                          <div
                            className="col-1 text-center"
                            onClick={() =>
                              setInEditField(OVERVIEW_FORM_FIELDS.STATE)
                            }
                          >
                            <i className="pe-2">
                              <img src={editicon} alt="" />
                            </i>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>City</label>
                        </div>

                        <div className="col-11 text-center">
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="primary"
                              id="dropdown-basic"
                              className="ms-0"
                            >
                              {selectedCity
                                ? selectedCity.label
                                : 'Please select an option'}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {location.cities?.map((option, index: number) => (
                                <Dropdown.Item
                                  key={index}
                                  disabled={
                                    inEditField !== OVERVIEW_FORM_FIELDS.CITY
                                  }
                                  onClick={() =>
                                    onInputChange(option, 'selectedCity')
                                  }
                                >
                                  {option.label}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>

                        {inEditField !== OVERVIEW_FORM_FIELDS.CITY && (
                          <div
                            className="col-1 text-center"
                            onClick={() =>
                              setInEditField(OVERVIEW_FORM_FIELDS.CITY)
                            }
                          >
                            <i className="pe-2">
                              <img src={editicon} alt="" />
                            </i>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Street</label>
                        </div>

                        <div className="col-11 text-center">
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="primary"
                              id="dropdown-basic"
                              className="ms-0"
                            >
                              {selectedStreet
                                ? selectedStreet.label
                                : 'Please select an option'}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {location.streets.map((option, index: number) => (
                                <Dropdown.Item
                                  key={index}
                                  disabled={
                                    inEditField !== OVERVIEW_FORM_FIELDS.STREET
                                  }
                                  onClick={() =>
                                    onInputChange(option, 'selectedStreet')
                                  }
                                >
                                  {option.label}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>

                        {inEditField !== OVERVIEW_FORM_FIELDS.STREET && (
                          <div
                            className="col-1 text-center"
                            onClick={() =>
                              setInEditField(OVERVIEW_FORM_FIELDS.STREET)
                            }
                          >
                            <i className="pe-2">
                              <img src={editicon} alt="" />
                            </i>
                          </div>
                        )}
                      </div>
                    </div>
                  </OverviewCollapseItem>

                  <OverviewCollapseItem
                    toggleEditing={toggleEditing}
                    toggle={(item) => toggleOption(item)}
                    name={'What will you pay for Hours Worked?'}
                    isActive={
                      activeItem == 'What will you pay for Hours Worked?'
                    }
                  >
                    <div className={styles.ratedRadio}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>
                            {' '}
                            Select the net payment terms that in which you will
                            pay for this contract
                          </label>
                        </div>

                        <div className="col-11 text-center mb-3">
                          {/* <h6 className="text-white">
                        
                        </h6> */}
                          {/* <div className={styles.rateradio}> */}
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="primary"
                              id="dropdown-basic"
                              className="ms-0 ps-0"
                              disabled={
                                inEditField !==
                                OVERVIEW_FORM_FIELDS.PAYMENT_TERMS
                              }
                            >
                              {rates.paymentTerms
                                ? rates.paymentTerms.label
                                : 'Please select an option'}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {rates?.paymentTermsList?.map(
                                (option, index: number) => (
                                  <Dropdown.Item
                                    key={index}
                                    onClick={() =>
                                      onRateChange(option, 'paymentTerms')
                                    }
                                  >
                                    {option.label}
                                  </Dropdown.Item>
                                )
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>

                        {inEditField !== OVERVIEW_FORM_FIELDS.PAYMENT_TERMS && (
                          <div
                            className="col-1 text-center"
                            onClick={() =>
                              setInEditField(OVERVIEW_FORM_FIELDS.PAYMENT_TERMS)
                            }
                          >
                            <i className="pe-2">
                              <img src={editicon} alt="" />
                            </i>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={styles.fromGroup}>
                      <div className="row align-items-start justify-content-between">
                        <div className="col-12">
                          <label>Base Rate($ per hour)</label>
                        </div>

                        <div className="col-11 text-center">
                          <input
                            disabled={
                              inEditField !== OVERVIEW_FORM_FIELDS.BASE_RATE
                            }
                            type="number"
                            name="baseRate"
                            placeholder="$/hour"
                            value={rates.baseRate}
                            onChange={({ target }) =>
                              onRateChange(Number(target.value), 'baseRate')
                            }
                          />

                          <p>0-40 Hours Monday-Saturday</p>
                        </div>

                        {inEditField !== OVERVIEW_FORM_FIELDS.BASE_RATE && (
                          <div
                            className="col-1 text-center"
                            onClick={() =>
                              setInEditField(OVERVIEW_FORM_FIELDS.BASE_RATE)
                            }
                          >
                            <i className="pe-2">
                              <img src={editicon} alt="" />
                            </i>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={styles.fromGroup}>
                      <div className="row align-items-start justify-content-between">
                        <div className="col-12">
                          <label>OT Rate($ per hour)</label>
                        </div>

                        <div className="col-11 text-center">
                          <input
                            disabled={
                              inEditField !== OVERVIEW_FORM_FIELDS.OT_RATE
                            }
                            type="text"
                            name="OTrate"
                            placeholder="$/hour"
                            value={rates.otRate}
                            onChange={({ target }) =>
                              onRateChange(Number(target.value), 'otRate')
                            }
                          />

                          <p>41+ Hours Monday-Saturday</p>
                        </div>

                        {inEditField !== OVERVIEW_FORM_FIELDS.OT_RATE && (
                          <div
                            className="col-1 text-center"
                            onClick={() =>
                              setInEditField(OVERVIEW_FORM_FIELDS.OT_RATE)
                            }
                          >
                            <i className="pe-2">
                              <img src={editicon} alt="" />
                            </i>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={styles.fromGroup}>
                      <div className="row align-items-start justify-content-between">
                        <div className="col-12">
                          <label>DT Rate($ per hour)</label>
                        </div>

                        <div className="col-11 text-center">
                          <input
                            disabled={
                              inEditField !== OVERVIEW_FORM_FIELDS.DT_RATE
                            }
                            type="text"
                            name="DTrate"
                            placeholder="$/hour"
                            value={rates.dtRate}
                            onChange={({ target }) =>
                              onRateChange(Number(target.value), 'dtRate')
                            }
                          />

                          <p>Sunday and US Holidays</p>
                        </div>

                        {inEditField !== OVERVIEW_FORM_FIELDS.DT_RATE && (
                          <div
                            className="col-1 text-center"
                            onClick={() =>
                              setInEditField(OVERVIEW_FORM_FIELDS.DT_RATE)
                            }
                          >
                            <i className="pe-2">
                              <img src={editicon} alt="" />
                            </i>
                          </div>
                        )}
                      </div>
                    </div>
                  </OverviewCollapseItem>

                  <OverviewCollapseItem
                    toggleEditing={toggleEditing}
                    toggle={(item) => toggleOption(item)}
                    name={'Travel Time'}
                    isActive={activeItem == 'Travel Time'}
                  >
                    <div className={styles.ratedRadio}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <h6>Will there be travel for this job?</h6>
                        </div>

                        <div className="col-11 text-start">
                          <div className={styles.rateradio}>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault1"
                                id="flexRadioDefault1"
                                checked={travelData.isTravelRequired}
                                disabled={
                                  inEditField !==
                                  OVERVIEW_FORM_FIELDS.TRAVEL_ALLOWED
                                }
                                onChange={() =>
                                  onTravelDataChange(true, 'isTravelRequired')
                                }
                              />
                              <label className="form-check-label">Yes</label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault2"
                                id="flexRadioDefault2"
                                checked={!travelData.isTravelRequired}
                                disabled={
                                  inEditField !==
                                  OVERVIEW_FORM_FIELDS.TRAVEL_ALLOWED
                                }
                                onChange={() =>
                                  onTravelDataChange(false, 'isTravelRequired')
                                }
                              />
                              <label className="form-check-label">No</label>
                            </div>
                          </div>
                        </div>

                        {inEditField !==
                          OVERVIEW_FORM_FIELDS.TRAVEL_ALLOWED && (
                            <div
                              className="col-1 text-center"
                              onClick={() =>
                                setInEditField(
                                  OVERVIEW_FORM_FIELDS.TRAVEL_ALLOWED
                                )
                              }
                            >
                              <i className="pe-2">
                                <img src={editicon} alt="" />
                              </i>
                            </div>
                          )}
                      </div>
                    </div>
                    {travelData.isTravelRequired === true && (
                      <>
                        <div className={styles.fromGroup}>
                          <div className="row align-items-center justify-content-between">
                            <div className="col-12">
                              <label>Travel Time Rate</label>
                            </div>

                            <div className="col-11 text-center">
                              <input
                                type="number"
                                name="travelRate"
                                placeholder="$Enter Amount"
                                value={travelData.travelTimeRate}
                                disabled={
                                  inEditField !==
                                  OVERVIEW_FORM_FIELDS.TRAVEL_TIME_RATE
                                }
                                onChange={(e) =>
                                  onTravelDataChange(
                                    Number(e.target.value),
                                    'travelTimeRate'
                                  )
                                }
                              />
                            </div>

                            {inEditField !==
                              OVERVIEW_FORM_FIELDS.TRAVEL_TIME_RATE && (
                                <div
                                  className="col-1 text-center"
                                  onClick={() =>
                                    setInEditField(
                                      OVERVIEW_FORM_FIELDS.TRAVEL_TIME_RATE
                                    )
                                  }
                                >
                                  <i className="pe-2">
                                    <img src={editicon} alt="" />
                                  </i>
                                </div>
                              )}
                          </div>
                        </div>
                        <div className={styles.fromGroup}>
                          <div className="row align-items-center justify-content-between">
                            <div className="col-12">
                              <label>Travel Tip Allowed(per month)?</label>
                            </div>

                            <div className="col-11 text-center">
                              <input
                                type="number"
                                value={travelData.travelTipAllowedPerMonth}
                                name="travelTip"
                                placeholder="$Enter Amount"
                                disabled={
                                  inEditField !==
                                  OVERVIEW_FORM_FIELDS.TRAVEL_TIP_ALLOWED
                                }
                                onChange={(e) =>
                                  onTravelDataChange(
                                    Number(e.target.value),
                                    'travelTipAllowedPerMonth'
                                  )
                                }
                              />
                            </div>

                            {inEditField !==
                              OVERVIEW_FORM_FIELDS.TRAVEL_TIP_ALLOWED && (
                                <div
                                  className="col-1 text-center"
                                  onClick={() =>
                                    setInEditField(
                                      OVERVIEW_FORM_FIELDS.TRAVEL_TIP_ALLOWED
                                    )
                                  }
                                >
                                  <i className="pe-2">
                                    <img src={editicon} alt="" />
                                  </i>
                                </div>
                              )}
                          </div>
                        </div>

                        <div className={styles.fromGroup}>
                          <div className="row align-items-center justify-content-between">
                            <div className="col-12">
                              <label>Travel hours per round trip?</label>
                            </div>

                            <div className="col-11 text-center">
                              <input
                                type="number"
                                name="travelHours"
                                placeholder="$Enter Amount"
                                value={travelData.travelHoursPerRoundTrip}
                                disabled={
                                  inEditField !==
                                  OVERVIEW_FORM_FIELDS.TRAVEL_HOURS
                                }
                                onChange={(e) =>
                                  onTravelDataChange(
                                    Number(e.target.value),
                                    'travelHoursPerRoundTrip'
                                  )
                                }
                              />
                            </div>

                            {inEditField !==
                              OVERVIEW_FORM_FIELDS.TRAVEL_HOURS && (
                                <div
                                  className="col-1 text-center"
                                  onClick={() =>
                                    setInEditField(
                                      OVERVIEW_FORM_FIELDS.TRAVEL_HOURS
                                    )
                                  }
                                >
                                  <i className="pe-2">
                                    <img src={editicon} alt="" />
                                  </i>
                                </div>
                              )}
                          </div>
                        </div>
                      </>
                    )}
                  </OverviewCollapseItem>

                  <OverviewCollapseItem
                    toggleEditing={toggleEditing}
                    toggle={(item) => toggleOption(item)}
                    name={'Expenses'}
                    isActive={activeItem == 'Expenses'}
                  >
                    <div className={styles.ratedRadio}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Are travel expense reimbursed?</label>
                        </div>

                        <div className="col-11 text-start">
                          <div className={styles.rateradio}>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault"
                                id="flexRadioDefault1"
                                checked={isExpenseReimbursed}
                                disabled={
                                  inEditField !==
                                  OVERVIEW_FORM_FIELDS.IS_EXPENSE_REIMBURSED
                                }
                                onChange={() =>
                                  onExpanceChange(true, 'isExpenseReimbursed')
                                }
                              />
                              <label className="form-check-label">Yes</label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="flexRadioDefault2"
                                id="flexRadioDefault2"
                                checked={!isExpenseReimbursed}
                                disabled={
                                  inEditField !==
                                  OVERVIEW_FORM_FIELDS.IS_EXPENSE_REIMBURSED
                                }
                                onChange={() =>
                                  onExpanceChange(false, 'isExpenseReimbursed')
                                }
                              />
                              <label className="form-check-label">No</label>
                            </div>
                          </div>
                        </div>

                        {inEditField !==
                          OVERVIEW_FORM_FIELDS.IS_EXPENSE_REIMBURSED && (
                            <div
                              className="col-1 text-center"
                              onClick={() =>
                                setInEditField(
                                  OVERVIEW_FORM_FIELDS.IS_EXPENSE_REIMBURSED
                                )
                              }
                            >
                              <i className="pe-2">
                                <img src={editicon} alt="" />
                              </i>
                            </div>
                          )}
                      </div>
                    </div>
                    <>
                      {isExpenseReimbursed ? (
                        <div>
                          <div className={styles.fromGroup}>
                            <div className="row align-items-center justify-content-between">
                              <div className="col-12">
                                <label>Daily per Diem?</label>
                              </div>

                              <div className="col-11 text-center">
                                <input
                                  type="number"
                                  name="dailyPerDiem"
                                  placeholder="$/hour"
                                  value={dailyPerDiem}
                                  disabled={
                                    inEditField !==
                                    OVERVIEW_FORM_FIELDS.DAILY_PER_DIEM
                                  }
                                  onChange={(e) =>
                                    onExpanceChange(
                                      Number(e.target.value),
                                      'dailyPerDiem'
                                    )
                                  }
                                />
                              </div>

                              {inEditField !==
                                OVERVIEW_FORM_FIELDS.DAILY_PER_DIEM && (
                                  <div
                                    className="col-1 text-center"
                                    onClick={() =>
                                      setInEditField(
                                        OVERVIEW_FORM_FIELDS.DAILY_PER_DIEM
                                      )
                                    }
                                  >
                                    <i className="pe-2">
                                      <img src={editicon} alt="" />
                                    </i>
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className={styles.fromGroup}>
                            <div className="row align-items-center justify-content-between">
                              <div className="col-12">
                                <label>Travel day per Diem?</label>
                              </div>

                              <div className="col-11 text-center">
                                <input
                                  type="number"
                                  name="travelDayPerDiem"
                                  placeholder="$/hour"
                                  value={travelDayPerDiem}
                                  disabled={
                                    inEditField !==
                                    OVERVIEW_FORM_FIELDS.TRAVEL_PER_DIEM
                                  }
                                  onChange={(e) =>
                                    onExpanceChange(
                                      Number(e.target.value),
                                      'travelDayPerDiem'
                                    )
                                  }
                                />
                              </div>

                              {inEditField !==
                                OVERVIEW_FORM_FIELDS.TRAVEL_PER_DIEM && (
                                  <div
                                    className="col-1 text-center"
                                    onClick={() =>
                                      setInEditField(
                                        OVERVIEW_FORM_FIELDS.TRAVEL_PER_DIEM
                                      )
                                    }
                                  >
                                    <i className="pe-2">
                                      <img src={editicon} alt="" />
                                    </i>
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className={styles.fromGroup}>
                            <div className="row align-items-center justify-content-between">
                              <div className="col-12">
                                <label>Hotel allowance per Day?</label>
                              </div>

                              <div className="col-11 text-center">
                                <input
                                  type="number"
                                  name="hotelAllowance"
                                  placeholder="$/hour"
                                  value={hotelAllowance}
                                  disabled={
                                    inEditField !==
                                    OVERVIEW_FORM_FIELDS.HOTEL_ALLOWANCE
                                  }
                                  onChange={(e) =>
                                    onExpanceChange(
                                      Number(e.target.value),
                                      'hotelAllowance'
                                    )
                                  }
                                />
                              </div>

                              {inEditField !==
                                OVERVIEW_FORM_FIELDS.HOTEL_ALLOWANCE && (
                                  <div
                                    className="col-1 text-center"
                                    onClick={() =>
                                      setInEditField(
                                        OVERVIEW_FORM_FIELDS.HOTEL_ALLOWANCE
                                      )
                                    }
                                  >
                                    <i className="pe-2">
                                      <img src={editicon} alt="" />
                                    </i>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </>
                  </OverviewCollapseItem>

                  <OverviewCollapseItem
                    toggleEditing={toggleEditing}
                    toggle={(item) => toggleOption(item)}
                    name={'More Expenses'}
                    isActive={activeItem == 'More Expenses'}
                  >
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Rental car Allowance?</label>
                        </div>

                        <div className="col-11 text-center">
                          <input
                            type="number"
                            name="rentalCarAllowance"
                            placeholder="$/hour"
                            value={rentalCarAllowance}
                            disabled={
                              inEditField !==
                              OVERVIEW_FORM_FIELDS.RENTAL_CAR_ALLOWANCE
                            }
                            onChange={(e) =>
                              onExpenseDataChange(
                                Number(e.target.value),
                                'rentalCarAllowance'
                              )
                            }
                          />
                        </div>
                        {inEditField !==
                          OVERVIEW_FORM_FIELDS.RENTAL_CAR_ALLOWANCE && (
                            <div
                              className="col-1 text-center"
                              onClick={() =>
                                setInEditField(
                                  OVERVIEW_FORM_FIELDS.RENTAL_CAR_ALLOWANCE
                                )
                              }
                            >
                              <i className="pe-2">
                                <img src={editicon} alt="" />
                              </i>
                            </div>
                          )}
                      </div>
                    </div>
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Fuel?</label>
                        </div>

                        <div className="col-11 text-center">
                          <input
                            type="number"
                            name="fuel"
                            placeholder="$/hour"
                            value={fuel}
                            disabled={inEditField !== OVERVIEW_FORM_FIELDS.FUEL}
                            onChange={(e) =>
                              onExpenseDataChange(
                                Number(e.target.value),
                                'fuel'
                              )
                            }
                          />
                        </div>

                        {inEditField !== OVERVIEW_FORM_FIELDS.FUEL && (
                          <div
                            className="col-1 text-center"
                            onClick={() =>
                              setInEditField(OVERVIEW_FORM_FIELDS.FUEL)
                            }
                          >
                            <i className="pe-2">
                              <img src={editicon} alt="" />
                            </i>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Airfare Allowance?</label>
                        </div>

                        <div className="col-11 text-center">
                          <input
                            type="number"
                            name="airFareAllowance"
                            placeholder="$/hour"
                            value={airFareAllowance}
                            disabled={
                              inEditField !==
                              OVERVIEW_FORM_FIELDS.AIRFARE_ALLOWANCE
                            }
                            onChange={(e) =>
                              onExpenseDataChange(
                                Number(e.target.value),
                                'airFareAllowance'
                              )
                            }
                          />
                        </div>

                        {inEditField !==
                          OVERVIEW_FORM_FIELDS.AIRFARE_ALLOWANCE && (
                            <div
                              className="col-1 text-center"
                              onClick={() =>
                                setInEditField(
                                  OVERVIEW_FORM_FIELDS.AIRFARE_ALLOWANCE
                                )
                              }
                            >
                              <i className="pe-2">
                                <img src={editicon} alt="" />
                              </i>
                            </div>
                          )}
                      </div>
                    </div>
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Milleage?</label>
                        </div>

                        <div className="col-11 text-center">
                          <input
                            type="number"
                            name="mileage"
                            placeholder="$/hour"
                            value={mileage}
                            disabled={
                              inEditField !== OVERVIEW_FORM_FIELDS.MILLAGE
                            }
                            onChange={(e) =>
                              onExpenseDataChange(
                                Number(e.target.value),
                                'mileage'
                              )
                            }
                          />
                        </div>

                        {inEditField !== OVERVIEW_FORM_FIELDS.MILLAGE && (
                          <div
                            className="col-1 text-center"
                            onClick={() =>
                              setInEditField(OVERVIEW_FORM_FIELDS.MILLAGE)
                            }
                          >
                            <i className="pe-2">
                              <img src={editicon} alt="" />
                            </i>
                          </div>
                        )}
                      </div>
                    </div>
                  </OverviewCollapseItem>

                  <OverviewCollapseItem
                    toggleEditing={toggleEditing}
                    toggle={(item) => toggleOption(item)}
                    name={'ON-SITE Customer Contact'}
                    isActive={activeItem == 'ON-SITE Customer Contact'}
                  >
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Primary Name </label>
                        </div>

                        <div className="col-11 text-center">
                          <input
                            type="text"
                            name="primaryName "
                            placeholder="Primary Name "
                            value={primaryName}
                            disabled={
                              inEditField !== OVERVIEW_FORM_FIELDS.PRIMARY_NAME
                            }
                            onChange={(e) =>
                              onDetailChange(e.target.value, 'primaryName')
                            }
                          />
                        </div>

                        {inEditField !== OVERVIEW_FORM_FIELDS.PRIMARY_NAME && (
                          <div
                            className="col-1 text-center"
                            onClick={() =>
                              setInEditField(OVERVIEW_FORM_FIELDS.PRIMARY_NAME)
                            }
                          >
                            <i className="pe-2">
                              <img src={editicon} alt="" />
                            </i>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Primary Phone</label>
                        </div>

                        <div className="col-11 text-center">
                          <input
                            type="number"
                            name="primaryPhoneNumber"
                            placeholder="Primary Phone"
                            value={primaryPhoneNumber}
                            disabled={
                              inEditField !== OVERVIEW_FORM_FIELDS.PRIMARY_PHONE
                            }
                            onChange={(e) =>
                              onDetailChange(
                                Number(e.target.value),
                                'primaryPhoneNumber'
                              )
                            }
                          />
                        </div>

                        {inEditField !== OVERVIEW_FORM_FIELDS.PRIMARY_PHONE && (
                          <div
                            className="col-1 text-center"
                            onClick={() =>
                              setInEditField(OVERVIEW_FORM_FIELDS.PRIMARY_PHONE)
                            }
                          >
                            <i className="pe-2">
                              <img src={editicon} alt="" />
                            </i>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Primary Email</label>
                        </div>

                        <div className="col-11 text-center">
                          <input
                            type="email"
                            name="primaryEmail"
                            placeholder="Primary Email"
                            value={primaryEmail}
                            disabled={
                              inEditField !== OVERVIEW_FORM_FIELDS.PRIMARY_EMAIL
                            }
                            onChange={(e) =>
                              onDetailChange(e.target.value, 'primaryEmail')
                            }
                          />
                        </div>
                        {inEditField !== OVERVIEW_FORM_FIELDS.PRIMARY_EMAIL && (
                          <div
                            className="col-1 text-center"
                            onClick={() =>
                              setInEditField(OVERVIEW_FORM_FIELDS.PRIMARY_EMAIL)
                            }
                          >
                            <i className="pe-2">
                              <img src={editicon} alt="" />
                            </i>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Secondary Name</label>
                        </div>

                        <div className="col-11 text-center">
                          <input
                            type="text"
                            name="secondaryName"
                            placeholder="Secondary Name"
                            value={secondaryName}
                            disabled={
                              inEditField !==
                              OVERVIEW_FORM_FIELDS.SECONDARY_NAME
                            }
                            onChange={(e) =>
                              onDetailChange(e.target.value, 'secondaryName')
                            }
                          />
                        </div>

                        {inEditField !==
                          OVERVIEW_FORM_FIELDS.SECONDARY_NAME && (
                            <div
                              className="col-1 text-center"
                              onClick={() =>
                                setInEditField(
                                  OVERVIEW_FORM_FIELDS.SECONDARY_NAME
                                )
                              }
                            >
                              <i className="pe-2">
                                <img src={editicon} alt="" />
                              </i>
                            </div>
                          )}
                      </div>
                    </div>
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Secondary Phone Number </label>
                        </div>

                        <div className="col-11 text-center">
                          <input
                            type="number"
                            name="secondaryPhoneNumber"
                            placeholder="Secondary Phone Number "
                            value={secondaryPhoneNumber}
                            disabled={
                              inEditField !==
                              OVERVIEW_FORM_FIELDS.SECONDARY_PHONE
                            }
                            onChange={(e) =>
                              onDetailChange(
                                Number(e.target.value),
                                'secondaryPhoneNumber'
                              )
                            }
                          />
                        </div>

                        {inEditField !==
                          OVERVIEW_FORM_FIELDS.SECONDARY_PHONE && (
                            <div
                              className="col-1 text-center"
                              onClick={() =>
                                setInEditField(
                                  OVERVIEW_FORM_FIELDS.SECONDARY_PHONE
                                )
                              }
                            >
                              <i className="pe-2">
                                <img src={editicon} alt="" />
                              </i>
                            </div>
                          )}
                      </div>
                    </div>
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Secondary Email</label>
                        </div>

                        <div className="col-11 text-center">
                          <input
                            type="email"
                            name="secondaryEmail"
                            placeholder="Secondary Email"
                            value={secondaryEmail}
                            disabled={
                              inEditField !==
                              OVERVIEW_FORM_FIELDS.SECONDARY_EMAIL
                            }
                            onChange={(e) =>
                              onDetailChange(e.target.value, 'secondaryEmail')
                            }
                          />
                        </div>

                        {inEditField !==
                          OVERVIEW_FORM_FIELDS.SECONDARY_EMAIL && (
                            <div
                              className="col-1 text-center"
                              onClick={() =>
                                setInEditField(
                                  OVERVIEW_FORM_FIELDS.SECONDARY_EMAIL
                                )
                              }
                            >
                              <i className="pe-2">
                                <img src={editicon} alt="" />
                              </i>
                            </div>
                          )}
                      </div>
                    </div>
                  </OverviewCollapseItem>

                  <OverviewCollapseItem
                    toggleEditing={toggleEditing}
                    toggle={(item) => toggleOption(item)}
                    name={'Non Mandatory Hourly Contract Fields'}
                    isActive={
                      activeItem == 'Non Mandatory Hourly Contract Fields'
                    }
                  >
                  
                    <div className={styles.fromGroup}>
                      <div className={styles.ratedRadio}>
                        <div className="row align-items-center justify-content-between">
                          <div className="col-12">
                            <label>US Legal Residents Required?</label>
                          </div>

                          <div className="col-12 text-start">
                            <div className={styles.rateradio}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="isLegalResidence"
                                  checked={isLegalResidence}
                                  disabled={
                                    inEditField !==
                                    OVERVIEW_FORM_FIELDS.IS_LEGAL_RESIDENCE
                                  }
                                  onChange={(e) =>
                                    onLegalInputdata(true, 'isLegalResidence')
                                  }
                                />
                                <label className="form-check-label">Yes</label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="isLegalResidence"
                                  checked={!isLegalResidence}
                                  disabled={
                                    inEditField !==
                                    OVERVIEW_FORM_FIELDS.IS_LEGAL_RESIDENCE
                                  }
                                  onChange={(e) =>
                                    onLegalInputdata(false, 'isLegalResidence')
                                  }
                                />
                                <label className="form-check-label">No</label>
                              </div>
                            </div>
                          </div>
                          {inEditField !==
                            OVERVIEW_FORM_FIELDS.IS_LEGAL_RESIDENCE && (
                              <div
                                className="col-1 text-center"
                                onClick={() =>
                                  setInEditField(
                                    OVERVIEW_FORM_FIELDS.IS_LEGAL_RESIDENCE
                                  )
                                }
                              >
                                <i className="pe-2">
                                  <img src={editicon} alt="" />
                                </i>
                              </div>
                            )}
                        </div>
                      </div>
                      {!isLegalResidence && (
                        <div className={styles.ratedRadio}>
                          <div className="row align-items-center justify-content-between">
                            <div className="col-12">
                              <label>
                                {' '}
                                Visa that allows to work legally in the Us
                                Required?
                              </label>
                            </div>

                            <div className="col-12 text-start">
                              <div className={styles.rateradio}>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="isVisa"
                                    id="isVisa"
                                    checked={isVisa}
                                    disabled={
                                      inEditField !==
                                      OVERVIEW_FORM_FIELDS.IS_VISA
                                    }
                                    onChange={(e) =>
                                      onLegalInputdata(true, 'isVisa')
                                    }
                                  />
                                  <label className="form-check-label">
                                    Yes
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="isVisa1"
                                    id="isVisa1"
                                    checked={!isVisa}
                                    disabled={
                                      inEditField !==
                                      OVERVIEW_FORM_FIELDS.IS_VISA
                                    }
                                    onChange={(e) =>
                                      onLegalInputdata(false, 'isVisa')
                                    }
                                  />
                                  <label className="form-check-label">No</label>
                                </div>
                              </div>
                            </div>

                            {inEditField !==
                              OVERVIEW_FORM_FIELDS.IS_VISA && (
                                <div
                                  className="col-1 text-center"
                                  onClick={() =>
                                    setInEditField(
                                      OVERVIEW_FORM_FIELDS.IS_VISA
                                    )
                                  }
                                >
                                  <i className="pe-2">
                                    <img src={editicon} alt="" />
                                  </i>
                                </div>
                              )}
                          </div>
                        </div>
                      )}
                    </div>
                  </OverviewCollapseItem>

                  <OverviewCollapseItem
                    toggleEditing={toggleEditing}
                    toggle={(item) => toggleOption(item)}
                    name={'Eligibility Questions'}
                    isActive={activeItem == 'Eligibility Questions'}
                  >
                    <div className="row align-items-center justify-content-between">
                      <div className="col-12">
                        <label>Create Eligibility Questions</label>
                      </div>
                    </div>

                    {eligibilityQuestions?.map((x, i: number) => (
                      <>
                        <div
                          className={styles.fromGroup}
                          style={{ maxWidth: '100%' }}
                        >
                          <div className={styles.AddBtns}>
                            <label>Question{i + 1}:</label>
                            <div
                              className={styles.AddBtn}
                              style={{
                                display: 'd-flex',
                                alignItems: 'center',
                                justifyContent: 'between',
                              }}
                            >
                              <div className="d-flex align-items-center justify-content-between w-100">
                                <div className="col-10">
                                  <textarea
                                    // type="text"
                                    placeholder="Write your question here"
                                    value={x}
                                    className="form-control w-100"
                                    style={{
                                      minHeight: '150px',
                                      width: '100%',
                                    }}
                                    onChange={(e) =>
                                      onEligibilityQusChange(e.target.value, i)
                                    }
                                    disabled={
                                      inEditField !==
                                      OVERVIEW_FORM_FIELDS.ELIGIBILITY_QUESTION
                                    }
                                  />
                                </div>
                                <div className="col-2 ps-2">
                                  {i === eligibilityQuestions.length - 1 && (
                                    <button
                                      type="button"
                                      onClick={addNewEligibilityQuestion}
                                      style={{
                                        background: '#0053CD',
                                        padding: '10px',
                                        width: '40px',
                                        height: '40px',
                                        color: '#fff',
                                        borderRadius: '50%',
                                        fontSize: '20px',
                                        lineHeight: '0',
                                        // marginLeft: "30px",
                                      }}
                                    >
                                      +
                                    </button>
                                  )}
                                  {eligibilityQuestions.length > 1 && (
                                    <button
                                      type="button"
                                      style={{
                                        background: '#0053CD',
                                        padding: '10px',
                                        width: '40px',
                                        height: '40px',
                                        color: '#fff',
                                        borderRadius: '50%',
                                        fontSize: '20px',
                                        lineHeight: '0',
                                        // marginLeft: "30px",
                                      }}
                                      onClick={() =>
                                        deleteEligibilityQuestion(i)
                                      }
                                    >
                                      -
                                    </button>
                                  )}
                                  {inEditField !==
                                    OVERVIEW_FORM_FIELDS.ELIGIBILITY_QUESTION && (
                                      <div
                                        className="col-1 text-center"
                                        onClick={() =>
                                          setInEditField(
                                            OVERVIEW_FORM_FIELDS.ELIGIBILITY_QUESTION
                                          )
                                        }
                                      >
                                        <i className="pe-2">
                                          <img src={editicon} alt="" />
                                        </i>
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </OverviewCollapseItem>

                  <OverviewCollapseItem
                    toggleEditing={toggleEditing}
                    toggle={(item) => toggleOption(item)}
                    name={'Interview Questions'}
                    isActive={activeItem == 'Interview Questions'}
                  >
                    <div className="row align-items-center justify-content-between">
                      <div className="col-12">
                        <label>Create Interview Questions</label>
                      </div>
                    </div>
                    {interviewQuestions?.map((x, i) => (
                      <>
                        <div
                          className={styles.fromGroup}
                          style={{ maxWidth: '100%' }}
                        >
                          <div className={styles.AddBtns}>
                            <div className="row align-items-center justify-content-between">
                              <div className="col-12">
                                <label>Question{i + 1}:</label>
                              </div>

                              <div className="col-12 text-start">
                                <div
                                  className={styles.AddBtn}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'between',
                                  }}
                                >
                                  <div className="d-flex align-items-center justify-content-between w-100">
                                    <div className="col-10">
                                      <textarea
                                        // type="text"
                                        placeholder="Write your question here"
                                        value={x}
                                        className="form-control w-100"
                                        style={{ minHeight: '150px' }}
                                        onChange={(e) =>
                                          onInyterviewQuestionChange(
                                            e.target.value,
                                            i
                                          )
                                        }
                                        disabled={
                                          inEditField !==
                                          OVERVIEW_FORM_FIELDS.INTERVIEW_QUESTION
                                        }
                                      />
                                    </div>
                                    <div className="col-2 ps-2">
                                      {i === interviewQuestions.length - 1 && (
                                        <button
                                          type="button"
                                          onClick={addNewQuestion}
                                          style={{
                                            background: '#0053CD',
                                            padding: '10px',
                                            width: '40px',
                                            height: '40px',
                                            color: '#fff',
                                            borderRadius: '50%',
                                            fontSize: '20px',
                                            lineHeight: '0',
                                            // marginLeft: "30px",
                                          }}
                                        >
                                          +
                                        </button>
                                      )}
                                      {interviewQuestions.length > 1 && (
                                        <button
                                          type="button"
                                          style={{
                                            background: '#0053CD',
                                            padding: '10px',
                                            width: '40px',
                                            height: '40px',
                                            color: '#fff',
                                            borderRadius: '50%',
                                            fontSize: '20px',
                                            lineHeight: '0',
                                            // marginLeft: "30px",
                                          }}
                                          onClick={() => deleteQuestion(i)}
                                        >
                                          -
                                        </button>
                                      )}
                                      {inEditField !==
                                        OVERVIEW_FORM_FIELDS.INTERVIEW_QUESTION && (
                                          <div
                                            className="col-1 text-center"
                                            onClick={() =>
                                              setInEditField(
                                                OVERVIEW_FORM_FIELDS.INTERVIEW_QUESTION
                                              )
                                            }
                                          >
                                            <i className="pe-2">
                                              <img src={editicon} alt="" />
                                            </i>
                                          </div>
                                        )}
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </OverviewCollapseItem>

                  <OverviewCollapseItem
                    toggleEditing={toggleEditing}
                    toggle={(item) => toggleOption(item)}
                    name={'Programming / Technical Skills'}
                    isActive={activeItem == 'Programming / Technical Skills'}
                  >
                    {technicalSkillsData?.skills?.map((x, i) => (
                      <>
                        <div className="text-end my-4">
                          {technicalSkillsData.skills.length > 1 && (
                            <button
                              className="p-1 btn btn-white"
                              type="button"
                              onClick={() => deleteSkill(i)}
                              disabled={
                                inEditField !==
                                OVERVIEW_FORM_FIELDS.PROGRAMMING_SKILLS
                              }
                            >
                              <i>
                                <img
                                  src={Delete}
                                  alt=""
                                  width={25}
                                  height={25}
                                />
                              </i>
                            </button>
                          )}
                        </div>
                        <div
                          className="rounded-3 p-3"
                          style={{ background: 'rgba(255, 255,255, 0.5)' }}
                        >
                          <div className={styles.fromGroup}>
                            <div className="row align-items-center justify-content-between">
                              <div className="col-12">
                                <label>Manufacturer</label>
                              </div>

                              <div className="col-11 text-start">
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="primary"
                                    id="dropdown-basic"
                                    className=" ms-0"
                                    disabled={
                                      inEditField !==
                                      OVERVIEW_FORM_FIELDS.PROGRAMMING_SKILLS
                                    }
                                  >
                                    {x.category.label ??
                                      'Please select an option'}
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <FormControl
                                      autoFocus
                                      className="mx-3 my-2 w-auto"
                                      placeholder="Search..."
                                      disabled={
                                        inEditField !==
                                        OVERVIEW_FORM_FIELDS.PROGRAMMING_SKILLS
                                      }
                                      onChange={handleSearch}
                                      value={searchTerm}
                                    />
                                    {filteredCategories.length === 0 ? (
                                      <Dropdown.Item disabled>
                                        No options found
                                      </Dropdown.Item>
                                    ) : (
                                      filteredCategories?.map(
                                        (option, index: number) => (
                                          <Dropdown.Item
                                            key={index}
                                            onClick={() =>
                                              handleChangeManufacturer(
                                                option,
                                                i,
                                                'category'
                                              )
                                            }
                                          >
                                            {option.label}
                                          </Dropdown.Item>
                                        )
                                      )
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                              {inEditField !==
                                OVERVIEW_FORM_FIELDS.PROGRAMMING_SKILLS && (
                                  <div
                                    className="col-1 text-center"
                                    onClick={() =>
                                      setInEditField(
                                        OVERVIEW_FORM_FIELDS.PROGRAMMING_SKILLS
                                      )
                                    }
                                  >
                                    <i className="pe-2">
                                      <img src={editicon} alt="" />
                                    </i>
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className={styles.fromGroup}>
                            <div className="row align-items-center justify-content-between">
                              <div className="col-12">
                                <label>Model / Controller</label>
                              </div>

                              <div className="col-10 text-start">
                                <Select
                                  isMulti
                                  name="colors"
                                  options={x.subCategoriesList}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={(val) =>
                                    onSkillsChange(val as any, 'subCategory', i)
                                  }
                                  value={x.subCategory}
                                  isDisabled={
                                    inEditField !==
                                    OVERVIEW_FORM_FIELDS.MODEL_CONTROLLER
                                  }
                                />
                              </div>
                              {inEditField !==
                                OVERVIEW_FORM_FIELDS.MODEL_CONTROLLER && (
                                  <div
                                    className="col-2 text-center"
                                    onClick={() =>
                                      setInEditField(
                                        OVERVIEW_FORM_FIELDS.MODEL_CONTROLLER
                                      )
                                    }
                                  >
                                    <i className="pe-2">
                                      <img src={editicon} alt="" />
                                    </i>
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className={styles.fromGroup}>
                            <div className="row align-items-center justify-content-between">
                              <div className="col-12">
                                <label>Skills</label>
                              </div>

                              <div className="col-11 text-start">
                                <div
                                  className={`${styles.radioBTn} ${styles.radStepOne}`}
                                >
                                  <div>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name={`flexRadioDefault${i}`}
                                        id={`flexRadioDefault1${i}`}
                                        checked={x.isRequired}
                                        onChange={(e) =>
                                          onSkillsChange(true, 'isRequired', i)
                                        }
                                        disabled={
                                          inEditField !==
                                          OVERVIEW_FORM_FIELDS.SKILLS_REQUIRED
                                        }
                                      />
                                      <label className="form-check-label">
                                        Mandatory
                                      </label>
                                    </div>
                                  </div>
                                  <div>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name={`flexRadioDefault2${i}`}
                                        id={`flexRadioDefault2${i}`}
                                        checked={!x.isRequired}
                                        onChange={(e) =>
                                          onSkillsChange(false, 'isRequired', i)
                                        }
                                        disabled={
                                          inEditField !==
                                          OVERVIEW_FORM_FIELDS.SKILLS_REQUIRED
                                        }
                                      />
                                      <label className="form-check-label">
                                        Good to have
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {inEditField !==
                                OVERVIEW_FORM_FIELDS.SKILLS_REQUIRED && (
                                  <div
                                    className="col-1 text-center"
                                    onClick={() =>
                                      setInEditField(
                                        OVERVIEW_FORM_FIELDS.SKILLS_REQUIRED
                                      )
                                    }
                                  >
                                    <i className="pe-2">
                                      <img src={editicon} alt="" />
                                    </i>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </OverviewCollapseItem>

                  <OverviewCollapseItem
                    toggleEditing={toggleEditing}
                    toggle={(item) => toggleOption(item)}
                    name={'Software Licenses '}
                    isActive={activeItem == 'Software Licenses '}
                  >
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Choose Software Licenses</label>
                        </div>

                        <div className="col-11 text-start">
                          <Select
                            isMulti
                            name="softwareLicenses"
                            options={options}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(val) =>
                              onSoftwareLicenseChange(val, 'softwareLicenses')
                            }
                            value={softwareLicenses}
                            isDisabled={
                              inEditField !==
                              OVERVIEW_FORM_FIELDS.SOFTWARE_LICENSES
                            }
                          />
                        </div>
                        {inEditField !==
                          OVERVIEW_FORM_FIELDS.SOFTWARE_LICENSES && (
                            <div
                              className="col-1 text-center"
                              onClick={() =>
                                setInEditField(
                                  OVERVIEW_FORM_FIELDS.SOFTWARE_LICENSES
                                )
                              }
                            >
                              <i className="pe-2">
                                <img src={editicon} alt="" />
                              </i>
                            </div>
                          )}
                     
                   
                    <div className="col-11 text-start mt-3">
                      <div
                        className={`${styles.radioBTn} ${styles.radStepOne}`}
                      >
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name={`isRequired$`}
                            id={`isRequired1$`}
                            disabled={
                              inEditField !==
                              OVERVIEW_FORM_FIELDS.SOFTWARE_LICENSES_REQUIRED
                            }
                            checked={isRequired}
                            onChange={() =>
                              onSoftwareLicenseChange(true, 'isRequired')
                            }
                          />
                          <label className="form-check-label">Mandatory</label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name={`flexRadioDefault2$`}
                            id={`flexRadioDefault2$`}
                            checked={!isRequired}
                            disabled={
                              inEditField !==
                              OVERVIEW_FORM_FIELDS.SOFTWARE_LICENSES_REQUIRED
                            }
                            onChange={() =>
                              onSoftwareLicenseChange(false, 'isRequired')
                            }
                          />
                          <label className="form-check-label">
                            Good to have
                          </label>
                        </div>
                      </div>
                      </div>

                        {inEditField !==
                          OVERVIEW_FORM_FIELDS.SOFTWARE_LICENSES_REQUIRED && (
                            <div
                              className="col-1 text-center"
                              onClick={() =>
                                setInEditField(
                                  OVERVIEW_FORM_FIELDS.SOFTWARE_LICENSES_REQUIRED
                                )
                              }
                            >
                              <i className="pe-2">
                                <img src={editicon} alt="" />
                              </i>
                            </div>
                          )}
                         </div>
                         </div>
                  </OverviewCollapseItem>

                  <OverviewCollapseItem
                    toggleEditing={toggleEditing}
                    toggle={(item) => toggleOption(item)}
                    name={'Drug Test and Backgroud Check'}
                    isActive={activeItem == 'Drug Test and Backgroud Check'}
                  >
                    <>
                      <div className={styles.fromGroup}>
                        <div className="row align-items-center justify-content-between">
                          <div className="col-12">
                            <label>Drug Test Required</label>
                          </div>

                          <div className="col-11 text-start">
                            <div
                              className={`${styles.radioBTn} ${styles.radStepOne}`}
                            >
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name={`isDrugTestRequired$`}
                                  id={`isDrugTestRequired$`}
                                  checked={isDrugTestRequired}
                                  disabled={
                                    inEditField !==
                                    OVERVIEW_FORM_FIELDS.DRUG_TEST_REQUIRED
                                  }
                                  onChange={() =>
                                    onDrugTestValue(true, 'isDrugTestRequired')
                                  }
                                />
                                <label className="form-check-label">Yes</label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name={`isDrugTestRequired$`}
                                  id={`isDrugTestRequired$`}
                                  checked={!isDrugTestRequired}
                                  disabled={
                                    inEditField !==
                                    OVERVIEW_FORM_FIELDS.DRUG_TEST_REQUIRED
                                  }
                                  onChange={() =>
                                    onDrugTestValue(false, 'isDrugTestRequired')
                                  }
                                />
                                <label className="form-check-label">No</label>
                              </div>
                            </div>
                          </div>

                          {inEditField !==
                            OVERVIEW_FORM_FIELDS.DRUG_TEST_REQUIRED && (
                              <div
                                className="col-1 text-center"
                                onClick={() =>
                                  setInEditField(
                                    OVERVIEW_FORM_FIELDS.DRUG_TEST_REQUIRED
                                  )
                                }
                              >
                                <i className="pe-2">
                                  <img src={editicon} alt="" />
                                </i>
                              </div>
                            )}
                        </div>
                      </div>

                      {isDrugTestRequired && (
                        <>
                          <div className={styles.fromGroup}>
                            <div className="row align-items-center justify-content-between">
                              <div className="col-12">
                                <label>Drug Test Recency</label>
                              </div>

                              <div className="col-11 text-center">
                                <div className={styles.AddBtn}>
                                  <input
                                    type="text"
                                    placeholder="Enter Text"
                                    value={drugRegacy}
                                    disabled={
                                      inEditField !==
                                      OVERVIEW_FORM_FIELDS.DRUG_REGENCY
                                    }
                                    onChange={({ target: { value } }) =>
                                      onDrugTestValue(value, 'drugRegacy')
                                    }
                                  />
                                </div>
                              </div>
                              {inEditField !==
                                OVERVIEW_FORM_FIELDS.DRUG_REGENCY && (
                                  <div
                                    className="col-1 text-center"
                                    onClick={() =>
                                      setInEditField(
                                        OVERVIEW_FORM_FIELDS.DRUG_REGENCY
                                      )
                                    }
                                  >
                                    <i className="pe-2">
                                      <img src={editicon} alt="" />
                                    </i>
                                  </div>
                                )}
                            </div>
                          </div>
                        </>
                      )}
                    </>
                    <>
                      <div className={styles.fromGroup}>
                        <div className="row align-items-center justify-content-between">
                          <div className="col-12">
                            <label>Background check Required</label>
                          </div>

                          <div className="col-11 text-start">
                            <div
                              className={`${styles.radioBTn} ${styles.radStepOne}`}
                            >
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name={`isBackgroundCheckRequired$`}
                                  id={`isBackgroundCheckRequired1$`}
                                  checked={isBackgroundCheckRequired}
                                  disabled={
                                    inEditField !==
                                    OVERVIEW_FORM_FIELDS.BG_TEST_REQUIRED
                                  }
                                  onChange={() =>
                                    onBackgroudCheck(
                                      true,
                                      'isBackgroundCheckRequired'
                                    )
                                  }
                                />
                                <label className="form-check-label">Yes</label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name={`isBackgroundCheckRequired$`}
                                  checked={!isBackgroundCheckRequired}
                                  disabled={
                                    inEditField !==
                                    OVERVIEW_FORM_FIELDS.BG_TEST_REQUIRED
                                  }
                                  onChange={() =>
                                    onBackgroudCheck(
                                      false,
                                      'isBackgroundCheckRequired'
                                    )
                                  }
                                />
                                <label className="form-check-label">No</label>
                              </div>
                            </div>
                          </div>

                          {inEditField !==
                            OVERVIEW_FORM_FIELDS.BG_TEST_REQUIRED && (
                              <div
                                className="col-1 text-center"
                                onClick={() =>
                                  setInEditField(
                                    OVERVIEW_FORM_FIELDS.BG_TEST_REQUIRED
                                  )
                                }
                              >
                                <i className="pe-2">
                                  <img src={editicon} alt="" />
                                </i>
                              </div>
                            )}
                        </div>
                      </div>

                      {isBackgroundCheckRequired && (
                        <div className={styles.fromGroup}>
                          <div className="row align-items-center justify-content-between">
                            <div className="col-12">
                              <label>Background check Recency</label>
                            </div>

                            <div className="col-11 text-center">
                              <div className={styles.AddBtn}>
                                <input
                                  type="text"
                                  placeholder="Enter Text"
                                  value={backgroundRegecy}
                                  disabled={
                                    inEditField !==
                                    OVERVIEW_FORM_FIELDS.BG_REGENCY
                                  }
                                  onChange={({ target: { value } }) =>
                                    onBackgroudCheck(value, 'backgroundRegecy')
                                  }
                                />
                              </div>
                            </div>
                            {inEditField !==
                              OVERVIEW_FORM_FIELDS.BG_REGENCY && (
                                <div
                                  className="col-1 text-center"
                                  onClick={() =>
                                    setInEditField(
                                      OVERVIEW_FORM_FIELDS.BG_REGENCY
                                    )
                                  }
                                >
                                  <i className="pe-2">
                                    <img src={editicon} alt="" />
                                  </i>
                                </div>
                              )}
                          </div>
                        </div>
                      )}
                    </>
                  </OverviewCollapseItem>

                  <OverviewCollapseItem
                    toggleEditing={toggleEditing}
                    toggle={(item) => toggleOption(item)}
                    name={'Procedures and Policies'}
                    isActive={activeItem == 'Procedures and Policies'}
                  >
                    <div className={styles.fromGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label>Procedures and Policies</label>
                        </div>

                        <div className="col-11 text-center">
                          <CKEditor
                            editor={ClassicEditor}
                            data={proceduresAndPolicies.proceduresAndPolicies}
                            disabled={
                              inEditField !==
                              OVERVIEW_FORM_FIELDS.PROCEDURES_AND_POLICIES
                            }
                            onChange={(event, editor) =>
                              onEditorValueChange(editor.getData())
                            }
                          />
                        </div>
                        {inEditField !==
                          OVERVIEW_FORM_FIELDS.PROCEDURES_AND_POLICIES && (
                            <div
                              className="col-1 text-center"
                              onClick={() =>
                                setInEditField(
                                  OVERVIEW_FORM_FIELDS.PROCEDURES_AND_POLICIES
                                )
                              }
                            >
                              <i className="pe-2">
                                <img src={editicon} alt="" />
                              </i>
                            </div>
                          )}
                      </div>
                    </div>
                    {/* <div className={styles.formGroup}>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-12">
                          <label
                            htmlFor="fileUpload"
                            className={styles.fileLabel}
                          >
                            Upload a file:
                          </label>
                        </div>

                        <div className="col-12 text-start">
                          <div className="p-2 border d-inline-block rounded-3 bg-white">
                            <input
                              type="file"
                              id="fileUpload"
                              className={styles.fileInput}
                            //onChange={handleFileChange}
                            />
                          </div>

                        </div>
                      </div>
                    </div> */}
                  </OverviewCollapseItem>
                </ul>
              </div>
            </div>
          </div>

          {/* <CompanyInfoModal/> */}
          <div className="col-lg-10 mx-auto col-12">
            <div className={styles.regisFooter}>
              <div className="container">
                <div className="col-lg-8 col-12 ms-auto">
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ gap: '20px' }}
                  >
                    <div className="col-4 text-center">
                      <div className={styles.DraftBtns}>
                        <SaveAsDraftButton />
                      </div>
                    </div>

                    <div className="col-8 text-end my-3 btn-hover-effect">
                      <h6
                        onClick={handleSendForApproval}
                        className="btn bg-white arrowblue border-grey border-1 rounded-pill position-relative py-lg-3 py-2 pe-5"
                      >
                        <span className="px-lg-4 px-2 text-12 fw-semibold">
                          Send For Approval
                        </span>
                        <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ">
                          <img src={whitearrow} alt="" />
                        </i>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalDetail.show && (
        <CustomModal
          key={key}
          show={modalDetail.show}
          backdrop="static"
          showCloseBtn={true}
          isRightSideModal={false}
          mediumWidth={false}
          // className={modalDetail.flag === "infoBusiness" ? "infoBusiness" : ""}
          // child={
          //   modalDetail.flag === "infoBusiness" ? (
          //     <CompanyInfoModal onCloseModal={() => handleOnCloseModal()} />
          //   ) : (
          //     ""
          //   )
          // }
          className={modalDetail.flag === 'plan' ? 'plan' : ''}
          child={
            modalDetail.flag === 'plan' ? (
              <Subscription onClosePlanModal={() => handleOnCloseModal()} />
            ) : (
              ''
            )
          }
          // header={
          //   <div className="modalHeader_">
          //     <div className="common_">
          //       {modalDetail.flag === "infoBusiness" ? (
          //         <h2 className="text-22 text-white mb-0 text-center live-modal-title">
          //           Business Details
          //         </h2>
          //       ) : (
          //         ""
          //       )}
          //     </div>
          //   </div>
          // }
          onCloseModal={() => handleOnCloseModal()}
        />
      )}
    </>
  );
};

export default OverviewContract;
