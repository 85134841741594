import React, { FC, useEffect, useState } from 'react';
import HeaderContent from '../../../../../shared/Components/HeaderContent/headerContent';
import styles from "../../../../../components/work/styles/style.module.css"
import Occupations from "../../../../../assets/images/occupations.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import { Dropdown, FormControl } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import Select from "react-select";
import { RootState } from "../../../../../redux/store";
import { getMasterTypes } from "../../../../../feature/auth/signup/api";
import { IOption } from "../../../../../Interfaces";
import { MASTER_TYPE } from "../../../../../utils/enums/masterTypes.enum";
import { toast } from "react-toastify";
import { MESSAGES } from "../../../../../utils/messages";
import tip from "../../../../../assets/images/tip.png";
import { CONSTANT_MANAGER } from "../../../../../utils/constans/constants";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../../../../utils/routes/AppRoutes";
import Toltip from '../../../../../components/common/toltip/page';
import { validationSchemaForOccupation } from '../../../../../components/work/validations';
import { postMasterType } from '../../../../../components/work/api';
import { APIResponseError } from '../../../../../components/work/interface';
import { setActiveStep, setOccupation, setOccupationsList } from '../../../../../redux/reducers/addDirectJob';
import { DIRECT_JOB_STEPS } from '../Enums';


const AddOccupationForDirectJob: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filterValue, setFilterValue] = useState<string>("");

  const { occupation, occupationsList } = useSelector(
    (state: RootState) => state.directJob
  );

  const formik = useFormik({
    initialValues: {},
    validationSchema: validationSchemaForOccupation,
    onSubmit: (values) => { },
  });

  const getOccupationData = async () => {
    const response = await getMasterTypes(MASTER_TYPE.OCCUPATIONS);
    const mappedOccupations = response.data.map<IOption>((x) => ({
      label: x.name,
      value: x._id,
    }));

    dispatch(setOccupationsList(mappedOccupations));
  };

  useEffect(() => {
    getOccupationData();
  }, []);

  const handleSubmitCustomOccupation = async () => {
    const payload = {
      type: MASTER_TYPE.OCCUPATIONS,
      name: searchTerm,
      alias: searchTerm,
    };

    toast.promise(postMasterType(payload), {
      pending: {
        render() {
          return MESSAGES.POSTING_CONTRACTS.VERIFYING;
        },
      },
      success: {
        render(newOccupation) {
          formik.setFieldValue(
            "selectedPrimaryOptionName",
            newOccupation.data.data.name
          );
          setFilterValue("")
          setSearchTerm("")
          dispatch(
            setOccupation({
              ...occupation,
              primaryOccupation: {
                label: newOccupation.data.data.name,
                value: newOccupation.data.data._id,
              },
      
            })
          );

          return MESSAGES.POSTING_CONTRACTS.ADDING;
        },
      },
      error: {
        render(error) {
          let errorMesage = error as APIResponseError;
          return errorMesage?.data?.data?.message;
        },
      },
    });
  };

  const handleGoToNextStep = () => {
    if (
      !occupation.primaryOccupation &&
      occupation.secondaryOccupations.length === 0
    ) {
      toast.error("Please Add Occupation");
    } else {
      dispatch(setActiveStep(DIRECT_JOB_STEPS.ADD_DESCIPTION));
    }
  };

  const handleGoBack = () => {
    navigate(APP_ROUTES.ADD_CONTRACT);
    //dispatch(setActi(veStep(ADD_CONTRACT_STEPS.POST_TYPE));
  };

  const handleOccupationChange = (value: any, key: string) => {
    // TODO: According to your wish

    // if (key === 'secondaryOccupations' && value.length > 4) {
    //   return;
    // }
    dispatch(setOccupation({ ...occupation, [key]: value }));
  };

  const primaryOccOptions =
    filterValue && filterValue !== ""
      ? occupationsList.filter((x) =>
        x.label.toLowerCase().includes(filterValue.toLowerCase())
      )
      : occupationsList;

  return (
    <>
      <HeaderContent />
      <div className="py-4 heightautoscroll">
        <div className={styles.signuppage}>
          <div className="contractpages">
            <form onSubmit={formik.handleSubmit}>
              <div style={{ position: "relative" }}>
                <div className="container">
                  <div className="row pt-2 h-100 align-items-start">
                    <div className="col-lg-6">
                    <div className={styles.registerLeft}>
                      <h4 className="text-white text-32 fw-semibold">
                        Who Do You Need?
                      </h4>
                      <p className="text-16 text-white opacity-75">
                        What is the Primary and Secondary Occupations of
                        <br />
                        the person that you are looking for?
                      </p>

                <div className='col-lg-11 col-12'
                      >
                        <figure>
                          <img src={Occupations} alt="" className="img-fluid" />
                        </figure>
                      </div>
                    </div>
                    
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className={styles.registerBord}>
                        <div className={styles.signUPContainer}>
                         
                          <div className={styles.registerRight}>
                            <div
                              className={styles.fromGroup}
                              style={{ marginBottom: "30px" }}
                            >
                              <div className="topTipHover">
                                <label className={styles.starlabel}>
                                  Primary Occupation
                                </label>
                                <i className="">
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>

                                <Toltip
                                  slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                  labelId={
                                    CONSTANT_MANAGER.ADD_CONTRACT
                                      .PRIMARY_OCCUPATION
                                  }
                                />
                              </div>
                              {/* <i className="fa-duotone fa-asterisk"></i> */}
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="primary"
                                  id="dropdown-basic"
                                >
                                  {occupation.primaryOccupation
                                    ? occupation.primaryOccupation.label
                                    : "Please select an option"}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <div className="">
                                    <FormControl
                                      autoFocus
                                      className="mx-3 my-2 w-auto"
                                      placeholder="Search..."
                                      onChange={(e) =>
                                        setFilterValue(e.target.value)
                                      }
                                      value={filterValue}
                                    />
                                  </div>

                                  <div
                                    style={{
                                      maxHeight: "300px",
                                      // overflowY: "scroll",
                                    }}
                                  >
                                    {primaryOccOptions.length === 0 ? (
                                      <Dropdown.Item disabled>
                                        No options found
                                      </Dropdown.Item>
                                    ) : ([{ "label": "Select primary occupation", "value": "" }, ...primaryOccOptions]
                                      .map(
                                        (option, index: number) => (
                                          <>
                                            <Dropdown.Item
                                              key={index}
                                              onClick={() =>
                                                handleOccupationChange(
                                                  option,
                                                  "primaryOccupation"
                                                )
                                              }
                                            >
                                              {option.label}
                                            </Dropdown.Item>
                                          </>
                                        )
                                      )
                                    )}
                                    <div className={styles.addOccuatin}>
                                      <div className="col-8">
                                        <input
                                          type="text"
                                          placeholder="Type an occupation here you want to add..."
                                          name="customOccupation"
                                          value={searchTerm}
                                          onChange={(e) =>
                                            setSearchTerm(e.target.value)
                                          }
                                          onBlur={formik.handleBlur}
                                          onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                              handleSubmitCustomOccupation();
                                              e.preventDefault();
                                            }
                                          }}

                                        />
                                      </div>

                                    </div>
                                  </div>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>

                            <div
                              className={styles.fromGroup}
                              style={{ marginBottom: "30px" }}
                            >
                              <div className="topTipHover">
                                <label>Secondary Occupations</label>
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                                <Toltip
                                  slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                  labelId={
                                    CONSTANT_MANAGER.ADD_CONTRACT
                                      .SECONDARY_OCCUPATION
                                  }
                                />
                              </div>
                              <Select
                                isMulti
                                name="colors"
                                options={occupationsList as any}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(val) =>
                                  handleOccupationChange(
                                    val,
                                    "secondaryOccupations"
                                  )
                                }
                                value={occupation.secondaryOccupations}
                              />

                              {/* {formik.touched.selectedSecondaryOptionName &&
                            formik.errors.selectedSecondaryOptionName && (
                              <div className={styles.error}>
                                {formik.errors.selectedSecondaryOptionName}
                              </div>
                            )} */}
                            </div>
                          </div>
                          <div className="row align-items-center justify-content-between mt-lg-5 mt-0">
                            <div className="col-4 text-start my-3 btn-hover-effect">
                              <h6
                                onClick={() => handleGoBack()}
                                className="btn bg-white arrowyellow border-white border-1 rounded-pill position-relative py-lg-3 py-2 ps-5 backbutton mb-0"
                              >
                                <i className="bg-yellow rounded-pill arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                                  <img src={blackarrow} alt="" />
                                </i>
                                <span className="px-lg-4 px-2 ps-3 text-12 fw-semibold d-block">
                                  Back
                                </span>
                              </h6>
                            </div>
                            <div className="col-8">
                              <div
                                className="d-flex align-items-center jusify-content-end"
                                style={{ gap: "10px" }}
                              >
                                {/* <div className="col-6">
                                  <SaveAsDraftButton />
                                </div> */}
                                <div className="col-12 text-end my-3 btn-hover-effect">
                                  <h6
                                    onClick={handleGoToNextStep}
                                    className="btn bg-white arrowblue border-white border-1 rounded-pill position-relative py-lg-3 py-2 pe-5 mb-0"
                                  >
                                    <span className="px-lg-4 px-2 pe-lg-3 text-12 fw-semibold d-block">
                                      Next
                                    </span>
                                    <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                                      <img src={whitearrow} alt="" />
                                    </i>
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddOccupationForDirectJob;
