import React, { useState } from "react";
import Header from "../../header/routes";
import { useNavigate } from "react-router-dom";
import search from "../../../assets/images/search.svg";
import styles from "../styles/style.module.css";
import { FormControl } from "react-bootstrap";

function Search() {
  const [filterValue, setFilterValue] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [value, setValue] = useState("");

  const navigate = useNavigate();
  const handleGoToNext = () => {
    navigate("/feeds");
  };

  return (
    <>
      <div className="form-search col-10">
        <span className="input-search bg-white">
          <i>
            <img src={search} alt="" width={15} height={15} />
          </i>
        </span>
        {/* <input
          type="text"
          className="form-control text-13 text-grey"
          placeholder="Search"
          aria-label="Username"
          aria-describedby="" 
          value={value}
          onChange={(e) => setValue(e.target.value)}
        /> */}
        <FormControl
          autoFocus
          className="form-control text-13 text-grey"
          placeholder="Search..."
          onChange={(e) => setFilterValue(e.target.value)}
          value={filterValue}
        />
      </div>
    </>
  );
}

export default Search;
