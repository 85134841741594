import React, { useEffect, useState } from 'react'
import Header from '../../../header/routes'
import info from "../../../../assets/images/info.png"
import exportt from "../../../../assets/images/exportt.png"
import { Link, useLocation, useParams } from 'react-router-dom'
import styles from "../../../common/timesheets/styles/styles/style.module.css"
import moment from 'moment'
import { getInvoice } from '../../expense/api'
import { INVOICE_UNPAID_TYPE, } from '../../../../utils/enums/timesheetOrExpenseStatusTypes'


const InvoiceTable = () => {
    const { id } = useParams();
    const location=useLocation()
    const searchParams = new URLSearchParams(location.search);
    const isBusiness = searchParams.get('isBusiness'); 

    const [approvedExpenseData, setApprovedExpenseData] = useState<any>();
    const [pendingExpenseData, setPendingExpenseData] = useState<any>();
    const [loader, setLoader] = useState<boolean>(true);

    const getApprovedData = async () => {
        const response = await getInvoice(id, INVOICE_UNPAID_TYPE.PAID, false);
        setApprovedExpenseData(response?.data);
      };
    
      const getPendingData = async () => {
        const response = await getInvoice(id, INVOICE_UNPAID_TYPE.UNPAID, isBusiness as unknown as boolean); 
        setPendingExpenseData(response?.data);
      };
    
      useEffect(() => {
        const fetchData = async () => {
          await getApprovedData();
          await getPendingData();
          setLoader(false); 
        };
    
        fetchData();
      }, [isBusiness]);

    return (
        <>
            <Header />
            <div className={styles.TimeShettTable}>
                <div className='container'>
                    <h3>Invoices</h3>
                    {loader ? <div className="spinner-grow text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div> :
                        <>
                            <div className={styles.TimeShettTableOuter}>
                                <div className={styles.TimeShettTableHead}>
                                    <h4>Paid Invoices <img src={info} alt="" /></h4>
                                    <button> <img src={exportt} alt="" />Export Data</button>
                                </div>
                                <table>
                                    <tr>
                                        <th>WR#</th>
                                        <th>PROFESSIONAL NAME</th>
                                        <th>START DATE</th>
                                        <th>END DATE</th>
                                        <th>APPROVED DATE</th>
                                        <th>TOTAL AMOUNT</th>
                                        <th>ACTIONS</th>
                                    </tr>

                                    <tr>
                                        {approvedExpenseData?.length > 0 ? approvedExpenseData?.map((data: any, idx: number) => {
                                            return (
                                                <>
                                                    <td key={idx}>#{data?.work?.workNumber}</td>
                                                    <td>{data?.user?.name}</td>
                                                    <td>{moment(data.startDate).format("MMM DD, YYYY")}</td>
                                                    <td >{moment(data.endDate).format("MMM DD, YYYY")}</td>

                                                    <td >{moment(data.approvedDate).format("MMM DD, YYYY")}</td>
                                                    <td>${data?.total}</td>
                                                    <td><Link to={`/expense-detail/${data?._id}`}>View Details</Link></td>
                                                </>
                                            )
                                        }) : <p>No Invoices Found</p>}

                                    </tr>

                                </table>
                            </div>
                            <div className={styles.TimeShettTableOuter}>
                                <div className={styles.TimeShettTableHead}>
                                    <h4> Pending to be Paid <img src={info} alt="" /></h4>
                                    <button> <img src={exportt} alt="" />Export Data</button>
                                </div>
                                <table>
                                    <tr>
                                        <th>WR#</th>
                                        <th>PROFESSIONAL NAME</th>
                                        <th>START DATE</th>
                                        <th>END DATE</th>
                                        <th>APPROVED DATE</th>
                                        <th>TOTAL AMOUNT</th>
                                        <th>ACTIONS</th>
                                    </tr>

                                    <tr>
                                        {pendingExpenseData?.length > 0 ? pendingExpenseData?.map((data: any, idx: number) => {
                                            return (
                                                <>
                                                    <td key={idx}>#{data?.work?.workNumber}</td>
                                                    <td>{data?.user?.name}</td>
                                                    <td>{moment(data.startDate).format("MMM DD, YYYY")}</td>
                                                    <td >{moment(data.endDate).format("MMM DD, YYYY")}</td>
                                                    <td >{moment(data.approvedDate).format("MMM DD, YYYY")}</td>
                                                    <td>${data?.total}</td>
                                                    <td><Link to={`/invoice/${data?._id}`}>Pay Now</Link></td>
                                                </>
                                            )
                                        }) : <p>No Invoices Found</p>}
                                    </tr>

                                </table>
                            </div>
                        </>
                    }

                </div>
            </div>
        </>

    )
}

export default InvoiceTable