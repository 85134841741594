import { AxiosResponse } from "axios";
import { axios } from "../../../lib/axios";
import { API_ROUTES } from "../../../utils/routes/apiRoutes";
import { UserProfileByIdData } from "../interface";



export const getUserProfileById = async (id: string, type?: string): Promise<AxiosResponse<UserProfileByIdData>> => {
  if (type) {
    return axios.get(`${API_ROUTES.PROFILE.GET_USERPROFILE_BY_ID}/${id}?type=${type}`);
  } else {
    return axios.get(`${API_ROUTES.PROFILE.GET_USERPROFILE_BY_ID}/${id}`);

  }
};

