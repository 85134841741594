import React, { useState } from "react";
import Post from "./CustomModal/PostTypeConfirmationModal";
import ConfirmModal from "./CustomModal/confirmationModal";
import LoginSucessModal from "./CustomModal/LoginSuccessModal";
import { SaveAsDraftAuthErrorDialog } from "../components/work/contractSteps/Components";

const Test = () => {
    const [showPost, setShowPost] = useState<boolean>(false)
    const [showAddMore, setShowAddMorw] = useState<boolean>(false)
    const [showCongratulation, setShowCongratulation] = useState<boolean>(false)
    const [askLogin, setAskLogin] = useState<boolean>(false)

    return (

        <>
            <button onClick={() => setShowPost(true)}>Post Standard Contract</button>
            <button onClick={() => setShowAddMorw(true)}>Add More details</button>
            <button onClick={() => setShowCongratulation(true)}>Congratulation Login</button>
            <button onClick={() => setAskLogin(true)}>Ask For login</button>

            {showPost && <Post />}
            {showAddMore && <ConfirmModal />}
            {showCongratulation && <LoginSucessModal />}

            <SaveAsDraftAuthErrorDialog
                show={askLogin}
                onHide={() => setAskLogin(false)}
            />
            
        </>


    )
}
export default Test



            // <SaveAsDraftAuthErrorDialog
            //     show={askLogin}
            //     onHide={() => setAskLogin(false)}
            // />
            // <Post
            //     show={showPost}
            //     onHide={() => setShowPost(false)}
            // />
            // <ConfirmModal
            //     show={showAddMore}
            //     onHide={() => setShowAddMorw(false)}
            // />
            //     <LoginSucessModal
            //     show={showCongratulation}
            //     onHide={() => setShowCongratulation(false)}
            // />
 


    
