import moment from 'moment';
import { AddContractRootState, AddDirectJobRootState } from '../models';
import { DIRECT_JOB_STEPS } from '../pages/ChoosePostType/Components/AddDirectJob/Enums';
import { ADD_CONTRACT_STEPS } from '../shared/enums';
import { AUTH } from './auth';
import { requestForToken } from './firebase_Integration/firebase';

const storage = {
  setClientInterviewUserId: (userId: string) => {
    window.localStorage.setItem('clientInterviewUserId', userId);
  },
  getClientInterviewUserId: () => {
    return window.localStorage.getItem('clientInterviewUserId') as string;
  },
  clearClientInterviewUserId: () => {
    window.localStorage.removeItem('clientInterviewUserId');
  },
  setClientInterviewEventId: (data: any) => {
    const cretateArray = [];
    cretateArray.push(data);
    window.localStorage.setItem('clientInterviewEventId', JSON.stringify(data));
  },
  getClientInterviewEventId: (day: any, userId: string, time: string) => {
    if (day && userId) {
      const clientInterviewEventId = JSON.parse(window.localStorage.getItem('clientInterviewEventId') as string);
      if (Array.isArray(clientInterviewEventId)) {
        return clientInterviewEventId.filter((item: any) => item.eventDate === day && item.userId === userId);
      } else {
        if (clientInterviewEventId.day === day && clientInterviewEventId.userId === userId) {
          return clientInterviewEventId;
        }
        return [];
      }
    } else if (day && time) {
      const clientInterviewEventId = JSON.parse(window.localStorage.getItem('clientInterviewEventId') as string);

      // calculate time within 20 min range
      const timeRange = 20 * 60 * 1000;
      const currentTime = moment().valueOf();
      const eventTime = moment(time).valueOf();

      if (Array.isArray(clientInterviewEventId)) {
        return clientInterviewEventId.filter((item: any) => item.eventDate === day && Math.abs(eventTime - currentTime) <= timeRange);
      } else if (clientInterviewEventId.day === day && Math.abs(eventTime - currentTime) <= timeRange) {
        return clientInterviewEventId;
      }
    }
  },
  clearClientInterviewEventId: () => {
    return window.localStorage.removeItem('clientInterviewEventId');
  },
  setInterviewData: (data: any) => {
    window.localStorage.setItem('interviewData', JSON.stringify(data));
  },
  getInterviewData: (day: string, userId: string, time: string) => {
    if (day && userId) {
      const interviewData = JSON.parse(window.localStorage.getItem('interviewData') as string);
      if (Array.isArray(interviewData)) {
        return interviewData.filter((item: any) => item.day === day && item.userId === userId);
      } else {
        if (interviewData.day === day && interviewData.userId === userId) {
          return interviewData;
        }
        return [];
      }
    } else if (day && time) {
      const interviewData = JSON.parse(window.localStorage.getItem('interviewData') as string);

      // calculate time within 20 min range
      const timeRange = 20 * 60 * 1000;
      const currentTime = moment().valueOf();
      const eventTime = moment(time).valueOf();

      if (Array.isArray(interviewData)) {
        return interviewData.filter((item: any) => item.day === day && Math.abs(eventTime - currentTime) <= timeRange);
      } else if (interviewData.day === day && Math.abs(eventTime - currentTime) <= timeRange) {
        return interviewData;
      }
    }
  },
  clearInterviewData: (day: string, userId: string) => {
    const data = JSON.parse(window.localStorage.getItem('interviewData') as string);
    const newData = data.filter((item: any) => item.day != day && item.userId != userId);
    if (newData.length) window.localStorage.setItem('interviewData', JSON.stringify(newData));
    else window.localStorage.removeItem('interviewData');
  },
  setInterviewUserId: (data: any) => {
    window.localStorage.setItem('interviewUserId', JSON.stringify(data));
  },
  getInterviewUserId: () => {
    return window.localStorage.getItem('interviewUserId') as string;
  },
  deleteInterviewUserId: () => {
    window.localStorage.removeItem('interviewUserId');
  },
  getSessionId: () => {
    return window.localStorage.getItem('sessionId') as string;
  },
  setSessionId: (sessionId: string) => {
    window.localStorage.setItem('sessionId', sessionId);
  },
  clearSessionId: () => {
    window.localStorage.removeItem('sessionId');
  },
  getDeviceToken: () => {
    requestForToken();
    return window.localStorage.getItem('deviceToken') as string;
  },
  setDeviceToken: (deviceToken: string) => {
    window.localStorage.setItem('deviceToken', deviceToken);
  },
  clearDeviceToken: () => {
    window.localStorage.removeItem('deviceToken');
  },
  getToken: () => {
    return window.localStorage.getItem('token') as string;
  },
  setToken: (token: string) => {
    window.localStorage.setItem('token', token);
  },
  clearToken: () => {
    window.localStorage.removeItem('token');
  },
  getAuthUserId: () => {
    return window.localStorage.getItem('authUserId');
  },
  getDeviceType: () => {
    return window.localStorage.getItem('deviceType');
  },
  setAuthUserId: (authUserId: string) => {
    window.localStorage.setItem('authUserId', authUserId);
  },
  setDeviceType: (deviceType: string) => {
    window.localStorage.setItem('deviceType', deviceType);
  },
  clearAuthUserId: () => {
    window.localStorage.removeItem('authUserId');
  },
  validateRememberMe: () => {
    if (window.localStorage.getItem(AUTH.REMEMBERME) === AUTH.TRUE) {
      return true;
    } else {
      return false;
    }
  },
  storeRememberMe: (token: string) => {
    window.localStorage.setItem(AUTH.REMEMBERME, token);
  },

  saveAddContractActiveStep: (step: ADD_CONTRACT_STEPS) => {
    localStorage.setItem('addContractActiveStep', step.toString());
  },

  getAddContractActiveStep: (): ADD_CONTRACT_STEPS => {
    return (
      (localStorage.getItem('addContractActiveStep') as ADD_CONTRACT_STEPS) ??
      ADD_CONTRACT_STEPS.POST_TYPE
    );
  },
  saveContract: (contract: any) => {
    localStorage.setItem('contractData', JSON.stringify(contract));
  },

  getSavedContract: (): AddContractRootState => {
    const data = localStorage.getItem('contractData');

    return data !== null && data !== ''
      ? JSON.parse(data)
      : { ...new AddContractRootState() };
  },

  clearSavedContract: () => {
    localStorage.removeItem('contractData');
  },




  saveDirectJobActiveSteps: (step: DIRECT_JOB_STEPS) => {
    localStorage.setItem('addDirectjobActiveStep', step.toString());
  },

  getDirectJobActiveSteps: (): DIRECT_JOB_STEPS => {
    return (
      (localStorage.getItem('addDirectjobActiveStep') as unknown as DIRECT_JOB_STEPS) ??
      DIRECT_JOB_STEPS.ADD_OCCUPATION
    );
  },
  saveDirectJob: (contract: any) => {
    localStorage.setItem('directJobData', JSON.stringify(contract));
  },

  getSavedJobs: (): AddDirectJobRootState => {
    const data = localStorage.getItem('directJobData');

    return data !== null && data !== ''
      ? JSON.parse(data)
      : { ...new AddContractRootState() };
  },

  clearSavedJobs: () => {
    localStorage.removeItem('directJobData');
  },
};

export default storage;
