//client's cred

//my cred priyanka
// const appId = '5af97c686c594d0986b8d33933c295f7'
// Jishan Token
// const appId = '4367dacd4572477a872a8f95bc014036'
// const primaryAppCertificate = '72e9397263e543229ab511bffd12abe0'

const appId = '25a2296187cf4ec6a3626b687f850acb'


import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AgoraRTC, {
    LocalUser,
    RemoteUser,
    useLocalCameraTrack,
    useLocalMicrophoneTrack,
    useRemoteAudioTracks,
    useRemoteUsers,
} from "agora-rtc-react";
import moment from "moment";
import storage from "../../utils/storage";

const LiveVideo: React.FC = () => {
    const navigate = useNavigate();

    // Function to get video information for the user
    const getUserVideoInfo = useCallback(() => {
        const currentDay = moment().format('MM/DD/YYYY');
        const currentTime = moment().format('HH:mm:ss');
        let userId: any = storage.getClientInterviewUserId() || null;
        userId = userId && userId.split()[0];
        const videoInfo = storage.getClientInterviewEventId(currentDay, userId, currentTime);
        if (!Array.isArray(videoInfo?.videoCallDetails)) {
            return {
                token: videoInfo?.videoCallDetails?.token,
                channelName: videoInfo?.videoCallDetails?.channelName,
                uid: videoInfo?.videoCallDetails?.uid,
            };
        }
        return videoInfo[0];
    }, []);

    const { token, channelName, uid } = getUserVideoInfo() || {};
    console.log(`🚀 JISHH 🤦‍♂️😒 --  ~  file: LiveVideo.tsx:46 ~  uid:`, uid);
    console.log(`🚀 JISHH 🤦‍♂️😒 --  ~  file: LiveVideo.tsx:46 ~  channelName:`, channelName);
    console.log(`🚀 JISHH 🤦‍♂️😒 --  ~  file: LiveVideo.tsx:46 ~  token:`, token);

    const [micOn, setMic] = useState(true);
    const [cameraOn, setCamera] = useState(true);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [activeConnection, setActiveConnection] = useState(true);
    const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn);
    const { localCameraTrack } = useLocalCameraTrack(cameraOn);

    const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

    useEffect(() => {
        const joinAndPublishTracks = async () => {
            try {
                console.log('Requesting permissions...');
                await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
                console.log('Permissions granted');

                console.log('Joining channel...');
                await client.join(appId, channelName, token, uid);

                console.log('Creating and publishing tracks...');
                const [microphoneTrack, cameraTrack] = await AgoraRTC.createMicrophoneAndCameraTracks();
                await client.publish([microphoneTrack, cameraTrack]);

                console.log('Joined channel and published tracks');
                setLoading(false);
            } catch (err) {
                console.error('Error joining or publishing tracks:', err);
                setError(`Error: ${err}`);
            }
        };

        if (token && channelName) {
            joinAndPublishTracks();
        }

        return () => {
            client.leave();
        };
    }, [token, channelName, appId, uid, client]);

    const remoteUsers = useRemoteUsers();
    const { audioTracks } = useRemoteAudioTracks(remoteUsers);

    useEffect(() => {
        audioTracks.forEach(track => track.play());
    }, [audioTracks]);

    const handleToggleMic = () => setMic(prevState => !prevState);

    const handleToggleCamera = async () => {
        try {
            if (cameraOn && localCameraTrack) {
                localCameraTrack.stop();
                await client.unpublish([localCameraTrack]);
            } else if (!cameraOn) {
                const newCameraTrack = await AgoraRTC.createCameraVideoTrack();
                await client.publish([newCameraTrack]);
            }
            setCamera(prevState => !prevState);
        } catch (err) {
            console.error('Error toggling camera:', err);
            setError(`Error: ${err}`);
        }
    };

    const handleDisconnect = () => {
        setActiveConnection(false);
        storage.clearClientInterviewEventId();
        storage.clearClientInterviewUserId();
        navigate('/');
    };

    const handleMuteAllRemote = () => {
        audioTracks.forEach(track => track.stop());
    };

    if (loading) {
        return <div>Loading your video stream... Please wait</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div id="videoContainer">
            <div id="remoteVideoGrid">
                {remoteUsers.map(user => (
                    <div key={user.uid} className="remote-video-container">
                        <RemoteUser user={user} />
                    </div>
                ))}
            </div>

            <div id="localVideo">
                <LocalUser
                    audioTrack={localMicrophoneTrack}
                    videoTrack={localCameraTrack}
                    cameraOn={cameraOn}
                    micOn={micOn}
                    playAudio={micOn}
                    playVideo={cameraOn}
                />
                <div id="controlsToolbar">
                    <div id="mediaControls">
                        <button className="btn" onClick={handleToggleMic}>
                            {micOn ? 'Mute Mic' : 'Unmute Mic'}
                        </button>
                        <button className="btn" onClick={handleToggleCamera}>
                            {cameraOn ? 'Turn Off Camera' : 'Turn On Camera'}
                        </button>
                        <button className="btn" onClick={handleMuteAllRemote}>
                            Mute All Remote Users
                        </button>
                    </div>
                    <button id="endConnection" onClick={handleDisconnect}>
                        Disconnect
                    </button>
                </div>
            </div>
        </div>
    );
};

export default LiveVideo;