import React from "react";
import styles from "../styles/style.module.css";
import { User } from "./page";
import { API_ROUTES } from "../../../utils/routes/apiRoutes";
import { axios } from "../../../lib/axios";

interface ReportModalProps {
  onClose: () => void;
  userInfo: User | null;
}
const ReportModal: React.FC<ReportModalProps> = ({ onClose, userInfo }) => {
  const handleReportUser = async () => {
    if (userInfo) {
      try {
        const response = await axios.post(API_ROUTES.CHAT.REPORT_USER, {
            reportedId: userInfo.id,reason:"this user is spam"
        });
        if (response.status === 200) {
          console.log("User blocked successfully");

          onClose(); // Close the modal after blocking
        } else {
          console.error("Failed to block user");
        }
      } catch (error) {
        console.error("Error blocking user:", error);
      }
    }
  };

  return (
    <div className="modal" style={{ display: "block" }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <span className={styles.CloseModal}>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            ></button>
          </span>
          <div className="modal-body">
            <div className={styles.Chatalert}>
              <span>
                <i className="fas fa-ban"></i>
              </span>
              <h4>Report this User ?</h4>
              <p>The user will be report from the chat .</p>
              <div className={styles.BloackBtn}>
                <button onClick={onClose}>Cancel</button>
                <button onClick={handleReportUser}>Report</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportModal;
