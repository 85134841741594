import React, { useEffect, useState } from "react";
import { getPaymentStatus } from "./api";
import { PAYMENT_STATUS } from "../../utils/enums/types";
import storage from "../../utils/storage";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../utils/routes/AppRoutes";


const Success = () => {
  const sessionId = storage.getSessionId();
  const navigate = useNavigate();

  const fetchStatus = async () => {
    const payload = {
      status: PAYMENT_STATUS.SUCCESS,
    };
    await getPaymentStatus(payload, sessionId);
  };

  useEffect(() => {
    fetchStatus();
  }, []);

  const handleRedirect = () => {
    navigate(APP_ROUTES.HOME);
    storage.clearSessionId();
  };



  return (
    <div className="payment-success-container">
    <div className="payment-success-card">
      <h2 className="payment-success-title">Your Payment Was Successful!</h2>
      <p className="payment-success-message">
        Thank you for your purchase. Your transaction has been completed successfully.
      </p>
      <button type="button" onClick={handleRedirect} className="payment-success-button">
        Go to Home
      </button>
    </div>
  </div>
  
  
  );
};

export default Success;
