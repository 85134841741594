import React, { useEffect } from "react";
import styles from "../../../styles/style.module.css";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import storage from "../../../../../utils/storage";
import { RootState } from "../../../../../redux/store";
import { rateValidationSchema } from "../../../validations";
import {
  setActiveStep,
  setRates,
} from "../../../../../redux/reducers/addContract";
import { ADD_CONTRACT_STEPS } from "../../../../../shared/enums";
import { getPaymentTerms } from "../../../../paymentTerms/api";
import { Dropdown } from "react-bootstrap";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import Toltip from "../../../../common/toltip/page";
import tip from "../../../../../assets/images/tip.png";
import { SaveAsDraftButton } from "../../Components";
import Occupations from "../../../../../assets/images/occupations.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import { CONSTANT_MANAGER } from "../../../../../utils/constans/constants";

const AddRates: React.FC = () => {
  const dispatch = useDispatch();
  const token = storage.getToken();

  const { rates } = useSelector((state: RootState) => state.addContract);

  const formik: any = useFormik({
    initialValues: {
      baseRate: rates.baseRate || "",
      otRate: rates.otRate || "",
      dtRate: rates.dtRate || "",
      paymentTerms: rates.paymentTerms || null,
    },
    validationSchema: rateValidationSchema,
    onSubmit: () => {
      dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_TRAVEL_DATA));
    },
  });

  useEffect(() => {
    const paymentTerms = async () => {
      const response = await getPaymentTerms();
      response.data.hourly.forEach((x) => {
        x.label = x.paymentTerm.toString();
        x.value = x._id;
      });

      dispatch(setRates({ ...rates, paymentTermsList: response.data.hourly }));
    };

    paymentTerms();
  }, []);

  const handleBack = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_LOCATION));
  };

  // const handleGoToNextStep = () => {
  //   dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_TRAVEL_DATA));
  // };

  const onInputChange = (value: any, key: string) => {
    formik.setFieldValue(key, value);
    dispatch(setRates({ ...rates, [key]: value }));
  };

  return (
    <>
      <HeaderContent />

      <div className="py-4 heightautoscroll">
        <div className={styles.signuppage}>
          <form onSubmit={formik.handleSubmit}>
            <div className={styles.registerOuter}>
              <div className="container">
                <div className={styles.registerBord}>
                  <div className="row pt-2 h-100 align-items-start">
                    <div className="col-lg-6">
                      <div className={styles.registerLeft}>
                        <h4 className="text-white text-32 fw-semibold">
                          Bill Rates
                        </h4>
                        <p className="text-16 text-white opacity-75">
                          What will you pay for Hours Worked?
                        </p>

                        <div className="col-lg-11 col-12">
                          <figure>
                            <img
                              src={Occupations}
                              alt=""
                              className="img-fluid"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className={styles.signUPContainer}>
                        <div className={styles.registerRight}>
                          <div
                            className={styles.fromGroup}
                            style={{ marginBottom: "30px" }}
                          >
                            <div className={styles.ratedRadio}>
                              <div className="topTipHover">
                                <label className={styles.starlabel}>
                                  Select the net payment terms that in which you
                                  will pay for this contract
                                </label>
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                                <Toltip
                                  slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                  labelId={
                                    CONSTANT_MANAGER.ADD_CONTRACT
                                      .SELECT_NET_PAYMENT_CONTRACT
                                  }
                                />
                              </div>
                              <div className={styles.inputWithDropdown}>
                                <Dropdown
                                // style={{ position: "relative", left: "-110px" }}
                                >
                                  <Dropdown.Toggle
                                    variant="primary"
                                    id="dropdown-basic"
                                  >
                                    {rates.paymentTerms
                                      ? rates.paymentTerms.label
                                      : "Please select an option"}
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {rates?.paymentTermsList?.map(
                                      (option, index: number) => (
                                        <Dropdown.Item
                                          key={index}
                                          onClick={() =>
                                            onInputChange(
                                              option,
                                              "paymentTerms"
                                            )
                                          }
                                        >
                                          {option.label}
                                        </Dropdown.Item>
                                      )
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                                {formik.touched.paymentTerms &&
                                  formik.errors.paymentTerms && (
                                    <div className={styles.error}>
                                      {formik.errors.paymentTerms.label}
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                          <div
                            className={styles.fromGroup}
                            style={{ marginBottom: "30px" }}
                          >
                            <div className="topTipHover">
                              <label className={styles.starlabel}>
                                Base Rate($ per hour)
                              </label>
                              <i>
                                <img
                                  className="customeTolImg"
                                  src={tip}
                                  alt=""
                                />
                              </i>
                              <Toltip
                                slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                labelId={
                                  CONSTANT_MANAGER.ADD_CONTRACT.BASE_RATE
                                }
                              />
                            </div>
                            <input
                              type="number"
                              className="form-control rounded-pill h-48"
                              name="baseRate"
                              placeholder="$/hour"
                              value={rates.baseRate}
                              onChange={({ target }) =>
                                onInputChange(Number(target.value), "baseRate")
                              }
                            />

                            <p>0-40 Hours Monday-Saturday</p>

                            {formik.touched.baseRate &&
                            formik.errors.baseRate ? (
                              <div className={styles.error}>
                                {formik.errors.baseRate}
                              </div>
                            ) : null}
                          </div>
                          <div
                            className={styles.fromGroup}
                            style={{ marginBottom: "30px" }}
                          >
                            <div className="topTipHover">
                              <label> Overtime Rate($ per hour)</label>
                              <i>
                                <img
                                  className="customeTolImg"
                                  src={tip}
                                  alt=""
                                />
                              </i>
                              <Toltip
                                slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                labelId={CONSTANT_MANAGER.ADD_CONTRACT.OT_RATES}
                              />
                            </div>
                            <input
                              type="text"
                              name="otRate"
                              className="form-control rounded-pill h-48"
                              placeholder="$/hour"
                              value={rates.otRate}
                              onChange={({ target }) =>
                                onInputChange(Number(target.value), "otRate")
                              }
                            />

                            <p>41+ Hours Monday-Saturday</p>
                          </div>
                          <div
                            className={styles.fromGroup}
                            style={{ marginBottom: "30px" }}
                          >
                            <div className="topTipHover">
                              <label>
                                {" "}
                                Sunday and Holiday Rate($ per hour)
                              </label>
                              <i>
                                <img
                                  className="customeTolImg"
                                  src={tip}
                                  alt=""
                                />
                              </i>
                              <Toltip
                                slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                labelId={CONSTANT_MANAGER.ADD_CONTRACT.DT_RATE}
                              />
                            </div>
                            <input
                              type="text"
                              name="dtRate"
                              className="form-control rounded-pill h-48"
                              placeholder="$/hour"
                              value={rates.dtRate}
                              onChange={({ target }) =>
                                onInputChange(Number(target.value), "dtRate")
                              }
                            />

                            <p>Sunday and US Holidays</p>
                          </div>
                        </div>
                      </div>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-4 text-start my-3 btn-hover-effect">
                          <h6
                            onClick={() => handleBack()}
                            className="btn bg-white arrowyellow border-white border-1 rounded-pill position-relative py-lg-3 py-2 ps-5 backbutton"
                          >
                            <span className="px-lg-4 px-2 text-12 fw-semibold d-inline-block">
                              Back
                            </span>
                            <i className="bg-yellow rounded-pill arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                              <img src={blackarrow} alt="" />
                            </i>
                          </h6>
                        </div>

                        <div className="col-4">
                          <SaveAsDraftButton />
                        </div>

                        <div className="col-4 text-end my-3 btn-hover-effect">
                          <button
                            type="submit"
                            className="btn bg-white arrowblue border-white border-1 rounded-pill position-relative py-lg-3 py-2 pe-5"
                          >
                            <span className="px-lg-4 px-2 text-12 fw-semibold d-inline-block">
                              Next
                            </span>
                            <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                              <img src={whitearrow} alt="" />
                            </i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddRates;
