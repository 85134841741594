import React from 'react';
import { publicRoutes } from './public';
import { Navigate, Route, Routes } from 'react-router-dom';
import protectedRoutes from './protected';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { AUTH_ROUTES } from '../utils/routes/AuthRoutes';
import { Authorization } from '../shared';

export type RouteParams = {
  element: any;
  path: string;
  isPublicAccessible?: boolean;
};

const AppRoutes = () => {
  const { isAuthenticated } = useSelector(
    (state: RootState) => state.authentication
  );

  return (
    <Routes>
      {publicRoutes.map((x) => (
        <Route key={x.path} path={x.path} element={<>{x.element}</>} />
      ))}

      {protectedRoutes.map((x) => (
        <Route
          key={x.path}
          path={x.path}
          element={
            !isAuthenticated ? (
              <Navigate to={AUTH_ROUTES.LOGIN} />
            ) : (
              <Authorization>{x.element}</Authorization>
            )
          }
        />
      ))}
    </Routes>
  );
};

export default AppRoutes;
