import React from "react";
import { useNavigate } from "react-router-dom";
import shareprofile from "../../../../../assets/images/share_profile.svg";
import Jobs from "../../../../../assets/images/job.svg";
import location from "../../../../../assets/images/location_icon.svg";
import styles from "../styles/style.module.css"
import Slider from "react-slick";
import { RecommendedjobsProps } from "../../../../Feeds/interface";
import { APP_ROUTES } from "../../../../../utils/routes/AppRoutes";


function Completedcontracts({ workList, refresh }: RecommendedjobsProps) {
  const navigate = useNavigate();
  // const handleApply = (id: string) => {
  //   applyWork(id).then((res) => {
  //     refresh();
  //   });
  // };
  const handleWorkDetails = (id: string) => {
    navigate(`${APP_ROUTES.WORK}/${id}`);
  };

  const handleGoToNext = () => {
    navigate("/feeds");
  };
  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 4.2,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3.2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="recommendedjobs">
        <div className="row align-items-center justify-content-between">
          <Slider {...settings}>
            {workList?.map((item, ind) => {
              return (
                <div key={ind} className="col border rounded-3 p-lg-3 p-2 overflow-hidden">
                  <div className={styles.textstatuscompleted}>
                      <span className="text-center text-right">Completed</span>
                    </div>
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    
                    <div className="newheadingstyle">
                      <h3 className="mb-0 text-16">{item?.occupation?.name}</h3>
                    </div>
                    <div className="">
                      <i>
                        <img src={shareprofile} alt="" />
                      </i>
                    </div>
                  </div>
                  <div className="">
                    <p className="text-12 d-flex align-items-center mb-2">
                      <i className="me-1">
                        <img src={location} alt="" width={15} height={14} />
                      </i>
                      {item.address}
                    </p>
                    <p className="alert light-danger text-12 p-2 d-inline-flex align-items-center mb-2">
                      <i className="me-1">
                        <img src={Jobs} alt="" width={15} height={14} />
                      </i>
                      Hourly Contract
                    </p>
                  </div>
                  <div className="skills">
                    <div className="newheadingstyle">
                      <h3 className="text-14">Skills</h3>
                    </div>
                    <ul className="ps-0 d-flex flex-wrap align-items-center justify-content-start gap-2 mx-0">
                      {item.skilledInfo?.map((skill, ind) => {
                        return (
                          <li
                            key={ind}
                            className="col badge text-bg-light text-grey fw-normal text-12"
                          >
                            {skill?.name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  <div className={styles.payrates}>
                    <h3 className="fw-semibold mt-4 text-14">Pay Rates</h3>
                    <ul className="row g-3 ps-0 mb-0 mt-2">
                      <li className="col mt-0">
                        <div className="">
                          <h3 className="text-13 fw-noraml mb-2">Base</h3>
                          <p className="text-primary text-16 mb-2 fw-semibold">
                            ${item.rates.baseRate}
                          </p>
                        </div>
                      </li>
                      <li className="col mt-0">
                        <div className="">
                          <h3 className="text-13 fw-noraml mb-2">Overtime</h3>
                          <p className="text-primary text-16 mb-2 fw-semibold">
                            ${item.rates.overTimeRate}
                          </p>
                        </div>
                      </li>
                      <li className="col mt-0">
                        <div className="">
                          <h3 className="text-13 fw-noraml mb-2">
                            Sun/Holidays
                          </h3>
                          <p className="text-primary text-16 mb-2 fw-semibold">
                            ${item.rates.doubleTimeRate}
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="mt-3">
                    <div className="row align-items-center g-2">
                      {item.isApplied ? (
                        <div className="col">
                          <button
                            type="button"
                            className="btn btn-primary w-100 text-12"
                          >
                            Applied
                          </button>
                        </div>
                      ) : (
                        <div className="col">
                          <button
                            // onClick={() => handleApply(item._id)}
                            type="button"
                            className="btn btn-primary w-100 text-12"
                          >
                            Apply Now
                          </button>
                        </div>
                      )}
                      <div className="col">
                        <button
                          onClick={() => handleWorkDetails(item._id)}
                          type="button"
                          className="btn btn-white w-100 text-center border text-12"
                        >
                          View Details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </>
  );
}

export default Completedcontracts;
