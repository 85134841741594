import React, { useEffect, useState } from 'react'
import styles from "../../common/timesheets/styles/styles/style.module.css"
import Header from '../../header/routes'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { getexpenseById } from './api'
import { getMondayOfCurrentWeek, getWeekDaysMap } from '../../../utils/daysMap'
import WhiteGloveContractDetails from '../Contracts/whiteGloveHourlyContract/whileGloveHourlyContract'


const ExpenseDetails = () => {
  const { id } = useParams();
  const [expenseDetails, setExpenseDetails] = useState<any>();

  const approvedExpense = async () => {
    const response: any = await getexpenseById(id);
    setExpenseDetails(response?.data);
  };

  useEffect(() => {
    approvedExpense();
  }, [])



  return (
    <>
      <Header />
      <div className={styles.pageOuter}>
        <div className='container'>


          <div className={styles.rightContainer}>
            <h2>Expense Details</h2>

            <div className={`${styles.baseInfo} ${styles.noMarg}`}>
            {/* <WhiteGloveContractDetails/> */}
              <div className='ExpenseReportBord'>
                <div className='expenseLogo'>

                  <div className={styles.personalDetials}>
                    <div className={styles.topBrd}>
                      <div className={styles.innerbrd}><b>Engineers :</b></div>
                      <div className={styles.innerbrd}>{expenseDetails?.user?.name}</div>
                      <div className={styles.innerbrd}><b>Monday / Week Start Date</b></div>
                    </div>
                    <div className={styles.topBrd}>
                      <div className={styles.innerbrd}><b>Customer Company Name</b></div>
                      <div className={styles.innerbrd}>...</div>
                      <div className={styles.innerbrd}>{moment(expenseDetails?.work?.times.startDate).format("MMM DD, YYYY")}</div>
                    </div>
                    <div className={styles.topBrd}>
                      <div className={styles.innerbrd}><b>Customer # :</b></div>
                      <div className={styles.innerbrd}>47110</div>
                    </div>
                    <div className={styles.topBrd}>
                      <div className={styles.innerbrd}><b>Customer Contact : </b></div>
                      <div className={styles.innerbrd}>{expenseDetails?.work?.user?.name}</div>
                      <div className={styles.innerbrd}><b>Customer Email : </b></div>
                      <div className={styles.innerbrd}>{expenseDetails?.work?.user?.email}</div>
                    </div>
                    <div className={styles.topBrd}>
                      <div className={styles.innerbrd}><b>Work Number :  </b></div>
                      <div className={styles.innerbrd}>#{expenseDetails?.work?.workNumber}</div>
                    </div>
                    <div className={styles.topBrd}>
                      <div className={styles.innerbrd}><b>End Customer :   </b></div>
                      <div className={styles.innerbrd}>#10179800</div>
                    </div>
                  </div>

                  <div className={`${styles.personalDetials} ${styles.expenseTable}`}>
                    <div className={styles.topBrd}>
                      <div className={styles.innerbrd}></div>
                      <div className={styles.innerbrd}></div>
                      <div className={styles.innerbrd}><b>Monday</b></div>
                      <div className={styles.innerbrd}><b>Tuesday</b></div>
                      <div className={styles.innerbrd}><b>Webnesday</b></div>
                      <div className={styles.innerbrd}><b>Thursday</b></div>
                      <div className={styles.innerbrd}><b>Friday</b></div>
                      <div className={styles.innerbrd}><b>Saturday</b></div>
                      <div className={styles.innerbrd}><b>Sunday</b></div>
                      <div className={styles.innerbrd}><b>Weekly Total</b></div>
                    </div>
                    <div className={styles.topBrd}>
                      <div className={styles.innerbrd}><b>Expense :</b></div>
                      <div className={styles.innerbrd}><b>Allownce :</b></div>
                      <div className={styles.innerbrd}>10/5/2023</div>
                      <div className={styles.innerbrd}>10/5/2023</div>
                      <div className={styles.innerbrd}>10/5/2023</div>
                      <div className={styles.innerbrd}>10/5/2023</div>
                      <div className={styles.innerbrd}>10/5/2023</div>
                      <div className={styles.innerbrd}>10/5/2023</div>
                      <div className={styles.innerbrd}>10/5/2023</div>
                      <div className={styles.innerbrd}><b>10/5/2023</b></div>
                    </div>
                    <div className={styles.topBrd}>
                      <div className={styles.innerbrd}>Pre Dim Foood</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}><b>10/5/2023</b></div>
                    </div>
                    <div className={styles.topBrd}>
                      <div className={styles.innerbrd}>Pre Dim Foood</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}><b>10/5/2023</b></div>
                    </div>
                    <div className={styles.topBrd}>
                      <div className={styles.innerbrd}>Pre Dim Foood</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}>$10</div>
                      <div className={styles.innerbrd}><b>10/5/2023</b></div>
                    </div>


                  </div>
                  {/* <div className='AprovdDate'>
                    <p className='mb-1'><b>Approved By:</b> Toood Viller</p>
                    <p className='mb-1'><b>IP Address:</b> 12345</p>
                    <p className='mb-1'><b>Approved Date:</b> 10/4/45</p>
                    <p className='mt-5'><b>Date:</b> </p>


                  </div> */}
                </div>
              </div>

            </div>


          </div>
        </div>

      </div>
    </>

  )
}

export default ExpenseDetails