import React from "react";
import styles from "../styles/style.module.css";
import info from "../../../assets/images/info_icon.svg";
import Profileinfo from "../../Profileinfo/routes";
import Toltip from "../../common/toltip/page";
import tip from "../../../assets/images/tip.png";
import { CONSTANT_MANAGER } from "../../../utils/constans/constants";
const AddMissingInfo = () => {
  return (
    <div className={styles.myfriends}>
      <div className="container">
        <div className={styles.new_feeds}>
          <div className="">
            <div className="row align-items-center justify-content-between mb-3">
              <div className="col-lg-6 col-12">
                <div className="newheadingstyle">
                  <div className="topTipHover">
                    <div className="d-flex align-items-center">
                      <h4 className="form-check-label text-20 fw-bold  font-inter">
                        Add Missing Profile Informations
                      </h4>
                      <i>
                        <img className="customeTolImg" src={tip} alt="" />
                      </i>
                      <Toltip
                        slug={CONSTANT_MANAGER.SLUG_FEED.FEED}
                        labelId={
                          CONSTANT_MANAGER.FEED_DATA
                            .ADD_MISSING_PROFILE_INFORMATION
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <Profileinfo />
            </div>
            <></>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMissingInfo;
