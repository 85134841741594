import React, { useEffect, useState } from 'react'
import moment from 'moment'
import styles from "../../expense/styles/style.module.css"
import infogr from "../../../../assets/images/infogr.png"
import Header from '../../../header/routes'
import { useLocation, useParams } from 'react-router-dom'
import { getTimesheetById } from '../api'
import { GetTimesheetByIdResponse } from '../interface/getTimesheetById'
import { getMondayOfCurrentWeek, getWeekDaysMap } from '../../../../utils/daysMap'
import WhiteGloveContractDetails from '../../Contracts/whiteGloveHourlyContract/whileGloveHourlyContract'


const TimesheetStatus = () => {
    const { id } = useParams();
    const location = useLocation();
    const currentStatus = location?.state?.activeStatus
    const ongoingContractsDetails = location?.state?.currentOngoingData
    const [timeSheetStatusData, setTimesheetStatusData] = useState<GetTimesheetByIdResponse>()
    const [activeTab, setActiveTab] = useState(currentStatus);
    const [currentdata,setCurrentData]=useState<any>([])

    const approvedTimesheets = async () => {
        const response: any = await getTimesheetById(id);
        setTimesheetStatusData(response?.data);
    };

    useEffect(() => {
        approvedTimesheets();
        setCurrentData(ongoingContractsDetails)
        setActiveTab(currentStatus);
    }, [])

    const mondayDate = getMondayOfCurrentWeek();
    const daysMap = getWeekDaysMap();

    return (
        <>
            <Header />
            <div className={styles.pageOuter}>
                <div className='container'>


                    <div className={styles.rightContainer}>
                        <h2>Timesheet Details</h2>
                        <ul className={styles.timeSheetFilter}>

                            {['current', 'due', 'pending', 'approved'].includes(activeTab) && (
                                <li className={styles.active}>
                                    <span>
                                        {activeTab === 'current' && 'Current Timesheet'}
                                        {activeTab === 'due' && 'Due Timesheet'}
                                        {activeTab === 'pending' && 'Pending Timesheet'}
                                        {activeTab === 'approved' && 'Approved'}
                                    </span>
                                </li>
                            )}


                        </ul>
                        <div className={`${styles.baseInfo} ${styles.noMarg}`}>
                            <WhiteGloveContractDetails ongoingContractDetails={currentdata} />
                            <div
                                className={
                                    timeSheetStatusData?.status === "approved"
                                        ? styles.approved
                                        : styles.Starptimsshetet
                                }
                            >
                                <h3>
                                    {timeSheetStatusData?.status === "approved"
                                        ? "Approved"
                                        : timeSheetStatusData?.status === "pending"
                                            ? "Waiting For Approval"
                                            : timeSheetStatusData?.status === "draft"
                                                ? "Current"
                                                : timeSheetStatusData?.isDue === true
                                                    ? "Due"
                                                    : ""}
                                </h3>
                                <button>
                                    {timeSheetStatusData?.status === "approved"
                                        ? "Approved Timesheet"
                                        : timeSheetStatusData?.status === "pending"
                                            ? "Pending Timesheet"
                                            : timeSheetStatusData?.status === "draft"
                                                ? "Current Timesheet"
                                                : timeSheetStatusData?.isDue === true
                                                    ? "Due Timesheet"
                                                    : ""}
                                </button>
                            </div>

                            <div className={`${styles.timeSheetdetils} ${styles.noMarg}`}>
                                <div className={styles.timeSheetdetilshead}>
                                    <h4>Timesheet Details <img src={infogr} alt="" /></h4>

                                </div>
                                <div className={styles.personalDetials}>
                                    <div className={styles.topBrd}>
                                        <div className={styles.innerbrd}><b>Engineers :</b></div>
                                        <div className={styles.innerbrd}>{timeSheetStatusData?.user?.name}</div>
                                        <div className={styles.innerbrd}><b>Monday / Week Start Date</b></div>
                                    </div>
                                    <div className={styles.topBrd}>
                                        <div className={styles.innerbrd}><b>Customer Company Name</b></div>
                                        <div className={styles.innerbrd}>..</div>
                                        <div className={styles.innerbrd}>{mondayDate}</div>
                                    </div>
                                    <div className={styles.topBrd}>
                                        <div className={styles.innerbrd}><b>Customer # :</b></div>
                                        <div className={styles.innerbrd}>47110</div>
                                    </div>
                                    <div className={styles.topBrd}>
                                        <div className={styles.innerbrd}><b>Customer Contact : </b></div>
                                        <div className={styles.innerbrd}>{timeSheetStatusData?.work?.user?.name}</div>
                                        <div className={styles.innerbrd}><b>Customer Email : </b></div>
                                        <div className={styles.innerbrd}>{timeSheetStatusData?.work?.user?.email}</div>
                                    </div>
                                    <div className={styles.topBrd}>
                                        <div className={styles.innerbrd}><b>Work Number :  </b></div>
                                        <div className={styles.innerbrd}>#{timeSheetStatusData?.work?.workNumber}</div>
                                    </div>
                                    {/* <div className={styles.topBrd}>
                                        <div className={styles.innerbrd}><b>End Customer :   </b></div>
                                        <div className={styles.innerbrd}>#10179800</div>
                                    </div> */}
                                </div>

                                <div className={styles.personalDetials}>
                                    <div className={`${styles.Morefieldss} ${styles.topBrd}`}>
                                        <div className={styles.innerbrd}><b>Day</b></div>
                                        <div className={styles.innerbrd}><b>Date</b></div>
                                        <div className={styles.innerbrd}><b>Hours Worked Per Day</b></div>
                                        <div className={styles.innerbrd}><b>Sunday / Holidays</b></div>
                                        <div className={styles.innerbrd}><b>Travel Time Hours</b></div>
                                        <div className={styles.innerbrd}><b>Total</b></div>
                                    </div>

                                    {timeSheetStatusData?.timesheetDetails?.map((data, idx) => {
                                        return (
                                            <>
                                                <div key={idx} className={`${styles.topBrd} ${styles.Morefieldss}`}>

                                                    <div className={styles.innerbrd}><b></b></div>
                                                    <div className={styles.innerbrd}><b>{moment(data?.workedDate).format("DD-MM-YYYY")}</b></div>
                                                    <div className={styles.innerbrd}><b>{data?.hourPerDay}</b></div>
                                                    <div className={styles.innerbrd}>
                                                        <input
                                                            type="checkbox"
                                                            checked={data?.isHoliday}
                                                            readOnly
                                                        />
                                                    </div>

                                                    <div className={styles.innerbrd}><b>{data?.travelTime}</b></div>
                                                    <div className={styles.innerbrd}><b>{data?.total}</b></div>
                                                </div>
                                                <div className={`${styles.topBrd} ${styles.brdColor}`}>
                                                    <div className={styles.innerbrd}> Work Report</div>
                                                    <div className={styles.innerbrd}>{data?.dailyStatus}</div>
                                                </div>
                                            </>
                                        )
                                    })}




                                </div>

                                <div className={styles.billingDetails}>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className={styles.billingDeailLeft}>
                                                <ul>
                                                    <li><span><b>Total Straight Time</b></span> <label><b>{timeSheetStatusData?.totalStraightTime}</b></label></li>
                                                    <li><span>Total Over Time</span> <label>{timeSheetStatusData?.overTime}</label></li>
                                                    <li><span>Total Double TIme</span> <label>{timeSheetStatusData?.doubleTime}</label></li>
                                                    <li><span>Total Travel TIme</span> <label>{timeSheetStatusData?.totalTravelTime}</label></li>
                                                    <li><span>Total Hours</span> <label>{timeSheetStatusData?.totalHours}</label></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className={styles.billingDeailLeft}>
                                                <ul>
                                                    <li><span><b>Bill Rates</b></span> <label><b>{timeSheetStatusData?.ssaRates?.baseRate}</b></label></li>
                                                    <li><span>Base Rate (per hour)</span> <label>{timeSheetStatusData?.ssaRates?.baseRate}</label></li>
                                                    <li><span>OT Rate (per hour)</span> <label>{timeSheetStatusData?.ssaRates?.overTimeRate}</label></li>
                                                    <li><span>DT Rate (per hour)</span> <label>{timeSheetStatusData?.ssaRates?.doubleTimeRate}</label></li>
                                                    <li><span>Travel Time Rate</span> <label>{timeSheetStatusData?.totalTravelTime}</label></li>
                                                    <li><span>Travel Trips Allowed (per month)</span> <label>{timeSheetStatusData?.ssaRates?.travelTimeAllowed}</label></li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.addComment}>
                                    <p>Approved By : {timeSheetStatusData?.approvedBy?.name}</p>
                                    <p>
                                        Approved Date:{" "}
                                        {timeSheetStatusData?.approvedDate
                                            ? moment(timeSheetStatusData.approvedDate).format("MMMM D, YYYY")
                                            : "N/A"}
                                    </p>
                                    {timeSheetStatusData?.status === "pending" ?
                                        <p style={{ color: 'red' }}>Pending For Approval</p>
                                        : ""}

                                    <p>Approval Signature
                                        -------
                                    </p>
                                </div>
                            </div>


                        </div>


                    </div>
                </div>

            </div>
        </>

    )
}

export default TimesheetStatus