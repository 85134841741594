import React, { useState } from "react";
import styles from "../styles/style.module.css";
import Search from "../../search/routes";
import info from "../../../assets/images/info_icon.svg";
import DateTime from "../../Datetimefilter/routes";
import { getRecommendedWork } from "../api";
import { FEED } from "../../../utils/feed";
import { ReccommendedWork } from "../interface";
import Professionals from "../routes/Professionals/routes/hourlyContractList";
import { CONSTANT_MANAGER } from "../../../utils/constans/constants";
import Toltip from "../../common/toltip/page";
import tip from "../../../assets/images/tip.png";
const ProfessionalsComp = () => {
  const [contracts, setContracts] = useState<ReccommendedWork[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [rfq, setRfq] = useState<ReccommendedWork[]>([]);
  const [jobs, setJobs] = useState<ReccommendedWork[]>([]);

  const refresh = () => {
    setLoading(true);
    getRecommendedWork({ workType: FEED.WORK_TYPES.RFQ }).then((res) => {
      setRfq(res.data);
    });
    getRecommendedWork({ workType: FEED.WORK_TYPES.DIRECT_JOB }).then((res) => {
      setJobs(res.data);
    });
    getRecommendedWork({ workType: FEED.WORK_TYPES.HOURLY_CONTRACT }).then(
      (res) => {
        setContracts(res.data);
        setLoading(false);
      }
    );
  };
  return (
    <div className={styles.professionals}>
      <div className="container">
        <div className={styles.new_feeds}>
          <div className="">
            <div className="row align-items-center justify-content-between">
              <div className="col-6">
                <Search />
              </div>
            </div>
            <div className="row align-items-center justify-content-between mt-3 mb-3 g-3">
              <div className="col-lg-6 col-12">
                <div className="newheadingstyle">
                 
                  <div className="topTipHover">
                          <div className="d-flex align-items-center">
                            <h4 className="form-check-label text-20 fw-bold  font-inter">
                            Professionals
                            </h4>
                            <i>
                              <img className="customeTolImg" src={tip} alt="" />
                            </i>
                            <Toltip
                              slug={CONSTANT_MANAGER.SLUG_FEED.FEED}
                              labelId={
                                CONSTANT_MANAGER.FEED_DATA.PROFESSIONALS
                              }
                            />
                          </div>
                        </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="filter_sort">
                  <div className="ms-auto">
                    <div className="row align-items-center justify-content-end">
                      <div className="col-md-5">
                        <DateTime />
                      </div>

                      <div className="col-md-5">
                        <div className="d-flex align-items-center justify-content-end">
                          <span className="me-2">Sort:</span>
                          <div className="dropdown">
                            <button
                              className="dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Date and time
                            </button>
                            <ul className="dropdown-menu w-auto">
                              <li>
                                <a className="dropdown-item" href="#">
                                  Action
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  Another action
                                </a>
                              </li>
                              <li>
                                <a className="dropdown-item" href="#">
                                  Something else here
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Professionals
              workList={contracts}
              refresh={refresh}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfessionalsComp;
