import React, { FC } from 'react';
import { useAppSelector } from '../../../../redux/hooks';
import { RootState } from '../../../../redux/store';
import {
  AdDescription,
  AddPromotions,
  BoostAds,
  UploadImages,
} from './Components';
import { ADD_PAID_ADS } from '../../../../shared/enums/AddPaidAdsSteps';

const AddPaidAdds: FC = () => {
  const addPaidAds = useAppSelector((state: RootState) => state.paiAds);
  const { activeStep } = addPaidAds;

  const renderActiveStep = () => {
    switch (activeStep) {
      case ADD_PAID_ADS.AD_DESCRIPTION:
        return <AdDescription />;
      case ADD_PAID_ADS.UPLOAD_IMAGE:
        return <UploadImages />;
      case ADD_PAID_ADS.BOOST_ADD:
        return <BoostAds />;
      case ADD_PAID_ADS.ADD_PROMOTION:
        return <AddPromotions />;
      default:
        return <AdDescription />;
    }
  };

  return renderActiveStep();
};

export default AddPaidAdds;
