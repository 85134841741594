import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface AuthRootState {
  isAuthenticated: boolean;
  authToken?: string | null;
  userId?: string | null;
  deviceToken?: string | null;
  deviceType?: string | null;
}

const initialState: AuthRootState = {
  authToken: null,
  userId: null,
  isAuthenticated: false,
  deviceToken: null,
  deviceType: null,
};

const addContractSlice = createSlice({
  name: 'authenticationSlice',
  initialState,
  reducers: {
    authenticateUser: (
      state,
      action: PayloadAction<{
        userId: string;
        token: string;
        isLoading: boolean;
      }>
    ) => {
      state.isAuthenticated = true;
      state.userId = action.payload.userId;
      state.authToken = action.payload.token;
    },
    logoutUser: (state) => {
      state.authToken = null;
      state.userId = null;
      state.isAuthenticated = false;
    },
  },
});

export const { authenticateUser, logoutUser } = addContractSlice.actions;
export default addContractSlice.reducer;
