import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import * as Yup from "yup";

const userNames = Yup.object().shape({
  firstName: Yup.string()
    .required("Please enter your first name")
    .matches(/^[A-Za-z\s]+$/, "First name contains only alphabetic characters")
    .max(25)
    .test(
      "no-whitespace",
      "First name should not start or end with whitespace",
      (value) => {
        if (value) {
          return value === value.trim(); // Check if value is equal to its trimmed version
        }
        return true;
      }
    ),

  lastName: Yup.string()
    .required("Please enter your last name")
    .matches(/^[A-Za-z\s]+$/, "Last name contains only alphabetic characters")
    .max(25)
    .test(
      "no-whitespace",
      "Last name should not start or end with whitespace",
      (value) => {
        if (value) {
          return value === value.trim(); // Check if value is equal to its trimmed version
        }
        return true;
      }
    ),
});

const Contactschema = Yup.object().shape({
  phoneNumber: Yup.string()
    .required("Phone number is required!")
    .test("phone-number", function (value) {
      // if (value) {
      //   if (
      //     !isPossiblePhoneNumber(value as string) ||
      //     !isValidPhoneNumber(value as string)
      //   ) {
      //     return this.createError({
      //       message: "Invalid mobile number",
      //       path: this.path,
      //     });
      //   }
      //   return true;
      // }
      return true;
    }),
});

const verifyOTPSchema = Yup.object().shape({
  verificationCode: Yup.string().required("Required"),
});

const EmailSchema = Yup.object().shape({
  email: Yup.string().required("Please enter your email address").email(),
});

const PasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Please confirm your password"),
});

const userName = Yup.object().shape({
  userName: Yup.string().required("Please enter email or phone number"),
});

const passwordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
      "Password must contain at least 1 number, 1 uppercase letter, and 1 special character"
    )
    .required("Password is required"),
});
const occupationDropdownSchema = Yup.object().shape({
  selectedOccupation: Yup.array()
    .of(Yup.string())
    .required("Occupation is required"),
});

const dropdownValidationSchema = Yup.object().shape({
  selectedOption: Yup.string().required("Citizenship  is required"),
});
export {
  userNames,
  Contactschema,
  verifyOTPSchema,
  userName,
  EmailSchema,
  PasswordSchema,
  occupationDropdownSchema,
  dropdownValidationSchema,
  passwordSchema,
};
