import React, { useEffect, useState } from 'react'
import Header from '../../header/routes'
import info from "../../../assets/images/info.png"
import exportt from "../../../assets/images/exportt.png"
import { Link, useParams } from 'react-router-dom'
import styles from "../../common/timesheets/styles/styles/style.module.css"
import moment from 'moment'
import { changeExpenseStatus, getExpense } from './api'
import { TIMESHEET_OR_EXPENSE_TYPE } from '../../../utils/enums/timesheetOrExpenseStatusTypes'
import { toast } from 'react-toastify'
import Confirm from '../Contracts/OpenContracts/modals/Confirm'

const ExpenseTable = () => {
    const { id } = useParams();
    const [approvedExpenseData, setApprovedExpenseData] = useState<any>();
    const [pendingExpenseData, setPendingExpenseData] = useState<any>();
    const [loader, setLoader] = useState<boolean>(true);
    const [confirmModal, setConfirmModal] = useState<boolean>(false);
    const [selectedStatus, setSelectedStatus] = useState<string>("");
    const [selectedId, setSelectedId] = useState<string>("");

    const getApprovedData = async () => {
        const response = await getExpense(id, TIMESHEET_OR_EXPENSE_TYPE.APPROVED)
        setApprovedExpenseData(response?.data)
        setLoader(false);
    };
    const getPendingdata = async () => {
        const response = await getExpense(id, TIMESHEET_OR_EXPENSE_TYPE.PENDING)
        setPendingExpenseData(response?.data)
        setLoader(false);
    };
    useEffect(() => {
        getApprovedData();
        getPendingdata();
    }, [])

    const handleApproveExpense = (status: string, id: string) => {
        setConfirmModal(true);
        setSelectedStatus(status)
        setSelectedId(id)
    }
    const handleRejectExpense = (status: string, id: string) => {
        setConfirmModal(true);
        setSelectedStatus(status);
        setSelectedId(id)
    }
    const handleClose = () => {
        setConfirmModal(false);
    };
    const changeStatus = () => {
        const payload = {
            selectedId: selectedId,
            selectedStatus: selectedStatus
        }
        toast.promise(
            changeExpenseStatus(payload.selectedId, payload.selectedStatus),
            {
                pending: {
                    render() {
                        return "Changing Status";
                    },
                },
                success: {
                    render({ }) {
                        handleClose();
                        getApprovedData();
                        getPendingdata();
                        return "Status Changed Successfully";
                    },
                },
                error: {
                    render(error) {
                        return "Status Change Failed";
                    },
                },
            }
        );
    };
    return (
        <>
            <Header />
            <div className={styles.TimeShettTable}>
                <Confirm
                    action="Select"
                    handleClose={handleClose}
                    showModal={confirmModal}
                    changeStatus={changeStatus}
                    status={selectedStatus}
                />
                <div className='container'>
                    <h3>Expenses</h3>
                    {loader ? <div className="spinner-grow text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div> :
                        <>
                            <div className={styles.TimeShettTableOuter}>
                                <div className={styles.TimeShettTableHead}>
                                    <h4>Expense Approved <img src={info} alt="" /></h4>
                                    <button> <img src={exportt} alt="" />Export Data</button>
                                </div>
                                <table>
                                    <tr>
                                        <th>WR#</th>
                                        <th>PROFESSIONAL NAME</th>
                                        <th>LOCATION</th>
                                        <th>START DATE</th>
                                        <th>END DATE</th>
                                        <th>APPROVED DATE</th>
                                        <th>TOTAL AMOUNT</th>
                                        <th>STATUS</th>
                                        <th>ACTIONS</th>
                                    </tr>

                                    <tr>
                                        {approvedExpenseData?.length > 0 ? approvedExpenseData?.map((data: any, idx: number) => {
                                            return (
                                                <>
                                                    <td key={idx}>#{data?.work?.workNumber}</td>
                                                    <td>{data?.user?.name}</td>
                                                    <td>{data?.work?.workNumber}</td>
                                                    <td>{moment(data.startDate).format("MMM DD, YYYY")}</td>
                                                    <td >{moment(data.endDate).format("MMM DD, YYYY")}</td>

                                                    <td >{moment(data.approvedDate).format("MMM DD, YYYY")}</td>
                                                    <td>${data?.total}</td>
                                                    <td><span className={styles.approvedf}>
                                                        {data.status}
                                                    </span></td>
                                                    <td><Link to={`/expense-detail/${data?._id}`}>View Details</Link></td>
                                                </>
                                            )
                                        }) : <p>No Expenses Found</p>}

                                    </tr>

                                </table>
                            </div>
                            <div className={styles.TimeShettTableOuter}>
                                <div className={styles.TimeShettTableHead}>
                                    <h4>Expense Pending for approval <img src={info} alt="" /></h4>
                                    <button> <img src={exportt} alt="" />Export Data</button>
                                </div>
                                <table>
                                    <tr>
                                        <th>WR#</th>
                                        <th>PROFESSIONAL NAME</th>
                                        <th>LOCATION</th>
                                        <th>START DATE</th>
                                        <th>END DATE</th>
                                        <th>APPROVED DATE</th>
                                        <th>TOTAL AMOUNT</th>
                                        <th>Status</th>
                                        <th>View Details</th>
                                        <th>ACTIONS</th>
                                    </tr>

                                    <tr>
                                        {pendingExpenseData?.length > 0 ? pendingExpenseData?.map((data: any, idx: number) => {
                                            return (
                                                <>
                                                    <td key={idx}>#{data?.work?.workNumber}</td>
                                                    <td>{data?.user?.name}</td>
                                                    <td>{data?.work?.workNumber}</td>
                                                    <td>{moment(data.startDate).format("MMM DD, YYYY")}</td>
                                                    <td>{moment(data.endDate).format("MMM DD, YYYY")}</td>

                                                    <td >{moment(data.approvedDate).format("MMM DD, YYYY")}</td>
                                                    <td>${data?.total}</td>
                                                    <td>
                                                        {data?.status === "pending" ? (
                                                            <span>Pending</span>
                                                        ) : data?.status === "approved" ? (
                                                            <span className={styles.approvedf}>
                                                                Approved
                                                            </span>
                                                        ) : data?.status === "rejected" ? (
                                                            <span className={styles.Decloine}>
                                                                Declined
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </td>
                                                    <td><Link to={`/expense-report/${data?._id}`}>View Details</Link></td>
                                                    <td>
                                                        <button onClick={() => handleApproveExpense(TIMESHEET_OR_EXPENSE_TYPE.APPROVED, data._id)} > Approve Timesheet </button>
                                                        <button onClick={() => handleRejectExpense(TIMESHEET_OR_EXPENSE_TYPE.REJECT, data._id)} > Reject Timesheet </button>
                                                    </td>
                                                </>
                                            )
                                        }) : <p>No Expenses Found</p>}
                                    </tr>

                                </table>
                            </div>
                        </>
                    }

                </div>
            </div>
        </>

    )
}

export default ExpenseTable