import React, { FC, useState } from "react";
import styles from "../styles/style.module.css";
import CustomModal from "../../../../../shared/CustomModal/customModal";
import { WORK_STATUS, WORK_STATUS_TYPE } from "../../../../../utils/enums/status";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import { PLAN_TYPE } from "../../../../../utils/enums/types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { initContract, setPlanType } from "../../../../../redux/reducers/addContract";
import Swal from 'sweetalert2';
import storage from "../../../../../utils/storage";
import { AddContractRootState } from "../../../../../models";
import { saveContract, updateContract } from "../../../api";
import { APIResponseError } from "../../../interface";
import { SaveAsDraftAuthErrorDialog } from "../../Components";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../../../../utils/routes/AppRoutes";
import Post from "../../../../../shared/CustomModal/PostTypeConfirmationModal";

interface Props {
  onClosePlanModal: () => void;
}
const Subscription: FC<Props> = ({ onClosePlanModal }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { Plantype } = useSelector((state: RootState) => state.addContract);
  const contractStatus = WORK_STATUS_TYPE.PENDING;
  const token = storage.getToken();
  const { authentication } = useSelector((state: RootState) => state);
  const { isAuthenticated } = authentication;
  const addContract = useSelector((state: RootState) => state.addContract);
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });

  };

  const handlePublishContract = (value: PLAN_TYPE) => {
    dispatch(setPlanType(value));
  };


  const publishContract = async () => {
    const savedContractId = addContract._id;
    const payload = {
      ...new AddContractRootState({
        ...addContract,
        contractStatus,
      }).serialize(),
    };
    if (savedContractId && savedContractId !== '') {
      try {
        const response = await updateContract(payload, savedContractId);
          Swal.fire({
            title: 'Contract updated successfully!',
            text: 'Your contract has been updated successfully!',
            icon: 'success',
            confirmButtonText: 'View Dashboard',
            cancelButtonText: 'Continue',
            showCancelButton: true,
            customClass: {
              confirmButton: 'swal-custom-button', // Custom class for confirm button
              cancelButton: 'swal-cancel-button', // Custom class for cancel button
            },
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              });
              navigate(APP_ROUTES.HOME);
            }
          });
        
      } catch (error) {
        const errorMessage = error as APIResponseError;
        Swal.fire({
          title: 'Error',
          text: errorMessage?.data?.data?.message || 'An error occurred.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
  }
  else{
    try {
      await saveContract(payload);
      onClosePlanModal()
      Swal.fire({
        title: 'Congratulations!',
        text: 'Contract Sent for approval successfully!',
        icon: 'success',
        confirmButtonText: 'View Dashboard',
      }).then((result) => {
        if (result.isConfirmed) {
          storage.clearSavedContract();
          dispatch(initContract(new AddContractRootState()));
          navigate(APP_ROUTES.HOME)
        }
      });
    } catch (error) {
      const errorMessage = error as APIResponseError;
      Swal.fire({
        title: 'Error',
        text: errorMessage?.data?.data?.message || 'An error occurred.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  }
  };

  const handleSubmitContract = async () => {
    if (!token && !isAuthenticated) {
      setShowErrorDialog(true);
      return;
    } else if (Plantype === PLAN_TYPE.STANDARD) {
      setModalDetail({
        show: true,
        title: "",
        flag: "post",
      });
    } else {
      await publishContract();
    }
  };

  const handlePublish = () => {
    Swal.fire({
      title: "Are you sure, you want to send contract for approval?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Publish",
    }).then((result) => {
      // Run handleSubmitContract only when the "Save" button is clicked
      if (result.isConfirmed) {
        handleSubmitContract();
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
      // No need to do anything on cancel, as the modal will just close
    });
  };

  const onClose = () => {
    onClosePlanModal()
  };
  return (
    <div className={styles.Subacription} style={{ padding: "40px 0 0" }}>
      <SaveAsDraftAuthErrorDialog
        show={showErrorDialog}
        onHide={() => setShowErrorDialog(false)}
      />
      <div className="container">
        <div className={styles.PricingConatiner}>
          <div className="row align-items-start g-3">
            <div className="col-md-6">
              <div
                className={`${styles.innerSubscription} ${Plantype === PLAN_TYPE.WHITEGLOVE ? styles.GradBorder : ''}`}
              >
                <h4>White Glove</h4>
                <h6>
                  For Customers Who Want Automate America to bill them and pay
                  the professionals
                </h6>
                <span
                  onClick={() => handlePublishContract(PLAN_TYPE.WHITEGLOVE)}
                  style={{ cursor: "pointer" }}
                >
                  Publish with White Glove{" "}
                </span>

                <ul>
                  <li>
                    <label>Dedicated Account Management:</label>
                    <p>
                      Receive personalized assistance from an account manager
                      through phone, SMS, email, and in-app messaging.
                    </p>
                  </li>
                  <li>
                    <label>
                      Billing and Payment Services: Invoicing by Automate
                      America :
                    </label>
                    <p>
                      You do business with Automate America, not individuals and
                      numerous service companies.
                    </p>
                  </li>

                  <li>
                    <label>Flexible Contractor Payments:</label>
                    <p>
                      Contractors or Service Companies are paid by Automate
                      America, not you. We offer applicants to be paid whenever
                      they choose.
                    </p>
                  </li>
                  <li>
                    <label>Efficient Supplier and Contractor Connection:</label>
                    <p>
                      Customers gain access to thousands of contractors and
                      service company employee without the need to search for
                      suppliers, all within Automate America&apos;strusted
                      system.
                    </p>
                  </li>
                  <li>
                    <label>Contract Broadcasting:</label>
                    <p>
                      Automate America broadcasts your contract to thousands of
                      qualified engineers, who can then apply immediately.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className={`${styles.innerSubscription} ${Plantype === PLAN_TYPE.STANDARD ? styles.GradBorder : ''}`}
              >
                <h4>Standard</h4>
                <h6>
                  The basics for individuals <br />
                  and organisations
                </h6>
                <span
                  onClick={() => handlePublishContract(PLAN_TYPE.STANDARD)}
                  style={{ cursor: "pointer" }}
                >
                  Publish with Standrad{" "}
                </span>
                <ul>
                  <li>
                    <label>Posting contracts</label>
                    <p>
                      Users can manage applicants by shortlisting, hiring, and
                      rejecting candidates.
                    </p>
                  </li>
                  <li>
                    <label>Communication Features: In-App Communication:</label>
                    <p>
                      The platform offers in-app messaging and video interviews
                      for streamlined communication.
                    </p>
                  </li>

                  <li>
                    <label>Direct Billing:</label>
                    <p>
                      You will be billed directly by the contractor or service
                      companybperforming the work, not by Automate America like
                      the White Glove Option.
                    </p>
                  </li>
                  <li>
                    <label>Payments:</label>
                    <p>
                      You pay the contractor or service company directly for
                      services, not through Automate America like the White
                      Glove Option.
                    </p>
                  </li>
                  <li>
                    <label>Usage Restrictions:</label>
                    <p>
                      Users will not have access to Automate America&apos;s in
                      app timesheets, nor expense reports, or the automated
                      invoicing, and purchase order management tools available
                      to the White Glove Option.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="row mx-0">
        <div className="col-6 my-3 btn-hover-effect">
          <h6
            onClick={onClose}
            className="btn bg-white arrowyellow border-white border-1 rounded-pill position-relative py-lg-3 py-2 ps-5 backbutton"
          >
            <i className="bg-yellow rounded-pill arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
              <img src={blackarrow} alt="" />
            </i>
            <span className="px-lg-4 px-2 text-12 fw-semibold">Not Now</span>

          </h6>
        </div>
        <div className="col-6 text-end my-3 btn-hover-effect">
          <h6
            onClick={handlePublish}
            className="btn bg-white arrowblue border-white border-1 rounded-pill position-relative py-lg-3 py-2 pe-5"
          >
            <span className="px-lg-4 px-2 text-12 fw-semibold">Publish</span>
            <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
              <img src={whitearrow} alt="" />
            </i>
          </h6>
        </div>
      </div>
      {modalDetail.show && (
        <CustomModal
          show={modalDetail.show}
          backdrop="static"
          showCloseBtn={true}
          isRightSideModal={false}
          mediumWidth={false}
          className={modalDetail.flag === "post" ? "post" : ""}
          child={
            modalDetail.flag === "post" ? (
              <Post onCloseModal={() => handleOnCloseModal()}
              publishContract={() => publishContract()}
            />
            ) : null // Render nothing if none of the conditions match
          }
          onCloseModal={() => handleOnCloseModal()}
        />
      )}
    </div>
  );
};

export default Subscription;
