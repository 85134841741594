export const AUTH_ROUTES = {
  //registration routes
  PERSONAL_INFO: "/personal-info",
  SEND_OTP: "/send-otp",
  PHONE_VERIFY: "/phone-verify",
  OTP_VERIFY: "/otp-verify",
  OCCUPATION: "/occupation",
  EMAIL_VERIFY: "/email-verify",
  PASSWORD: "/password",
  CITIZENSHIP: "/citizenship",
  ABOUT_US: "/about-us",
  PRIVACY_POLICY:'/privacy-policy',

  //Login routes
  LOGIN: "/login",
  LOGIN_VERIFICATIONS: "/login/verify-otp",
  FORGOT_PASSWORD: "/forgot-password",
  OTP_VERIFICATION: "/otp-verification",
  RECOVER_PASSWORD: "/recover-password",
};
