import React, { FC, PropsWithChildren } from 'react';
import { Collapse } from 'react-bootstrap';
import styles from '../../../reviewcontract/styles/style.module.css';
import editicon from '../../../../../assets/images/edit_icon.svg';
import arrownext from '../../../../../assets/images/whitearrow.svg';

interface Props extends PropsWithChildren {
  //name: ADD_CONTRACT_STEPS;
  name: string;
  isActive: boolean;
  toggle: (item: string) => void;
  toggleEditing: () => void;
}

const OverviewCollapseItem: FC<Props> = ({
  name,
  children,
  isActive,
  toggle,
  toggleEditing,
}) => {
  return (
    <li className="position-relative">
      <div className={styles.headingstyle} onClick={() => toggle(name)}>
        <div className="row align-items-center justify-content-center">
          <div className="col-12" style={{ height: 'auto' }}>
            <h3 className="mb-0">{name}</h3>
          </div>
          {/* <div className="col-1 text-end">
            <i>
              <img src={arrownext} alt="" />
            </i>
          </div> */}
        </div>
      </div>
      <div className={styles.headingdes}>
        <div className="row align-items-center justify-content-center">
          <div>
            <div className="col-12" style={{ height: 'auto' }}>
              {/* <div className="col-1 text-end" onClick={toggleEditing} style={{ position: "absolute", top: "10px", right: "30px" }}>
                <i>
                  <img src={editicon} alt="" />
                </i>
              </div> */}
              {children}
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default OverviewCollapseItem;
