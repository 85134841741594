import React, { useEffect, useState } from "react";
import styles from "../../styles/styles.module.css";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import storage from "../../../../../utils/storage";
import { RootState } from "../../../../../redux/store";
import {
  setActiveStep,
  setSoftwareLicenseDetails,
} from "../../../../../redux/reducers/addContract";
import { ADD_CONTRACT_STEPS } from "../../../../../shared/enums";
import { getMasterTypes } from "../../../../../feature/auth/signup/api";
import { MASTER_TYPE } from "../../../../../utils/enums/masterTypes.enum";
import { toast } from "react-toastify";
import { postMasterType } from "../../../api";
import { MESSAGES } from "../../../../../utils/messages";
import { APIResponseError } from "../../../interface";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import Select from "react-select";
import Occupations from "../../../../../assets/images/occupations.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";

const SoftWareLicenses: React.FC = () => {
  const dispatch = useDispatch();
  const [text, setText] = useState<string>("");
  const { softwareLicenseDetails } = useSelector(
    (state: RootState) => state.addContract
  );
  const { softwareLicenses, options, isRequired } = softwareLicenseDetails;

  const formIk: any = useFormik({
    initialValues: {
      softwareLicenses,
    },
    onSubmit: () => {
      dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_SECURITY_REQUIREMENT));
    },
  });

  useEffect(() => {
    getSoftwareLicense();
  }, []);

  const getSoftwareLicense = async () => {
    const response = await getMasterTypes(MASTER_TYPE.SOFTWARE_LICENCES);
    response.data.forEach((x) => {
      x.label = x.name;
      x.value = x._id;
    });

    dispatch(
      setSoftwareLicenseDetails({
        ...softwareLicenseDetails,
        options: response.data,
      })
    );
  };

  const onInputChange = (value: any, key: string) => {
    dispatch(
      setSoftwareLicenseDetails({
        ...softwareLicenseDetails,
        [key]: value,
      })
    );
  };

  const handleGoBack = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_INTERVIEW_QUESTIONS));
  };

  const handleNext = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_SECURITY_REQUIREMENT));
  };

  // const handleSubmitCustomOccupation = async () => {
  //   const { softwareLicenses } = softwareLicenseDetails;

  //   const payload = {
  //     type: MASTER_TYPE.OCCUPATIONS,
  //     name: text,
  //     alias: text,
  //   };

  //   toast.promise(postMasterType(payload), {
  //     pending: {
  //       render() {
  //         return MESSAGES.POSTING_CONTRACTS.VERIFYING;
  //       },
  //     },
  //     success: {
  //       render(newOccupation) {
  //         const {
  //           data: { data },
  //         } = newOccupation;

  //         dispatch(
  //           setSoftwareLicenseDetails({
  //             ...softwareLicenseDetails,
  //             softwareLicenses: [
  //               ...softwareLicenses,
  //               { label: data.name, value: data._id },
  //             ],
  //           })
  //         );

  //         setText("");
  //         return MESSAGES.POSTING_CONTRACTS.ADDING;
  //       },
  //     },
  //     error: {
  //       render(error) {
  //         let errorMesage = error as APIResponseError;
  //         return errorMesage?.data?.data?.message;
  //       },
  //     },
  //   });
  // };

  return (
    <>
      <HeaderContent />
      <div className="py-4 heightautoscroll">
      <div className={styles.signuppage}>
        <div className="contractpages">
          
            <form onSubmit={formIk.handleSubmit}>
              <div className={styles.registerOuter}>
                <div className="container">
                  <div className={styles.registerBord}>
                    <div className="row">
                      <div className="col-md-6">
                      <div className={styles.registerLeft}>
                              <h4 className="text-black text-38 fw-semibold">
                                Software Linenses Required
                              </h4>
                              <p className="text-16 text-white opacity-75">
                                You can provide all the information here
                                regarding the contract; you just need to fill in
                                all the details here.
                              </p>
                           
                        <div className="col-lg-11 col-12"
                          
                        >
                          {/* <p>Add a Legal information of the work that you need</p> */}
                          <figure>
                            <img
                              src={Occupations}
                              alt=""
                              className="img-fluid"
                            />
                          </figure>
                        </div>
                      </div>
                      </div>
                      <div className="col-md-6">
                        <div className={styles.signUPContainerx}>
                          <div className={styles.registerRight}>
                            
                            <div className={styles.fromGroup}>
                              <label>
                                Choose Software Licenses
                              </label>
                              <Select
                                isMulti
                                name="softwareLicenses"
                                options={options}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(val) =>
                                  onInputChange(val, "softwareLicenses")
                                }
                                value={softwareLicenses}
                              />

                              {/* <div
                                className={styles.addOccuatin}
                                style={{
                                  marginTop: "15px",
                                  marginBottom: "15px",
                                }}
                              >
                                <input
                                  type="text"
                                  placeholder="Other..."
                                  name="otherSoftwareLicance"
                                  value={text}
                                  className="form-control rounded-pill"
                                  onChange={(e) => setText(e.target.value)}
                                />

                           
                                <button
                                  type="button"
                                  className="btn bg-white arrowblue border-white border-1 rounded-pill position-relative py-3 px-5 mt-3"
                                  onClick={handleSubmitCustomOccupation}
                                >
                                  Add +
                                </button>
                              </div> */}
                            </div>
                            <div className={styles.fromGroup}>
                              <ul
                                className={`${styles.radioBTn} ${styles.radStepOne}`}
                                style={{ flexDirection: "column" }}
                              >
                                <li className="" style={{background: "transparent"}}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name={`isRequired$`}
                                      id={`isRequired1$`}
                                      checked={isRequired}
                                      onChange={() =>
                                        onInputChange(true, "isRequired")
                                      }
                                    />
                                    <label className="form-check-label">
                                      Mandatory
                                    </label>
                                  </div>
                                </li>
                                <li style={{background: "transparent"}}>
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name={`flexRadioDefault2$`}
                                      id={`flexRadioDefault2$`}
                                      checked={!isRequired}
                                      onChange={() =>
                                        onInputChange(false, "isRequired")
                                      }
                                    />
                                    <label className="form-check-label">
                                      Good to have
                                    </label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="row align-items-center justify-content-between">
                        <div className="col-4 text-start my-3 btn-hover-effect">
                          <h6
                            onClick={() => handleGoBack()}
                            className="btn bg-white arrowyellow border-white border-1 rounded-pill position-relative py-lg-3 py-2 ps-5 backbutton"
                          ><i className="bg-yellow rounded-pill arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                              <img src={blackarrow} alt="" />
                            </i>
                            <span className="px-lg-4 px-2 text-12 fw-semibold">
                              Back
                            </span>
                            
                          </h6>
                        </div>
                        <div className="col-8">
                          <div
                            className="d-flex align-items-center jusify-content-end"
                           
                          >
                            <div className="col-6">
                              {/* <SaveAsDraftButton /> */}
                            </div>
                            <div className="col-6 text-end my-3 btn-hover-effect">
                              <h6
                                onClick={handleNext}
                                className="btn bg-white arrowblue border-white border-1 rounded-pill position-relative py-lg-3 py-2 pe-5"
                              >
                                <span className="px-lg-4 px-2 text-12 fw-semibold">
                                  Next
                                </span>
                                <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                                  <img src={whitearrow} alt="" />
                                </i>
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      </div>
                      
                    </div>

                    {/* <div className={styles.regisFooter}>
                  <div className={styles.bkBTn} onClick={handleGoBack}>
                    <span>‹ Back</span>
                  </div>
                  <div className={styles.DraftBtns}>
                    <div className={styles.draftBTn}>
                      <button>Skip</button>
                    </div>

                    <div className={styles.bkBTn} onClick={handleNext}>
                      <button type="submit">Next ›</button>
                    </div>
                  </div>
                </div> */}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SoftWareLicenses;
