import React from "react";
import styles from "../styles/style.module.css";
import arrowwithoutcircle_blue from "../../../assets/images/arrow_withoutcircle_blue.svg";

const CurrentData = () => {
  return (
    <div className={styles.automateamerica}>
      <div className="container mb-3">
        <div className={styles.automateamericablock}>
          <div className="row align-items-center g-4 justify-content-between mt-3 mx-0 flex-row-reverse py-lg-5 py-3 g-3">
            <div className="col-lg-3 col-6 text-center text-white">
              <h3 className="text-white text-22">200K +</h3>
              <h5 className="text-16">Active Users</h5>
              <button className="btn btn-white text-blue text-14">
                View All{" "}
                <i className="ps-2">
                  <img
                    src={arrowwithoutcircle_blue}
                    alt=""
                    width={10}
                    height={10}
                  />
                </i>
              </button>
            </div>
            <div className="col-lg-3 col-6 text-center text-white">
              <h3 className="text-white text-22">200K +</h3>
              <h5 className="text-16">Active Users</h5>
              <button className="btn btn-white text-blue text-14">
                View All{" "}
                <i className="ps-2">
                  <img
                    src={arrowwithoutcircle_blue}
                    alt=""
                    width={10}
                    height={10}
                  />
                </i>
              </button>
            </div>
            <div className="col-lg-3 col-6 text-center text-white">
              <h3 className="text-white text-22">200K +</h3>
              <h5 className="text-16">Active Users</h5>
              <button className="btn btn-white text-blue text-14">
                View All{" "}
                <i className="ps-2">
                  <img
                    src={arrowwithoutcircle_blue}
                    alt=""
                    width={10}
                    height={10}
                  />
                </i>
              </button>
            </div>
            <div className="col-lg-3 col-6 text-center text-white">
              <h3 className="text-white text-22">200K +</h3>
              <h5 className="text-16">Active Users</h5>
              <button className="btn btn-white text-blue text-14">
                View All{" "}
                <i className="ps-2">
                  <img
                    src={arrowwithoutcircle_blue}
                    alt=""
                    width={10}
                    height={10}
                  />
                </i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentData;
