import { axios } from '../../../../lib/axios';
import { AxiosResponse } from '../../../../shared/models';
import { API_ROUTES } from '../../../../utils/routes/apiRoutes';
import { verifyOTPResponse } from '../../signup/interface';
import {
  ForgetPasswordResponse,
  ForgotPasswordPayload,
  LoginResponse,
  LoginUserPayload,
  ResetPasswordResponse,
  VerifyOtpForLoginSuccessResponse,
  VerifyOtpForgetPassword,
  resetPasswordPayload,
} from '../interface';

export const login = (payload: LoginUserPayload): Promise<LoginResponse> => {
  return axios.post(API_ROUTES.AUTH.LOGIN, payload);
};
export const logout = (): Promise<any> => {
  return axios.post(API_ROUTES.AUTH.LOGOUT_USER);
};

export const forgotPassword = (
  payload: ForgotPasswordPayload
): Promise<ForgetPasswordResponse> => {
  return axios.post(API_ROUTES.AUTH.FORGOT_PASSWORD, payload);
};

export const resetPassword = (
  payload: resetPasswordPayload
): Promise<ResetPasswordResponse> => {
  return axios.post(API_ROUTES.AUTH.RESET_PASSWORD, payload);
};
export const verifyOTP = (
  payload: VerifyOtpForgetPassword
): Promise<verifyOTPResponse> => {
  return axios.put(API_ROUTES.AUTH.VERIFY_OTP, payload);
};

export const verifyOTPForLogin = (otp: string,userName: string,token: string): Promise<AxiosResponse<VerifyOtpForLoginSuccessResponse>> => {
  return axios.put(API_ROUTES.AUTH.VERIFY_OTP, {
    verificationToken: token,
    verificationCode: otp,
    type: 'login',
    userName: userName,
  });
};


export const resendOtpLogin = (userName: string,verificationToken: string): Promise<AxiosResponse<any>> => {
  return axios.post(API_ROUTES.AUTH.RESEND_OTP_LOGIN, {
    verificationToken: verificationToken,
    type: 'login',
    userName: userName,
  });
};