import React, { useEffect, useState } from "react";
import styles from "../styles/sigup.module.css";
import { useNavigate } from "react-router-dom";
import logo from "../../../../assets/images/logo.png";
import left from "../../../../assets/images/left.png";
import down from "../../../../assets/images/down.png";
import { useFormik } from "formik";
import { occupationDropdownSchema } from "../validations";
import { useDispatch, useSelector } from "react-redux";
import { setOccupation } from "../../../../redux/reducers/auth";
import { AUTH_ROUTES } from "../../../../utils/routes/AuthRoutes";
import { getMasterTypes } from "../api";
import { Dropdown, FormControl } from "react-bootstrap";
import { MASTER_TYPE } from "../../../../utils/enums/masterTypes.enum";
import HeaderContent from "../../../../shared/Components/HeaderContent/headerContent";
import whitearrow from '../../../../assets/images/whitearrow.svg';


const Occupation: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [occupationList, setOccupationList] = useState([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const userDetail = useSelector((state: any) => state.userData);

  const formik = useFormik({
    initialValues: {
      selectedOccupation: [],
      selectedOccupationName: "",
    },
    validationSchema: occupationDropdownSchema,

    onSubmit: (values) => {
      const occupationData: any = {
        occupation: values.selectedOccupation,
      };

      dispatch(setOccupation(occupationData));
      navigate(AUTH_ROUTES.CITIZENSHIP);
    },
  });


  const getoccupationData = async () => {
    const response: any = await getMasterTypes(MASTER_TYPE.OCCUPATIONS);
    setOccupationList(response?.data);
  };


  useEffect(() => {
    getoccupationData();
  }, []);

  const handleSearch = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const filteredOptions = (occupationList ?? []).filter(
    (option: { name: string }) =>
      option?.name.toLowerCase().includes(searchTerm.toLowerCase())

  )
  const handleDropdownSelect = (option: { _id: string; name: string }) => {
    const updatedSelectedOccupation = [
      ...formik.values.selectedOccupation,
      option._id,
    ];
    formik.setFieldValue("selectedOccupation", updatedSelectedOccupation);
    formik.setFieldValue("selectedOccupationName", option.name);
  };

  return (
    <>
      <HeaderContent />
      <div className="py-4 heightautoscroll ">
      <div className={styles.occupationdetails}>
        <div className={styles.signuppage}>
          <div className={styles.signUPContainer} style={{ padding: "15px" }}>
            <form onSubmit={formik.handleSubmit}>
              <h5> Primary Occupation</h5>
              <span className={styles.Leftntm}>
                <a href="#">
                  <img src={left} alt="" onClick={() => navigate(-1)} />
                </a>
              </span>
              <p>Select occupation</p>
              <div className={styles.fromGroup}>

                <Dropdown>
                  <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    {formik.values.selectedOccupationName
                      ? formik.values.selectedOccupationName
                      : "Please select an option"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                  <div className={styles.searchinputteam}>
                  <FormControl
                  autoFocus
                  className="position-absolute left-0 right-0"
                  placeholder="Search..."
                  style={{ width: "calc(100% - 30px) !important;"}}
                  onChange={handleSearch}
                  value={searchTerm}
                />
                </div>
                <div className={styles.droptopsearch}>
                    {filteredOptions.length === 0 && (
                      <Dropdown.Item disabled>No options found</Dropdown.Item>
                    )}
                    {[{ "name": "Select primary occupation", "_id": "" }, ...filteredOptions]?.map((option: any, index: number) => (
                      <Dropdown.Item
                        key={index}
                        onClick={() => handleDropdownSelect(option)}
                      >
                        {option.name}
                      </Dropdown.Item>
                    ))}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>

                {formik.touched.selectedOccupation &&
                  formik.errors.selectedOccupation && (
                    <div className={styles.error}>
                      {formik.errors.selectedOccupation}
                    </div>
                  )}
                {/* <span><img src={down} alt="" /></span> */}
              </div>

              {/* <div className={styles.fromGroup}>
            <input type="submit" value="Next ›" />
          </div> */}
              <div className="col-lg-3 col-6 mx-auto">
                <div className="text-center my-3 btn-hover-effect border rounded-pill">
                  {/* <input type="submit"/> */}
                  <button
                    type="submit"
                    className="btn bg-white arrowblue border-white border-1 rounded-pill position-relative py-lg-3 py-2 w-100 pe-5 text-center"
                  >
                    <span className="px-lg-4 px-2 text-12 fw-semibold w-100">Next</span>
                    <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                      <img src={whitearrow} alt="" />
                    </i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      </div>
    </>

  );
};
export default Occupation;
