import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer, CalendarProps } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import pro from "../../assets/images/pro.png";
import loc from "../../assets/images/loc.png";
import rdinfo from "../../assets/images/rdinfo.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import "./calendar.module.style.css";
import styles from "../../components/common/css/styles/style.module.css";
import AddEvent from "./AddEvent";
import { getEvents } from "./api";
import { Link } from "react-router-dom";
import GetAllTaskCalander from "./components/allTaskCalander";
import { TYPE } from "../../utils/enums/types";

const localizer = momentLocalizer(moment);

const AddCalendar = () => {
  const [allEvents, setAllEvents] = useState<{ title: string; start: Date; end?: Date }[]>([]);
  const [getAllEventData, setAllEventData] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [view, setView] = useState<string>("month");
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [allTask, setAllTask] = useState<string>('allTask');
  const [upcomingTask, setUpcomingTask] = useState<any>([]);
  const [completedTask, setCompletedTask] = useState<any>([]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleViewChange = (newView: string) => {
    setView(newView);
  };

  const handleNavigate = (date: Date, view: string) => {
    setCurrentDate(date);
    setView(view);
  };

  const fetchEvents = async () => {
    try {
      const [calanderData, AllTasks] = await Promise.all([getEvents(TYPE.MONTH), getEvents(TYPE.ALL)]);
      if (calanderData?.data) {
        const formattedEvents = calanderData?.data?.map((event: any) => ({
          title: event?.title,
          start: moment.utc(event?.eventDate).local().toDate(),
          end: moment.utc(event?.eventDate).local().toDate(),
        }));
        setAllEvents(formattedEvents);
      }
      if (AllTasks?.data?.length) setAllEventData(AllTasks?.data);
    } catch (err) {
      setError("Failed to fetch events");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => { fetchEvents(); }, []);

  const handleAddEvents = (event: { title: string; start: Date }) => {
    const end = moment(event.start).add(1, "hour").toDate();
    setAllEvents([...allEvents, { ...event, end }]);
  };

  const handelTaskCalenderData = async (string: string) => {
    console.log(`🚀 JISHH 🤦‍♂️😒 --  ~  file: AddCalendar.tsx:78 ~  handelTaskCalenderData ~  string:`, string);
    setAllTask(string);
    if (string === "upcoming") {
      const data = await getEvents(TYPE.UPCOMING)
      setUpcomingTask(data?.data);
    } else if (string === "completed") {
      const data = await getEvents(TYPE.COMPLETED)
      setCompletedTask(data?.data);
    }
  }

  const eventStyleGetter = (event: any) => {
    let style: React.CSSProperties = {};
    switch (event.title) {
      case "New Meeting":
        style.backgroundColor = "#2880DA";
        break;
      case "Office Meeting":
        style.backgroundColor = "#FFD800";
        break;
      case "Timesheet Due":
        style.backgroundColor = "#5CDD42";
        break;
      case "Clients Meeting/Dealing":
        style.backgroundColor = "#ffdb58 ";
        style.color = "black";
        break;
    }
    return {
      style: style,
    };
  };

  return (
    <>
      <div className="calenderBlock">
        <div className="container">
          <div className="calendertopBar">
            <h1>My Calender</h1>
            <Button type="button" onClick={openModal}>
              <i className="fas fa-plus"></i> Add Events
            </Button>
          </div>
          <div className="grid">
            <div className="left">
              <Calendar
                localizer={localizer}
                events={allEvents}
                startAccessor="start"
                endAccessor="end"
                eventPropGetter={eventStyleGetter}
                style={{ height: 700 }}
                onView={handleViewChange}
                onNavigate={handleNavigate}

              />
            </div>
            <div className="right">
              <div className="rightBar">
                <div className="informationItem">
                  <h5>Activity Information</h5>
                  <ul>
                    <li>Uncoming Task</li>
                    <li>Pending Task</li>
                    <li>Completed Task </li>
                  </ul>
                </div>
                <div className="recentItem">
                  <h5>Recent Activity</h5>
                  <div className="item">
                    <div className="image">
                      <span></span>
                    </div>
                    <div className="text">
                      <h6>Added New Meeting by Greg Wong</h6>
                      <p>Offline Meeting with Dan Brown at 28 June 2023</p>
                      <p>Today 07.11 AM</p>
                    </div>
                  </div>
                  <div className="item">
                    <div className="image">
                      <span></span>
                    </div>
                    <div className="text">
                      <h6>Added New Meeting by Greg Wong</h6>
                      <p>Offline Meeting with Dan Brown at 28 June 2023</p>
                      <p>Today 07.11 AM</p>
                    </div>
                  </div>
                  <div className="item">
                    <div className="image">
                      <span></span>
                    </div>
                    <div className="text">
                      <h6>Added New Meeting by Greg Wong</h6>
                      <p>Offline Meeting with Dan Brown at 28 June 2023</p>
                      <p>Today 07.11 AM</p>
                    </div>
                  </div>
                  <div className="item">
                    <div className="image">
                      <span></span>
                    </div>
                    <div className="text">
                      <h6>Added New Meeting by Greg Wong</h6>
                      <p>Offline Meeting with Dan Brown at 28 June 2023</p>
                      <p>Today 07.11 AM</p>
                    </div>
                  </div>
                  <div className="item">
                    <div className="image">
                      <span></span>
                    </div>
                    <div className="text">
                      <h6>Added New Meeting by Greg Wong</h6>
                      <p>Offline Meeting with Dan Brown at 28 June 2023</p>
                      <p>Today 07.11 AM</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddEvent isOpen={isModalOpen} onClose={closeModal} addEvent={handleAddEvents} />

      <div className={styles.detailsInfo}>
        <div className="container">
          <div className={styles.headingBar}>
            <div className="">
              <ul>
                <li className={styles.activeList}>
                  <Link to="" onClick={() => handelTaskCalenderData("allTask")}>All Tasks</Link>
                </li>
                <li>
                  <Link to="" onClick={() => handelTaskCalenderData("upcoming")}>Upcoming</Link>
                </li>
                <li>
                  <Link to="" onClick={() => handelTaskCalenderData("completed")}>Completed</Link>
                </li>
              </ul>
            </div>
            <div className="">
              <button>
                <i className="fas fa-upload"></i> Export
              </button>
            </div>
          </div>
          <div className="row">
            {/* 1 */}
            {allTask === "allTask" && <GetAllTaskCalander getAllEventData={getAllEventData} /> ||
              allTask === "upcoming" && <GetAllTaskCalander getAllEventData={upcomingTask} /> ||
              allTask === "completed" && <GetAllTaskCalander getAllEventData={completedTask} />
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCalendar;
