import React, { useEffect } from "react";
import { getPaymentStatus } from "./api";
import { PAYMENT_STATUS } from "../../utils/enums/types";
import storage from "../../utils/storage";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../utils/routes/AppRoutes";

const Cancel = () => {
  const sessionId = storage.getSessionId();
  const navigate = useNavigate();

  const fetchStatus = async () => {
    const payload = {
      status: PAYMENT_STATUS.FAILED,
    };
    await getPaymentStatus(payload, sessionId);
  };

  useEffect(() => {
    fetchStatus();
  }, []);

  const handleRedirect = () => {
    navigate(APP_ROUTES.HOME);
    storage.clearSessionId();
  };

  return (
    <div className="payment-success-container">
    <div className="payment-success-card">
      <h2 className="payment-cancel-title">Oops! Your Payment is failed!</h2>
      <button type="button" onClick={handleRedirect} className="payment-success-button">
        Go to Home
      </button>
    </div>
  </div>
  );
};

export default Cancel;
