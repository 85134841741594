import { axios } from "../../../../lib/axios";
import { AxiosResponse, MasterTypeResponse } from "../../../../shared/models";
import { API_ROUTES } from "../../../../utils/routes/apiRoutes";
import {
  CitizenshipsResponse,
  PrivacyPolicyResponse,
  SendOTP,
  SignupDetails,
  SignupUserSuccessResponse,
  VerifyOTP,
  sendOTPResponse,
  verifyOTPResponse,
} from "../interface";

export const signup = (
  payload: SignupDetails
): Promise<SignupUserSuccessResponse> => {
  return axios.post(API_ROUTES.AUTH.SIGNUP, payload);
};
export const sendOTP = (payload: SendOTP): Promise<sendOTPResponse> => {
  return axios.post(API_ROUTES.AUTH.SEND_OTP, payload);
};
export const verifyOTP = (payload: VerifyOTP): Promise<verifyOTPResponse> => {
  return axios.put(API_ROUTES.AUTH.VERIFY_OTP, payload);
};
export const getMasterTypes = async (type: string,limit = 1000): Promise<AxiosResponse<MasterTypeResponse[]>> => {
  return axios.get(
    `${API_ROUTES.MASTER.GET_MASTERBYTYPE}?type=${type}&limit=${limit}`
  );
};
export const getCitizenshipList = async (): Promise<CitizenshipsResponse> => {
  return axios.get(`${API_ROUTES.MASTER.CITIZENSHIPS}`);
};
export const getPrivacyPolicy = async (): Promise<PrivacyPolicyResponse> => {
  return axios.get(`${API_ROUTES.COMMON.PRIVACY_POLICY}`);
};