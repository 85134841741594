import { configureStore } from '@reduxjs/toolkit';
import userDataReducer from '../reducers/auth';
import {
  dashboardReducer,
  signupDataReducer,
  contractReducer,
  addContractReducer,
  authenticatedReducer,
  paidAdsReducer,
  addDirectJobReducer,
  videoJoinConfigReducer
} from '../reducers';
import storage from '../../utils/storage';

export const store = configureStore({
  reducer: {
    userData: userDataReducer,
    signupResponse: signupDataReducer,
    dashboard: dashboardReducer,
    contractPost: contractReducer,
    addContract: addContractReducer,
    authentication: authenticatedReducer,
    paiAds: paidAdsReducer,
    directJob: addDirectJobReducer,
    videoJoinConfig: videoJoinConfigReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

store.subscribe(() => {
  const state = store.getState().addContract;

  if (state.started) {
    storage.saveContract(state);
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
