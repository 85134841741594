import { axios } from "../../../lib/axios";
import { API_ROUTES } from "../../../utils/routes/apiRoutes";
import { BOOST_TYPE } from "../../../utils/enums/types";
import { PLAN_RESPONSE, BoostResponse } from "../interface";
import { AxiosResponse } from "../../../shared/models";

export const getBoostPlan = async (): Promise<AxiosResponse<PLAN_RESPONSE[]>> => {
  return axios.get(API_ROUTES.BOOST.PLAN, {
    params: {
      type: BOOST_TYPE.PROFILE,
    },
  });
};

export const postBoostProfile = async (payload: any): Promise<AxiosResponse<BoostResponse>> => {
  return axios.post(`${API_ROUTES.BOOST.BOOST}`, payload);
};
