import React, { useEffect, useState } from "react";
import styles from "../../css/styles/style.module.css";
import { InterviewQuestionResponse } from "../OpenContracts/interface";
import { getInterviewQuestions } from "../OpenContracts/api";


interface InterViewQusProps {
  workId: string;
  userId: string;
  onClose: () => void;
}

const ViewInterViewQuestions: React.FC<InterViewQusProps> = ({
  workId,
  userId,
  onClose,
}) => {
  const [viewInterviewQuestions, setShowInterviewQuestions] = useState<InterviewQuestionResponse>();
  const [loading, setLoading] = useState<boolean>(true);
  const getInterviewQus = async () => {
    const response = await getInterviewQuestions(
      userId,
      workId,
    );
    setShowInterviewQuestions(response?.data);
    setLoading(false);
  };
  useEffect(() => {
    getInterviewQus();
  }, []);

  return (
    <>
      <div className="modal" style={{ display: "block" }}>
        <div className="modal-dialog ElibalModal InterviewModal modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">InterView Questions</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              >
                {/* <i className="fas fa-times"></i> */}
                </button>
            </div>
            <div className="modal-body">
              {loading ? (
                <p  className="  p-3 text-center">Loading..........</p>
              ) : (
                <div className={styles.EligibalModal}>
                  <div className="row  p-3">
                    <div className="col-md-">
                      <div className={styles.leftEligibal}>
                        <form>
                          {viewInterviewQuestions?.interviewAnswers?.map(
                            (data, idx) => {
                              return (
                                <div key={idx} className={styles.formGroup}>
                                  <label>
                                    {idx + 1}.{data.question.question}
                                  </label>
                                  <p><b>Answer:</b> {data.answer} </p>
                                </div>
                              );
                            }
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewInterViewQuestions;
