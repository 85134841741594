import moment from "moment";

interface DaysMap {
  [key: string]: string;
}

export const getWeekDaysMap = (): DaysMap => {
  const startOfWeek = moment().startOf("isoWeek");
  const daysMap: DaysMap = {};

  for (let i = 0; i < 7; i++) {
    const currentDay = startOfWeek.clone().add(i, "days");
    daysMap[currentDay.format("dddd")] = currentDay.format("YYYY-MM-DD");
  }

  return daysMap;
};

export const getDaysOfWeek = (): Date[] => {
  const startOfWeek = moment().startOf("isoWeek");
  const response: Date[] = [];

  for (let i = 0; i < 7; i++) {
    const currentDay = startOfWeek.clone().add(i, "days");
    response.push(currentDay.toDate());
    // daysMap[currentDay.format('dddd')] = currentDay.format('YYYY-MM-DD');
  }

  return response;
};

export const getWeekDates = (): string[] => {
  const startOfWeek = moment().startOf("isoWeek");
  const weekDates: string[] = [];

  for (let i = 0; i < 7; i++) {
    const currentDate = startOfWeek.clone().add(i, "days");
    weekDates.push(currentDate.format("YYYY-MM-DD"));
  }

  return weekDates;
};

export const getMondayOfCurrentWeek = () => {
  return moment().startOf("isoWeek").format("YYYY-MM-DD");
};
