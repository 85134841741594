import React from "react";
import styles from "../../../styles/style.module.css";
import Occupations from "../../../../../assets/images/occupations.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import storage from "../../../../../utils/storage";
import { RootState } from "../../../../../redux/store";
import {
  setActiveStep,
  setTravelData,
} from "../../../../../redux/reducers/addContract";
import { ADD_CONTRACT_STEPS } from "../../../../../shared/enums";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import Toltip from "../../../../common/toltip/page";
import tip from "../../../../../assets/images/tip.png";
import { SaveAsDraftButton } from "../../Components";
import { CONSTANT_MANAGER } from "../../../../../utils/constans/constants";

const AddTravel: React.FC = () => {
  const dispatch = useDispatch();
  const token = storage.getToken();

  const { travelData } = useSelector((state: RootState) => state.addContract);

  const formIk: any = useFormik({
    initialValues: {
      isTravelRequired: travelData.isTravelRequired,
      travelTimeRate: travelData.travelTimeRate,
      travelTipAllowedPerMonth: travelData.travelTipAllowedPerMonth,
      travelHoursPerRoundTrip: travelData.travelHoursPerRoundTrip,
    },
    onSubmit: () => {
      dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_EXPENSES));
    },
  });

  const handleGoBack = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_RATES));
  };
  // const handleGoToNextStep = () => {
  //   dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_EXPENSES));
  // };

  const onChange = (value: string | number | boolean, key: string) => {
    formIk.setFieldValue(key, value);

    if (key === "isTravelRequired" && value === false) {
      dispatch(
        setTravelData({
          isTravelRequired: false,
        })
      );
      return;
    }

    dispatch(
      setTravelData({
        ...travelData,
        [key]: value,
      })
    );
  };

  return (
    <>
      <HeaderContent />

      <div className="bgbluegradient addtravelcheck py-4">
        <div className={styles.signuppage}>
          <form onSubmit={formIk.handleSubmit}>
            <div className={styles.registerOuter}>
              <div className="container">
                <div className={styles.registerBord}>
                  <div className="row">
                    <div className="col-lg-6">
                    <div className={styles.registerLeft}>
                            <h4 className="text-white text-32 fw-semibold">
                              Travel Time
                            </h4>
                            <p className="text-16 text-white opacity-75">
                              You can provide all the information here regarding
                              the contract; you just need to fill in all the
                              details here.
                            </p>
                          
                      <div
                        className="col-lg-11 col-12"
                      >
                        <figure>
                          <img src={Occupations} alt="" className="img-fluid" />
                        </figure>
                      </div>
                    </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className={styles.signUPContainer}>
                        <div className={styles.registerRight}>
                          
                          <div className={styles.formGroup} style={{ marginBottom: "30px" }}>
                            {" "}
                            <div className="topTipHover">
                              <label className={styles.starlabel}>Will There Be Travel For This Job?</label>
                              <i>
                                <img className="customeTolImg" src={tip} alt="" />
                              </i>
                              <Toltip
                                slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                labelId={
                                  CONSTANT_MANAGER.ADD_CONTRACT
                                    .WHERE_TRAVEL_FOR_THIS_JOB
                                }
                              />
                            </div>
                            <div className={styles.rateradio}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  id="flexRadioDefault1"
                                  checked={travelData.isTravelRequired}
                                  onChange={() =>
                                    onChange(true, "isTravelRequired")
                                  }
                                />
                                <label className="form-check-label text-white">Yes</label>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault2"
                                  id="flexRadioDefault2"
                                  checked={!travelData.isTravelRequired}
                                  onChange={() =>
                                    onChange(false, "isTravelRequired")
                                  }
                                />
                                <label className="form-check-label text-white">No</label>
                              </div>
                            </div>
                          </div>

                          {travelData.isTravelRequired === true && (
                            <>
                              <div className={styles.fromGroup} style={{ marginBottom: "30px" }}>
                                {" "}
                                <div className="topTipHover">
                                  <label>Travel Time Rate</label>
                                  <i>
                                    <img
                                      className="customeTolImg"
                                      src={tip}
                                      alt=""
                                    />
                                  </i>
                                  <Toltip
                                    slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                    labelId={
                                      CONSTANT_MANAGER.ADD_CONTRACT
                                        .TRAVEL_TIME_RATE
                                    }
                                  />
                                </div>
                                <input
                                  type="number"
                                  name="travelRate"
                                  className="form-control rounded-pill h-48"
                                  placeholder="Enter Travel Rate "
                                  value={travelData.travelTimeRate}
                                  onChange={(e) =>
                                    onChange(
                                      Number(e.target.value),
                                      "travelTimeRate"
                                    )
                                  }
                                />
                              </div>
                              <div className={styles.fromGroup} style={{ marginBottom: "30px" }}>
                                {" "}
                                <div className="topTipHover">
                                  <label>Travel Trip Allowed(per month)?</label>{" "}
                                  <i>
                                    <img
                                      className="customeTolImg"
                                      src={tip}
                                      alt=""
                                    />
                                  </i>
                                  <Toltip
                                    slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                    labelId={
                                      CONSTANT_MANAGER.ADD_CONTRACT
                                        .TRAVEL_TRIP_ALLOWED
                                    }
                                  />
                                </div>
                                <input
                                  type="number"
                                  value={travelData.travelTipAllowedPerMonth}
                                  name="travelTip"
                                  className="form-control rounded-pill h-48"
                                  placeholder="Enter Travel Tip"
                                  onChange={(e) =>
                                    onChange(
                                      Number(e.target.value),
                                      "travelTipAllowedPerMonth"
                                    )
                                  }
                                />
                              </div>
                              <div className={styles.fromGroup} style={{ marginBottom: "30px" }}>
                                {" "}
                                <div className="topTipHover">
                                  <label>Travel Hours Per Round Trip?</label>{" "}
                                  <i>
                                    <img
                                      className="customeTolImg"
                                      src={tip}
                                      alt=""
                                    />
                                  </i>
                                  <Toltip
                                    slug={CONSTANT_MANAGER.SLUG.ADD_CONTRACT}
                                    labelId={
                                      CONSTANT_MANAGER.ADD_CONTRACT
                                        .TRAVEL_HOURS_PER_ROUND_TRIP
                                    }
                                  />
                                </div>
                                <input
                                  type="number"
                                  className="form-control rounded-pill h-48"
                                  name="travelHours"
                                  placeholder="Enter Travel hours"
                                  value={travelData.travelHoursPerRoundTrip}
                                  onChange={(e) =>
                                    onChange(
                                      Number(e.target.value),
                                      "travelHoursPerRoundTrip"
                                    )
                                  }
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-4 text-start my-3 btn-hover-effect">
                          <h6
                            onClick={() => handleGoBack()}
                            className="btn bg-white arrowyellow border-white border-1 rounded-pill position-relative py-lg-3 py-2 ps-5 backbutton"
                          >
                            <i className="bg-yellow rounded-pill arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                              <img src={blackarrow} alt="" />
                            </i>
                            <span className="px-lg-4 px-2 text-12 fw-semibold d-inline-block">Back</span>

                          </h6>
                        </div>
                      
                            <div className="col-4">
                              <SaveAsDraftButton />
                            </div>

                            <div className="col-4 text-end my-3 btn-hover-effect">
                              <button
                                type="submit"
                                className="btn bg-white arrowblue border-white border-1 rounded-pill position-relative py-lg-3 py-2 pe-5"
                              >
                                <span className="px-lg-4 px-2 text-12 fw-semibold d-inline-block">
                                  Next
                                </span>
                                <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                                  <img src={whitearrow} alt="" />
                                </i>
                              </button>
                            </div>
                          </div>
                     
                    </div>
                  </div>
                </div>
                {/* <div className={styles.regisFooter}>
                <div className="" onClick={() => handleGoBack()}>
                  <span>‹ Back</span>
                </div>
                <div className={styles.DraftBtns}>
                  <SaveAsDraftButton />
                </div>
                <div className="">
                  <button type="submit">Next ›</button>
                </div>
              </div> */}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default AddTravel;
