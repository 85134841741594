import React from "react";
import styles from "../styles/style.module.css";
import { User } from "./page";
import { axios } from "../../../lib/axios";
import { API_ROUTES } from "../../../utils/routes/apiRoutes";

interface BlockModalProps {
  onClose: () => void;
  userInfo: User | null;
  onBlockSuccess: () => void;
  isBlocked:boolean;

}

const BlockModal: React.FC<BlockModalProps> = ({ onClose, userInfo ,onBlockSuccess,isBlocked}) => {
  const handleBlockUser = async () => {
   
    if (userInfo) {
      try {
        const response = await axios.post(API_ROUTES.CHAT.BLOCK_CHAT_USER, {
          blockedId: userInfo.id,
        });
        if (response.status === 200) {
          console.log("User blocked successfully");
          onBlockSuccess()
          onClose(); // Close the modal after blocking
        } else {
          console.error("Failed to block user");
        }
      } catch (error) {
        console.error("Error blocking user:", error);
      }
    }
  };
//   const handleUnblockUser = async () => {
//     if (userInfo) {
//       try {
//         console.log('userInfo',userInfo);
//         const response = await axios.post(API_ROUTES.CHAT.UNBLOCK_CHAT_USER, {
//           blockedId: userInfo.id,
//         });
//         if (response.status === 200) {
//           console.log('User unblocked successfully');
//           onUnblockSuccess(); // Notify parent component
//           onClose(); // Close the modal after unblocking
//         } else {
//           console.error('Failed to unblock user');
//         }
//       } catch (error) {
//         console.error('Error unblocking user:', error);
//       }
//     }
//   };
  return (
    <div className="modal" style={{ display: "block" }}>
      <div className="modal-dialog">
        <div className="modal-content">
          <span className={styles.CloseModal}>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            ></button>
          </span>
          <div className="modal-body">
            <div className={styles.Chatalert}>
              <span>
                <i className="fas fa-ban"></i>
              </span>
              <h4>{isBlocked? "Unblock":"block"} this User ?</h4>
              <p>The user will be {isBlocked? "Unblock":"block"} from the chat .</p>
              <div className={styles.BloackBtn}>
                <button onClick={onClose}>Cancel</button>
                <button onClick={ handleBlockUser}>{isBlocked? "Unblock":"block"}</button>
  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockModal;
