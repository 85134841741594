import React, { FC, useEffect, useState } from "react";
import { Form, Formik, Field, FormikProps } from "formik";
import * as Yup from "yup";
import ReactSelect from "../../../../../shared/Components/Fields/ReactSelect";
import TextField from "../../../../../shared/Components/Fields/TextField";
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import { getCitizenshipList, getMasterTypes } from "../../../../../feature/auth/signup/api";
import 'react-phone-number-input/style.css';
import { MASTER_TYPE } from "../../../../../utils/enums/masterTypes.enum";
import { API_ROUTES } from "../../../../../utils/routes/apiRoutes";
import { axios } from "../../../../../lib/axios";
import { states } from "../../Constants/locationData";
import { cities } from "../../Constants/locationData";
import { IOption } from "../../../../../Interfaces";
import RequiredIcon from "../../../../../shared/Components/Fields/RequiredIcon";
// Define the validation schema using Yup
const validationSchema = Yup.object().shape({
  countryId: Yup.string().required('Country is required'),
  companyId: Yup.number()
  .required("Company ID is required")
  .test(
    "is-valid-id",
    "Company ID must be a valid ID",
    (value) => !!value && /^[0-9]+$/.test(String(value))// Example regex: only numeric values
  ),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  state: Yup.string().required('State is required'),
  city: Yup.string().required('City is required'),
  phoneNumber: Yup.string().required('Phone number is required'),
});

interface FormValues {
  countryId: string;
  companyId: string;
  email: string;
  state: string;
  city: string;
  phoneNumber: string;
 
}

const CompanyInfoModal: FC = () => {
  const [citizenshipList, setCitizenshipList] = useState<{ value: string; label: string }[]>([]);
  const [companiesList, setCompaniesList] = useState<{ value: string; label: string }[]>([]);; // Assume this list is available
  // const [countryCode, setCountryCode] = useState("");
  // const [state, setState] = useState({});
  // const [savedFullNumber, setSavedFullNumber] = useState("");
  // const [phoneNumber, setPhoneNumber] = useState(
  // ""
  // );
  useEffect(() => {
    getComapinesData();
  }, []);
  useEffect(() => {
    getCitizenshipData();
  }, []);
  
  
 // Fetch citizenship data
 const getCitizenshipData = async () => {
  try {
    const response: any = await getCitizenshipList();
    // Map response data to match the expected format for ReactSelect
    setCitizenshipList(response?.data?.map((item: any) => ({
      value: item._id,
      label: item.name
    })) || []);
  } catch (error) {
    console.error('Error fetching citizenship data:', error);
  }
};
const getComapinesData = async () => {
  try {
  const response: any = await getMasterTypes(MASTER_TYPE.COMPANIES);
  // Map response data to match the expected format for ReactSelect
  setCompaniesList(response?.data?.map((item: any) => ({
    value: item._id,
    label: item.name
  })) || []);
} catch (error) {
  console.error('Error fetching citizenship data:', error);
}
};
const handlePhoneChange = (
  value: string | undefined,
  setFieldValue: (field: string, value: any) => void
) => {
  if (value) {
    const phoneNumber = parsePhoneNumber(value);

    if (phoneNumber) {
      const countryCode = `+${phoneNumber.countryCallingCode}`; // Extract country calling code with a "+" sign
      const nationalNumber = phoneNumber.nationalNumber; // Extract national number

      const combinedPhoneNumber = `${countryCode}${nationalNumber}`; // Combine country code and national number

      // Update the form value for phoneNumber with the combined number
      setFieldValue("phoneNumber", combinedPhoneNumber || "");
    } else {
      // Handle invalid phone number case
      setFieldValue("phoneNumber", "");
    }
  } else {
    setFieldValue("phoneNumber", "");
  }
};
 const formSubmit = (
  payload: FormValues
): Promise<any> => {
  return axios.post(API_ROUTES.MASTER.COMPANIES_LIST, payload);
};

const onChangeSelectState = (selectedOption: IOption | null, setFieldValue: (field: string, value: any) => void) => {
  setFieldValue('state', selectedOption ? selectedOption.label : '');
};
const onChangeSelectCity = (selectedOption: IOption | null, setFieldValue: (field: string, value: any) => void) => {
  setFieldValue('state', selectedOption ? selectedOption.label : '');
};
  return (
    <div>
      <Formik
        initialValues={{
          countryId: "",
          companyId: "",
          email: "",
          state: "",
          city: "",
          phoneNumber: "",
      
          
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          // Call formSubmit function to send the form data
          formSubmit(values)
            .then(response => {
              console.log("API Response:", response);
              // Handle success response (e.g., show success message, close modal)
            })
            .catch(error => {
              console.error("API Error:", error);
              // Handle error (e.g., show error message)
            })
            .finally(() => {
              setSubmitting(false); // Ensure form is not stuck in submitting state
            });
        }}
       
      >
        {({ errors, touched, setFieldValue, handleSubmit }: FormikProps<FormValues>) => (
          <Form className="row g-1" onSubmit={handleSubmit}>
            <div className="col-md-4 mb-3">
              <ReactSelect
        
                label="Company"
                options={companiesList}
                notrequired={false}
                errors={
                  touched?.companyId ? errors?.companyId : undefined
                }
                touched={touched?.companyId}
                onChange={(e) => setFieldValue("companyId",e?.value || '')}
              />
            </div>
            <div className="col-md-4 mb-3">
              <ReactSelect
                label="Country"
                options={citizenshipList}
                errors={
                  touched?.countryId ? errors?.countryId : undefined
                }
                touched={touched?.countryId}
                onChange={(e) => setFieldValue("countryId", e?.value || '')}
              />
            </div>
            <div className="col-md-4 mb-3">
            <TextField
                type="text"
                name="email"
                errors={touched?.email ? errors?.email : undefined}
                touched={touched?.email}
                label="Email"
                placeholder="email"
                className="form-control block mx-auto mb-[24px]"
                notrequired={false}
              />
            </div>
            <div className="col-md-4 mb-3">
              <ReactSelect
                label="State"
                options={states}
                notrequired={false}
                errors={
                  touched?.state ? errors?.state : undefined
                }
                touched={touched?.state}
                // value={selectedCustomer}
                onChange={(e) => onChangeSelectState(e, setFieldValue)}
              />
            </div>
            <div className="col-md-4 mb-3">
              <ReactSelect
                label="City"
                options={cities}
                notrequired={false}
                errors={
                  touched?.city ? errors?.city : undefined
                }
                touched={touched?.city}
                // value={selectedCustomer}
                onChange={(e) => onChangeSelectCity(e, setFieldValue)}
              />
            </div>
            <div className="col-md-4 mb-3">
              <label htmlFor="phoneNumber">Phone Number</label>  <RequiredIcon/>
              <PhoneInput
                id="phoneNumber"
                name="phoneNumber"
                placeholder="Enter phone number"
                onChange={(value) => handlePhoneChange(value, setFieldValue)}
                defaultCountry="US"
                international
              />
              {touched.phoneNumber && errors.phoneNumber && (
                <div className="text-danger">{errors.phoneNumber}</div>
              )}
            </div>
            <div className="col-md-2">
              <button type="submit" className="btn btn-primary">Submit</button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CompanyInfoModal;
