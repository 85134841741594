import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import storage from '../storage';

const firebaseConfig = {
    apiKey: "AIzaSyDe7heWyITW2XTLJcjgd93gcqL6o80ihVo",
    authDomain: "automate-america-v2.firebaseapp.com",
    projectId: "automate-america-v2",
    storageBucket: "automate-america-v2.appspot.com",
    messagingSenderId: "445973699131",
    appId: "1:445973699131:web:9c1dbbdcc5062935421a1e",
    measurementId: "G-KX8Z1K7YH5"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging();

const vapidKey: string = process.env.REACT_APP_VAPID_KEY as string;

export const requestForToken = () => {
    return getToken(messaging, { vapidKey: vapidKey })
        .then((currentToken:string) => {
            console.log(currentToken,'currentToken');
            if (currentToken) {
                storage.setDeviceToken(currentToken);
            }
        })
        .catch((err:any) => {
            console.log("Error occured while retrieving token", err);
        })
}

export const onMessageListener = () => {
    return new Promise((resolve) => {
        onMessage(messaging, (payload:any) => {
            console.log("payload", payload)
            resolve(payload);
        });
    });
};

export default messaging;