import { Field } from "formik";
import React from "react";
import RequiredIcon from "./RequiredIcon";

interface TextFieldProps {
  label: string;
  type: string;
  name: string;
  className?: string;
  placeholder?: string;
  labelClassName?: string;
  touched?: string | boolean;
  errors?: string;
  disabled?: boolean;
  notrequired?: boolean;
}

const TextField: React.FC<TextFieldProps> = ({
  type,
  name,
  label,
  placeholder,
  className,
  touched,
  errors,
  disabled,
  notrequired,
}) => (
  <div className="form-group">
    <label className="block text-sm font-medium leading-6 text-gray-900">
      {label}
      
    </label>
    {!notrequired && <RequiredIcon />} 
    <Field
      type={type}
      name={name}
      disabled={disabled}
      className={className ?? "form-control"}
      placeholder={placeholder}
    />
    {(errors || touched) && (
      <p className="error-msg mb-[24px] text-sm mt-2">{errors}</p>
    )}
  </div>
);

export default TextField;
