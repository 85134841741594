import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../styles/sigup.module.css";
import left from "../../../../assets/images/left.png";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { setContact } from "../../../../redux/reducers/auth";
import { AUTH_ROUTES } from "../../../../utils/routes/AuthRoutes";
import { toast } from "react-toastify";
import { sendOTP } from "../api";
import { MESSAGES } from "../../../../utils/messages";
import { APIResponseError, sendOTPResponse } from "../interface";
import "react-phone-number-input/style.css"; // For default styles
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import HeaderContent from "../../../../shared/Components/HeaderContent/headerContent";
import { RootState } from "../../../../redux/store";
import whitearrow from "../../../../assets/images/whitearrow.svg";
import { Contactschema } from "../validations";

const PhoneVerify: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetail = useSelector((state: RootState) => state.userData);

  const [phoneNumber, setPhoneNumber] = useState(
    userDetail ? userDetail?.contact?.phoneNum : ""
  );
  const [countryCode, setCountryCode] = useState("");
  const [savedFullNumber, setSavedFullNumber] = useState("");

  const handlePhoneChange = (value: any) => {
    if (value) {
      // Extract country code using regex
      const countryCodeMatch = value.match(/^\+\d+/);
      const extractedCountryCode = countryCodeMatch ? countryCodeMatch[0] : "";
      // Remove country code from the value to get the phone number
      const parsedNumber = parsePhoneNumber(value);
      const fullPhoneNumber = `+${parsedNumber?.countryCallingCode}${parsedNumber?.nationalNumber}`;
      setSavedFullNumber(fullPhoneNumber);
      if (parsedNumber) {
        setCountryCode(parsedNumber?.countryCallingCode);
        setPhoneNumber(parsedNumber?.nationalNumber);
      }
      formik.setFieldValue("phoneNumber", parsedNumber?.nationalNumber);
    } else {
      setCountryCode("");
      setPhoneNumber("");
      formik.setFieldValue("phoneNumber", "");
    }
  };

  const formik = useFormik({
    initialValues: {
      phoneNumber: phoneNumber,
    },
    validationSchema: Contactschema,
    onSubmit: (values) => {
      const payload = {
        countryCode: `+${countryCode}`,
        phoneNumber: values.phoneNumber,
      };
      toast.promise(sendOTP(payload), {
        pending: {
          render() {
            return MESSAGES.SENDOTP.VERIFY;
          },
        },
        success: {
          render({ data }) {
            let successResponse = data as sendOTPResponse;
            const contactObj: any = {
              phoneNum: savedFullNumber,
              phoneNumVerifyId: successResponse?.data?._id,
              countryCode: countryCode,
              number: values.phoneNumber,
            };
            dispatch(setContact(contactObj));
            navigate(AUTH_ROUTES.OTP_VERIFY);
            return MESSAGES.SENDOTP.SUCCESS;
          },
        },
        error: {
          render(error) {
            let errorMessage = error as APIResponseError;
            return errorMessage?.data?.data?.message;
          },
        },
      });
    },
  });

  return (
    <>
      <HeaderContent />
      <div className="py-4 heightautoscroll">
        <div className={styles.signuppage}>
          <div className={styles.signUPContainer} style={{ padding: "15px" }}>
            <form onSubmit={formik.handleSubmit}>
              <h5>Enter phone number</h5>
              <span className={styles.Leftntm}>
                <a href="#">
                  <img src={left} alt="" onClick={() => navigate(-1)} />
                </a>
              </span>
              <p>
                A code will be texted to this number for verification or we will
                call you.
              </p>

              <div className={styles.fromGroup}>
                <div className={styles.resentOTP}>
                  <PhoneInput
                    placeholder="Eg. +1 254 2546 333"
                    //value={countryCode + phoneNumber} // Combine countryCode and phoneNumber for display
                    onChange={handlePhoneChange}
                    defaultCountry="US"
                    international
                    countrySelectProps={{ unicodeFlags: true }}
                  />
                  <p>
                    Would you rather receive a phone call with your {" "}
                    <br />
                    verifiction code?
                  </p>
                  {formik.touched.phoneNumber && (
                    <div className={styles.error}>
                      {formik.errors.phoneNumber}
                    </div>
                  )}
                </div>
                {/* <p>You would rather receive a verification code?</p> */}
                {/* <p className="mb-0">
                  <a href="#">Receive a verification code?</a>
                </p> */}
              </div>
              {/* <div className={styles.fromGroup}>
            <input type="submit" value="Next ›" />
          </div> */}
              <div className="col-lg-3 col-6 mx-auto">
                <div className="text-center my-3 btn-hover-effect border rounded-pill">
                  {/* <input type="submit"/> */}
                  <button
                    type="submit"
                    className="btn bg-white arrowblue border-white border-1 rounded-pill position-relative py-lg-3 py-2 w-100 pe-5 text-center"
                  >
                    <span className="px-lg-4 px-2 text-12 fw-semibold w-100">
                      Next
                    </span>
                    <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                      <img src={whitearrow} alt="" />
                    </i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PhoneVerify;
