import React from "react";
import moment from "moment";
import styles from "../../css/styles/style.module.css"
import info from "../../../../assets/images/info_icon.svg";
import loc from "../../../../assets/images/loc.png";
import userImg from "../../../../assets/images/user.jpg"
import { ContractResponse } from "../interface";

interface Props {
 ongoingContractDetails: ContractResponse
}
const WhiteGloveContractDetails: React.FC<Props> = ({ ongoingContractDetails }) => {


    return (
        <div className="Contracts OngoingContractDetails">
            <div className="container">

                <div className={styles.OngoingContractDetails}>
                    <div className={styles.ContractTabs}>

                        <div className={styles.OuterSlides}>
                            <div className="">

                                <div className={styles.ContarctHead}>
                                    <div className={styles.leftContract}>
                                        <h5>White Glove Hourly Contracts<i className="ms-2"><img src={info} alt="" /></i></h5>
                                    </div>
                                    <div className={styles.leftContract}>

                                        <button className="fw-semibold">Ongoing</button>
                                    </div>
                                </div>
                                <div className={styles.outerNameDetail}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className={styles.leftName}>

                                                <div className={styles.PrileImg}>
                                                    <span className="text-center">
                                                        <img src={userImg} alt="" />
                                                        <h6 className="py-2">#{ongoingContractDetails?.workNumber}</h6>
                                                        <button className="fw-semibold btn-info rounded-2 px-3 py-2 border-0 text-14">{ongoingContractDetails?.workType === "hourly_contract" ? "Hourly Contract" : ""}</button>
                                                    </span>
                                                    <div className="ms-3">
                                                        {/* <h5>Bradely Innes</h5> */}
                                                        <h6>{ongoingContractDetails?.occupation?.name}</h6>
                                                        <p>
                                                            <img src={loc} alt="" /> {ongoingContractDetails?.address}
                                                        </p>
                                                        <p>Rate PerHour : ${ongoingContractDetails?.rates?.baseRate}</p>
                                                        <div className={styles.rightName}>
                                                            <p>Start Date: {moment(ongoingContractDetails?.times?.startDate).format("MMM DD, YYYY")}</p>
                                                            <p>Est End Date: 12/18/2023</p>
                                                            <p>Expense Terms : Net 15</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-md-6">
                                            <div className={styles.leftName}>

                                                <div className={styles.PrileImg}>
                                                    <span className="text-center">
                                                        <img src={ongoingContractDetails?.contractor?.profilePicture ? ongoingContractDetails?.contractor?.profilePicture : ongoingContractDetails?.userInfo?.profilePicture} alt="" />
                                                        {ongoingContractDetails?.ownWork === true ?
                                                            <h5 className="py-2">Contractor</h5>
                                                            :
                                                            <h5 className="py-2">Professional</h5>
                                                        }
                                                    </span>

                                                    <div className="ms-3">
                                                        <h5>{ongoingContractDetails?.contractor?.name?ongoingContractDetails?.contractor?.name:ongoingContractDetails?.userInfo?.name}</h5>

                                                        <h6>{ongoingContractDetails?.contractor?.occupation?.name?ongoingContractDetails?.contractor?.occupation?.name:ongoingContractDetails?.userInfo?.occupation?.name}</h6>
                                                        <h6>Date of Application: {moment(ongoingContractDetails?.times?.startDate).format("MMM/DD/YYYY")}</h6>
                                                        <p>
                                                            <img src={loc} alt="" />{ongoingContractDetails?.userInfo?.address}
                                                        </p>

                                                        <div className={styles.rightName}>
                                                            <p>Start Date: 10/18/2023</p>
                                                            <p>Est End Date: 12/18/2023</p>
                                                            <p>Expense Terms : Net 15</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default WhiteGloveContractDetails

