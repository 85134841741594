import React, { FC } from 'react';
import { useAppSelector } from '../../../../redux/hooks';
import { RootState } from '../../../../redux/store';
import AddDescription from './components/AddDescription';
import UploadImages from './components/UploadImages';
import { ADD_PAID_ADS } from '../../../../shared/enums/AddPaidAdsSteps';

const AddSocialPost: FC = () => {
  const addPaidAds = useAppSelector((state: RootState) => state.paiAds);
  const { activeStep } = addPaidAds;

  const renderActiveStep = () => {
    switch (activeStep) {
      case ADD_PAID_ADS.AD_DESCRIPTION:
        return <AddDescription />;
      case ADD_PAID_ADS.UPLOAD_IMAGE:
        return <UploadImages />;
      default:
        return <AddDescription />;
    }
  };

  return renderActiveStep();
};

export default AddSocialPost;
