import React from "react";
import styles from "../styles/style.module.css";
import info from "../../../assets/images/info_icon.svg";
import FollowUs from "../../FollowUs/routes";
import Toltip from "../../common/toltip/page";
import tip from "../../../assets/images/tip.png";
import { CONSTANT_MANAGER } from "../../../utils/constans/constants";
const FollowUsOn = () => {
  return (
    <div className={styles.followUs}>
      <div className="container">
        <div className="">
          <div className="">
            <div className="">
              <div className="newheadingstyle">
                <div className="topTipHover">
                  <div className="d-flex align-items-center">
                    <h4 className="form-check-label text-20 fw-bold  font-inter">
                      Follow Us On
                    </h4>
                    <i>
                      <img className="customeTolImg" src={tip} alt="" />
                    </i>
                    <Toltip
                      slug={CONSTANT_MANAGER.SLUG_FEED.FEED}
                      labelId={CONSTANT_MANAGER.FEED_DATA.FOLLOW_US_ON}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <FollowUs />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FollowUsOn;
