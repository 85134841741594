import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ADD_CONTRACT_STEPS } from '../../shared/enums';
import {
  AddContractRootState,
  BackgroundCheck,
  ChooseOccupation,
  CustomerDetails,
  DrugTest,
  Durations,
  ExpenseData,
  KeyInformation,
  LegalDetails,
  Location,
  ProceduresAndPolicies,
  Rates,
  SoftwareLicenseDetails,
  TechnicalSkillsData,
  TravelData,
} from '../../models';
import { IOption } from '../../Interfaces';
import { WORK_STATUS_TYPE } from '../../utils/enums/status';
import { PLAN_TYPE } from '../../utils/enums/types';

const initialState = {
  ...new AddContractRootState({
    _id: null,
    started: false,
    contractStatus: WORK_STATUS_TYPE.DRAFT,
    Plantype: PLAN_TYPE.WHITEGLOVE,
    activeStep: ADD_CONTRACT_STEPS.CHOOSE_OCCUPATION,
    contractType: 'hourly_contract',
    occupationsList: [],
    occupation: {
      primaryOccupation: undefined,
      secondaryOccupations: [],
    },
    durations: {
      startDate: new Date(),
      endDate: new Date(),
      duration: 1,
    },
    keyInformation: {
      noOfPeople: 0,
      shift: '',
      plannedHoursPerDay: 0,
      plannedDaysPerWeek: 0,
    },
    location: {
      selectedCountry: { label: '', value: '' },
      selectedCity: { label: '', value: '' },
      selectedState: { label: '', value: '' },
      selectedStreet: { label: '', value: '' },
      cities: [],
      countries: [],
      states: [],
      streets: [],
    },
    rates: {
      paymentTerms: { label: '', value: '' },
      baseRate: 0,
      dtRate: 0,
      otRate: 0,
      paymentTermsList: [],
    },
    travelData: { isTravelRequired: false },
    expenseData: { isExpenseReimbursed: false },
    customerDetails: {
      sameAsCustomer: false,
      primaryName: '',
      primaryEmail: '',
      primaryPhoneNumber: '',
    },
    legalDetails: {},
    technicalSkillsData: {
      skillTypes: [],
      skills: [
        {
          isRequired: false,
          category: { label: '', value: '' },
          subCategoriesList: [],
          subCategory: [],
        },
      ],
    },
    drugTest: {
      isDrugTestRequired: false,
      drugRegacy: '',
    },
    backgroundCheck: {
      isBackgroundCheckRequired: false,
      backgroundRegecy: '',
    },
    eligibilityQuestions: [''],
    interviewQuestions: [''],
    softwareLicenseDetails: {
      options: [],
      isRequired: false,
      softwareLicenses: [],
    },
    proceduresAndPolicies: { proceduresAndPolicies: '' },
  }),
};

const addContractSlice = createSlice({
  name: 'addContract',
  initialState,
  reducers: {
    initContract: (state, action: PayloadAction<AddContractRootState>) =>
      action.payload,
    setActiveStep: (state, action: PayloadAction<ADD_CONTRACT_STEPS>) => {
      state.activeStep = action.payload;
    },
    setPlanType: (state, action: PayloadAction<PLAN_TYPE>) => {
      state.Plantype = action.payload;
    },
    setContractType: (state, action: PayloadAction<string>) => {
      state.contractType = action.payload;
    },
    setOccupation: (state, action: PayloadAction<ChooseOccupation>) => {
      state.occupation = { ...action.payload };
    },
    setOccupationsList: (state, action: PayloadAction<IOption[]>) => {
      state.occupationsList = [...action.payload];
    },
    setWorkDescription: (state, action: PayloadAction<string>) => {
      state.workDescription = action.payload;
    },
    setDurations: (state, action: PayloadAction<Durations>) => {
      state.durations = { ...action.payload };
    },
    setKeyInformation: (state, action: PayloadAction<KeyInformation>) => {
      state.keyInformation = { ...action.payload };
    },
    setLocation: (state, action: PayloadAction<Location>) => {
      state.location = { ...action.payload };
    },
    setRates: (state, action: PayloadAction<Rates>) => {
      state.rates = { ...action.payload };
    },
    setTravelData: (state, action: PayloadAction<TravelData>) => {
      state.travelData = { ...action.payload };
    },
    setExpenseData: (state, action: PayloadAction<ExpenseData>) => {
      state.expenseData = { ...action.payload };
    },
    setCustomerDetails: (state, action: PayloadAction<CustomerDetails>) => {
      state.customerDetails = { ...action.payload };
    },
    setLegalDetails: (state, action: PayloadAction<LegalDetails>) => {
      state.legalDetails = { ...action.payload };
    },
    setTechnicalSkills: (state, action: PayloadAction<TechnicalSkillsData>) => {
      state.technicalSkillsData = { ...action.payload };
    },
    setDrugTest: (state, action: PayloadAction<DrugTest>) => {
      state.drugTest = { ...action.payload };
    },
    setBackgroundCheck: (state, action: PayloadAction<BackgroundCheck>) => {
      state.backgroundCheck = { ...action.payload };
    },
    setEligibilityQuestions: (state, action: PayloadAction<string[]>) => {
      state.eligibilityQuestions = [...action.payload];
    },
    setInterviewQuestions: (state, action: PayloadAction<string[]>) => {
      state.interviewQuestions = [...action.payload];
    },
    setSoftwareLicenseDetails: (
      state,
      action: PayloadAction<SoftwareLicenseDetails>
    ) => {
      state.softwareLicenseDetails = { ...action.payload };
    },
    setProceduresAndPolicies: (
      state,
      action: PayloadAction<ProceduresAndPolicies>
    ) => {
      state.proceduresAndPolicies = { ...action.payload };
    },
    setStarted: (state, action: PayloadAction<boolean>) => {
      state.started = action.payload;
    },
    setContractId: (state, action: PayloadAction<string>) => {
      state._id = action.payload;
    },
  },
});

export const {
  setActiveStep,
  setContractType,
  initContract,
  setOccupation,
  setOccupationsList,
  setWorkDescription,
  setDurations,
  setKeyInformation,
  setLocation,
  setRates,
  setTravelData,
  setExpenseData,
  setCustomerDetails,
  setLegalDetails,
  setTechnicalSkills,
  setDrugTest,
  setBackgroundCheck,
  setEligibilityQuestions,
  setInterviewQuestions,
  setSoftwareLicenseDetails,
  setProceduresAndPolicies,
  setStarted,
  setPlanType,
  setContractId,
} = addContractSlice.actions;

export default addContractSlice.reducer;
