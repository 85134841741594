
import { axios } from "../../../../../lib/axios";
import { AxiosResponse } from "../../../../../shared/models";
import { API_ROUTES } from "../../../../../utils/routes/apiRoutes";
import { UpdateProfileResponse } from "../../../../home/interface";
import {
  ContractDetails,
  InterviewQuestionResponse,
  WorkResponse,
  getEventDetails
} from "../interface";

export const getOpenContracts = async (
  workType: string,
  workStatus: string,
  workTypeState: string
): Promise<WorkResponse> => {
  return axios.get(
    `${API_ROUTES.CONTRACTS.OPEN_CONTRACTS
    }?workType=${workType}&type=${2}&workStatus=${workStatus}&workTypeState=${workTypeState}`
  );
};

export const getWorkDetail = async (
  id: any,
  type: string
): Promise<WorkResponse> => {
  return axios.get(
    `${API_ROUTES.CONTRACTS.GET_WORKDETAILS}/${id}?type=${type}`
  );
};

export const getFilteredContractsByStatus = async (
  id: any,
  type: string
): Promise<AxiosResponse<ContractDetails[]>> => {
  return axios.get(
    `${API_ROUTES.CONTRACTS.FILTER_CONTRACTS}/${id}?type=${type}`
  );
};

export const changeWorkStatus = async (id: string, status: string, workId: string
): Promise<UpdateProfileResponse> => {
  return axios.put(`${API_ROUTES.WORK.CHANGE_WORK_STATUS}/${id}`, {
    status,
    workId,
  });
};

export const getInterviewQuestions = async (
  userId: string,
  workId: string
): Promise<AxiosResponse<InterviewQuestionResponse>> => {
  return axios.get(
    `${API_ROUTES.INTERVIEW_QUESTIONS.GET_INTERVIEW_QUESTION_BYUSERID}?userId=${userId}&workId=${workId}`
  );
};

export const getEventsById = async (userId: string, workId: string, eventType: string = 'interview', interviewType: string = 'video'): Promise<AxiosResponse<getEventDetails>> => {
  return await axios.get(`${API_ROUTES.EVENTS_CALENDAR.GET_EVENTS}?type=day&userId=${userId}&workId=${workId}&eventType=${eventType}&interviewType=${interviewType}`)
}

export const generateRtcToken = async (channelName: string, uid: number, role: string): Promise<AxiosResponse<any>> => {
  return axios.post(`${API_ROUTES.RTC_TOKEN.GENERATE_RTC_TOKEN}`, { channelName, uid, role });
}