import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./styles.css"

interface ConnectFormProps {
    isCandidate?: boolean;
}

const createTokenUrl = 'https://dev-api.automateamerica.com/api/common/rtc-token';

const ConnectForm = ({ isCandidate }: ConnectFormProps) => {
    const searchParams = new URLSearchParams(window.location.search);
    const [channelName, setChannelName] = useState(searchParams.get('channelName') || '');
    const [invalidInputMsg, setInvalidInputMsg] = useState('')
    const [userId, setUserId] = useState<string>("");
    const [token, setToken] = useState(searchParams.get('token') || '')
    const navigate = useNavigate()
    
    const handleConnect = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      //BACKEND - need to provide api to me that will check weather the token is valid or not

      //Handle the token part of the user don't have the token then he had to create the new token (Basically the interviewer)
      if(!searchParams.get('token')){
        const data = {channelName, uid:userId, role: 'publisher'};
        axios.post(createTokenUrl, data).then((res) => {
            console.log("resss", res);
            let token = res.data.data.token;
            const trimmedChannelName = channelName.trim()
            if (trimmedChannelName === '') {
                e.preventDefault() // keep the page from reloading on form submission
                setInvalidInputMsg("Channel name can't be empty.") // show warning
                setChannelName('') // resets channel name value in case user entered blank spaces 
                return;
            }
            navigate(`/interviewcabin?token=${token}&channelName=${channelName}`)
        });
        return;
      }
      navigate(`/interviewcabin?token=${token}&channelName=${channelName}`)
    };

    return (
        <div>
            {isCandidate ? "Candidate Connect Form":"Interviewer ConnectForm"}
            <form onSubmit={handleConnect}>
                <div>
                    <input
                        id="userId"
                        type='text'
                        placeholder='User Name'
                        value={userId}
                        onChange={(e) => {
                            setUserId(e.target.value)
                            setInvalidInputMsg('')
                        }}
                        required
                        title={"Please enter number only"}
                    />
                    <input
                        id="channelName"
                        type='text'
                        placeholder='Channel Name'
                        value={channelName}
                        onChange={(e) => {
                            setChannelName(e.target.value)
                            setInvalidInputMsg('')
                        }}
                        required
                    />
                    {
                        isCandidate && <input
                        id="token"
                        type='text'
                        required
                        placeholder='Token'
                        value={token}
                        onChange={(e) => {
                            setToken(e.target.value)
                            setInvalidInputMsg('')
                        }}
                    />
                    }
                    <button>Connect</button>
                    { invalidInputMsg && <p style={{color: 'red'}}> {invalidInputMsg} </p>}
                </div>
            </form>
        </div>
    )
}

export default ConnectForm;