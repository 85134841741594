export const PROFILE={
    FORMIK:{
        PROFILE_IMAGE:"profileImage",
        name:"name",
			address:"address",
            bio:"bio",
            baseRate:"baseRate",
            overTimeRate:"overTimeRate",
            doubleTimeRate:"doubleTimeRate",

    },
    TOAST:{
        EDIT:{
            EDIT:"Editing Profile",
            EDITED:"Profile Edited Successfully",
            NOT_EDITED:"Failed To Edit Profile"
        }

    }
}