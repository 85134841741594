import React from 'react'
import styles from "../../../css/styles/style.module.css"
import { Button, Modal } from 'react-bootstrap';
type ModalProp = {
    handleClose: () => void,
    showModal: boolean,
    action?: string,
    workStatus?: string,
    id?: string,
    status?: string,
    changeStatus: () => void


};
const Confirm = ({ showModal, handleClose, action, workStatus, id, status, changeStatus }: ModalProp) => {
    return (
        <Modal
            show={showModal}
            onHide={() => {
                handleClose();
            }}
        >
            <div >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Are You Sure You Want To Perform This Action?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={styles.buttonContainer}>
                        <Button className={styles.button} onClick={changeStatus}  >
                            Confirm
                        </Button>
                        <Button onClick={handleClose}>
                            Cancel
                        </Button></div>
                </Modal.Body>
            </div>
        </Modal>
    )
}

export default Confirm