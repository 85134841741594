import React from "react";
import styles from "../styles/style.module.css";
import postadbanner from "../../../assets/images/postadbanner.svg";
import arrowwithoutcircle_blue from "../../../assets/images/arrow_withoutcircle_blue.svg";
import { APP_ROUTES } from "../../../utils/routes/AppRoutes";
import { useNavigate } from "react-router-dom";

const PostYourAds = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.postadblock}>
      <div className="container">
        <div className={styles.postad}>
          <div className="row align-items-center g-4 justify-content-between flex-row-reverse mt-3 mx-0 py-lg-5 py-3">
            <div className="col-lg-6 col-12">
              <div className="newheadingstyle">
                <h2>Post your ads</h2>
                <p className="text-24 text-white">
                  Strategies for Boosting Engagement Unlock
                  <br /> the Power of Engagement with These
                  <br /> Proven Techniques
                </p>
                <button className="btn btn-white text-blue text-20"
                onClick={() => navigate(APP_ROUTES.ADD_PAID_ADS)}>
                  Post Now{" "}
                  <i className="ps-2">
                    <img
                      src={arrowwithoutcircle_blue}
                      alt=""
                      width={15}
                      height={15}
                    />
                  </i>
                </button>
              </div>
            </div>
            <div className="col-lg-6 d-lg-block d-none text-center">
              <figure>
                <img src={postadbanner} alt="" className="img-fluid" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostYourAds;
