import React from "react";
import Select from "react-select";
import RequiredIcon from "./RequiredIcon";

export interface Option {
  value: string;
  label: string;
}

interface ReactSelectProps {
  options: { value: string; label: string }[] | undefined;
  value?: { value: string; label: string };
  label: string;
  onChange: (selectedOption: Option | null) => void;
  touched?: string | boolean;
  errors?: string;
  notrequired?: boolean;
}

const ReactSelect: React.FC<ReactSelectProps> = ({
  options = [],
  label,
  onChange,
  touched,
  errors,
  value,
  notrequired
}) => {
  return (
    <div className="select-options">
      <label className="form-label block">{label}</label>{!notrequired && <RequiredIcon/>} 
      <Select
        value={value}
        options={options}
        onChange={(selectedOption: Option | null) => onChange(selectedOption)}
      />
      {(errors || touched) && (
        <p className="error-msg mb-[24px] text-sm">{errors}</p>
      )}
    </div>
  );
};

export default ReactSelect;
