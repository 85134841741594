import React from 'react'
import styles from "../styles/style.module.css"
import Slider from "react-slick";
import loc from "../../../../assets/images/loc.png"
import info from "../../../../assets/images/info.png"
import orbag from "../../../../assets/images/orbag.png"

import { Link } from 'react-router-dom';
import { CONSTANT_MANAGER } from '../../../../utils/constans/constants';
import tip from "../../../../../src/assets/images/tip.png";
import Toltip from '../../toltip/page';
function Jobs() {
  var Jobslide = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    centerPadding: '60px',
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
    <div className='mt-4'>
      <div className={styles.Jobs}>
      
        <div className="topTipHover">
          <div className="d-flex align-items-center">
            <h4 className="form-check-label text-20 fw-bold  font-inter">
            Jobs 
            </h4>
            <i>
              <img className="customeTolImg" src={tip} alt="" />
            </i>
            <Toltip
              slug={CONSTANT_MANAGER.SLUG_HOME.HOME}
              labelId={CONSTANT_MANAGER.HOME_DATA.JOBS.DATA.JOBS}
            />
          </div>
        </div>
        <div className={styles.ContractTabs}>
          <div className={styles.AllContract}>
            <div className="topTipHover">
          <div className="d-flex align-items-center">
            <h4 className="form-check-label text-20 fw-bold  font-inter">
            Complete Contracts
            </h4>
            <i>
              <img className="customeTolImg" src={tip} alt="" />
            </i>
            <Toltip
              slug={CONSTANT_MANAGER.SLUG_HOME.HOME}
              labelId={CONSTANT_MANAGER.HOME_DATA.JOBS.DATA.COMPLETE_CONTRACTS}
            />
          </div>
        </div>
            <Link to="#">View All <span> <i className="fas fa-chevron-right"></i> </span> </Link>
          </div>
          <Slider {...Jobslide}>
            <div className={styles.JobSlider}>
              <h5>Job #2844 <span> <img src={orbag} alt="" /> Direct Job</span></h5>
              <h6><img src={loc} alt="" /> City, State</h6>
              <p>Robot Programming <span>(Open)</span></p>
            </div>
            <div className={styles.JobSlider}>
              <h5>Job #2844 <span> <img src={orbag} alt="" /> Direct Job</span></h5>
              <h6><img src={loc} alt="" /> City, State</h6>
              <p>Robot Programming <span className={styles.Close}>(Close)</span></p>
            </div>
            <div className={styles.JobSlider}>
              <h5>Job #2844 <span> <img src={orbag} alt="" /> Direct Job</span></h5>
              <h6><img src={loc} alt="" /> City, State</h6>
              <p>Robot Programming <span className={styles.Close}>(Close)</span></p>
            </div>
          </Slider>
        </div>
      </div>
      </div>
    </>
  )
}

export default Jobs