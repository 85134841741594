import { axios } from "../../../lib/axios";
import { AxiosResponse } from "../../../shared/models";
import { API_ROUTES } from "../../../utils/routes/apiRoutes";
import { ConnectWithFriendsResponse, SendReq } from "../interface";

export const ConnectWithFriends = async (): Promise<AxiosResponse<ConnectWithFriendsResponse[]>> => {
    return  axios.get(`${API_ROUTES.FEED.CONNECT_WITH_FRIENDS}?limit=100&page=1`);
};

export const sendRequest = async (payload:SendReq): Promise<any> => {
    return axios.post(`${API_ROUTES.FEED.CONNECT_WITH_FRIENDS}`, payload);
}
