import React, { useEffect, useState } from "react";
import styles from "../styles/sigup.module.css";
// import styles from "../../styles/auth.module.css";
import { useNavigate } from "react-router-dom";
import logo from "../../../../assets/images/logo.png";
import left from "../../../../assets/images/left.png";
import down from "../../../../assets/images/down.png";
import { dropdownValidationSchema } from "../validations";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { setCitizenship } from "../../../../redux/reducers/auth";
import { AUTH_ROUTES } from "../../../../utils/routes/AuthRoutes";
import { getCitizenshipList } from "../api";
import { Dropdown, FormControl } from "react-bootstrap";
import whitearrow from "../../../../assets/images/whitearrow.svg";
import HeaderContent from "../../../../shared/Components/HeaderContent/headerContent";

const Citizenship: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [citizenship, setCitizenshipList] = useState([]);

  interface FormValues {
    selectedOption: string;
    selectedCitizenshipName: string;
  }

  const initialValues: FormValues = {
    selectedOption: "",
    selectedCitizenshipName: "",
  };

  const formik: any = useFormik({
    initialValues: initialValues,
    validationSchema: dropdownValidationSchema,
    onSubmit: (values: { selectedOption: any }) => {
      const selectedOption: any = {
        selectedOption: values.selectedOption,
      };
      console.log(selectedOption.selectedOption, "selectedOption");

      dispatch(setCitizenship(selectedOption.selectedOption));
      navigate(AUTH_ROUTES.ABOUT_US);
    },
  });
  const getCitizenshipData = async () => {
    const response: any = await getCitizenshipList();
    setCitizenshipList(response?.data);
  };

  useEffect(() => {
    getCitizenshipData();
  }, []);

  const handleSearch = (e: any) => {
    setSearchTerm(e.target.value);
  };
  const filteredOptions = (citizenship ?? []).filter(
    (option: { name: string }) =>
      option?.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const handleDropdownSelect = (option: { _id: string; name: string }) => {
    formik.setFieldValue("selectedOption", option._id);
    formik.setFieldValue("selectedCitizenshipName", option.name);
  };

  return (
    <>
      <HeaderContent />
      <div className="py-4 heightautoscroll">
      <div className={styles.occupationdetails}>
        <div className={styles.signuppage}>
          {/* <div className={styles.signupHead}>
				<div className={styles.leftSign}>
					<img src={logo} alt="" />
				</div>
				<div className={styles.rightSign}>
					<p className="mb-0">Already have an account? <a href="/">Sign in</a></p>
					<button>Need Help?</button>
				</div>
			</div> */}
          <div className={styles.signUPContainer} style={{padding: "15px"}}>
            <form onSubmit={formik.handleSubmit}>
              <h5> Citizenship </h5>
              <span className={styles.Leftntm}>
                <a href="#">
                  <img src={left} alt="" onClick={() => navigate(-1)} />
                </a>
              </span>
              <p>please enter the country of your citizenship</p>
              <div className={styles.fromGroup}>
                <Dropdown>
                  <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    {formik.values.selectedCitizenshipName
                      ? formik.values.selectedCitizenshipName
                      : "Please select an option"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                  <div className={styles.searchinputteam}>
                  <FormControl
                  autoFocus
                  className="position-absolute left-0 right-0"
                  placeholder="Search..."
                  style={{ width: "calc(100% - 30px) !important;"}}
                  onChange={handleSearch}
                  value={searchTerm}
                />
                </div>
                <div className={styles.droptopsearch}>
                    {filteredOptions.length === 0 && (
                      <Dropdown.Item disabled>No options found</Dropdown.Item>
                    )}
                    {[{ "name": "Select Citizenship", "_id": "" }, ...filteredOptions]?.map((option: any, index: number) => (
                      <Dropdown.Item
                        key={index}
                        onClick={() => handleDropdownSelect(option)}
                      >
                        {option.name}
                      </Dropdown.Item>
                    ))}
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                {formik.touched.selectedOption &&
                  formik.errors.selectedOption && (
                    <div className={styles.error}>
                      {formik.errors.selectedOption}
                    </div>
                  )}
                {/* <span><img src={down} alt="" /></span> */}
              </div>

              {/* <div className={styles.fromGroup}>
						<input type='submit' value="Next ›" />
					</div> */}
              <div className="col-lg-3 col-6 mx-auto">
                <div className="text-center my-3 btn-hover-effect border rounded-pill">
                  {/* <input type="submit"/> */}
                  <button
                    type="submit"
                    className="btn bg-white arrowblue border-white border-1 rounded-pill position-relative py-lg-3 py-2 w-100 pe-5 text-center"
                  >
                    <span className="px-lg-4 px-2 text-12 fw-semibold w-100">Next</span>
                    <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                      <img src={whitearrow} alt="" />
                    </i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};
export default Citizenship;
