import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ADD_CONTRACT_STEPS } from '../../shared/enums';

export interface ContractDataState {
  addContractActiveStep: ADD_CONTRACT_STEPS;
}

const initialState: ContractDataState = {
  addContractActiveStep: ADD_CONTRACT_STEPS.POST_TYPE,
};
const contractStepsSlice = createSlice({
  name: 'contractSteps',
  initialState,
  reducers: {
    setAddContractActiveStep: (
      state,
      action: PayloadAction<ADD_CONTRACT_STEPS>
    ) => {
      state.addContractActiveStep = action.payload;
    },
  },
});
export const { setAddContractActiveStep } = contractStepsSlice.actions;
export default contractStepsSlice.reducer;
