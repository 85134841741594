import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "../styles/home.module.css";
import tmone from "../../../assets/images/tmone.png";
import tmtwo from "../../../assets/images/tmtwo.png";
import tmthree from "../../../assets/images/tmthree.png";
import tmfour from "../../../assets/images/tmfour.png";
import pin from "../../../assets/images/pin.png";
import userImg from "../../../assets/images/user.jpg";
import link from "../../../assets/images/link.png";
import Header from "../../header/routes";
import { userProfileResponse } from "../interface";
import { getuserProfile, updateUserProfile } from "../api";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { WORK_TABS } from "../../../utils/enums/workTabs.enum";
import { changeContract, changeTab } from "../../../redux/reducers/dashboard";
import { CONTRACT_STATUS } from "../../../utils/enums/status";
import Jobs from "../../common/jobs/routes";
import CustomerRequest from "../../common/customerrequest/routes";
import PendingApplication from "../../common/pendingapplication/routes";
import Myteams from "../../Myteams/routes";
import PendingContracts from "../../common/Contracts/PendingContracts/pendingContracts";
import OpenContacts from "../../common/Contracts/OpenContracts/openContracts";
import DraftContracts from "../../common/Contracts/DraftContracts/draftContracts";
import Shedulecontract from "../../common/Contracts/schedulecontract/routes";
import Completedcontracts from "../../common/Contracts/completedcontracts/routes";
import ViewProfile from "../../profileview/routes/page";
import Closecontract from "../../common/closedcontract/routes/page";
import OnGoingContracts from "../../common/Contracts/OngoingContracts/ongoingContracts";
import DirectJob from "../../common/Contracts/DirectJob/directJob";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dashboardData = useSelector((state: RootState) => state.dashboard);
  const { activeTab } = dashboardData;
  const [profileDetetails, setProfileDetails] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);

  const handleShowActiveTab = (value: WORK_TABS): void => {
    dispatch(changeTab(value));
  };

  const refresh = () => {
    setLoading(true);
    getuserProfile().then((res) => {
      setProfileDetails(res);
      setLoading(false);
    });
  };

  useEffect(() => {
    refresh();
  }, []);

  const handleToggle = () => {
    updateUserProfile({
      isAvailable: !profileDetetails?.data.isAvailable,
    }).then((res) => {
      refresh();
    });
  };
  return (
    <>
      <Header />
      {!loading ? (
        <div className="HomeOuter">
          <div className={styles.Mydashboard}>
            <div className="container">
              <div className={styles.topDashborad}>
                <h3>My Dashboard</h3>
                <div className="row g-lg-4 g-3">
                  <div className="col-lg-3 col-md-6 col-12">
                    <div className={styles.timsheetInner}>
                      <span>
                        <img src={tmone} alt="" />
                      </span>
                      <div>
                        <h6>Timesheets</h6>
                        <h5>
                          {profileDetetails?.data?.totlalTimesheets}
                          <Link to="#">
                            More <i className="fas fa-arrow-right"></i>
                          </Link>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                    <div
                      className={`${styles.timsheetInner} ${styles.timeTwo}`}
                    >
                      <span>
                        <img src={tmtwo} alt="" />
                      </span>
                      <div>
                        <h6>Expense Reports</h6>
                        <h5>
                          {profileDetetails?.data?.totalPosts}
                          <Link to="#">
                            More <i className="fas fa-arrow-right"></i>
                          </Link>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                    <div
                      className={`${styles.timsheetInner} ${styles.timeThree}`}
                    >
                      <span>
                        <img src={tmthree} alt="" />
                      </span>
                      <div>
                        <h6>Invoices Past</h6>
                        <h5>
                          {profileDetetails?.data?.totlalInvoices}
                          <Link to="#">
                            More <i className="fas fa-arrow-right"></i>
                          </Link>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-12">
                    <div
                      className={`${styles.timsheetInner} ${styles.timeFOur}`}
                    >
                      <span>
                        <img src={tmfour} alt="" />
                      </span>
                      <div>
                        <h6>Purchase Orders</h6>
                        <h5>
                          {profileDetetails?.data?.totalPosts}{" "}
                          <Link to="#">
                            More <i className="fas fa-arrow-right"></i>
                          </Link>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.ProfieDash}>
                <div className={styles.ProfileLink}>
                  <Link to="#">View as Business</Link>
                  <Link to="#">Search Work</Link>
                  <Link to="#">Search Customers</Link>
                  <Link to="#">Wishlist</Link>
                  <button onClick={() => navigate("/editProfile")}>
                    <i className="fas fa-pen"></i> Edit profile
                  </button>
                </div>
                <div className="row align-items-center mx-0">
                  <div className="col-xl-2 col-lg-3 col-12">
                    <div className={styles.ProfileDetails}>
                      <div className={styles.Imagero}>
                        <img
                          src={
                            profileDetetails?.data?.profilePicture
                              ? profileDetetails?.data?.profilePicture
                              : userImg
                          }
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-10 col-lg-9 col-12 px-3 py-4">
                    <div className={styles.NamePro}>
                      <div className="d-flex align-items-center justify-content-between">
                        <h5>{profileDetetails?.data?.name} </h5>
                        <div className="form-check form-switch p-0">
                          <div>
                            <label
                              className={`form-check-label ${profileDetetails?.data?.isAvailable
                                ? styles.available
                                : styles.unavailable
                                }`}
                            >
                              {profileDetetails?.data?.isAvailable
                                ? "I am Available"
                                : "Unavailable"}
                            </label>
                            <input
                              checked={profileDetetails?.data?.isAvailable}
                              className="form-check-input"
                              type="checkbox"
                              id="flexSwitchCheckChecked"
                              onChange={handleToggle}
                            />
                          </div>
                        </div>
                      </div>

                      <h6 className="my-0">
                        {profileDetetails?.data?.occupationDetails?.length && profileDetetails?.data?.occupationDetails[0]?.name}{" "}
                        <span>
                          <i className="fas fa-star"></i> (
                          {profileDetetails?.data?.totlalReview} reviews)
                        </span>
                      </h6>
                      <div className="row align-items-center justify-content-between">
                        <div className="col-xl-4 col-lg-5 col-12">
                          <ul>
                            <li>
                              <img src={pin} alt="" />{" "}
                              {profileDetetails?.data?.countoryDetails?.name}
                            </li>
                            <li>
                              <img src={link} alt="" />{" "}
                              {profileDetetails?.data?.phoneNumber}
                            </li>
                          </ul>
                        </div>

                        <div className="col-xl-8 col-lg-8 col-12">
                          <div
                            className={styles.SocailBRn}
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <button>Boost My Profile</button>
                            <ul>
                              <span>Share :</span>
                              <li>
                                <i className="fas fa-envelope"></i>
                              </li>
                              <li>
                                <i className="fab fa-linkedin-in"></i>
                              </li>
                              <li>
                                <i className="fab fa-facebook-f"></i>
                              </li>
                              <li>
                                <i className="fab fa-instagram"></i>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className={styles.SocialProfile}>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className={styles.ContractTbs}>
              <div className="p-4 bg-white" >
                <ul className={styles.tabList}>
                  <li
                    className={
                      activeTab === WORK_TABS.DONEBYME ? styles.active : ""
                    }
                    onClick={() => handleShowActiveTab(WORK_TABS.DONEBYME)}
                  >
                    Work Being Done By Me or My Employees
                  </li>
                  <li
                    className={
                      activeTab === WORK_TABS.DONEFORME ? styles.active : ""
                    }
                    onClick={() => handleShowActiveTab(WORK_TABS.DONEFORME)}
                  >
                    Work Being Done For Me
                  </li>
                </ul>
                {/* <div className={styles.commonNav}>
                <ul className={styles.homeFiletr}>
                  <li><span>Ongoing</span></li>
                  <li><span>Open</span></li>
                  <li><span>Pending</span></li>
                  <li><span>Draft</span></li>
                </ul>
              </div> */}
              </div>



              {activeTab === WORK_TABS.DONEFORME ? (
                <>
                  <div className={styles.ContractTabs}>
                    <OnGoingContracts />
                    <PendingContracts />
                    <OpenContacts />
                  </div>
                  <DirectJob />
                  <DraftContracts />
                  {/* <Closecontract /> */}
                </>
              ) : (
                <>
                  <div className={styles.ContractTabs}>
                    <OnGoingContracts />
                    {/* <PendingContracts /> */}
                    <Shedulecontract />
                    <Completedcontracts
                      workList={[]}
                      refresh={function (): void {
                        throw new Error("Function not implemented.");
                      }}
                      loading={false}
                    />
                  </div>
                  <Jobs />
                  <CustomerRequest />
                  <PendingApplication />
                  <Myteams />
                  <ViewProfile />

                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className={`text-center ${styles.spinner}`}>
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Home;
