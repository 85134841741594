import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from '../../css/styles/style.module.css';
import bags from '../../../../assets/images/bags.png';
import info from '../../../../assets/images/info.png';
import pro from '../../../../assets/images/pro.png';
import loc from '../../../../assets/images/loc.png';
import Slider from 'react-slick';
import { APP_ROUTES } from '../../../../utils/routes/AppRoutes';
import { CONTRACT_Type } from '../../../../utils/enums/types';
import moment from 'moment';
import { TYPE, WORK_STATUS_TYPE } from '../../../../utils/enums/status';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { WORK_TABS } from '../../../../utils/enums/workTabs.enum';
import { getContractList } from '../api';
import { ContractResponse } from '../interface';


const OnGoingContracts = () => {
  const navigate = useNavigate();
  const dashboardData = useSelector((state: RootState) => state.dashboard);
  const { activeTab } = dashboardData;
  const [onGoingContracts, setOngoingContracts] = useState<ContractResponse[]>([]);
  console.log(onGoingContracts, 'onGoingContracts');

  const [loading, setLoading] = useState<boolean>(true);

  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 2.02,
    centerPadding: '60px',
    slidesToScroll: 2,
    infinite: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2.02,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.02,
          slidesToScroll: 1,
        },
      },
    ],
  };
  // useEffect(() => {
  //     if (activeTab === WORK_TABS.DONEFORME) {
  //         getOngoingContractsForMe();
  //         return
  //     }
  //     else if (activeTab === WORK_TABS.DONEBYME) {
  //         getOngoingContractsByMe();
  //         return
  //     }
  // }, [activeTab]);

  // const getOngoingContractsForMe = async () => {
  //     const response: any = await getContractList(CONTRACT_Type.HOURLY_CONTRACT, TYPE.DONEFORME, WORK_STATUS_TYPE.ONGOING);
  //     setOngoingContracts(response?.data);
  //     setLoading(false)
  // };

  // const getOngoingContractsByMe = async () => {
  //     const response: any = await getContractList(CONTRACT_Type.HOURLY_CONTRACT, TYPE.DONEBYME, WORK_STATUS_TYPE.ONGOING);
  //     setOngoingContracts(response?.data);
  //     setLoading(false)
  // };

  useEffect(() => {
    const fetchOngoingContracts = async () => {
      setLoading(true);
      try {
        let response: ContractResponse | any;
        if (activeTab === WORK_TABS.DONEFORME) {
          response = await getContractList(
            CONTRACT_Type.HOURLY_CONTRACT,
            TYPE.DONEFORME,
            WORK_STATUS_TYPE.ONGOING
          );
        } else if (activeTab === WORK_TABS.DONEBYME) {
          response = await getContractList(
            CONTRACT_Type.HOURLY_CONTRACT,
            TYPE.DONEBYME,
            WORK_STATUS_TYPE.ONGOING
          );
        } else {
          response = { data: [] };
        }
        setOngoingContracts(response.data);
      } catch (error) {
        console.error('Error fetching ongoing contracts:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchOngoingContracts();
  }, [activeTab]);

  const handleViewTimesheet = (workdata: ContractResponse) => {
    if (workdata.ownWork) {
      navigate(`${APP_ROUTES.TIMESHEETDETAIL_TABLE}/${workdata?._id}`, { state: { workdata } });
    }
    else {
      navigate(`${APP_ROUTES.TIMESHEET_TABLE}/${workdata?._id}`, { state: { workdata } });
    }
  }

  const handleViewExpense = (workdata: ContractResponse) => {
    if (workdata.ownWork) {
      navigate(`${APP_ROUTES.EXPENSE_STATUS_TABLE}/${workdata?._id}`)
    }
    else {
      navigate(`${APP_ROUTES.EXPENSE_TABLE}/${workdata?._id}`);
    }
  }

  const handleViewInvoice = (workdata: ContractResponse) => {
    if (workdata.ownWork) {
      navigate(`${APP_ROUTES.INVOICE_TABLE}/${workdata?._id}?isBusiness=${true}`);
    }    
    else {
      navigate(`${APP_ROUTES.INVOICE_TABLE}/${workdata?._id}`);
    }
  }

  return (
    <>
      {onGoingContracts?.length > 0 && (
        <div className="Contracts">
          <div className={styles.ContractTabs}>
            <div className={styles.AllContract}>
              <h3 className=" text-24">
                Ongoing Contracts <img src={info} alt="" />
              </h3>
              {/* <Link to="/onGoingContracts">
                            View All{" "}
                            <span>
                                {" "}
                                <i className="fas fa-chevron-right"></i>{" "}
                            </span>{" "}
                        </Link> */}
            </div>

            {loading ? (
              <div className="spinner-grow text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <div className={styles.OuterSlides}>
                <Slider {...settings}>
                  {onGoingContracts?.length > 0
                    ? onGoingContracts?.map((data: any, idx) => {
                      return (
                        <div key={idx} className={styles.ContarctOuter}>
                          <div className={styles.ContarctHead}>
                            <div className="row align-item-center justify-content-between mx-0 w-100">
                              <div className="col-xl-5 col-lg-12 col-md-5 col-12">
                                <div className={styles.leftContract}>
                                  <h5>Contract ID : #{data?.workNumber}</h5>
                                  <span>
                                    Start Date :
                                    {moment(data.times.startDate).format(
                                      'MMM DD, YYYY'
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-12 col-md-7 col-12">
                                <div
                                  className={styles.leftContract}
                                  style={{ textAlign: 'end' }}
                                >
                                  <button className={styles.CoptText}>
                                    <i className="far fa-copy"></i> Copy
                                  </button>
                                  {/* <button>{data?.workStatus === WORK_STATUS_TYPE.ONGOING ? "Ongoing" : data.workStatus === WORK_STATUS_TYPE.APPROVED ? "Approved" : data.workStatus === WORK_STATUS_TYPE.OPEN ? "Open" : ""}</button> */}
                                  <button>Ongoing</button>

                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={styles.outerNameDetail}>
                            <div className="row align-items-center">
                              <div className="col-md-12">
                                <div className={styles.outerNDetail}>
                                  <div className="row align-items-center">
                                    <div className="col-6">
                                      <div className={styles.PrileImgs}>
                                        <h4 className="fw-semibold text-20">
                                          {' '}
                                          {data?.ownWork
                                            ? 'Contractor Details'
                                            : 'Professional Details'}
                                        </h4>
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <div className={styles.rightName}>
                                        {data?.ownWork && data.planType === "whiteglove" ? (
                                          <h6>
                                            Working:{' '}
                                            {data?.professional?.name}
                                          </h6>
                                        ) : (
                                          <span
                                            className={styles.contarctBag}
                                          >
                                            <img
                                              src={bags}
                                              alt="White Glove Contract"
                                            />
                                            {data.planType === 'whiteglove'
                                              ? 'White Glove Contract'
                                              : data.planType === "standard" ? 'Standard Contract' : ""}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2 col-12">
                                <div className={styles.leftName}>
                                  <div className={styles.PrileImg}>
                                    <span className="text-center w-100">
                                      <img
                                        src={
                                          data?.contractor?.profilePicture
                                            ? data?.contractor?.profilePicture
                                            : data?.userInfo?.profilePicture
                                        }
                                        alt=""
                                        height={70}
                                        width={70}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-5 col-6">
                                <div className={styles.leftName}>
                                  <div className={styles.PrileImg}>
                                    <div className="">
                                      {data?.ownWork ? (
                                        <h5>{data?.contractor?.name}</h5>
                                      ) : (
                                        <h5>{data?.userInfo?.name}</h5>
                                      )}

                                      {data?.ownWork ? (
                                        <h6>
                                          {data?.contractor?.occupation?.name}
                                        </h6>
                                      ) : (
                                        <h6>
                                          {data?.userInfo?.occupation?.name}
                                        </h6>
                                      )}
                                      {data?.ownWork ? (
                                        <p>
                                          <img src={loc} alt="" />{' '}
                                          {data?.address}
                                        </p>
                                      ) : (
                                        <p>
                                          {' '}
                                          <img src={loc} alt="" />{' '}
                                          {data?.userInfo?.address}{' '}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-5 col-6">
                                <div className={styles.rightName}>
                                  <p>
                                    Start Date:{' '}
                                    {moment(data.times.startDate).format(
                                      'MMM DD, YYYY'
                                    )}
                                  </p>
                                  {/* <p>End Date: {moment(data.times.startDate).format("MMM DD, YYYY")}</p> */}
                                  <p>
                                    Rate Per Hour : Net
                                    {
                                      data?.userInfo?.hourlyPayment
                                        ?.paymentTerm
                                    }
                                  </p>
                                  <p>
                                    Expense Terms : Net{' '}
                                    {
                                      data?.userInfo?.expensePayment
                                        ?.paymentTerm
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          {data.planType === "whiteglove" &&
                            <div className={styles.timeSheets}>
                              <div className="row">
                                <div className="col-md-6">
                                  <div
                                    className={styles.timesheetData}
                                    onClick={() => { handleViewTimesheet(data) }}
                                  >
                                    <h4 className="fw-bold">Timesheet</h4>
                                    <ul>
                                      <li>
                                        <span>Current Timesheet</span>{' '}
                                        <label>
                                          {data?.timesheet?.current}
                                        </label>
                                      </li>
                                      <li className={styles.red}>
                                        <span>Due Timesheet</span>{' '}
                                        <label>{data?.timesheet?.due}</label>
                                      </li>
                                      <li className={styles.yeloo}>
                                        <span>Pending</span>{' '}
                                        <label>
                                          {data?.timesheet?.pending}
                                        </label>
                                      </li>
                                      <li className={styles.gren}>
                                        <span>Approved</span>{' '}
                                        <label>
                                          {data?.timesheet?.approved}
                                        </label>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div
                                    className={styles.timesheetData}
                                    onClick={() => { handleViewExpense(data) }}
                                  >
                                    <h4 className="fw-bold">Expenses</h4>
                                    <ul>
                                      <li>
                                        <span>Current Expense</span>{' '}
                                        <label>{data?.expense?.current}</label>
                                      </li>
                                      <li className={styles.red}>
                                        <span>Due Expense</span>{' '}
                                        <label>{data?.expense?.current}</label>
                                      </li>
                                      <li className={styles.yeloo}>
                                        <span>Pending</span>{' '}
                                        <label>{data?.expense?.current}</label>
                                      </li>
                                      <li className={styles.gren}>
                                        <span>Approved</span>{' '}
                                        <label>{data?.expense?.current}</label>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className={styles.timesheetData}
                                    onClick={() => { handleViewInvoice(data) }}
                                  >
                                    <h4 className="fw-bold">Invoices</h4>
                                    <ul>
                                      <li className={styles.red}>
                                        <span>Due </span>{' '}
                                        <label>{data?.invoice?.due}</label>
                                      </li>
                                      <li className={styles.yeloo}>
                                        <span>Past Due</span>{' '}
                                        <label>{data?.invoice?.pastDue}</label>
                                      </li>
                                      <li className={styles.gren}>
                                        <span>Paid</span>{' '}
                                        <label>{data?.invoice?.isPaid}</label>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className={styles.timesheetData}>
                                    <h4 className="fw-bold">Purchase Orders</h4>
                                    <ul>
                                      <li>
                                        <span>Current Order</span>{' '}
                                        <label>{data?.invoice?.pastDue}</label>
                                      </li>
                                      <li className={styles.red}>
                                        <span>Due Order</span>{' '}
                                        <label>{data?.invoice?.pastDue}</label>
                                      </li>
                                      <li className={styles.yeloo}>
                                        <span>Pending</span>{' '}
                                        <label>{data?.invoice?.pastDue}</label>
                                      </li>
                                      <li className={styles.gren}>
                                        <span>Approved</span>{' '}
                                        <label>{data?.invoice?.pastDue}</label>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              {data?.ownWork ? (
                                <>
                                  <div className="text-center py-3">
                                    <button
                                      onClick={() =>
                                        navigate(
                                          `${APP_ROUTES.SSAAGGREMENT}/${data?._id}`
                                        )
                                      }
                                      className="btn btn-primary w-50 mx-auto"
                                    >
                                      View SSA
                                    </button>
                                  </div>

                                </>
                              ) : (
                                <div className='row'>
                                <div className='col-6'>
                                  <div className="text-center py-3">
                                    <button
                                      onClick={() =>
                                        navigate(
                                          `${APP_ROUTES.MSAAGGREMENT}/${data._id}/${data.userInfo._id}`
                                        )
                                      }
                                      className="btn btn-primary w-100 mx-auto"
                                    >
                                      View MSA
                                    </button>
                                  </div>
                                  </div>
                                  <div className='col-6'>
                                  <div className="text-center py-3">
                                    <button
                                      onClick={() =>
                                        navigate(
                                          `${APP_ROUTES.ONGOING_CONTRACT_DETAIL}/${data?._id}`,
                                          {
                                            state: { professionalFlag: true,data }, // Add your flag here
                                          }
                                        )
                                      }
                                      className="btn btn-primary w-100 mx-auto"
                                    >
                                      Track Progress
                                    </button>
                                  </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          }


                        </div>
                      );
                    })
                    : ''}
                </Slider>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default OnGoingContracts;
