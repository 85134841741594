import * as Yup from "yup";
const emailOrPhone = Yup.string().test(
  "email-or-phone",
  "Please enter a valid email address or phone number",
  function (value) {
    // Regular expression for validating an email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Regular expression for validating a phone number (simplified for demonstration)
    const phoneRegex = /^\+?[1-9]\d{1,14}$/;

    // Check if the value matches either the email or phone regex
    return emailRegex.test(value as string) || phoneRegex.test(value as string);
  }
);

const loginSchema = Yup.object().shape({
  userName: emailOrPhone.required("Email or phone number is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
      "Password must contain at least 1 number, 1 uppercase letter, and 1 special character"
    )
    .required("Password is required"),
});

export default loginSchema;
