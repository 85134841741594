import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import trash from "../../../assets/images/trash.png";
import eyehidden from "../../../assets/images/eye_hidden.png";
import avatar from "../../../assets/images/Avatardummy.png";
import selfrating from "../../../assets/images/self_rating.svg";
import penbl from "../../../assets/images/penbl.png";
import tip from "../../../assets/images/tip.png";
import thumb from "../../../assets/images/profile_picture.png";
import penciledit from "../../../assets/images/penciledit.svg";
import downloadbalck from "../../../assets/images/download_black.svg";
import printblack from "../../../assets/images/print_black.svg";
import certificate from "../../../assets/images/certificate.png";
import star from "../../../assets/images/icon_star.svg";
import flag from "../../../assets/images/flag.svg";
import shareprofile from "../../../assets/images/share_profile.svg";
import aeroplane from "../../../assets/images/airoplane.svg";
import security from "../../../assets/images/security.svg";
import check from "../../../assets/images/check_green.svg";
import dummy from "../../../assets/images/dummy.jpeg";
import photoid from "../../../assets/images/photoid.jpg";
import productoffered from "../../../assets/images/product_offered.jpg";
import styles from "../styles/style.module.css";
import Header from "../../header/routes";
import { userProfileResponse } from "../../home/interface";
import {
  getuserProfile,
  updateUserProfile,
  deactivateAccount,
} from "../../home/api";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import "react-phone-number-input/style.css";
import { uploadFile } from "../../../common/api";
import { PROFILE } from "../../../utils/profile";
import Industries from "../components/Industries";
import EducationCerti from "../components/EducationCerti";
import PersonalFiles from "../components/PersonalFiles";
import SocialMedia from "../components/SocialMedia";
import Occupation from "../components/Occupation";
import PayRates from "../components/PayRates";
import Experience from "../components/Experience";
import Skills from "../components/Skills";
import About from "../components/About";
import {
  EducationDetails,
  IndustriesDetails,
  SkillsDetails,
  OccupationDetails,
} from "../interface";
import Swal from "sweetalert2";
import { ACCOUNT_STATUS } from "../../../utils/enums/status";

function Editprofile() {
  const [profileDetetails, setProfileDetails] = useState<userProfileResponse>();
  const [loading, setLoading] = useState<boolean>(true);
  const [education, setEducation] = useState<EducationDetails[]>([]);
  const [industries, setIndustries] = useState<IndustriesDetails[]>([]);
  const [skills, setSkills] = useState<SkillsDetails[]>([]);
  const [instaLink, setInstaLink] = useState("");
  const [fbLink, setFbLink] = useState("");
  const [linkedinLink, setLinkedinLink] = useState("");
  const [twiterLink, setTwiterLink] = useState("");
  const [occupation, setOccupation] = useState<OccupationDetails[]>([]);

  const uploadImage = (e: any) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    uploadFile(formData).then((res) => {
      profileDetailsFormik.setFieldValue(
        PROFILE.FORMIK.PROFILE_IMAGE,
        res.data.files
      );
    });
  };

  const profileDetailsFormik = useFormik({
    initialValues: {
      name: profileDetetails?.data?.name,
      address: profileDetetails?.data?.address,
      profileImage: profileDetetails?.data?.profilePicture,
      bio: profileDetetails?.data?.bio,
      baseRate: profileDetetails?.data?.rates?.baseRate,
      overTimeRate: profileDetetails?.data?.rates?.overTimeRate,
      doubleTimeRate: profileDetetails?.data?.rates?.doubleTimeRate,
    },
    // validationSchema:CouponDetailsSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const payload = {
        name: values?.name as string,
        address: values?.address as string,
        profilePicture: values?.profileImage as string,
        bio: values?.bio as string,
        rates: {
          baseRate: values?.baseRate as number,
          overTimeRate: values?.overTimeRate as number,
          doubleTimeRate: values?.doubleTimeRate as number,
        },
      };
      toast.promise(updateUserProfile(payload), {
        pending: {
          render() {
            return PROFILE.TOAST.EDIT.EDIT;
          },
        },
        success: {
          render() {
            refresh();
            return PROFILE.TOAST.EDIT.EDITED;
          },
        },
        error: {
          render() {
            return PROFILE.TOAST.EDIT.NOT_EDITED;
          },
        },
      });
    },
  });

  const refresh = () => {
    setLoading(true);
    getuserProfile().then((res) => {
      console.log("Profile Data ==> ", res.data);
      setEducation(res?.data?.educations);
      setIndustries(res?.data?.industries);
      setSkills(res?.data?.skillsDetails);
      setInstaLink(res?.data.instagramLink);
      setFbLink(res.data.facebookLink);
      setLinkedinLink(res.data.linkedinLink);
      setTwiterLink(res.data.twitterLink);
      setProfileDetails(res);
      setOccupation(res.data.occupationDetails);
      setLoading(false);
    });
  };

  useEffect(() => {
    refresh();
  }, []);

  const handleDeactivateAccount = async () => {
    const payload = {
      status: false,
    };
    console.log(payload);

    const initialResult = await Swal.fire({
      title: "Are you sure?",
      text: "Are you sure to deactivate your account?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Deactivate!",
      cancelButtonText: "No, cancel",
    });
    if (initialResult.isConfirmed) {
      try {
        console.log(payload);

        await deactivateAccount(payload);
        await Swal.fire({
          title: "Deactivation Done",
          text: "You can activate your account anytime",
          icon: "success",
          confirmButtonText: "OK",
        });
        refresh();
      } catch (err) {
        console.log("Error occuring while deactivating account", err);
      }
    }
  };

  return (
    <>
      <Header />
      <div className={styles.EditProfieOuter}>
        <div className="container">
          <div className={styles.EditProfieOuterBord}>
            <div className="row mx-0">
              <div className="col-lg-4 col-12 p-0">
                <div className={styles.LeftditPro}>
                  <h3 className="fw-semibold">My Profile</h3>
                  <ul>
                    <li>
                      <Link to="#">Profile</Link>
                    </li>
                    <li>
                      <Link to="#">Invoices/Payment</Link>
                    </li>
                    <li>
                      <Link to="#">Drafts Posts</Link>
                    </li>
                    <li>
                      <Link to="#">Rating & Feedback</Link>
                    </li>
                    <li>
                      <Link to="#">Contact us</Link>
                    </li>
                    <li>
                      <Link to="#">Notification Settings</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-8 col-12 p-0">
                {!loading ? (
                  <div className={styles.RightditPro}>
                    <h3 className="fw-semibold">Edit Profile</h3>

                    <div className={styles.ProfileEdit}>
                      <div className="row align-items-center mx-0">
                        <div className="col-1 text-center pe-0">
                          <div className={styles.ProfileEditHead}>
                            <img
                              src={
                                profileDetailsFormik.values.profileImage
                                  ? profileDetailsFormik.values.profileImage
                                  : avatar
                              }
                              className={styles.image}
                              alt=""
                            />

                            <div className={styles.middle}>
                              <img src={penbl} alt="" />
                              <div className={styles.deletAcount}></div>
                              <input
                                onChange={uploadImage}
                                type="file"
                                className="formcontrol"
                              />
                            </div>

                            {/* <div>
                          <h4 className="px-3">Profile Picture</h4>
                        </div> */}
                          </div>
                        </div>
                        <div className="col-10">
                          <div className="mt-3 ps-2">
                            <h4 className="text-22 fw-medium">
                              Andrew Globerman
                            </h4>
                            <p className="text-14">
                              Primary Occupations : UIUX Designer{" "}
                              <span className="badge bg-lightgreen text-medium py-1 text-11 px-4 rounded-pill">
                                Available
                              </span>
                              <span className="text-11 ps-2">For one week</span>
                            </p>
                          </div>
                        </div>
                        <div className="col-1">
                          <i>
                            <img src={penbl} alt="" />
                          </i>
                        </div>
                      </div>

                      <div className="px-3">
                        <h6 className="text-18 fw-semibold">
                          Personal Information
                          <span onClick={profileDetailsFormik.submitForm}>
                            {" "}
                            <div className={styles.deletAcount}>
                              <span>
                                <button
                                  type="button"
                                  className="btn btn-primary fw-normal text-14"
                                >
                                  Update
                                </button>
                              </span>
                            </div>
                          </span>
                        </h6>
                        <div className={styles.personalinfo}>
                          <ul className="row align-items-center">
                            <li className="col-md-4 col-6">
                              <span className="">First Name</span>
                              {/* <input
                              className={styles.editable}
                              name={PROFILE.FORMIK.name}
                              onChange={profileDetailsFormik.handleChange}
                              value={profileDetailsFormik.values?.name}
                            /> */}
                              <label>{profileDetailsFormik.values?.name}</label>
                            </li>
                            <li className="col-md-4 col-6">
                              <span className="">Last Name</span>
                              {/* <input
                              className={styles.editable}
                              
                              value={profileDetailsFormik.values?.name}
                            /> */}
                              <label>{profileDetailsFormik.values?.name}</label>
                            </li>
                            <li className="col-md-4 col-6">
                              <span>Email Id</span>
                              {/* <input
                              className={styles.editable}
                              value={profileDetetails?.data?.email}
                              disabled
                            /> */}
                              <label>{profileDetetails?.data?.email}</label>
                            </li>
                            <li className="col-md-4 col-6">
                              <span>Phone Number</span>
                              {/* <input
                              className={styles.editable}
                              value={profileDetetails?.data?.phoneNumber}
                              disabled
                            /> */}
                              <label>
                                {profileDetetails?.data?.phoneNumber}
                              </label>
                            </li>
                            <li className="col-md-4 col-6">
                              <span>Citizenship</span>
                              {/* <input
                              className={styles.editable}
                            /> */}
                              <label>Indian</label>
                            </li>
                            <li className="col-md-4 col-6">
                              <span>Address</span>
                              {/* <input
                              className={styles.editable}
                              name={PROFILE.FORMIK.address}
                              value={profileDetailsFormik.values.address}
                              onChange={profileDetailsFormik.handleChange}
                            /> */}
                              <label>
                                {profileDetailsFormik.values.address}
                              </label>
                            </li>
                            <li className="col-md-4 col-5">
                              <span>Account Type</span>
                              {/* <input
                              className={styles.editable}
                              value="Public"
                              disabled
                            /> */}
                              <label>Public</label>
                            </li>
                            <li className="col-md-4 col-5">
                              <span>Languages Spoken</span>
                              {/* <input
                              className={styles.editable}
                              value="Public"
                              disabled
                            /> */}
                              <label>English</label>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className={styles.deletAcount}>
                        <button
                          className="border-0 bg-transparent"
                          onClick={handleDeactivateAccount}
                        >
                          <i
                            className="me-2"
                            style={{ verticalAlign: "text-bottom" }}
                          >
                            <img src={trash} alt="" />
                          </i>
                          <span>Deactivate Account</span>
                        </button>
                        <button className="border-0 bg-transparent">
                          <i className="me-2">
                            <img src={eyehidden} alt="" />
                          </i>
                          <span>Hide Account</span>
                        </button>
                      </div>
                    </div>
                    <div className="px-3">
                      <About
                        bio={PROFILE.FORMIK.bio}
                        bioValue={profileDetailsFormik.values.bio}
                        onChange={profileDetailsFormik.handleChange}
                      />
                      <div className={styles.AboutEdit}>
                        <div className="row align-items-center justify-content-between mb-3">
                          <div className="col-6">
                            <div className="topTipHover">
                              <div className="d-flex align-items-center">
                                <h4 className="text-20 fw-bold  font-inter mb-0">
                                  Emergency Contact
                                </h4>
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                              </div>
                            </div>
                          </div>
                          <div className="col-6 text-end">
                            <span>
                              <img src={penbl} alt="" />
                            </span>
                          </div>
                        </div>
                        <div className={styles.personalinfo}>
                          <ul className="row align-items-center">
                            <li className="col-md-4 col-6">
                              <span className="">First Name</span>
                              {/* <input
                            className={styles.editable}
                            name={PROFILE.FORMIK.name}
                            onChange={profileDetailsFormik.handleChange}
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>{profileDetailsFormik.values?.name}</label>
                            </li>
                            <li className="col-md-4 col-6">
                              <span className="">Last Name</span>
                              {/* <input
                            className={styles.editable}
                            
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>{profileDetailsFormik.values?.name}</label>
                            </li>
                            <li className="col-md-4 col-6">
                              <span>Email Id</span>
                              {/* <input
                            className={styles.editable}
                            value={profileDetetails?.data?.email}
                            disabled
                          /> */}
                              <label>{profileDetetails?.data?.email}</label>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className={styles.AboutEdit}>
                        <div className="row align-items-center justify-content-between mb-3">
                          <div className="col-6">
                            <div className="topTipHover">
                              <div className="d-flex align-items-center">
                                <h4 className="text-20 fw-bold  font-inter mb-0">
                                  Professional Information
                                </h4>
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                              </div>
                            </div>
                          </div>
                          <div className="col-6 text-end">
                            <span>
                              <img src={penbl} alt="" />
                            </span>
                          </div>
                        </div>
                        <div className={styles.personalinfo}>
                          <ul className="row align-items-center">
                            <li className="col-md-4 col-6">
                              <span className="">Primary Occupation*</span>
                              {/* <input
                            className={styles.editable}
                            name={PROFILE.FORMIK.name}
                            onChange={profileDetailsFormik.handleChange}
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>UX/UI Designer</label>
                            </li>
                            <li className="col-md-12 col-4 mt-4">
                              <span className="">Additional Occupations</span>
                              {/* <input
                            className={styles.editable}
                            name={PROFILE.FORMIK.name}
                            onChange={profileDetailsFormik.handleChange}
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <ul className="row align-items-center g-4">
                                <li className="col-2">
                                  <label>UX/UI Designer</label>
                                </li>
                                <li className="col-2">
                                  <label>UX/UI Designer</label>
                                </li>
                                <li className="col-2">
                                  <label>UX/UI Designer</label>
                                </li>
                                <li className="col-2">
                                  <label>UX/UI Designer</label>
                                </li>
                                <li className="col-2">
                                  <label className="text-blue">
                                    + Add Up 5
                                  </label>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className={styles.AboutEdit}>
                        <div className=" mt-3">
                          <div className="row align-items-center">
                            <div className="col-8">
                              <div className="topTipHover">
                                <div className="d-flex align-items-center">
                                  <h4 className="text-20 fw-bold  font-inter mb-0">
                                    Top Skill*
                                  </h4>
                                  <i>
                                    <img
                                      className="customeTolImg"
                                      src={tip}
                                      alt=""
                                    />
                                  </i>
                                </div>
                              </div>
                            </div>
                            <div className="col-4 text-end">
                              <i>
                                <img src={penbl} alt="" />
                              </i>
                            </div>
                          </div>
                          <div
                            className={styles.personalinfo}
                            style={{ marginTop: "20px" }}
                          >
                            <span className="d-block text-14">Skill Name*</span>
                            <label>Autocad</label>
                          </div>
                          <div className="newheadingstyle mt-3">
                            <h4 className="text-16 fw-semibold mb-2">About</h4>
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Tempore totam minima nulla. Quas recusandae
                              inventore totam, est, obcaecati iste labore nulla
                              sequi enim ad nam. Atque inventore rem dolor
                              tenetur!Lorem ipsum dolor sit amet consectetur
                              adipisicing elit. Tempore totam minima nulla. Quas
                              recusandae inventore totam, est, obcaecati iste
                              labore nulla sequi enim ad nam. Atque inventore
                              rem dolor tenetur!
                            </p>

                            <h5 className="text-14">
                              Self Rating :{" "}
                              <i>
                                <img src={selfrating} alt="" />
                              </i>
                            </h5>
                            <ul
                              className={styles.personalinfo}
                              style={{ marginBottom: "0" }}
                            >
                              <li className="mb-3">
                                <span className="d-block text-14">
                                  Manufacturer
                                </span>
                                <label>Autocad</label>
                              </li>
                              <li className="mb-3">
                                <span className="d-block text-14">
                                  Model/Version/Controller
                                </span>
                                <label>Version 1.4</label>
                              </li>
                              <li className="mb-3">
                                <span className="d-block text-14">Image</span>
                                <img
                                  src={thumb}
                                  alt=""
                                  className="rouned-2"
                                  width={100}
                                  height={100}
                                />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className={styles.AboutEdit}>
                        <div className=" mt-3">
                          <div className="row align-items-center">
                            <div className="col-8">
                              <div className="topTipHover">
                                <div className="d-flex align-items-center">
                                  <h4 className="text-20 fw-bold  font-inter mb-0">
                                    Second Skill*
                                  </h4>
                                  <i>
                                    <img
                                      className="customeTolImg"
                                      src={tip}
                                      alt=""
                                    />
                                  </i>
                                </div>
                              </div>
                            </div>
                            <div className="col-4 text-end">
                              <i>
                                <img src={penbl} alt="" />
                              </i>
                            </div>
                          </div>
                          <div
                            className={styles.personalinfo}
                            style={{ marginTop: "20px" }}
                          >
                            <span className="d-block text-14">Skill Name*</span>
                            <label>Autocad</label>
                          </div>
                          <div className="newheadingstyle mt-3">
                            <h4 className="text-16 fw-semibold mb-2">About</h4>
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Tempore totam minima nulla. Quas recusandae
                              inventore totam, est, obcaecati iste labore nulla
                              sequi enim ad nam. Atque inventore rem dolor
                              tenetur!Lorem ipsum dolor sit amet consectetur
                              adipisicing elit. Tempore totam minima nulla. Quas
                              recusandae inventore totam, est, obcaecati iste
                              labore nulla sequi enim ad nam. Atque inventore
                              rem dolor tenetur!
                            </p>

                            <h5 className="text-14">
                              Self Rating :{" "}
                              <i>
                                <img src={selfrating} alt="" />
                              </i>
                            </h5>
                            <ul
                              className={styles.personalinfo}
                              style={{ marginBottom: "0" }}
                            >
                              <li className="mb-3">
                                <span className="d-block text-14">
                                  Manufacturer
                                </span>
                                <label>Autocad</label>
                              </li>
                              <li className="mb-3">
                                <span className="d-block text-14">
                                  Model/Version/Controller
                                </span>
                                <label>Version 1.4</label>
                              </li>
                              <li className="">
                                <span className="d-block text-14">Image</span>
                                <img
                                  src={thumb}
                                  alt=""
                                  className="rouned-2"
                                  width={100}
                                  height={100}
                                />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className={styles.AboutEdit}>
                        <div className=" mt-3">
                          <div className="row align-items-center">
                            <div className="col-8">
                              <div className="topTipHover">
                                <div className="d-flex align-items-center">
                                  <h4 className="text-20 fw-bold  font-inter mb-0">
                                    Other Skills*
                                  </h4>
                                  <i>
                                    <img
                                      className="customeTolImg"
                                      src={tip}
                                      alt=""
                                    />
                                  </i>
                                </div>
                              </div>
                            </div>
                            <div className="col-4 text-end">
                              <i>
                                <img src={penbl} alt="" />
                              </i>
                            </div>
                          </div>
                          <div
                            className={styles.personalinfo}
                            style={{ marginTop: "20px" }}
                          >
                            <span className="d-block text-14">Skill Name*</span>
                            <label>Autocad</label>
                          </div>
                          <div className="newheadingstyle mt-3">
                            <h4 className="text-16 fw-semibold mb-2">About</h4>
                            <p>
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit. Tempore totam minima nulla. Quas recusandae
                              inventore totam, est, obcaecati iste labore nulla
                              sequi enim ad nam. Atque inventore rem dolor
                              tenetur!Lorem ipsum dolor sit amet consectetur
                              adipisicing elit. Tempore totam minima nulla. Quas
                              recusandae inventore totam, est, obcaecati iste
                              labore nulla sequi enim ad nam. Atque inventore
                              rem dolor tenetur!
                            </p>

                            <h5 className="text-14">
                              Self Rating :{" "}
                              <i>
                                <img src={selfrating} alt="" />
                              </i>
                            </h5>
                            <ul
                              className={styles.personalinfo}
                              style={{ marginBottom: "0" }}
                            >
                              <li className="mb-3">
                                <span className="d-block text-14">
                                  Manufacturer
                                </span>
                                <label>Autocad</label>
                              </li>
                              <li className="mb-3">
                                <span className="d-block text-14">
                                  Model/Version/Controller
                                </span>
                                <label>Version 1.4</label>
                              </li>
                              <li className="">
                                <span className="d-block text-14">Image</span>
                                <img
                                  src={thumb}
                                  alt=""
                                  className="rouned-2"
                                  width={100}
                                  height={100}
                                />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      {/* <Skills skillDetails={skills} refresh={refresh} /> */}
                      <Experience />
                      {/* <div className={styles.AboutEdit}>
                        <div className="row align-items-center justify-content-between mb-3">
                          <div className="col-6">
                            <h5 className="text-20 fw-semibold mb-0 ">
                              Education{" "}
                            </h5>
                          </div>
                          <div className="col-6">
                            <div className=" text-end">
                              <span>
                                <img src={penbl} alt="" />
                              </span>
                            </div>
                          </div>
                        </div>
                        <ul className={styles.experienceSet}>
                          <li>
                            <span className={styles.expUi}></span>
                            <div>
                              <h4>BCA Degree</h4>
                              <h5>Govt College Chandigarh</h5>
                              <h6>
                                Start Date 12 Jun 2022 — End Date 12 Jun 2023
                              </h6>
                            </div>
                          </li>
                          <li>
                            <span className={styles.expUi}></span>
                            <div>
                              <h4>BCA Degree</h4>
                              <h5>Govt College Chandigarh</h5>
                              <h6>
                                Start Date 12 Jun 2022 — End Date 12 Jun 2023
                              </h6>
                            </div>
                          </li>
                          <li>
                            <h4 className="text-14 fw-semibold text-blue">
                              + Add more
                            </h4>
                          </li>
                        </ul>
                      </div> */}
                      <EducationCerti education={education} refresh={refresh} />

                      <div className={styles.AboutEdit}>
                        <div className="row align-items-center justify-content-between mb-3">
                          <div className="col-6">
                            <h5 className="text-20 fw-semibold mb-0 ">
                              Certifications{" "}
                            </h5>
                          </div>
                          <div className="col-6">
                            <div className=" text-end">
                              <span>
                                <img src={penbl} alt="" />
                              </span>
                            </div>
                          </div>
                        </div>
                        <ul className={styles.experienceSet}>
                          <div className="row align-items-center justify-content-center">
                            <li className="col-6">
                              <div>
                                <h4>Designing Certificate</h4>
                                <h5>Govt College Chandigarh</h5>
                                <h6>
                                  Start Date 12 Jun 2022 — End Date 12 Jun 2023
                                </h6>
                                <div className="position-relative">
                                  <figure>
                                    <img
                                      src={certificate}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </figure>
                                  <ul
                                    className="d-flex align-items-center justify-content-end position-absolute top-0 end-0"
                                    style={{ gap: "15px" }}
                                  >
                                    <li className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2">
                                      <i className="me-1">
                                        <img
                                          src={penciledit}
                                          alt=""
                                          width={12}
                                          height={12}
                                        />
                                      </i>
                                      <span className="text-10">Edit</span>
                                    </li>
                                    <li className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2">
                                      <i className="me-1">
                                        <img
                                          src={printblack}
                                          alt=""
                                          width={12}
                                          height={12}
                                        />
                                      </i>
                                      <span className="text-10">Print</span>
                                    </li>
                                    <li className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2">
                                      <i className="me-1">
                                        <img
                                          src={downloadbalck}
                                          alt=""
                                          width={12}
                                          height={12}
                                        />
                                      </i>
                                      <span className="text-10">Download</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </li>
                            <li className="col-6">
                              <div>
                                <h4>Designing Certificate</h4>
                                <h5>Govt College Chandigarh</h5>
                                <h6>
                                  Start Date 12 Jun 2022 — End Date 12 Jun 2023
                                </h6>
                                <div className="position-relative">
                                  <figure>
                                    <img
                                      src={certificate}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </figure>
                                  <ul
                                    className="d-flex align-items-center justify-content-end position-absolute top-0 end-0"
                                    style={{ gap: "15px" }}
                                  >
                                    <li className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2">
                                      <i className="me-1">
                                        <img
                                          src={penciledit}
                                          alt=""
                                          width={12}
                                          height={12}
                                        />
                                      </i>
                                      <span className="text-10">Edit</span>
                                    </li>
                                    <li className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2">
                                      <i className="me-1">
                                        <img
                                          src={printblack}
                                          alt=""
                                          width={12}
                                          height={12}
                                        />
                                      </i>
                                      <span className="text-10">Print</span>
                                    </li>
                                    <li className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2">
                                      <i className="me-1">
                                        <img
                                          src={downloadbalck}
                                          alt=""
                                          width={12}
                                          height={12}
                                        />
                                      </i>
                                      <span className="text-10">Download</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </li>
                            <li>
                              <h4 className="text-14 fw-semibold text-blue">
                                + Add more
                              </h4>
                            </li>
                          </div>
                        </ul>
                      </div>
                      <div className={styles.AboutEdit}>
                        <div className="row align-items-center justify-content-between mb-3">
                          <div className="col-6">
                            <div className="topTipHover">
                              <div className="d-flex align-items-center">
                                <h4 className="text-20 fw-bold  font-inter mb-0">
                                  Financial Information*
                                </h4>
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                              </div>
                            </div>
                          </div>
                          <div className="col-6 text-end">
                            <span>
                              <img src={penbl} alt="" />
                            </span>
                          </div>
                        </div>
                        <div className={styles.personalinfo}>
                          <ul className="row align-items-center">
                            <li className="col-md-4 col-6 mb-3">
                              <span className="">Pay Rate</span>
                              {/* <input
                            className={styles.editable}
                            name={PROFILE.FORMIK.name}
                            onChange={profileDetailsFormik.handleChange}
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>$48</label>
                            </li>
                            <li className="col-md-4 col-6 mb-3">
                              <span className="">Base Rate (0-40 hours)</span>
                              {/* <input
                            className={styles.editable}
                            
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>$56</label>
                            </li>
                            <li className="col-md-4 col-6 mb-3">
                              <span>Overtime Rate (41+ hours)</span>
                              {/* <input
                            className={styles.editable}
                            value={profileDetetails?.data?.email}
                            disabled
                          /> */}
                              <label>$56</label>
                            </li>
                            <li className="col-md-4 col-6 mb-3">
                              <span>Sunday and Holiday Rate</span>
                              {/* <input
                            className={styles.editable}
                            value={profileDetetails?.data?.email}
                            disabled
                          /> */}
                              <label>$56</label>
                            </li>
                            <li className="col-md-4 col-6 mb-3">
                              <span>
                                Flat Rate (All-inclusive, no overtime)
                              </span>
                              {/* <input
                            className={styles.editable}
                            value={profileDetetails?.data?.email}
                            disabled
                          /> */}
                              <label>$56</label>
                            </li>
                          </ul>
                        </div>
                        <div className={styles.note}>
                          <div className="d-flex align-items-center bg-lightblue p-3 rounded-2">
                            <div className="col-1 text-center">
                              <i>
                                <img src={security} alt="" />
                              </i>
                            </div>
                            <div className="col-11">
                              <p className="text-14 fw-medium mb-0">
                                Please provide your financial information
                                securely to facilitate payments. Your security
                                is extremely important to us, and only Automate
                                America Financial Administration will have
                                access to this information.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.AboutEdit}>
                        <div className="row align-items-center justify-content-between mb-3">
                          <div className="col-6">
                            <div className="topTipHover">
                              <div className="d-flex align-items-center">
                                <h4 className="text-20 fw-bold  font-inter mb-0">
                                  Banking Information
                                </h4>
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                              </div>
                            </div>
                          </div>
                          <div className="col-6 text-end">
                            <span>
                              <img src={penbl} alt="" />
                            </span>
                          </div>
                        </div>
                        <div className={styles.personalinfo}>
                          <ul className="row align-items-center">
                            <li className="col-md-4 col-6 mb-3">
                              <span className="">Bank Name</span>
                              {/* <input
                            className={styles.editable}
                            name={PROFILE.FORMIK.name}
                            onChange={profileDetailsFormik.handleChange}
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>ICICI Bank</label>
                            </li>
                            <li className="col-md-4 col-6 mb-3">
                              <span className="">Routing</span>
                              {/* <input
                            className={styles.editable}
                            
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>#4566</label>
                            </li>
                            <li className="col-md-4 col-6 mb-3">
                              <span>Account</span>
                              {/* <input
                            className={styles.editable}
                            value={profileDetetails?.data?.email}
                            disabled
                          /> */}
                              <label>9929************</label>
                            </li>
                            <li className="col-md-4 col-6 mb-3">
                              <span>Account Name</span>
                              {/* <input
                            className={styles.editable}
                            value={profileDetetails?.data?.email}
                            disabled
                          /> */}
                              <label>Mr. Johns Singh</label>
                            </li>
                          </ul>
                        </div>
                        <div className={styles.note}>
                          <div className="d-flex align-items-center bg-lightblue p-3 rounded-2">
                            <div className="col-1 text-center">
                              <i>
                                <img src={security} alt="" />
                              </i>
                            </div>
                            <div className="col-11">
                              <p className="text-14 fw-medium mb-0">
                                Please provide your financial information
                                securely to facilitate payments. Your security
                                is extremely important to us, and only Automate
                                America Financial Administration will have
                                access to this information.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.AboutEdit}>
                        <div className="row align-items-center justify-content-center">
                          <div className="col-6">
                            <div className="topTipHover">
                              <div className="d-flex align-items-center">
                                <h4 className="text-20 fw-bold  font-inter mb-0">
                                  Resume
                                </h4>
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <ul
                              className="d-flex align-items-center justify-content-end mb-0"
                              style={{ gap: "15px" }}
                            >
                              <li className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2">
                                <i className="me-1">
                                  <img
                                    src={printblack}
                                    alt=""
                                    width={12}
                                    height={12}
                                  />
                                </i>
                                <span className="text-10">Print</span>
                              </li>
                              <li className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2">
                                <i className="me-1">
                                  <img
                                    src={downloadbalck}
                                    alt=""
                                    width={12}
                                    height={12}
                                  />
                                </i>
                                <span className="text-10">Download</span>
                              </li>
                              <li>
                                <div className="text-end">
                                  <i>
                                    <img src={penbl} alt="" />
                                  </i>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className="text-start mt-2">
                            <h4 className="justify-content-start text-black fw-semibold text-14">
                              <i className="me-2">
                                <img src={check} alt="" width={15} />
                              </i>
                              Johns Reseme.pdf
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className={styles.AboutEdit}>
                        <div className="row align-items-center justify-content-between">
                          <div className="col-6">
                            <div className="topTipHover">
                              <div className="d-flex align-items-center">
                                <h4 className="text-20 fw-bold  font-inter mb-0">
                                  Background Check
                                </h4>
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className=" text-end">
                              <span>
                                <img src={penbl} alt="" />
                              </span>
                            </div>
                          </div>
                        </div>
                        <ul className={styles.experienceSet}>
                          <div className="row align-items-center justify-content-start">
                            <li className="col-6">
                              <div>
                                <h4>Johns Background check.pdf</h4>

                                <div className="position-relative">
                                  <figure>
                                    <img
                                      src={certificate}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </figure>
                                  <ul
                                    className="d-flex align-items-center justify-content-end position-absolute top-0 end-0"
                                    style={{ gap: "15px" }}
                                  >
                                    <li className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2">
                                      <i className="me-1">
                                        <img
                                          src={penciledit}
                                          alt=""
                                          width={12}
                                          height={12}
                                        />
                                      </i>
                                      <span className="text-10">Edit</span>
                                    </li>
                                    <li className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2">
                                      <i className="me-1">
                                        <img
                                          src={printblack}
                                          alt=""
                                          width={12}
                                          height={12}
                                        />
                                      </i>
                                      <span className="text-10">Print</span>
                                    </li>
                                    <li className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2">
                                      <i className="me-1">
                                        <img
                                          src={downloadbalck}
                                          alt=""
                                          width={12}
                                          height={12}
                                        />
                                      </i>
                                      <span className="text-10">Download</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </li>

                            <li>
                              <div className={styles.note}>
                                <div className="d-flex align-items-center bg-lightblue p-3 rounded-2">
                                  <div className="col-1 text-center">
                                    <i>
                                      <img src={security} alt="" />
                                    </i>
                                  </div>
                                  <div className="col-11">
                                    <p className="text-14 fw-medium mb-0">
                                      Please provide your financial information
                                      securely to facilitate payments. Your
                                      security is extremely important to us, and
                                      only Automate America Financial
                                      Administration will have access to this
                                      information.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </div>
                        </ul>
                      </div>
                      <div className={styles.AboutEdit}>
                        <div className="row align-items-center justify-content-between">
                          <div className="col-6">
                            <div className="topTipHover">
                              <div className="d-flex align-items-center">
                                <h4 className="text-20 fw-bold  font-inter mb-0">
                                  Photo ID
                                </h4>
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className=" text-end">
                              <span>
                                <img src={penbl} alt="" />
                              </span>
                            </div>
                          </div>
                        </div>
                        <ul className={styles.experienceSet}>
                          <div className="row align-items-center justify-content-start">
                            <li className="col-6">
                              <div>
                                <h4>Johns Photo ID.pdf</h4>

                                <div className="position-relative">
                                  <figure>
                                    <img
                                      src={photoid}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </figure>
                                  <ul
                                    className="d-flex align-items-center justify-content-end position-absolute top-0 end-0"
                                    style={{ gap: "15px" }}
                                  >
                                    <li className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2">
                                      <i className="me-1">
                                        <img
                                          src={penciledit}
                                          alt=""
                                          width={12}
                                          height={12}
                                        />
                                      </i>
                                      <span className="text-10">Edit</span>
                                    </li>
                                    <li className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2">
                                      <i className="me-1">
                                        <img
                                          src={printblack}
                                          alt=""
                                          width={12}
                                          height={12}
                                        />
                                      </i>
                                      <span className="text-10">Print</span>
                                    </li>
                                    <li className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2">
                                      <i className="me-1">
                                        <img
                                          src={downloadbalck}
                                          alt=""
                                          width={12}
                                          height={12}
                                        />
                                      </i>
                                      <span className="text-10">Download</span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </li>

                            <li>
                              <div className={styles.note}>
                                <div className="d-flex align-items-center bg-lightblue p-3 rounded-2">
                                  <div className="col-1 text-center">
                                    <i>
                                      <img src={security} alt="" />
                                    </i>
                                  </div>
                                  <div className="col-11">
                                    <p className="text-14 fw-medium mb-0">
                                      Please provide your financial information
                                      securely to facilitate payments. Your
                                      security is extremely important to us, and
                                      only Automate America Financial
                                      Administration will have access to this
                                      information.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </div>
                        </ul>
                      </div>
                      <div className={styles.AboutEdit}>
                        <div className="row align-items-center justify-content-center">
                          <div className="col-7">
                            <div className="topTipHover">
                              <div className="d-flex align-items-center">
                                <h4 className="text-20 fw-bold  font-inter mb-0">
                                  W9 Form, W-8BEN, or W-8BEN-E Form
                                </h4>
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                              </div>
                            </div>
                          </div>
                          <div className="col-5">
                            <ul
                              className="d-flex align-items-center justify-content-end mb-0"
                              style={{ gap: "15px" }}
                            >
                              <li className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2">
                                <i className="me-1">
                                  <img
                                    src={printblack}
                                    alt=""
                                    width={12}
                                    height={12}
                                  />
                                </i>
                                <span className="text-10">Print</span>
                              </li>
                              <li className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2">
                                <i className="me-1">
                                  <img
                                    src={downloadbalck}
                                    alt=""
                                    width={12}
                                    height={12}
                                  />
                                </i>
                                <span className="text-10">Download</span>
                              </li>
                              <li>
                                <div className="text-end">
                                  <i>
                                    <img src={penbl} alt="" />
                                  </i>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className="col-12 text-start my-3">
                            <h4 className="justify-content-start text-black fw-semibold text-14 mb-2">
                              <i className="me-2">
                                <img src={check} alt="" width={15} />
                              </i>
                              W9 Form.pdf
                            </h4>
                            <h4 className="justify-content-start text-black fw-semibold text-14 mb-2">
                              <i className="me-2">
                                <img src={check} alt="" width={15} />
                              </i>
                              W9 Form.pdf
                            </h4>
                            <h4 className="justify-content-start text-black fw-semibold text-14 mb-2">
                              <i className="me-2">
                                <img src={check} alt="" width={15} />
                              </i>
                              W9 Form.pdf
                            </h4>
                          </div>
                          <div className="col-12">
                            <div className={styles.note}>
                              <div className="d-flex align-items-center bg-lightblue p-3 rounded-2">
                                <div className="col-1 text-center">
                                  <i>
                                    <img src={security} alt="" />
                                  </i>
                                </div>
                                <div className="col-11">
                                  <p className="text-14 fw-medium mb-0">
                                    Please provide your financial information
                                    securely to facilitate payments. Your
                                    security is extremely important to us, and
                                    only Automate America Financial
                                    Administration will have access to this
                                    information.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.AboutEdit}>
                        <div className="row align-items-center justify-content-center">
                          <div className="col-7">
                            <div className="topTipHover">
                              <div className="d-flex align-items-center">
                                <h4 className="text-20 fw-bold  font-inter mb-0">
                                  Certificate of Insurance
                                </h4>
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                              </div>
                            </div>
                          </div>
                          <div className="col-5">
                            <ul
                              className="d-flex align-items-center justify-content-end mb-0"
                              style={{ gap: "15px" }}
                            >
                              <li className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2">
                                <i className="me-1">
                                  <img
                                    src={printblack}
                                    alt=""
                                    width={12}
                                    height={12}
                                  />
                                </i>
                                <span className="text-10">Print</span>
                              </li>
                              <li className="d-flex align-items-center px-2 py-1 border shadow bg-white rounded-2">
                                <i className="me-1">
                                  <img
                                    src={downloadbalck}
                                    alt=""
                                    width={12}
                                    height={12}
                                  />
                                </i>
                                <span className="text-10">Download</span>
                              </li>
                              <li>
                                <div className="text-end">
                                  <i>
                                    <img src={penbl} alt="" />
                                  </i>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className="col-12 text-start my-3">
                            <h4 className="justify-content-start text-black fw-semibold text-14 mb-2">
                              <i className="me-2">
                                <img src={check} alt="" width={15} />
                              </i>
                              Business liability insurance certificate.pdf
                            </h4>
                          </div>
                          <div className="col-12">
                            <div className={styles.note}>
                              <div className="d-flex align-items-center bg-lightblue p-3 rounded-2">
                                <div className="col-1 text-center">
                                  <i>
                                    <img src={security} alt="" />
                                  </i>
                                </div>
                                <div className="col-11">
                                  <p className="text-14 fw-medium mb-0">
                                    Please provide your financial information
                                    securely to facilitate payments. Your
                                    security is extremely important to us, and
                                    only Automate America Financial
                                    Administration will have access to this
                                    information.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <ProfessionalCompletency /> */}
                      {/* <Industries
                        industryDetails={industries}
                        refresh={refresh}
                      />
                      <Occupation occupationData={occupation} /> */}
                      {/* <EthicsValue />
                      <CommitComunity /> */}
                      {/* <PersonalFiles /> */}
                      <SocialMedia
                        instaLink={instaLink}
                        fbLink={fbLink}
                        linkedinLink={linkedinLink}
                        twiterLink={twiterLink}
                        refresh={refresh}
                      />
                      <div className={styles.AboutEdit}>
                        <div className="row align-items-center justify-content-between mb-3">
                          <div className="col-8">
                            <div className="topTipHover">
                              <div className="d-flex align-items-center">
                                <h4 className="text-20 fw-bold  font-inter mb-0">
                                  Company Information (Mandatory for job
                                  postings)
                                </h4>
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                              </div>
                            </div>
                          </div>
                          <div className="col-4 text-end">
                            <span>
                              <img src={penbl} alt="" />
                            </span>
                          </div>
                        </div>
                        <div className={styles.personalinfo}>
                          <ul className="row align-items-center">
                            <li className="col-md-4 col-6">
                              <span className="">Company Name*</span>
                              {/* <input
                            className={styles.editable}
                            name={PROFILE.FORMIK.name}
                            onChange={profileDetailsFormik.handleChange}
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>Company Name</label>
                            </li>
                          </ul>
                          <ul className="row align-items-center">
                            <li className="col-md-4 col-6">
                              <span className="">Company Address*</span>
                              {/* <input
                            className={styles.editable}
                            name={PROFILE.FORMIK.name}
                            onChange={profileDetailsFormik.handleChange}
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>Chandigarh</label>
                            </li>
                            <li className="col-md-4 col-6">
                              <span className="">Company Phone*</span>
                              {/* <input
                            className={styles.editable}
                            name={PROFILE.FORMIK.name}
                            onChange={profileDetailsFormik.handleChange}
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>+91 0987654321</label>
                            </li>
                            <li className="col-md-4 col-6">
                              <span className="">Company Email*</span>
                              {/* <input
                            className={styles.editable}
                            name={PROFILE.FORMIK.name}
                            onChange={profileDetailsFormik.handleChange}
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>Andre.g@gmail.com</label>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className={styles.AboutEdit}>
                        <div className="row align-items-center justify-content-between mb-3">
                          <div className="col-8">
                            <div className="topTipHover">
                              <div className="d-flex align-items-center">
                                <h4 className="text-20 fw-bold  font-inter mb-0">
                                  Business Classification
                                </h4>
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                              </div>
                            </div>
                          </div>
                          <div className="col-4 text-end">
                            <span>
                              <img src={penbl} alt="" />
                            </span>
                          </div>
                        </div>
                        <div className=" mb-3">
                          <div className="row align-items-center mb-3">
                            <div className="col-8">
                              <h4 className="fw-semibold text-14 mb-0">
                                Website Link
                              </h4>
                            </div>
                            <div className="col-4 text-end">
                              <span>
                                <img src={penbl} alt="" />
                              </span>
                            </div>
                          </div>
                          <div className="row align-items-center border rounded-2 h-44 mx-0">
                            <div className="col-10">
                              <input
                                type="text"
                                placeholder="Website"
                                className="py-2 w-100 border-0"
                              />
                            </div>
                            <div className="col-2 text-end">
                              <button
                                type="button"
                                className="btn text-blue fw-medium text-14 w-auto py-2"
                              >
                                +Add Link
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className=" mb-3 mt-2">
                          <div className="row align-items-center mb-3">
                            <div className="col-8">
                              <h4 className="fw-semibold text-14 mb-0">
                                Company Social Media Links
                              </h4>
                            </div>
                            <div className="col-4 text-end">
                              <span>
                                <img src={penbl} alt="" />
                              </span>
                            </div>
                          </div>
                          <div className=" mb-3">
                            <div className="row align-items-center border rounded-2 h-44 mx-0">
                              <div className="col-10">
                                <input
                                  type="text"
                                  placeholder="Instagram"
                                  // value={insta}
                                  className="py-2 w-100 border-0"
                                  // onChange={(e) => setInsta(e.target.value)}
                                />
                              </div>
                              <div className="col-2 text-end">
                                <button
                                  type="button"
                                  // onClick={handleInsta}
                                  className="btn text-blue fw-medium text-14 w-auto py-2"
                                >
                                  +Add Link
                                </button>
                              </div>
                            </div>
                            {instaLink ? (
                              <Link to={instaLink}>{instaLink}</Link>
                            ) : (
                              <p className="text-12">No Link Added yet!</p>
                            )}
                          </div>
                          <div className="mb-3">
                            <div className="row align-items-center border rounded-2 h-44 mx-0">
                              <div className="col-10">
                                <input
                                  type="text"
                                  placeholder="LinkedIn"
                                  // value={linkedin}
                                  className="py-2 w-100 border-0"
                                  // onChange={(e) => setLinkedin(e.target.value)}
                                />
                              </div>
                              <div className="col-2 text-end">
                                <button
                                  type="button"
                                  // onClick={handleLinkedin}
                                  className="btn text-blue fw-medium text-14 w-auto py-2"
                                >
                                  +Add Link
                                </button>
                              </div>
                            </div>
                            {linkedinLink ? (
                              <Link to={linkedinLink}>{linkedinLink}</Link>
                            ) : (
                              <p className="text-12">No Link Added yet!</p>
                            )}
                          </div>
                          <div className="mb-3">
                            <div className="row align-items-center border rounded-2 h-44 mx-0">
                              <div className="col-10">
                                <input
                                  type="text"
                                  placeholder="Facebook"
                                  // value={facebook}
                                  className="py-2 w-100 border-0"
                                  // onChange={(e) => setFacebook(e.target.value)}
                                />
                              </div>
                              <div className="col-2 text-end">
                                <button
                                  type="button"
                                  // onClick={handleFacebook}
                                  className="btn text-blue fw-medium text-14 w-auto py-2"
                                >
                                  + Add Link
                                </button>
                              </div>
                            </div>
                            {fbLink ? (
                              <Link to={fbLink}>{fbLink}</Link>
                            ) : (
                              <p className="text-12">No Link Added yet!</p>
                            )}
                          </div>
                          <div className="mb-3">
                            <div className="row align-items-center border rounded-2 h-44 mx-0">
                              <div className="col-10">
                                <input
                                  type="text"
                                  placeholder="youtube"
                                  // value={Youtube}
                                  className="py-2 w-100 border-0"
                                  // onChange={(e) => setFacebook(e.target.value)}
                                />
                              </div>
                              <div className="col-2 text-end">
                                <button
                                  type="button"
                                  // onClick={handleFacebook}
                                  className="btn text-blue fw-medium text-14 w-auto py-2"
                                >
                                  + Add Link
                                </button>
                              </div>
                            </div>
                            {fbLink ? (
                              <Link to={fbLink}>{fbLink}</Link>
                            ) : (
                              <p className="text-12">No Link Added yet!</p>
                            )}
                          </div>
                          <div className="mb-3">
                            <div className="row align-items-center border rounded-2 h-44 mx-0">
                              <div className="col-10">
                                <input
                                  type="text"
                                  placeholder="Twiter"
                                  // value={twiter}
                                  className="py-2 w-100 border-0"
                                  // onChange={(e) => setTwiter(e.target.value)}
                                />
                              </div>
                              <div className="col-2 text-end">
                                <button
                                  type="button"
                                  // onClick={handleTwiter}
                                  className="btn text-blue fw-medium text-14 w-auto py-2"
                                >
                                  + Add Link
                                </button>
                              </div>
                            </div>
                            {twiterLink ? (
                              <Link to={twiterLink}>{twiterLink}</Link>
                            ) : (
                              <p className="text-12">No Link Added yet!</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className={styles.AboutEdit}>
                        <div className="row align-items-center justify-content-between mb-3">
                          <div className="col-8">
                            <div className="topTipHover">
                              <div className="d-flex align-items-center">
                                <h4 className="text-20 fw-bold  font-inter mb-0">
                                  Products Offered
                                </h4>
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                              </div>
                            </div>
                          </div>
                          <div className="col-4 text-end">
                            <span>
                              <img src={penbl} alt="" />
                            </span>
                          </div>
                        </div>
                        <div className="row align-items-center g-2">
                          <div className="col-4">
                            <div className=" border-grey p-2 rounded-2">
                              <figure>
                                <img
                                  src={productoffered}
                                  className="img-fluid w-100"
                                  alt=""
                                />
                              </figure>
                              <div className=" mt-3 pt-3">
                                <div className="newheadingstyle">
                                  <h4 className="text-16 fw-semibold mb-2">
                                    Landing Page design in Figma
                                  </h4>
                                  <p>
                                    <span className="text-12 mb-0 clamp clamp2">
                                      Landing Page design in Figma (No coding,
                                      Only design mockup)
                                    </span>
                                  </p>
                                  <p>
                                    <span className="text-10 mb-0">
                                      3 days delivery — Aug 9, 2024
                                    </span>
                                  </p>
                                  <h5 className="text-14 fw-semibold">
                                    Price : $599
                                  </h5>
                                </div>
                              </div>
                              <div className="mt-3">
                                <div className="row align-items-center">
                                  <div className="col">
                                    <button
                                      type="button"
                                      className="btn btn-primary w-100 text-12 px-1  py-3 text-center "
                                    >
                                      Advertise This Product
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className=" border-grey p-2 rounded-2">
                              <figure>
                                <img
                                  src={productoffered}
                                  className="img-fluid w-100"
                                  alt=""
                                />
                              </figure>
                              <div className=" mt-3 pt-3">
                                <div className="newheadingstyle">
                                  <h4 className="text-16 fw-semibold mb-2">
                                    Landing Page design in Figma
                                  </h4>
                                  <p>
                                    <span className="text-12 mb-0 clamp clamp2">
                                      Landing Page design in Figma (No coding,
                                      Only design mockup)
                                    </span>
                                  </p>
                                  <p>
                                    <span className="text-10 mb-0">
                                      3 days delivery — Aug 9, 2024
                                    </span>
                                  </p>
                                  <h5 className="text-14 fw-semibold">
                                    Price : $599
                                  </h5>
                                </div>
                              </div>
                              <div className="mt-3">
                                <div className="row align-items-center">
                                  <div className="col">
                                    <button
                                      type="button"
                                      className="btn btn-primary w-100 text-12 px-1  py-3 text-center "
                                    >
                                      Advertise This Product
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className=" border-grey p-2 rounded-2">
                              <figure>
                                <img
                                  src={productoffered}
                                  className="img-fluid w-100"
                                  alt=""
                                />
                              </figure>
                              <div className=" mt-3 pt-3">
                                <div className="newheadingstyle">
                                  <h4 className="text-16 fw-semibold mb-2">
                                    Landing Page design in Figma
                                  </h4>
                                  <p>
                                    <span className="text-12 mb-0 clamp clamp2">
                                      Landing Page design in Figma (No coding,
                                      Only design mockup)
                                    </span>
                                  </p>
                                  <p>
                                    <span className="text-10 mb-0">
                                      3 days delivery — Aug 9, 2024
                                    </span>
                                  </p>
                                  <h5 className="text-14 fw-semibold">
                                    Price : $599
                                  </h5>
                                </div>
                              </div>
                              <div className="mt-3">
                                <div className="row align-items-center">
                                  <div className="col">
                                    <button
                                      type="button"
                                      className="btn btn-primary w-100 text-12 px-1 py-3 text-center "
                                    >
                                      Advertise This Product
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-4">
                          <h4 className="text-14 fw-semibold text-blue">
                            + Add more
                          </h4>
                        </div>
                      </div>
                      <div className={styles.AboutEdit}>
                        <div className="row align-items-center justify-content-between mb-3">
                          <div className="col-8">
                            <div className="topTipHover">
                              <div className="d-flex align-items-center">
                                <h4 className="text-20 fw-bold  font-inter mb-0">
                                  Services Offered Offered
                                </h4>
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                              </div>
                            </div>
                          </div>
                          <div className="col-4 text-end">
                            <span>
                              <img src={penbl} alt="" />
                            </span>
                          </div>
                        </div>
                        <div className="">
                          <h4 className="text-14 fw-semibold text-blue">
                            + Add more
                          </h4>
                        </div>
                      </div>
                      <div className={styles.AboutEdit}>
                        <div className="row align-items-center justify-content-between mb-3">
                          <div className="col-8">
                            <div className="topTipHover">
                              <div className="d-flex align-items-center">
                                <h4 className="text-20 fw-bold  font-inter mb-0">
                                  Other Business Locations
                                </h4>
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                              </div>
                            </div>
                          </div>
                          <div className="col-4 text-end">
                            <span>
                              <img src={penbl} alt="" />
                            </span>
                          </div>
                        </div>
                        <div className={styles.personalinfo}>
                          <ul className="row align-items-center">
                            <li className="col-md-4 col-6">
                              <span className="">Name of Facility</span>
                              {/* <input
                            className={styles.editable}
                            name={PROFILE.FORMIK.name}
                            onChange={profileDetailsFormik.handleChange}
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>Company Name</label>
                            </li>
                          </ul>
                          <ul className="row align-items-center">
                            <li className="col-md-4 col-6">
                              <span className="">Country</span>
                              {/* <input
                            className={styles.editable}
                            name={PROFILE.FORMIK.name}
                            onChange={profileDetailsFormik.handleChange}
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>Chandigarh</label>
                            </li>
                            <li className="col-md-4 col-6">
                              <span className="">Street</span>
                              {/* <input
                            className={styles.editable}
                            name={PROFILE.FORMIK.name}
                            onChange={profileDetailsFormik.handleChange}
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>Mall Road CHandigarh</label>
                            </li>
                            <li className="col-md-4 col-6">
                              <span className="">City</span>
                              {/* <input
                            className={styles.editable}
                            name={PROFILE.FORMIK.name}
                            onChange={profileDetailsFormik.handleChange}
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>Andre.g@gmail.com</label>
                            </li>
                            <li className="col-md-4 col-6">
                              <span className="">Contact Name</span>
                              {/* <input
                            className={styles.editable}
                            name={PROFILE.FORMIK.name}
                            onChange={profileDetailsFormik.handleChange}
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>Chandigarh</label>
                            </li>
                            <li className="col-md-4 col-6">
                              <span className="">Contact Phone</span>
                              {/* <input
                            className={styles.editable}
                            name={PROFILE.FORMIK.name}
                            onChange={profileDetailsFormik.handleChange}
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>+91 0987654321</label>
                            </li>
                            <li className="col-md-4 col-6">
                              <span className="">Contact Email</span>
                              {/* <input
                            className={styles.editable}
                            name={PROFILE.FORMIK.name}
                            onChange={profileDetailsFormik.handleChange}
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>Andre.g@gmail.com</label>
                            </li>
                          </ul>
                          <div className="">
                            <h4 className="text-14 fw-semibold text-blue">
                              + Add more
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className={styles.AboutEdit}>
                        <div className="row align-items-center justify-content-between mb-3">
                          <div className="col-8">
                            <div className="topTipHover">
                              <div className="d-flex align-items-center">
                                <h4 className="text-20 fw-bold  font-inter mb-0">
                                  Business Group
                                </h4>
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                              </div>
                            </div>
                          </div>
                          <div className="col-4 text-end">
                            <span>
                              <img src={penbl} alt="" />
                            </span>
                          </div>
                        </div>
                        <div className={styles.personalinfo}>
                          <ul className="row align-items-center">
                            <li className="col-md-4 col-6">
                              <span className="">Group Name</span>
                              {/* <input
                            className={styles.editable}
                            name={PROFILE.FORMIK.name}
                            onChange={profileDetailsFormik.handleChange}
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>Developer Team</label>
                            </li>
                          </ul>
                          <div className="">
                            <h4 className="text-16 fw-semibold">
                              Group Members
                            </h4>
                            <div className="row align-items-center">
                              <div className="col-4">
                                <div className="col border rounded-3">
                                  <div className="row flex-column align-items-center justify-content-between mb-2 bg-lightblue py-3 mx-0">
                                    <div className="row align-items-center justify-content-center gx-2">
                                      <div className="col-3 profile_icon">
                                        <figure className="profileCircle">
                                          <img
                                            src={dummy}
                                            alt=""
                                            className="img-fluid rounded-circle"
                                          />
                                        </figure>
                                      </div>
                                      <div className="col-9 ps-2">
                                        <div className="d-flex align-items-start justify-content-between">
                                          <div className="col-8">
                                            <div className="newheadingstyle">
                                              <h4 className="mb-0 text-16 clamp clamp1 fw-semibold">
                                                Justin C
                                              </h4>
                                              <p className="text-10 my-0 clamp clamp1 ">
                                                Robot Programmer{" "}
                                              </p>
                                              <p className="text-10 my-0 clamp clamp1 ">
                                                Reviews : 20{" "}
                                                <i>
                                                  <img src={star} alt="" />
                                                </i>
                                                4.5
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-2">
                                            <i>
                                              <img src={flag} alt="" />
                                            </i>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row align-items-center gx-2">
                                      <div className="col-10">
                                        <ul className="d-flex mb-0 align-items-center justify-content-start ps-0">
                                          <li className="d-inline-block">
                                            <p className="mb-0 bg-lightblue text-blue rounded-pill text-center text-11 d-inline-block px-2 py-1">
                                              Available
                                            </p>
                                            <p className="mb-0 ms-2 bg-lightgreen rounded-pill text-center text-11 d-inline-flex align-items-center px-2 py-1">
                                              <i className="me-1">
                                                <img src={aeroplane} alt="" />
                                              </i>
                                              Willing totravel : Yes
                                            </p>
                                          </li>
                                        </ul>
                                      </div>
                                      <div className="col-2">
                                        <figure className="bg-white rounded-circle px-2 py-1 mb-0 text-center">
                                          <img src={shareprofile} alt="" />
                                        </figure>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="p-2">
                                    <div className="skills">
                                      <h4 className="fw-semibold text-14 mb-0">
                                        Top Skills
                                      </h4>
                                      <ul className="ps-0 d-flex flex-wrap align-items-center justify-content-start gap-2 mx-0">
                                        <li className="d-inline-block pe-2">
                                          <p className="mb-0 bg-lightblue text-blue rounded-pill text-center text-11 d-inline-block px-2 py-1 fw-medium">
                                            Figma
                                          </p>
                                        </li>
                                      </ul>
                                    </div>
                                    <div>
                                      <h5 className="text-14">
                                        Self Rating :{" "}
                                        <i>
                                          <img src={selfrating} alt="" />
                                        </i>
                                      </h5>
                                    </div>
                                    <div className="mt-3">
                                      <div className="row align-items-center g-2">
                                        <div className="col">
                                          <button
                                            type="button"
                                            className="btn btn-primary w-100 text-10 px-1 text-center "
                                          >
                                            View Profile
                                          </button>
                                        </div>
                                        <div className="col">
                                          <button
                                            type="button"
                                            className="btn btn-primary w-100 text-10 px-1 text-center "
                                          >
                                            Shared data
                                          </button>
                                        </div>

                                        <div className="col">
                                          <button
                                            type="button"
                                            className="btn btn-white w-100 text-center border text-10 px-1"
                                          >
                                            Message
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="col border rounded-3">
                                  <div className="row flex-column align-items-center justify-content-between mb-2 bg-lightblue py-3 mx-0">
                                    <div className="row align-items-center justify-content-center gx-2">
                                      <div className="col-3 profile_icon">
                                        <figure className="profileCircle">
                                          <img
                                            src={dummy}
                                            alt=""
                                            className="img-fluid rounded-circle"
                                          />
                                        </figure>
                                      </div>
                                      <div className="col-9 ps-2">
                                        <div className="d-flex align-items-start justify-content-between">
                                          <div className="col-8">
                                            <div className="newheadingstyle">
                                              <h4 className="mb-0 text-16 clamp clamp1 fw-semibold">
                                                Justin C
                                              </h4>
                                              <p className="text-10 my-0 clamp clamp1 ">
                                                Robot Programmer{" "}
                                              </p>
                                              <p className="text-10 my-0 clamp clamp1 ">
                                                Reviews : 20{" "}
                                                <i>
                                                  <img src={star} alt="" />
                                                </i>
                                                4.5
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-2">
                                            <i>
                                              <img src={flag} alt="" />
                                            </i>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row align-items-center gx-2">
                                      <div className="col-10">
                                        <ul className="d-flex mb-0 align-items-center justify-content-start ps-0">
                                          <li className="d-inline-block">
                                            <p className="mb-0 bg-lightblue text-blue rounded-pill text-center text-11 d-inline-block px-2 py-1">
                                              Available
                                            </p>
                                            <p className="mb-0 ms-2 bg-lightgreen rounded-pill text-center text-11 d-inline-flex align-items-center px-2 py-1">
                                              <i className="me-1">
                                                <img src={aeroplane} alt="" />
                                              </i>
                                              Willing totravel : Yes
                                            </p>
                                          </li>
                                        </ul>
                                      </div>
                                      <div className="col-2">
                                        <figure className="bg-white rounded-circle px-2 py-1 mb-0 text-center">
                                          <img src={shareprofile} alt="" />
                                        </figure>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="p-2">
                                    <div className="skills">
                                      <h4 className="fw-semibold text-14 mb-0">
                                        Top Skills
                                      </h4>
                                      <ul className="ps-0 d-flex flex-wrap align-items-center justify-content-start gap-2 mx-0">
                                        <li className="d-inline-block">
                                          <p className="mb-0 bg-lightblue text-blue rounded-pill text-center text-11 d-inline-block px-2 py-1 fw-medium">
                                            Figma
                                          </p>
                                        </li>
                                      </ul>
                                    </div>
                                    <div>
                                      <h5 className="text-14">
                                        Self Rating :{" "}
                                        <i>
                                          <img src={selfrating} alt="" />
                                        </i>
                                      </h5>
                                    </div>
                                    <div className="mt-3">
                                      <div className="row align-items-center g-2">
                                        <div className="col">
                                          <button
                                            type="button"
                                            className="btn btn-primary w-100 text-10 px-1 text-center "
                                          >
                                            View Profile
                                          </button>
                                        </div>
                                        <div className="col">
                                          <button
                                            type="button"
                                            className="btn btn-primary w-100 text-10 px-1 text-center "
                                          >
                                            Shared data
                                          </button>
                                        </div>

                                        <div className="col">
                                          <button
                                            type="button"
                                            className="btn btn-white w-100 text-center border text-10 px-1"
                                          >
                                            Message
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-4">
                                <div className="col border rounded-3">
                                  <div className="row flex-column align-items-center justify-content-between mb-2 bg-lightblue py-3 mx-0">
                                    <div className="row align-items-center justify-content-center gx-2">
                                      <div className="col-3 profile_icon">
                                        <figure className="profileCircle">
                                          <img
                                            src={dummy}
                                            alt=""
                                            className="img-fluid rounded-circle"
                                          />
                                        </figure>
                                      </div>
                                      <div className="col-9 ps-2">
                                        <div className="d-flex align-items-start justify-content-between">
                                          <div className="col-8">
                                            <div className="newheadingstyle">
                                              <h4 className="mb-0 text-16 clamp clamp1 fw-semibold">
                                                Justin C
                                              </h4>
                                              <p className="text-10 my-0 clamp clamp1 ">
                                                Robot Programmer{" "}
                                              </p>
                                              <p className="text-10 my-0 clamp clamp1 ">
                                                Reviews : 20{" "}
                                                <i>
                                                  <img src={star} alt="" />
                                                </i>
                                                4.5
                                              </p>
                                            </div>
                                          </div>
                                          <div className="col-2">
                                            <i>
                                              <img src={flag} alt="" />
                                            </i>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row align-items-center gx-2">
                                      <div className="col-10">
                                        <ul className="d-flex mb-0 align-items-center justify-content-start ps-0">
                                          <li className="d-inline-block">
                                            <p className="mb-0 bg-lightblue text-blue rounded-pill text-center text-11 d-inline-block px-2 py-1">
                                              Available
                                            </p>
                                            <p className="mb-0 ms-2 bg-lightgreen rounded-pill text-center text-11 d-inline-flex align-items-center px-2 py-1">
                                              <i className="me-1">
                                                <img src={aeroplane} alt="" />
                                              </i>
                                              Willing totravel : Yes
                                            </p>
                                          </li>
                                        </ul>
                                      </div>
                                      <div className="col-2">
                                        <figure className="bg-white rounded-circle px-2 py-1 mb-0 text-center">
                                          <img src={shareprofile} alt="" />
                                        </figure>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="p-2">
                                    <div className="skills">
                                      <h4 className="fw-semibold text-14 mb-0">
                                        Top Skills
                                      </h4>
                                      <ul className="ps-0 d-flex flex-wrap align-items-center justify-content-start gap-2 mx-0">
                                        <li className="d-inline-block">
                                          <p className="mb-0 bg-lightblue text-blue rounded-pill text-center text-11 d-inline-block px-2 py-1 fw-medium">
                                            Figma
                                          </p>
                                        </li>
                                      </ul>
                                    </div>
                                    <div>
                                      <h5 className="text-14">
                                        Self Rating :{" "}
                                        <i>
                                          <img src={selfrating} alt="" />
                                        </i>
                                      </h5>
                                    </div>
                                    <div className="mt-3">
                                      <div className="row align-items-center g-2">
                                        <div className="col">
                                          <button
                                            type="button"
                                            className="btn btn-primary w-100 text-10 px-1 text-center "
                                          >
                                            View Profile
                                          </button>
                                        </div>
                                        <div className="col">
                                          <button
                                            type="button"
                                            className="btn btn-primary w-100 text-10 px-1 text-center "
                                          >
                                            Shared data
                                          </button>
                                        </div>

                                        <div className="col">
                                          <button
                                            type="button"
                                            className="btn btn-white w-100 text-center border text-10 px-1"
                                          >
                                            Message
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className=" mt-3">
                            <h4 className="text-14 fw-semibold text-blue">
                              + Add more
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className={styles.AboutEdit}>
                        <div className="row align-items-center justify-content-between mb-3">
                          <div className="col-8">
                            <div className="topTipHover">
                              <div className="d-flex align-items-center">
                                <h4 className="text-20 fw-bold  font-inter mb-0">
                                  Company Administration Contacts
                                </h4>
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                              </div>
                            </div>
                          </div>
                          <div className="col-4 text-end">
                            <span>
                              <img src={penbl} alt="" />
                            </span>
                          </div>
                        </div>
                        <div className={styles.personalinfo}>
                          <ul className="row align-items-center">
                            <li className="col-md-4 col-6">
                              <span className="">Name</span>
                              {/* <input
                            className={styles.editable}
                            name={PROFILE.FORMIK.name}
                            onChange={profileDetailsFormik.handleChange}
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>Company Name</label>
                            </li>
                          </ul>
                          <ul className="row align-items-center">
                            <li className="col-md-4 col-6">
                              <span className="">Email</span>
                              {/* <input
                            className={styles.editable}
                            name={PROFILE.FORMIK.name}
                            onChange={profileDetailsFormik.handleChange}
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>Chandigarh</label>
                            </li>
                            <li className="col-md-4 col-6">
                              <span className="">Phone</span>
                              {/* <input
                            className={styles.editable}
                            name={PROFILE.FORMIK.name}
                            onChange={profileDetailsFormik.handleChange}
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>Mall Road CHandigarh</label>
                            </li>
                            <li className="col-md-4 col-6">
                              <span className="">City</span>
                              {/* <input
                            className={styles.editable}
                            name={PROFILE.FORMIK.name}
                            onChange={profileDetailsFormik.handleChange}
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>Andre.g@gmail.com</label>
                            </li>
                          </ul>
                          <div className="">
                            <h4 className="text-14 fw-semibold text-blue">
                              + Add more
                            </h4>
                          </div>
                        </div>
                      </div>

                      <div className={styles.AboutEdit}>
                        <div className="row align-items-center justify-content-between mb-3">
                          <div className="col-8">
                            <div className="topTipHover">
                              <div className="d-flex align-items-center">
                                <h4 className="text-20 fw-bold  font-inter mb-0">
                                  Company Administration Contacts
                                </h4>
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                              </div>
                            </div>
                          </div>
                          <div className="col-4 text-end">
                            <span>
                              <img src={penbl} alt="" />
                            </span>
                          </div>
                        </div>
                        <div className={styles.personalinfo}>
                          <ul className="row align-items-center">
                            <li className="col-md-4 col-6">
                              <span className="">Name</span>
                              {/* <input
                            className={styles.editable}
                            name={PROFILE.FORMIK.name}
                            onChange={profileDetailsFormik.handleChange}
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>Company Name</label>
                            </li>
                          </ul>
                          <ul className="row align-items-center">
                            <li className="col-md-4 col-6">
                              <span className="">Email</span>
                              {/* <input
                            className={styles.editable}
                            name={PROFILE.FORMIK.name}
                            onChange={profileDetailsFormik.handleChange}
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>Chandigarh</label>
                            </li>
                            <li className="col-md-4 col-6">
                              <span className="">Phone</span>
                              {/* <input
                            className={styles.editable}
                            name={PROFILE.FORMIK.name}
                            onChange={profileDetailsFormik.handleChange}
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>Mall Road CHandigarh</label>
                            </li>
                            <li className="col-md-4 col-6">
                              <span className="">City</span>
                              {/* <input
                            className={styles.editable}
                            name={PROFILE.FORMIK.name}
                            onChange={profileDetailsFormik.handleChange}
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>Andre.g@gmail.com</label>
                            </li>
                          </ul>
                          <div className="">
                            <h4 className="text-14 fw-semibold text-blue">
                              + Add more
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className={styles.AboutEdit}>
                        <div className="row align-items-center justify-content-between mb-3">
                          <div className="col-8">
                            <div className="topTipHover">
                              <div className="d-flex align-items-center">
                                <h4 className="text-20 fw-bold  font-inter mb-0">
                                  Accounts Receivable Contact
                                </h4>
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                              </div>
                            </div>
                          </div>
                          <div className="col-4 text-end">
                            <span>
                              <img src={penbl} alt="" />
                            </span>
                          </div>
                        </div>
                        <div className={styles.personalinfo}>
                          <ul className="row align-items-center">
                            <li className="col-md-4 col-6">
                              <span className="">Name</span>
                              {/* <input
                            className={styles.editable}
                            name={PROFILE.FORMIK.name}
                            onChange={profileDetailsFormik.handleChange}
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>Company Name</label>
                            </li>
                          </ul>
                          <ul className="row align-items-center">
                            <li className="col-md-4 col-6">
                              <span className="">Email</span>
                              {/* <input
                            className={styles.editable}
                            name={PROFILE.FORMIK.name}
                            onChange={profileDetailsFormik.handleChange}
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>Chandigarh</label>
                            </li>
                            <li className="col-md-4 col-6">
                              <span className="">Phone</span>
                              {/* <input
                            className={styles.editable}
                            name={PROFILE.FORMIK.name}
                            onChange={profileDetailsFormik.handleChange}
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>Mall Road CHandigarh</label>
                            </li>
                            <li className="col-md-4 col-6">
                              <span className="">City</span>
                              {/* <input
                            className={styles.editable}
                            name={PROFILE.FORMIK.name}
                            onChange={profileDetailsFormik.handleChange}
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>Andre.g@gmail.com</label>
                            </li>
                          </ul>
                          <div className="">
                            <h4 className="text-14 fw-semibold text-blue">
                              + Add more
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className={styles.AboutEdit}>
                        <div className="row align-items-center justify-content-between mb-3">
                          <div className="col-8">
                            <div className="topTipHover">
                              <div className="d-flex align-items-center">
                                <h4 className="text-20 fw-bold  font-inter mb-0">
                                  Marketing Contact
                                </h4>
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                              </div>
                            </div>
                          </div>
                          <div className="col-4 text-end">
                            <span>
                              <img src={penbl} alt="" />
                            </span>
                          </div>
                        </div>
                        <div className={styles.personalinfo}>
                          <ul className="row align-items-center">
                            <li className="col-md-4 col-6">
                              <span className="">Name</span>
                              {/* <input
                            className={styles.editable}
                            name={PROFILE.FORMIK.name}
                            onChange={profileDetailsFormik.handleChange}
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>Company Name</label>
                            </li>
                          </ul>
                          <ul className="row align-items-center">
                            <li className="col-md-4 col-6">
                              <span className="">Email</span>
                              {/* <input
                            className={styles.editable}
                            name={PROFILE.FORMIK.name}
                            onChange={profileDetailsFormik.handleChange}
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>Chandigarh</label>
                            </li>
                            <li className="col-md-4 col-6">
                              <span className="">Phone</span>
                              {/* <input
                            className={styles.editable}
                            name={PROFILE.FORMIK.name}
                            onChange={profileDetailsFormik.handleChange}
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>Mall Road CHandigarh</label>
                            </li>
                            <li className="col-md-4 col-6">
                              <span className="">City</span>
                              {/* <input
                            className={styles.editable}
                            name={PROFILE.FORMIK.name}
                            onChange={profileDetailsFormik.handleChange}
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>Andre.g@gmail.com</label>
                            </li>
                          </ul>
                          <div className="">
                            <h4 className="text-14 fw-semibold text-blue">
                              + Add more
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className={styles.AboutEdit}>
                        <div className="row align-items-center justify-content-between mb-3">
                          <div className="col-8">
                            <div className="topTipHover">
                              <div className="d-flex align-items-center">
                                <h4 className="text-20 fw-bold  font-inter mb-0">
                                  Human Resources Contact
                                </h4>
                                <i>
                                  <img
                                    className="customeTolImg"
                                    src={tip}
                                    alt=""
                                  />
                                </i>
                              </div>
                            </div>
                          </div>
                          <div className="col-4 text-end">
                            <span>
                              <img src={penbl} alt="" />
                            </span>
                          </div>
                        </div>
                        <div className={styles.personalinfo}>
                          <ul className="row align-items-center">
                            <li className="col-md-4 col-6">
                              <span className="">Name</span>
                              {/* <input
                            className={styles.editable}
                            name={PROFILE.FORMIK.name}
                            onChange={profileDetailsFormik.handleChange}
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>Company Name</label>
                            </li>
                          </ul>
                          <ul className="row align-items-center">
                            <li className="col-md-4 col-6">
                              <span className="">Email</span>
                              {/* <input
                            className={styles.editable}
                            name={PROFILE.FORMIK.name}
                            onChange={profileDetailsFormik.handleChange}
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>Chandigarh</label>
                            </li>
                            <li className="col-md-4 col-6">
                              <span className="">Phone</span>
                              {/* <input
                            className={styles.editable}
                            name={PROFILE.FORMIK.name}
                            onChange={profileDetailsFormik.handleChange}
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>Mall Road CHandigarh</label>
                            </li>
                            <li className="col-md-4 col-6">
                              <span className="">City</span>
                              {/* <input
                            className={styles.editable}
                            name={PROFILE.FORMIK.name}
                            onChange={profileDetailsFormik.handleChange}
                            value={profileDetailsFormik.values?.name}
                          /> */}
                              <label>Andre.g@gmail.com</label>
                            </li>
                          </ul>
                          <div className="">
                            <h4 className="text-14 fw-semibold text-blue">
                              + Add more
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className={styles.AboutEdit}>
                        <h4 className="text-20 fw-bold  font-inter mb-0">
                          Interview Question
                        </h4>
                        <ul className="row align-items-center mt-3">
                          <li className="col-md-6 col-12 d-flex align-items-center mb-3">
                            <span>
                              <img
                                src={dummy}
                                alt=""
                                width={40}
                                height={40}
                                className="img-fluid rounded-circle"
                              />
                            </span>
                            <div className="ps-3">
                              <h6 className="text-14 mb-0">
                                Willing to work more than 60 miles from home
                                city
                              </h6>
                              <p className="text-12 mb-0">
                                Willing to work more than 60 miles from home
                                city
                              </p>
                            </div>
                          </li>

                          <li className="col-md-6 col-12 d-flex align-items-center mb-3">
                            <span>
                              <img
                                src={dummy}
                                alt=""
                                width={40}
                                height={40}
                                className="img-fluid rounded-circle"
                              />
                            </span>
                            <div className="ps-3">
                              <h6 className="text-14 mb-0">
                                Willing to work more than 60 miles from home
                                city
                              </h6>
                              <p className="text-12 mb-0">
                                Willing to work more than 60 miles from home
                                city
                              </p>
                            </div>
                          </li>
                          <li className="col-md-6 col-12 d-flex align-items-center mb-3">
                            <span>
                              <img
                                src={dummy}
                                alt=""
                                width={40}
                                height={40}
                                className="img-fluid rounded-circle"
                              />
                            </span>
                            <div className="ps-3">
                              <h6 className="text-14 mb-0">
                                Willing to work more than 60 miles from home
                                city
                              </h6>
                              <p className="text-12 mb-0">
                                Willing to work more than 60 miles from home
                                city
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                      {/* <PayRates
                        onChange={profileDetailsFormik.handleChange}
                        baseRate={PROFILE.FORMIK.baseRate}
                        overTimeRate={PROFILE.FORMIK.overTimeRate}
                        doubleTimeRate={PROFILE.FORMIK.doubleTimeRate}
                        baseRateValue={profileDetailsFormik.values?.baseRate?.toString()}
                        overTimeRateValue={profileDetailsFormik.values.overTimeRate?.toString()}
                        doubleTimeRateValue={profileDetailsFormik.values.doubleTimeRate?.toString()}
                      /> */}
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="px-3">
                      <div className={`text-center ${styles.spinner}`}>
                        <div className="spinner-border text-blue" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Editprofile;
