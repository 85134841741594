import React from "react";
import styles from "../styles/style.module.css";
import Search from "../../search/routes";
import info from "../../../assets/images/info_icon.svg";
import DateTime from "../../Datetimefilter/routes";
import SortFilter from "../../Sortfilter/routes";
import Endsore from "../../Endsore/routes/enduresments";
import Toltip from "../../common/toltip/page";
import tip from "../../../assets/images/tip.png";
import { CONSTANT_MANAGER } from "../../../utils/constans/constants";
const EndorseFriends = () => {
  return (
    <div className={styles.endorsefriends}>
      <div className="container">
        <div className={styles.new_feeds}>
          <div className="">
            <div className="row align-items-center justify-content-between mb-3">
              <div className="col-6">
                <Search />
              </div>
            </div>
            <div className="row align-items-center justify-content-between mb-3 g-3">
              <div className="col-lg-6 col-12">
                <div className="newheadingstyle">
                  <div className="topTipHover">
                    <div className="d-flex align-items-center">
                      <h4 className="form-check-label text-20 fw-bold  font-inter">
                        Endorse Your Friends
                      </h4>
                      <i>
                        <img className="customeTolImg" src={tip} alt="" />
                      </i>
                      <Toltip
                        slug={CONSTANT_MANAGER.SLUG_FEED.FEED}
                        labelId={
                          CONSTANT_MANAGER.FEED_DATA.ENDORSE_YOUR_FRIENDS
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="filter_sort">
                  <div className="ms-auto">
                    <div className="row align-items-center justify-content-end">
                      <div className="col-md-5">
                        <DateTime />
                      </div>

                      <div className="col-md-5">
                        <SortFilter />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Endsore />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EndorseFriends;
