import { axios } from "../../../lib/axios";
import { API_ROUTES } from "../../../utils/routes/apiRoutes";
import { Aggrement } from "../interface";

export const getSAAgrement = async (id: string|any,): Promise<Aggrement> => {
    return axios.get(`${API_ROUTES.AGGREMENT.SSA}/${id}`);
};

export const getMSAAgrement = async (id: string|any,userId:string|any): Promise<Aggrement> => {
    return axios.get(`${API_ROUTES.AGGREMENT.MSA}/${id}?userId=${userId}`);
};