import React from "react";

import Header from "../../header/routes";
import { useNavigate } from "react-router-dom";
import styles from "../styles/style.module.css";

function SortFilter() {
  return (
    <>
      <div className="d-flex align-items-center justify-content-end">
        <span className="me-2">Sort:</span>
        <div className="dropdown">
          <button
            className="dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            This Week
          </button>
          <ul className="dropdown-menu w-auto">
            <li>
              <a className="dropdown-item" href="#">
                Today
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                Last Week
              </a>
            </li>
            <li>
              <a className="dropdown-item" href="#">
                Month
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default SortFilter;
