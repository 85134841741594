import React from "react";
import left from "../../../../assets/images/left.png";
import styles from "../styles/sigup.module.css";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../../../redux/reducers/auth";
import { userNames } from "../validations";
import { AUTH_ROUTES } from "../../../../utils/routes/AuthRoutes";
import whitearrow from "../../../../assets/images/whitearrow.svg";
import HeaderContent from "../../../../shared/Components/HeaderContent/headerContent";

const PersonalInfo: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDetail = useSelector((state: any) => state.userData);

  const formik: any = useFormik({
    initialValues: {
      firstName: userDetail ? userDetail?.data?.firstName : "",
      lastName: userDetail ? userDetail?.data?.lastName : "",
    },
    validationSchema: userNames,
    onSubmit: (values) => {
      const userNamesObj = {
        firstName: values.firstName,
        lastName: values.lastName,
      };
      dispatch(setUserData(userNamesObj));
      navigate(AUTH_ROUTES.EMAIL_VERIFY);
    },
  });

  return (
    <>
      <HeaderContent />

      <div className="py-4 heightautoscroll">
        <div className={styles.signuppage}>
          <div className={styles.loginLeft}>
            <div className={styles.loginRight}>
              {/* <div className={styles.signupHead}>
						<div className={styles.leftSign}>
							<img src={logo} alt="" />
						</div>
						<div className={styles.rightSign}>
							<p className="mb-0">Already have an account? <a href="/">Sign in</a></p>
							<button>Need Help?</button>
						</div>
					</div> */}
              <div className={styles.signUPContainer} style={{padding: "15px"}}> 
                <form onSubmit={formik.handleSubmit}>
                  <h5>Enter your Name</h5>
                  <span className={styles.Leftntm}>
                    <a href="#">
                      <img src={left} alt="" onClick={() => navigate(-1)} />
                    </a>
                  </span>
                  <p>What is your full legal name?</p>
                  <div className={styles.fromGroup}>
                    <input
                      type="text"
                      placeholder="First Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      maxLength={25}
                      {...formik.getFieldProps("firstName")}
                    />
                    {formik.touched.firstName && (
                      <div className={styles.error}>
                        {formik.errors.firstName}
                      </div>
                    )}
                  </div>
                  <div className={styles.fromGroup}>
                    <input
                      type="text"
                      placeholder="Last Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      maxLength={25}
                      {...formik.getFieldProps("lastName")}
                    />
                    {formik.touched.lastName && (
                      <div className={styles.error}>
                        {formik.errors.lastName}
                      </div>
                    )}
                  </div>
                  {/* <div className={styles.fromGroup}>
								<input type='submit' value="Next ›" />
							</div> */}
                  <div className="col-lg-3 col-6 mx-auto">
                    <div className="text-center my-3 btn-hover-effect border rounded-pill">
                      {/* <input type="submit"/> */}
                      <button
                        type="submit"
                        className="btn bg-white arrowblue border-white border-1 rounded-pill position-relative py-lg-3 py-2 w-100 pe-5 text-center"
                      >
                        <span className="px-lg-4 px-2 text-12 fw-semibold w-100">
                          Next
                        </span>
                        <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                          <img src={whitearrow} alt="" />
                        </i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PersonalInfo;
