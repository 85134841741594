import React from "react";
import logo from "../../../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import left from "../../../../assets/images/left.png";
import { useFormik } from "formik";
import { EmailSchema, userName } from "../../signup/validations";
import styles from "../../signup/styles/sigup.module.css";
import { toast } from "react-toastify";
import { forgotPassword } from "../api";
import { MESSAGES } from "../../../../utils/messages";
import { APIResponseError, ForgetPasswordResponse } from "../interface";
import { AUTH_ROUTES } from "../../../../utils/routes/AuthRoutes";
import { setVerificationToken } from "../../../../redux/reducers/auth";
import { useDispatch } from "react-redux";
import whitearrow from "../../../../assets/images/whitearrow.svg";
import HeaderContent from "../../../../shared/Components/HeaderContent/headerContent";

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formik: any = useFormik({
    initialValues: {
      userName: "",
    },
    validationSchema: userName,
    onSubmit: (values) => {
      const payload = {
        userName: values?.userName,
      };
      toast.promise(forgotPassword(payload), {
        pending: {
          render() {
            return MESSAGES.LOGIN.VERIFY;
          },
        },
        success: {
          render({ data }) {
            let response = data as ForgetPasswordResponse;
            const verificationTokenObj = {
              userName: values.userName,
              verificationToken: response.data.verificationToken,
            };
            dispatch(setVerificationToken(verificationTokenObj));
            formik.resetForm();
            navigate(AUTH_ROUTES.OTP_VERIFICATION);
            return MESSAGES.SENDOTP.VERIFY;
          },
        },
        error: {
          render(error) {
            let errorMessage = error as APIResponseError;
            toast.error(errorMessage.data.message);
            return MESSAGES.LOGIN.INVALID;
          },
        },
      });
    },
  });

  return (
    <>
      <HeaderContent />
      <div className="bgbluegradient">
        <div className={styles.signuppage}>
          {/* <div className={styles.signupHead}>
				<div className={styles.leftSign}>
					<img src={logo} alt="" />
				</div>
				<div className={styles.rightSign}>
					<p className="mb-0">Already have an account? <a href="/">Sign in</a></p>
					<button>Need Help?</button>
				</div>
			</div> */}
          <div className={styles.signUPContainer}>
            <form onSubmit={formik.handleSubmit}>
              <h5>Forgot Password</h5>
              <span className={styles.Leftntm}>
                <a href="#">
                  <img src={left} alt="" onClick={() => navigate(-1)} />
                </a>
              </span>
              <p>
                {" "}
                Enter your email/phone address to receive a password reset link.
                Check your inbox for instructions on how to proceed
              </p>

              <div className={styles.fromGroup}>
                <div className={styles.resentOTP}>
                  <input
                    type="text"
                    placeholder="Email/Phone"
                    onChange={formik.handleChange}
                    value={formik.values.userName}
                    className={styles.userName}
                    {...formik.getFieldProps("userName")}
                  />
                  {formik.touched.userName && (
                    <div className={styles.error}>{formik.errors.userName}</div>
                  )}
                </div>
                <p>
                  {" "}
                  you would rather receive a verification
                  code
                </p>
                {/* <p className="mb-0">
                  {" "}
                  <a href="#">Receive a phone call?</a>
                </p> */}
              </div>
              {/* <div className={styles.fromGroup}>
						<input type='submit' value="Get OTP ›" />
					</div> */}
              <div className="col-6 col-xl-3  mx-auto">
                <div className="text-center my-3 btn-hover-effect border rounded-pill">
                  {/* <input type="submit"/> */}
                  <button
                    type="submit"
                    className="btn bg-white arrowblue border-grey border-1 rounded-pill position-relative py-lg-3 py-2 w-100 pe-5 text-center"
                  >
                    <span className="px-lg-4 px-2 text-12 fw-semibold w-100">Next</span>
                    <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                      <img src={whitearrow} alt="" />
                    </i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default ForgotPassword;
