import React from "react";
import styles from "../../css/styles/style.module.css"
import Header from "../../../header/routes";
import loc from "../../../../assets/images/loc.png";
import gprs from "../../../../assets/images/gprs.png";
import blsearch from "../../../../assets/images/blsearch.png";
import view from "../../../../assets/images/view.png";
import bags from "../../../../assets/images/bags.png";
import bags2 from "../../../../assets/images/bags2.png";
import bags3 from "../../../../assets/images/bag3.png";
import map from "../../../../assets/images/map.png";



const Findopportunity = () => {
  return (
    <>
      <Header />

      <div className={styles.FondOpportuniy}>
        <div className="container">
          <div className={styles.SearchData}>
            <div className={styles.FormGroup}>
              <span className="SeachIc">
                <img src={blsearch} alt="" />
              </span>
              <input
                type=" text"
                placeholder="Search by: Job tittle, Position, Keyword or People..."
              />
            </div>
            <div className={styles.FormGroup}>
              <span className="SeachIc">
                <img src={loc} alt="" />
              </span>
              <input type=" text" placeholder="City, state or zip code" />
              <span className={styles.SeachIcgps}>
                <img src={gprs} alt="" />
              </span>
            </div>
            <div className={styles.FormGroup}>
              <input type="submit" value="Search" />
            </div>
          </div>
          <div className={styles.FilerData}>
            <ul>
              <li>
                <span>People</span>
              </li>
              <li>
                <span>Hourly Contracts</span>
              </li>
              <li>
                <span>Direct Jobs</span>
              </li>
            
              <li>
                <button>
                  <img src={view} alt="" />
                  Map View
                </button>
              </li>
            </ul>
          </div>

          <div className="row mt-4">
            <div className="col-lg-4">
              <div className={styles.LeftOpportunity}>

                <div className={styles.ContarctOuter}>
                  <div className={styles.opprBox}>
                    <div className={styles.PendingCOnatctList}>
                      <h6>Robot Programming</h6>
                      <div className={styles.Loca}>
                        <p className={styles.Locate}>
                          <img src={loc} alt="" /> City, State
                        </p>
                      </div>
                      <span className={styles.Hrrate}> <img src={bags} alt="" /> Hourly Contract</span>
                      <div className={styles.PenigSkil}>
                        <h5>Skills</h5>
                        <ul>
                          <li>Lorem Ipsum</li>
                          <li>Figma</li>
                          <li>Adobe</li>
                        </ul>
                      </div>
                      <div className={styles.PenigSkilRate}>
                        <h5>Pay Rates</h5>
                        <ul>
                          <li>
                            <span>Base</span>
                            <label>$85</label>
                          </li>
                          <li>
                            <span>Overtime</span>
                            <label>$85</label>
                          </li>
                          <li>
                            <span>Sun/Holidays</span>
                            <label>$85</label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.ContarctOuter}>
                  <div className={styles.opprBox}>
                    <div className={styles.PendingCOnatctList}>
                      <h6>Robot Programming</h6>
                      <div className={styles.Loca}>
                        <p className={styles.Locate}>
                          <img src={loc} alt="" /> City, State
                        </p>
                      </div>
                      <span className={`${styles.Hrrate} ${styles.HrrateRed}`}>  <img src={bags2} alt="" />Hourly Contract</span>
                      <div className={styles.PenigSkil}>
                        <h5>Skills</h5>
                        <ul>
                          <li>Lorem Ipsum</li>
                          <li>Figma</li>
                          <li>Adobe</li>
                        </ul>
                      </div>
                      <div className={styles.PenigSkilRate}>
                        <h5>Pay Rates</h5>
                        <ul>
                          <li>
                            <span>Base</span>
                            <label>$85</label>
                          </li>
                          <li>
                            <span>Overtime</span>
                            <label>$180</label>
                          </li>
                          <li>
                            <span>Sun/Holidays</span>
                            <label>$185</label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.ContarctOuter}>
                  <div className={styles.opprBox}>
                    <div className={styles.PendingCOnatctList}>
                      <h6>Robot Programming</h6>
                      <div className={styles.Loca}>
                        <p className={styles.Locate}>
                          <img src={loc} alt="" /> City, State
                        </p>
                      </div>
                      <span className={`${styles.Hrrate} ${styles.Hrrate2}`}> <img src={bags3} alt="" /> Hourly Contract</span>
                      <div className={styles.PenigSkil}>
                        <h5>Skills</h5>
                        <ul>
                          <li>Lorem Ipsum</li>
                          <li>Figma</li>
                          <li>Adobe</li>
                        </ul>
                      </div>
                      <div className={styles.PenigSkilRate}>
                        <h5>Pay Rates</h5>
                        <ul>
                          <li>
                            <span>Base</span>
                            <label>$85</label>
                          </li>
                          <li>
                            <span>Overtime</span>
                            <label>$85</label>
                          </li>
                          <li>
                            <span>Sun/Holidays</span>
                            <label>$85</label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className={styles.RightOpportunity}>

                <div className={styles.ContarctOuter}>
                  <div className={styles.opprBox}>
                    <div className={styles.PendingCOnatctList}>
                      <h6 className={styles.name_robo}>Robot Programming</h6>
                      <div className={styles.Loca}>
                        <p className={styles.Locate}>
                          <img src={loc} alt="" /> City, State
                        </p>
                      </div>
                      <span className={styles.Hrrate}>Hourly Contract</span>
                      <div className={styles.PenigSkil}>
                        <h5>Job Description</h5>
                        <p className={styles.Peras}>
                          It is a long established fact that a reader will be
                          distracted by the readable content of a page when
                          looking at its layout. The point of using.
                        </p>
                      </div>
                      <div className={styles.PenigSkilRate22}>
                        <h5>Software Licenses</h5>
                        <div className={styles.SoftWareLicenece}>
                          <div className="row align-items-center w-100">
                            <div className="col-lg-6">
                              <ul className={`nav ${styles.TaglinesButton}`}>
                                <li><span className={styles.orangeButtons}>After Effects </span></li>
                                <li><span className={styles.greenButtons}>Figma </span></li>
                                <li><span className={styles.blueButtons}>Adobe </span></li>
                              </ul>
                            </div>
                            <div className="col-lg-6">
                              <ul className={`nav ${styles.Taglines}`}>
                                <li><span className={styles.greenBOx}></span>Good to have</li>
                                <li><span className={styles.blueBOx}> </span>Mandatory</li>
                              </ul>
                            </div>
                          </div>

                        </div>

                      </div>
                      <div className={styles.bordeerBottom}></div>
                      <div className={styles.PenigSkilRate}>
                        <h5 className={styles.payrate}>Pay Rates</h5>
                        <ul>
                          <li>
                            <span>Base</span>
                            <label>$85</label>
                          </li>
                          <li>
                            <span>Overtime</span>
                            <label>$85</label>
                          </li>
                          <li>
                            <span>Sun/Holidays</span>
                            <label>$85</label>
                          </li>
                        </ul>
                      </div>

                      <div className={styles.bordeerBottom}></div>
                      <h5 className={styles.payrate}>Other Details</h5>
                      <div className="row">
                        <div className="col-lg-7 col-sm-12">
                          <div className="row">
                            <div className="col-lg-6 col-sm-6">
                              <div className={styles.datesFlex}>
                                <h6>Start date </h6>
                                <p>11/13/2023</p>
                                <i className="far fa-calendar-alt"></i>
                              </div>
                            </div>

                            <div className="col-lg-6 col-sm-6">
                              <div className={styles.datesFlex}>
                                <h6>Duration</h6>
                                <p>1 week </p>
                                <i className="far fa-calendar-alt"></i>
                              </div>
                            </div>

                            <div className="col-lg-6 col-sm-6">
                              <div className={styles.datesFlex}>
                                <h6>People Needed </h6>
                                <p className="mb-0">1</p>
                                <i className="far fa-calendar-alt"></i>
                              </div>
                            </div>

                            <div className="col-lg-6 col-sm-6">
                              <div className={styles.datesFlex}>
                                <h6>Day Shift </h6>
                                <p className="mb-0">1 week</p>
                                <i className="far fa-calendar-alt"></i>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className={styles.bordeerBottom}></div>
                          <h5 className={styles.payrate}>Expenses</h5>
                          <div className="row">
                            <div className="col-lg-7 col-sm-12">
                              <div className="row">
                                <div className="col-lg-6 col-sm-6">
                                  <div className={styles.datesFlex2}>
                                    <h6>Daily Per Diam </h6>
                                    <p className={styles.dollar}>$500</p>
                                  </div>
                                </div>

                                <div className="col-lg-6 col-sm-6">
                                  <div className={styles.datesFlex2}>
                                    <h6>Duration</h6>
                                    <p className={styles.dollar}>$200 </p>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className={styles.bordeerBottom}></div>
                          <h5 className={styles.payrate}>Tools, Machines and equipment</h5>
                          <div className={styles.PenigSkil}>
                            <ul>
                              <li>Lorem Ipsum</li>
                              <li>Figma</li>
                              <li>Adobe</li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className={styles.bordeerBottom}></div>
                          <h5 className={styles.payrate}>Procedure and Policies</h5>
                          <p className={styles.Peras}>
                            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using.
                          </p>
                        </div>

                        <div className="col-md-12">
                          <div className={styles.bordeerBottom}></div>
                          <div className={styles.flexbtns}>
                            <button type="button" className={`btn ${styles.commonbtn} ${styles.applybtn}`}>Apply now ›</button>
                            <button type="button" className={`btn ${styles.commonbtn} ${styles.sharebtn}`}>Share </button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>


                {/* map view */}
                <div className={styles.mapView}>
                  <img src={map} alt="" className="img-fluid"/>
                </div>

                {/* end */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="col-md-9">
        <div className="RightOpportunity">
          <div className={styles.ContarctOuter}>
            <div className={styles.opprBox}>
              <div className={styles.PendingCOnatctList}>
                <h6>Robot Programming</h6>
                <div className={styles.Loca}>
                  <p>
                    <img src={loc} alt="" /> City, State
                  </p>
                </div>
                <span className={styles.Hrrate}>Hourly Contract</span>
                <div className={styles.PenigSkil}>
                  <h5>Job Description</h5>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using.
                  </p>
                </div>
                <div className={styles.PenigSkilRate}>
                  <h5>Software Licenses</h5>
                  <div className={styles.SoftWareLicenece}>
                    <div>
                      <span>After Effects</span>
                      <span>Figma</span>
                      <span>Adobe</span>
                    </div>
                    <div className={styles.Tagline}>
                      <label>
                        <span></span>Good to have
                      </label>
                      <label>
                        <span></span>Mandatory
                      </label>
                    </div>
                  </div>
                </div>
                <div className={styles.PenigSkilRate}>
                  <h5>Pay Rates</h5>
                  <ul>
                    <li>
                      <span>Base</span>
                      <label>$85</label>
                    </li>
                    <li>
                      <span>Base</span>
                      <label>$85</label>
                    </li>
                    <li>
                      <span>Base</span>
                      <label>$85</label>
                    </li>
                  </ul>
                </div>
                <div className={styles.OtherDetails}>
                  <h5>Other Details</h5>
                  <ul>
                    <li>
                      <span>
                        <img src={timeiing} alt="" />
                      </span>
                      <div>
                        <span>Start date </span>
                        <label>11/13/2023</label>
                      </div>
                    </li>
                    <li>
                      <span>
                        <img src={timeiing} alt="" />
                      </span>
                      <div>
                        <span>Duration </span>
                        <label>1 week</label>
                      </div>
                    </li>
                    <li>
                      <span>
                        <img src={man} alt="" />
                      </span>
                      <div>
                        <span>People Needed </span>
                        <label>1</label>
                      </div>
                    </li>
                    <li>
                      <span>
                        <img src={clocl} alt="" />
                      </span>
                      <div>
                        <span>Day Shift </span>
                        <label>1 week</label>
                      </div>
                    </li>
                    <li>
                      <span>
                        <img src={clocl} alt="" />
                      </span>
                      <div>
                        <span>Planned Hrs Per Day </span>
                        <label>5 hrs</label>
                      </div>
                    </li>
                    <li>
                      <span>
                        <img src={clocl} alt="" />
                      </span>
                      <div>
                        <span>Planned Days per week </span>
                        <label>1 week</label>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className={styles.PenigSkilRate}>
                  <h5>Expenses </h5>
                  <ul>
                    <li>
                      <span>Daily Per Diam</span>
                      <label>$500</label>
                    </li>
                    <li>
                      <span>Airfare Allowance</span>
                      <label>$200</label>
                    </li>
                  </ul>
                </div>
                <div className={`${styles.borderadd} ${styles.PenigSkil}`}>
                  <h5>Tools, Machines and equipment</h5>
                  <ul>
                    <li>Lorem Ipsum</li>
                    <li>Figma</li>
                    <li>Adobe</li>
                  </ul>
                </div>
                <div className={styles.PenigSkil}>
                  <h5>Procedure and Policies</h5>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using.
                  </p>
                </div>
                <div className={styles.ApplyBtn}>
                  <button className="cmbtn">Apply now ›</button>
                  <button className="cmbtntwo">Share</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default Findopportunity;
