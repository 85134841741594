import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import share from '../../../assets/images/share_grey.svg'
import addicon from '../../../assets/images/add_icon_grey.svg'
import styles from "../styles/style.module.css"
import Slider from 'react-slick'
import { ConnectWithFriendsResponse } from '../interface'
import { ConnectWithFriends } from '../api'
import dummy from "../../../assets/images/dummy.jpeg"
import { sendRequest } from '../api'
import { toast } from "react-toastify";
import { MESSAGES } from '../../../utils/messages'

const ConnectFrnds = () => {
  const [connectionReccomandation, setConnectionReccomandations] = useState<ConnectWithFriendsResponse[]>([]);
  const [requestedConnections, setRequestedConnections] = useState<Set<string>>(new Set());

  const getConnectFriends = async () => {
    const response = await ConnectWithFriends();
    setConnectionReccomandations(response.data);
  }
  useEffect(() => {
    getConnectFriends()
  }, []);

  const handleConnect = async (id: string) => {
    const payload = {
      connectionId: id,
    };
  
    // Show initial confirmation alert
    const initialResult = await Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to send a connection request?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Connect!',
      cancelButtonText: 'No, cancel',
    });
  
    if (initialResult.isConfirmed) {
      try {
        await toast.promise(sendRequest(payload), {
          pending: MESSAGES.SEND_REQ.PENDING,
          success: MESSAGES.SEND_REQ.SUCCESS,
          error: MESSAGES.SEND_REQ.ERROR,
        });
  
        setRequestedConnections(prev => new Set(prev.add(id)));
  
        // Show confirmation alert after the request is successfully sent
        await Swal.fire({
          title: 'Request Sent',
          text: 'Your connection request has been successfully sent.',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      } catch (err) {
        console.log(err);
      }
    }
  };
  

  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 4.2,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3.2,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <>
      <div className='recommendedjobs share_connect_friends'>
        <div className="row align-items-center justify-content-between">
          <Slider {...settings}>
            {connectionReccomandation?.map((data, idx) => {
              const isRequested = requestedConnections.has(data._id);
              return (
                <div key={idx} className='col border rounded-3  p-lg-3 p-2 text-center'>
                  <div className=' mb-2 mx-auto'>
                    <div className='newheadingstyle text-center'>
                      <figure className={styles.profileCircle} ><img src={data?.profilePicture ? data?.profilePicture : dummy} alt='' /></figure>
                      <h3 className='mb-0 text-16'>{data?.name}</h3>
                      <p className='text-12 mb-2'>{data?.address}</p>
                    </div>
                  </div>
                  <div className='mt-3'>
                    <div className='row align-items-center g-2'>
                      <div className='col'>
                        <button
                          type='button'
                          className={`btn w-100 text-center border text-12 d-flex align-items-center justify-content-center ${isRequested ? 'text-muted' : 'text-grey'}`}
                          disabled={isRequested}
                          onClick={() => !isRequested && handleConnect(data._id)}
                        >
                          <i className='me-1'>
                            <img src={addicon} alt='' width={15} height={14} />
                          </i>
                          {isRequested ? 'Requested' : 'Connect'}
                        </button>
                      </div>
                      <div className='col'><button type='button' className='btn btn-white w-100 text-center border text-12 d-flex align-items-center justify-content-center text-grey'><i className='me-1'><img src={share} alt='' width={15} height={14} /></i>Share</button>
                      </div>
                    </div>
                  </div>
                </div>
              
              )
            })}
          </Slider>
 
        </div>
      </div>
    </>
  );
}

export default ConnectFrnds