import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import reject from "../../../../assets/images/dlt.png";
import accepticon from "../../../../assets/images/acceptone.png";
import styles from "../styles/style.module.css";
import Slider from "react-slick";
import { ConnectWithFriendsResponse } from "../interface/interface";
import dummy from "../../../../assets/images/dummy.jpeg";
import { toast } from "react-toastify";
import { MESSAGES } from "../../../../utils/messages";
import { ConnectionRequest, AcceptRequest } from "../api";
import { STATUS } from "../interface/interface";

const ManageRequest = () => {
  const [connectionReccomandation, setConnectionReccomandations] = useState<
    ConnectWithFriendsResponse[]
  >([]);
  const [requestedConnections, setRequestedConnections] = useState<Set<string>>(new Set());


  const getConnectFriends = async () => {
    const response = await ConnectionRequest();
    setConnectionReccomandations(response.data);
  };

  useEffect(() => {
    getConnectFriends();
  }, []);

  const handleAction = async (value: string, id: string) => {
    const initialResult = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to accept a connection request?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Accept!",
      cancelButtonText: "No, cancel",
    });

    if (initialResult.isConfirmed) {
      try {
        const payload = {
          status: value,
        };
        await toast.promise(AcceptRequest(payload, id), {
          pending: MESSAGES.SEND_REQ.PENDING,
          success: MESSAGES.SEND_REQ.SUCCESS,
          error: MESSAGES.SEND_REQ.ERROR,
        });
        setRequestedConnections(prev => new Set(prev.add(id)));
        // Show confirmation alert after the request is successfully sent
        await Swal.fire({
          title: "Request Accepted",
          text: "You both are now friends.",
          icon: "success",
          confirmButtonText: "OK",
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 4.2,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: false,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3.2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  
  return (
    <>
      <div className="recommendedjobs share_connect_friends">
        <div className="row align-items-center justify-content-between">
          <Slider {...settings}>
            {connectionReccomandation?.map((data, idx) => {
                 const isRequested = requestedConnections.has(data._id);
              return (
                <div
                  key={idx}
                  className="col border rounded-3  p-lg-3 p-2 text-center"
                >
                  <div className=" mb-2 mx-auto">
                    <div className="newheadingstyle text-center">
                      <figure className={styles.profileCircle}>
                        <img
                          src={
                            data?.user?.profilePicture ? data?.user?.profilePicture : dummy
                          }
                          alt=""
                        />
                      </figure>
                      <h3 className="mb-0 text-16">{data?.user?.name}</h3>
                    </div>
                  </div>
                  <div className="mt-3">
                    <div className="row align-items-center g-2">
                      <div className="col">
                        <button
                          type="button"
                          className={`btn w-100 text-center border text-12 d-flex align-items-center justify-content-center`}
                          onClick={() =>isRequested && handleAction(STATUS.ACCEPT, data._id)}
                          disabled={isRequested}
           
                        >
                          <i className="me-1">
                            <img
                              src={accepticon}
                              alt=""
                              width={15}
                              height={14}
                            />
                          </i>
                          Accept
                        </button>
                      </div>
                      <div className="col">
                        <button
                          type="button"
                          className="btn btn-white w-100 text-center border text-12 d-flex align-items-center justify-content-center text-grey"
                          onClick={() => handleAction(STATUS.REJECT, data._id)}
                        >
                          <i className="me-1">
                            <img src={reject} alt="" width={15} height={14} />
                          </i>
                          Reject
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default ManageRequest;
