import React, { useEffect, useState } from 'react'
import styles from "../../common/timesheets/styles/styles/style.module.css"
import dlt from "../../../assets/images/dlt.png"
import companyLogo from "../../../assets/images/companyLogo.png"
import loc from "../../../assets/images/loc.png"
import infogr from "../../../assets/images/infogr.png"
import crtick from "../../../assets/images/crtick.png"
import pnedit from "../../../assets/images/pnedit.png"
import print from "../../../assets/images/print.png"
import dnlod from "../../../assets/images/dnlod.png"
import logo from "../../../assets/images/logo.png"
import Header from '../../header/routes'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import { getexpenseById } from './api'
import { getMondayOfCurrentWeek, getWeekDaysMap } from '../../../utils/daysMap'

function Invoice() {

    const { id } = useParams();
    const navigate=useNavigate();
    const [expenseDetails, setExpenseDetails] = useState<any>();

    const approvedExpense = async () => {
        const response: any = await getexpenseById(id);
        setExpenseDetails(response?.data);
    };

    useEffect(() => {
        approvedExpense();
    }, [])

    return (
        <>
            <Header />
            <div className={styles.pageOuter}>
                <div className='container'>


                    <div className={styles.rightContainer}>
                        <h2>Invoices</h2>

                        <div className={`${styles.baseInfo} ${styles.noMarg}`}>
                            <h4>Standrad Hourly Contracts <img src={infogr} alt="" /></h4>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className={styles.innerbaseinfo}>
                                        <div className={styles.leftInfo}>
                                            <img src={companyLogo} alt="" />
                                            <h6>#{expenseDetails?.work?.workNumber}</h6>
                                            <label>{expenseDetails?.work?.workType === "hourly_contract" ? "Hourly Contract" : ""}</label>
                                        </div>
                                        <div className={styles.rightInfo}>
                                            <h5>{expenseDetails?.work?.companyDetails?.name}</h5>
                                            <h5>{expenseDetails?.work?.address}</h5>
                                            <h5>{expenseDetails?.work?.occupation?.name}</h5>
                                            <h6><img src={loc} alt="" />{expenseDetails?.work?.street}</h6>
                                            <h6>Rate PerHour : ${expenseDetails?.work?.rates?.baseRate} (Net 90)</h6>
                                            <ul>
                                                <li>Start Date:{moment(expenseDetails?.work?.times.startDate).format("MMM DD, YYYY")}
                                                </li>
                                                <li> Est End Date: {moment(expenseDetails?.work?.times.endDate).format("MMM DD, YYYY")}</li>
                                                <li> Expense Terms : Net 30</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className={styles.innerbaseinfo}>
                                        <div className={styles.leftInfo}>
                                            <img src={expenseDetails?.user?.profilePicture} alt="" />
                                            <h6>#{expenseDetails?.work?.workNumber}</h6>
                                            <label>Contractor</label>
                                        </div>
                                        <div className={styles.rightInfo}>
                                            <h5>{expenseDetails?.user?.name}</h5>
                                            {/* <h5>{timeSheetStatusData?.work?.user?.}</h5> */}
                                            {/* <h5>{timeSheetStatusData?.user?.occupation?.name}</h5> */}
                                            <h6><img src={loc} alt="" />Evansville. IN</h6>
                                            <h6>Rate PerHour : $76.32 (Net 90)</h6>
                                            <ul>
                                                <li>Start Date: 05/29/2023</li>
                                                <li> Est End Date: 03/31/2024</li>
                                                <li> Expense Terms : Net 30</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={`${styles.timeSheetdetilshead} ${styles.AddSpace}`}>
                                <h4>Timesheet details  Details <img src={infogr} alt="" /></h4>
                                <div className={styles.editBtns}>
                                    <button>View Signed Timesheet </button>
                                    <button><img src={dlt} alt="" /> Delete</button>
                                    <button><img src={crtick} alt="" /> Notify</button>
                                    <button><img src={pnedit} alt="" /> Edit</button>
                                    <button><img src={print} alt="" /> Print</button>
                                    <button><img src={dnlod} alt="" /> Export Data</button>
                                </div>
                            </div>
                            <div className={`${styles.timeSheetdetils} ${styles.noMarg}`}>
                                <div className={styles.invoiceLogo}>
                                    <img src={logo} alt="" />
                                    <h3>INvoice</h3>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className={styles.leftInvoice}>
                                            <ul>
                                                <li>
                                                    <span><b>Week start Date: 09/12/27</b></span>
                                                    <span><b>Week End Date: 09/12/27</b></span>
                                                </li>
                                            </ul>
                                            <ul>
                                                <h6>Invoice to:</h6>
                                                <li><span>Customer</span><label>Therapy System</label></li>
                                                <li><span>Customer</span><label>Therapy System</label></li>
                                                <li><span>Customer</span><label>Therapy System</label></li>
                                                <li><span>Customer</span><label>Therapy System</label></li>

                                                <li><span>Customer</span><label>Therapy System</label></li>
                                                <li><span>Customer</span><label>Therapy System</label></li>
                                            </ul>
                                            <ul>
                                                <h6>Invoice to:</h6>
                                                <li><span>Customer</span><label>Therapy System</label></li>
                                                <li><span>Customer</span><label>Therapy System</label></li>
                                                <li><span>Customer</span><label>Therapy System</label></li>
                                                <li><span>Customer</span><label>Therapy System</label></li>

                                                <li><span>Customer</span><label>Therapy System</label></li>
                                                <li><span>Customer</span><label>Therapy System</label></li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div className={styles.leftInvoice}>

                                            <ul>
                                                <h6>Invoice Details:</h6>
                                                <li><span>Invoice No.</span><label>CR61677H</label></li>
                                                <li><span>Invoice No.</span><label>CR61677H</label></li>
                                                <li><span>Invoice No.</span><label>CR61677H</label></li>
                                                <li><span>Invoice No.</span><label>CR61677H</label></li>
                                                <li><span>Invoice No.</span><label>CR61677H</label></li>
                                            </ul>
                                            <ul>
                                                <h6>Supplier Deposit Information:</h6>
                                                <li><span>Bank Name</span><label>Therapy System</label></li>
                                                <li><span>Routing #:</span><label>Therapy System</label></li>
                                                <li><span>Bank Name</span><label>Therapy System</label></li>
                                                <li><span>Routing #:</span><label>Therapy System</label></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.BillingRate}>
                                    <table>
                                        <tr>
                                            <th>Description</th>
                                            <th>Hours</th>
                                            <th>Bill Rate</th>
                                            <th>Total</th>
                                        </tr>
                                        <tr>
                                            <td>Travel Time Program</td>
                                            <td><b>12.34</b></td>
                                            <td>$85.000</td>
                                            <td><b>$23.99</b></td>
                                        </tr>
                                    </table>
                                </div>
                                <div className={`${styles.leftInvoice} ${styles.CustomInvoice}`}>
                                    <ul>
                                        <li><span>Per Dim Food</span><label>$134</label></li>
                                        <li><span>Per Dim Food</span><label>$134</label></li>
                                        <li><span>Per Dim Food</span><label>$134</label></li>
                                        <li><span>Per Dim Food</span><label>$134</label></li>
                                        <li><span>Per Dim Food</span><label>$134</label></li>
                                    </ul>
                                    <ul className={styles.SammInvoice}>
                                        <li><span>Per Dim Food</span><label>$134</label></li>
                                        <li><span>Per Dim Food</span><label>$134</label></li>
                                        <li><span>Per Dim Food</span><label>$134</label></li>
                                        <li><span>Per Dim Food</span><label>$134</label></li>
                                        <li><span>Per Dim Food</span><label>$134</label></li>
                                    </ul>
                                </div>
                                <div className={styles.addComment}>
                                    <p><b>Approved By : </b> </p>
                                    <p><b>Name:</b> Jacob Sancher</p>

                                </div>
                                <div className={styles.PayBTn} onClick={()=>navigate("/invoice")}>
                                    <button className='cmbtn'>Pay Now</button>
                                </div>






                            </div>
                        </div>
                    </div></div>
            </div>
        </>
    )
}

export default Invoice