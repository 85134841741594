import React, { useEffect, useState } from "react";
import Header from "../../../header/routes";
import exportt from "../../../../assets/images/exportt.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styles from "../styles/styles/style.module.css";
import { APP_ROUTES } from "../../../../utils/routes/AppRoutes";
import moment from "moment";
import { getTimesheet } from "../api";
import { TIMESHEET_OR_EXPENSE_TYPE } from "../../../../utils/enums/timesheetOrExpenseStatusTypes";
import WhiteGloveContractDetails from "../../Contracts/whiteGloveHourlyContract/whileGloveHourlyContract";
import { getNextMonday } from "../../../../utils/getNextMonday";


const TimesheetDetailTable = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const ongoingContractsDetails = location?.state?.workdata
  const [loader, setLoader] = useState<boolean>(true);
  const [timesheetData, setTimeSheetData] = useState<any>();
  const [lastCreateTimeSheet, setLastCreateTimeSheet] = useState<any>("");
  const [activeStatus, setActiveStatus] = useState<string>(TIMESHEET_OR_EXPENSE_TYPE.CURRENT);
  const [currentOngoingData, setCurrentOngoingData] = useState<any>([])

  const refreshdata = (id: string, type: string) => {
    const getTimesheetdata = async () => {
      const response: any = await getTimesheet(id, type);
      setLastCreateTimeSheet(response?.data[0]?.createdAt ? response?.data[0]?.createdAt : "")
      setTimeSheetData(response?.data);
      setLoader(false);
    };
    getTimesheetdata();
  };

  const handleShowTimesheetStatus = (value: string) => {
    setActiveStatus(value);
    refreshdata(id as string, value);
    setLoader(false);
  };
  useEffect(() => {
    refreshdata(id as string, TIMESHEET_OR_EXPENSE_TYPE.CURRENT);
    setLoader(false);
  }, []);

  useEffect(() => {
    setCurrentOngoingData(ongoingContractsDetails)
  }, []);


  const handleViewStatusDetails = (timesheetId: string) => {
    navigate(`${APP_ROUTES.TIMESHEET_STATUS}/${timesheetId}`, {
      state: { activeStatus, currentOngoingData },
    });
  };
  const handleEditTimesheet = (timesheetId: string) => {
    navigate(`${APP_ROUTES.EDITTIMESHEET}/${id}/${timesheetId}`);
  };
  const handleAddTimesheet = (workId: any) => {
    navigate(`${APP_ROUTES.ADDTIMESHEET}/${workId}`);
  };

  return (
    <>
      <Header />
      <div className={styles.TimeShettTable}>
        <div className="container">
          <ul className={styles.timeSheetFilter}>
            <li
              className={activeStatus === "current" ? styles.active : ""}
              onClick={() =>
                handleShowTimesheetStatus(TIMESHEET_OR_EXPENSE_TYPE.CURRENT)
              }
            >
              <span>Current Timesheet</span>
            </li>
            <li
              className={activeStatus === "due" ? styles.active : ""}
              onClick={() =>
                handleShowTimesheetStatus(TIMESHEET_OR_EXPENSE_TYPE.DUE)
              }
            >
              <span>Due Timesheet</span>
            </li>
            <li
              className={activeStatus === "pending" ? styles.active : ""}
              onClick={() =>
                handleShowTimesheetStatus(TIMESHEET_OR_EXPENSE_TYPE.PENDING)
              }
            >
              <span>Pending Timesheet</span>
            </li>
            <li
              className={activeStatus === "approved" ? styles.active : ""}
              onClick={() =>
                handleShowTimesheetStatus(TIMESHEET_OR_EXPENSE_TYPE.APPROVED)
              }
            >
              <span>Approved</span>
            </li>
          </ul>
          <div className={styles.yimesheetBrd}>
            <WhiteGloveContractDetails ongoingContractDetails={currentOngoingData} />
            <div className="container">
              <div className={styles.TimeShettTableOuter}>
                <div className={styles.TimeShettTableHead}>
                  <h3>Timesheet Details</h3>
                  <div>
                    <button>
                      {" "}
                      <img src={exportt} alt="" />
                      Export Data
                    </button>
                    {moment(lastCreateTimeSheet).format("YYYY-MM-DD") != moment(new Date()).format("YYYY-MM-DD") && moment(getNextMonday(lastCreateTimeSheet)).format("YYYY-MM-DD") >= moment(new Date()).format("YYYY-MM-DD") &&
                      <button
                        onClick={() => handleAddTimesheet(currentOngoingData?._id)}
                      >
                        Add New Timesheet
                      </button>}

                  </div>
                </div>
                {loader ? (
                  <div className="spinner-grow text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <table>
                    <tr>
                      <th>WEEK START DATE</th>
                      <th>WEEK END DATE</th>
                      <th>Straight Time</th>
                      <th>OVER TIME</th>
                      <th>TOTAL HOURS</th>
                      <th>CREATEDDATE</th>
                      {/* <th>CREATED BY</th> */}
                      <th>APPROVED BY</th>
                      <th>APPROVED DATE</th>
                      <th>STATUS</th>
                      <th>ACTIONS</th>
                    </tr>
                    {timesheetData?.length > 0 ? (
                      timesheetData?.map((data: any, idx: number) => {
                        return (
                          <>
                            <tr>
                              <td key={idx}>
                                {moment(data.startDate).format("MMM DD, YYYY")}
                              </td>
                              <td>
                                {moment(data.endDate).format("MMM DD, YYYY")}
                              </td>
                              <td>{data?.totalStraightTime}</td>
                              <td>{data?.overTime}</td>
                              <td>{data?.totalHours}</td>
                              <td>
                                {moment(data.createdAt).format("MMM DD, YYYY")}
                              </td>
                              {/* <td>NA</td> */}
                              <td>{data?.approvedBy?.name}</td>
                              <td>
                                {moment(data.approvedDate).format(
                                  "MMM DD, YYYY"
                                )}
                              </td>
                              <td>
                                {data?.status === "draft" ? (
                                  <span>Not filled</span>
                                ) : data?.status === "pending" ? (
                                  <span className={styles.pending}>
                                    Pending
                                  </span>
                                ) : data?.status === "approved" ? (
                                  <span className={styles.approvedf}>
                                    Approved
                                  </span>
                                ) : data?.status === "rejected" ? (
                                  <span className={styles.Decloine}>
                                    Declined
                                  </span>
                                ) : (
                                  ""
                                )}
                              </td>

                              <td>
                                {data?.status === "draft" ? (
                                  <span
                                    onClick={() =>
                                      handleEditTimesheet(data?._id)
                                    }
                                  >
                                    Edit
                                  </span>
                                ) : data?.status === "approved" ? (
                                  <span
                                    onClick={() =>
                                      handleViewStatusDetails(data?._id)
                                    }
                                  >
                                    View Details
                                  </span>
                                ) : data?.status === "pending" ? (
                                  <span
                                    onClick={() =>
                                      handleViewStatusDetails(data?._id)
                                    }
                                  >
                                    View Details
                                  </span>
                                ) : data?.isDue === true ? (
                                  <span
                                    onClick={() =>
                                      handleViewStatusDetails(data?._id)
                                    }
                                  >
                                    View Details
                                  </span>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <p style={{ textAlign: "center" }}>
                        No Timesheet Data Available!
                      </p>
                    )}
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TimesheetDetailTable;
