export interface ConnectWithFriendsResponse {
    _id: string;
    user:{
        name: string;
        profilePicture: string;
    },
    pagination: Pagination;
}

interface Pagination {
    currentPage: number;
    lastPage: number;
    nextPage: number;
    limit: number;
    totalRecord: number;
    totalPage: number;
}

export interface AcceptReq {
    status: string;
}

export enum STATUS{
    ACCEPT = 'accepted',
    REJECT = 'rejected',
}