import React, { useEffect, useState } from "react";
import styles from "../../css/styles/style.module.css"
import loc from "../../../../assets/images/loc.png";
import Header from "../../../header/routes";
import rdinfo from "../../../../assets/images/rdinfo.png";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Confirm from "../OpenContracts/modals/Confirm";
import { toast } from "react-toastify";
import { APP_ROUTES } from "../../../../utils/routes/AppRoutes";
import { COUNT_TYPE, WORK_APPLY_TYPE } from "../../../../utils/enums/types";
import ContractData from "./fullDetailsContract";
import ViewInterViewQuestions from "./viewInterviewQuestions";
import { ContractDetails, getEventDetails } from "../OpenContracts/interface";
import { changeWorkStatus, getEventsById, getFilteredContractsByStatus, getWorkDetail } from "../OpenContracts/api";
import InterviewScheduleModal from "../../modals/ScheduleInterviewModal/InterviewScheduleModal";
import { useAppSelector } from "../../../../redux/hooks";
import { setVideoJoinConfig } from "../../../../redux/reducers/addVideoData";
import { useDispatch } from "react-redux";

const DetailOpenContract = () => {
  const [interviewScheduled, setInterviewScheduled] = useState<boolean>();
  const { id } = useParams();
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(true);
  const [openContractDetails, setOpenContractDetails] = useState<any>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [selectedContractForInterviewQuestion, setselectedContractForInterviewQuestion] = useState<ContractDetails | null>(null);
  const [selectedId, setSelectedId] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [professionalId, setProfessionalId] = useState<string>("")
  const [professionalName, setProfessionalName] = useState<string>("")
  const [filteredContacts, setFilteredContracts] = useState<ContractDetails[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  //JISHH
  const [getUserId, setGetUserId] = useState<string>("");
  const [getWorkId, setGetWorkId] = useState<string>("");
  const [eventResponseData, setEventResponseData] = useState<getEventDetails[]>([]);
  // hardcoded values temp
  let eventType = "interview"
  let interviewType = "video"


  const openModal = (contract: any) => {
    setIsModalOpen(true);
    setProfessionalId(contract.user._id)
    setProfessionalName(contract.user?.name)
  }
  const closeModal = () => setIsModalOpen(false);
  const [activeSelectedContractType, setActiveSelectedContractType] =
    useState<any>(WORK_APPLY_TYPE.RECEIVED);

  useEffect(() => { openContractDetailsList(); }, []);

  const handleCallback = (data: boolean) => {
    setInterviewScheduled(data);
    setIsModalOpen(false);
    closeModal();
  };

  const openContractDetailsList = async () => {
    const response: any = await getWorkDetail(id, COUNT_TYPE.WORK_APPLY);
    setOpenContractDetails(response?.data);
  };

  const refresh = (type: string) => {
    setLoading(true);
    setActiveSelectedContractType(type);
    const filteredContractList = async () => {
      const response = await getFilteredContractsByStatus(id, type);
      setFilteredContracts(response?.data);
      let internalUserId = response.data[0]?.userId
      let internalWorkId = response.data[0]?.workId
      const getEventDetails = async () => {
        const responseEvent: any = await getEventsById(internalUserId, internalWorkId, eventType, interviewType)
        setEventResponseData(responseEvent?.data)
      }
      await getEventDetails()
      setLoading(false);
    };
    filteredContractList();
  };



  const interviewEventTime = eventResponseData[0]?.eventTime

  useEffect(() => {
    // start call 5 min before the interview time 
    const startCall = () => {
      const eventTime = moment(eventResponseData[0]?.eventTime, "HH:mm").valueOf();
      const currentTime = +new Date();
      const timeDifference = eventTime - currentTime;
      if (timeDifference <= 5 * 60 * 1000) {
        setIsDisabled(false)
      }
    };
    startCall();

    const interval = setInterval(startCall, 1000);
    return () => clearInterval(interval);
  }, [interviewEventTime]);

  const handleStatusChange = (id: string, status: string) => {
    setSelectedId(id);
    setSelectedStatus(status);
    // changeWorkStatus(id, status,openContractDetails?._id).then((res) => {
    //     refresh(activeSelectedContractType
    // })
    setConfirmModal(true);
  };
  const handleClose = () => { setConfirmModal(false); };
  const changeStatus = () => {
    toast.promise(
      changeWorkStatus(selectedId, selectedStatus, openContractDetails?._id),
      {
        pending: {
          render() {
            return "Changing Status";
          },
        },
        success: {
          render({ }) {
            refresh(activeSelectedContractType);
            handleClose();
            return "Status Changed Successfully";
          },
        },
        error: {
          render(error) {
            return "Status Change Failed";
          },
        },
      }
    );
  };
  useEffect(() => { refresh(WORK_APPLY_TYPE.RECEIVED); }, []);

  const handleFilterContracts = async (value: string) => { refresh(value); };
  const handleShowInterviewQus = (value: ContractDetails) => { setselectedContractForInterviewQuestion(value); };
  const handleViewUserProfile = (value: ContractDetails) => { navigate(`${APP_ROUTES.USER_PROFILE}/${value.userId}`); };


  const isInterviewAlreadyScheduleForToday = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0");
    let yyyy = today.getFullYear();
    let currentDate = yyyy + "-" + mm + "-" + dd;

    let isInterviewScheduled = eventResponseData?.filter((item: any) =>
      item.eventType == "interview"
      && item.workId['_id'] === id
      && item.eventType === "interview"
      && item.interviewType === "video"
      && moment(item.eventDate).format("YYYY-MM-DD") === currentDate
    );
    return isInterviewScheduled?.length > 0 ? true : false
  }

  let interviewToday = isInterviewAlreadyScheduleForToday()
  const handleJoinCall = () => navigate(`${APP_ROUTES.VIDEO_CALL.INTERVIEW_CABIN}`);
  const eventDateInUTC = moment.utc(eventResponseData[0]?.eventDate).local().format("MM-DD-YYYY hh:mm A");

  return (
    <>
      <Header />
      <InterviewScheduleModal
        isOpen={isModalOpen}
        onClose={closeModal}
        professional_id={professionalId}
        work_id={openContractDetails?._id}
        occupation_name={openContractDetails?.occupation?.name}
        professional_name={professionalName}
        interviewScheduled={handleCallback} />
      {selectedContractForInterviewQuestion && (
        <ViewInterViewQuestions
          userId={selectedContractForInterviewQuestion.userId}
          workId={selectedContractForInterviewQuestion.workId}
          onClose={() => setselectedContractForInterviewQuestion(null)}
        />
      )}
      <div className="OpenContarct">
        <Confirm
          action="Select"
          handleClose={handleClose}
          showModal={confirmModal}
          id={selectedId}
          status={selectedStatus}
          workStatus={openContractDetails.id}
          changeStatus={changeStatus}
        />

        <div className="container">
          <div className={styles.PendingCOnatctList}>
            <div className={styles.contractPast}>
              <>
                <ContractData />
              </>
              <div className={styles.ClientProjects}>
                <div className="row">
                  <div className="col-md-3">
                    <div className={styles.clientProjectInner}>
                      <h5>Applications Received</h5>
                      <span>{openContractDetails?.receivedApplication}</span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className={styles.clientProjectInner}>
                      <h5>Shortlisted Applicants</h5>
                      <span>
                        {openContractDetails?.shortlistedApplication}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className={styles.clientProjectInner}>
                      <h5>Offers Pending</h5>
                      <span>{openContractDetails?.pendingApplication}</span>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div
                      className={`${styles.clientProjectInner} ${styles.RejectedAppl}`}
                    >
                      <h5>Rejected Applicants</h5>
                      <span>{openContractDetails?.rejectedApplication}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.OpenContactOuter}>
                <div className="container">
                  <div className={styles.commonNav}>
                    <ul>
                      <li
                        className={
                          activeSelectedContractType === "received"
                            ? styles.active
                            : ""
                        }
                        onClick={() =>
                          handleFilterContracts(WORK_APPLY_TYPE.RECEIVED)
                        }
                      >
                        <span>Applications Received</span>
                      </li>
                      <li
                        className={
                          activeSelectedContractType === "applied"
                            ? styles.active
                            : ""
                        }
                        onClick={() =>
                          handleFilterContracts(WORK_APPLY_TYPE.APPLIED)
                        }
                      >
                        <span>Offer Pending</span>
                      </li>
                      <li
                        className={
                          activeSelectedContractType === "accepted"
                            ? styles.active
                            : ""
                        }
                        onClick={() =>
                          handleFilterContracts(WORK_APPLY_TYPE.SHORTLISTED)
                        }
                      >
                        <span>Shortlisted Applicants</span>
                      </li>
                      <li
                        className={
                          activeSelectedContractType === "selected"
                            ? styles.active
                            : ""
                        }
                        onClick={() =>
                          handleFilterContracts(WORK_APPLY_TYPE.SELECTED)
                        }
                      >
                        <span>Applications Selected</span>
                      </li>
                      <li
                        className={
                          activeSelectedContractType === "rejected"
                            ? styles.active
                            : ""
                        }
                        onClick={() =>
                          handleFilterContracts(WORK_APPLY_TYPE.REJECTED)
                        }
                      >
                        <span>Rejected Applications</span>
                      </li>
                    </ul>
                  </div>
                  {!loading ? (
                    <div className={styles.OuterSlides}>
                      <div className={styles.ContarctOuter}>
                        {filteredContacts?.length > 0 ? (
                          filteredContacts?.map((contract) => {
                            if (
                              activeSelectedContractType ===
                              WORK_APPLY_TYPE.RECEIVED
                            ) {
                              return (
                                <div
                                  key={contract._id}
                                  className={styles.contractPast}
                                >
                                  <div className={styles.ContarctHead}>
                                    <div className={styles.leftContract}>
                                      <h5>
                                        Contract ID : #
                                        {contract.worksInfo.workNumber}
                                      </h5>
                                    </div>
                                    <div className={styles.leftContract}>
                                      <button className={styles.shedule}>
                                        {contract.status === "applied"
                                          ? "Applied"
                                          : contract.status === "accepted"
                                            ? "Shortlisted"
                                            : contract.status === "rejected"
                                              ? "Rejected"
                                              : contract.status === "selected"
                                                ? "Selected"
                                                : ""}
                                      </button>
                                    </div>
                                  </div>
                                  <div className={styles.outerNameDetail}>
                                    <div className="row align-items-center">
                                      <div className="col-md-12">
                                        <div className={styles.leftName}>
                                          <h4>Professional Details</h4>
                                        </div>
                                      </div>
                                      <div className="col-lg-2 col-12">
                                        <div className={styles.leftName}>
                                          <div className={styles.PrileImg}>
                                            <figure>
                                              <img
                                                src={
                                                  contract.user.profilePicture
                                                }
                                                alt=""
                                                style={{ width: "100px", minHeight: "70px" }}
                                                className=" img-fluid rounded-circle"
                                              />
                                            </figure>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-5 col-6">
                                        <div className={styles.leftName}>
                                          <div className={styles.PrileImgs}>
                                            <h5>{contract.user?.name}</h5>
                                            <span>
                                              {contract.user.occupation?.name}
                                            </span>
                                            <p>
                                              <img src={loc} alt="" />{" "}
                                              {contract?.user?.country?.name}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-5 col-6">
                                        <div className={styles.rightName}>
                                          <p>
                                            Start Date:{" "}
                                            {
                                              <span>
                                                {moment(
                                                  contract.worksInfo.times
                                                    .startDate
                                                ).format("MMM DD, YYYY")}
                                              </span>
                                            }
                                          </p>
                                          <p>
                                            End Date:{" "}
                                            {
                                              <span>
                                                {moment(
                                                  contract.worksInfo.times
                                                    .startDate
                                                ).format("MMM DD, YYYY")}
                                              </span>
                                            }
                                          </p>
                                          <p>
                                            Rate Per Hour : $
                                            {contract.worksInfo.rates.baseRate}
                                          </p>
                                          <p>
                                            Expense Terms : net$
                                            {
                                              contract.expensePayment
                                                .paymentTerm
                                            }
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>


                                  {contract.status ===
                                    WORK_APPLY_TYPE.APPLIED && (
                                      <div className={styles.VideoCallBtnreject}>
                                        <div className={styles.VideoCallBtn}>
                                          {/* <button><i className="far fa-times-circle"></i> Cancel Contract</button> */}
                                          {/* <button onClick={() => handleStatusChange(contract._id, WORK_APPLY_TYPE.REJECTED)} className=''><i className="far fa-times-circle"></i> View Profile </button>
                                                                <button onClick={() => handleStatusChange(contract._id, WORK_APPLY_TYPE.SELECTED)} className={styles.videBtn}><i className="far fa-check-circle"></i> View Interview Questions</button>
                                                                <button className={styles.videBtn}><i className="fas fa-video"></i>Shortlist</button> */}
                                          <button onClick={() => handleStatusChange(contract._id, WORK_APPLY_TYPE.SELECTED)} >
                                            Select{" "}
                                          </button>
                                          <button onClick={() => handleShowInterviewQus(contract)} type="button" >
                                            {" "}
                                            View Interview Questions
                                          </button>
                                          <button onClick={() => handleStatusChange(contract._id, WORK_APPLY_TYPE.SHORTLISTED)}  >  Shortlist
                                          </button>
                                          <button onClick={() => handleStatusChange(contract._id, WORK_APPLY_TYPE.REJECTED)}>
                                            Reject
                                          </button>
                                        </div>
                                      </div>
                                    )
                                  }
                                  {contract.status === WORK_APPLY_TYPE.SHORTLISTED && (
                                    <div className={styles.VideoCallBtn}>
                                      <button onClick={() => handleStatusChange(contract._id, WORK_APPLY_TYPE.SELECTED)
                                      } className={styles.videBtn}  >
                                        <i className="far fa-check-circle"></i>{" "}  Select </button>
                                      <button onClick={() => handleStatusChange(contract._id, WORK_APPLY_TYPE.REJECTED)}
                                        className={styles.videBtn} >
                                        <i className="far fa-times-circle"></i>{" "}
                                        Cancel Request
                                      </button>

                                      {interviewToday ? (
                                        <button
                                          className="videBtn joinCallBtn"
                                          style={{ background: isDisabled ? "grey" : "green" }}
                                          onClick={handleJoinCall}
                                          disabled={isDisabled}
                                        >
                                          <i className="fas fa-video"></i> Join Call At {eventDateInUTC}
                                        </button>
                                      ) : (
                                        <button
                                          className="videBtn"
                                          onClick={() => {
                                            if (interviewScheduled) {
                                              alert("Interview already scheduled! Please try again later.");
                                            } else {
                                              openModal(contract);
                                              setInterviewScheduled(true);
                                            }
                                          }}
                                        >
                                          <i className="fas fa-video"></i> {interviewScheduled ? "Interview Scheduled" : "Schedule Interview"}
                                        </button>
                                      )}


                                      {/* <button className="videBtn" onClick={() => openModal(contract)}>
                                        <i className="fas fa-video"></i> {interviewScheduled ? 'Scheduled Inerview Successfully!' : 'Schedule Interview'}
                                      </button>
                                      <button className="videBtn" style={{ background: "green" }} onClick={() => navigate(`${APP_ROUTES.VIDEO_CALL.HOST}`)}>
                                        <i className="fas fa-video"></i> {interviewScheduled ? 'Join Call!' : 'Schedule Interview'}
                                      </button> */}
                                    </div>
                                  )
                                  }
                                  {contract.status === WORK_APPLY_TYPE.SELECTED && (
                                    <>
                                      <div className={styles.VideoCallBtn}>
                                        <button onClick={() => handleViewUserProfile(contract)}>
                                          View Full Profile{" "}
                                        </button>
                                        <button onClick={() => handleStatusChange(contract._id, WORK_APPLY_TYPE.REJECTED)}
                                        >
                                          <i className="far fa-times-circle"></i>{" "}
                                          Reject
                                        </button>
                                      </div>
                                    </>
                                  )}
                                  {contract.status === WORK_APPLY_TYPE.REJECTED && (
                                    <div className={styles.VideoCallBtn}>
                                      {/* <button><i className="far fa-times-circle"></i> Cancel Contract</button> */}
                                      <button className="videBtn">
                                        <i className="fas fa-video"></i> Remove
                                      </button>
                                      <button
                                        onClick={() => handleStatusChange(contract._id, WORK_APPLY_TYPE.APPLIED)}
                                        className={styles.videBtn}
                                      >
                                        <i className="fas fa-undo"></i> Unreject{" "}
                                      </button>
                                      {/* <button><i className="far fa-times-circle"></i> Remove</button> */}
                                    </div>
                                  )}

                                  <div className={styles.Infor}>
                                    <p>
                                      <img src={rdinfo} alt="" /> If you cancel
                                      a project that you committed to, your
                                      account will be subject to cancelation.
                                    </p>
                                  </div>
                                </div>
                              );
                            } else if (
                              activeSelectedContractType ===
                              WORK_APPLY_TYPE.APPLIED
                            ) {
                              return (
                                <div
                                  key={contract._id}
                                  className={styles.contractPast}
                                >
                                  <div className={styles.ContarctHead}>
                                    <div className={styles.leftContract}>
                                      <h5>
                                        Contract ID : #
                                        {contract?.worksInfo?.workNumber}
                                      </h5>
                                    </div>
                                    <div className={styles.leftContract}>
                                      <button className={styles.shedule}>
                                        {contract.status
                                        }
                                      </button>
                                    </div>
                                  </div>
                                  <div className={styles.outerNameDetail}>
                                    <div className="row align-items-center">
                                      <div className="col-md-6">
                                        <div className={styles.leftName}>
                                          <h4>Contractor Details</h4>
                                          <div className={styles.PrileImg}>
                                            <span>
                                              <img
                                                src={
                                                  contract.user.profilePicture
                                                }
                                                alt=""
                                              />
                                            </span>
                                            <div>
                                              <h5>{contract.user?.name}</h5>
                                              <span>
                                                {contract.user.occupation?.name}
                                              </span>
                                              <p>
                                                <img src={loc} alt="" />{" "}
                                                {contract.worksInfo.address}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className={styles.rightName}>
                                          <p>
                                            Start Date:{" "}
                                            {
                                              <span>
                                                {moment(
                                                  contract.worksInfo.times
                                                    .startDate
                                                ).format("MMM DD, YYYY")}
                                              </span>
                                            }
                                          </p>
                                          <p>
                                            End Date:{" "}
                                            {
                                              <span>
                                                {moment(
                                                  contract.worksInfo.times
                                                    .startDate
                                                ).format("MMM DD, YYYY")}
                                              </span>
                                            }
                                          </p>
                                          <p>
                                            Rate Per Hour : $
                                            {contract.worksInfo.rates.baseRate}
                                          </p>
                                          <p>
                                            Expense Terms : net$
                                            {
                                              contract.expensePayment
                                                .paymentTerm
                                            }
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={styles.VideoCallBtn}>
                                    <button
                                      onClick={() =>
                                        handleStatusChange(
                                          contract._id,
                                          WORK_APPLY_TYPE.SHORTLISTED
                                        )
                                      }
                                    >
                                      <i className="far fa-check-circle"></i>{" "}
                                      Shortlist
                                    </button>
                                    <button
                                      onClick={() =>
                                        handleViewUserProfile(contract)
                                      }
                                    >
                                      View Full Profile{" "}
                                    </button>
                                    <button
                                      onClick={() =>
                                        handleStatusChange(
                                          contract._id,
                                          WORK_APPLY_TYPE.REJECTED
                                        )
                                      }
                                    >
                                      <i className="far fa-times-circle"></i>{" "}
                                      Reject
                                    </button>
                                  </div>
                                  <div className={styles.Infor}>
                                    <p>
                                      <img src={rdinfo} alt="" /> If you cancel
                                      a project that you committed to, your
                                      account will be subject to cancelation.
                                    </p>
                                  </div>
                                </div>
                              );
                            } else if (
                              activeSelectedContractType ===
                              WORK_APPLY_TYPE.SHORTLISTED
                            ) {
                              return (
                                <>
                                  <div className={styles.ContarctHead}>
                                    <div className={styles.leftContract}>
                                      <h5>
                                        Contract ID : #
                                        {contract?.worksInfo?.workNumber}
                                      </h5>
                                    </div>
                                    <div className={styles.leftContract}>
                                      <button className={styles.shedule}>
                                        {contract.status === "accepted"
                                          ? "Shortlisted"
                                          : ""}
                                      </button>
                                    </div>
                                  </div>
                                  <div className={styles.outerNameDetail}>
                                    <div className="row align-items-center">
                                      <div className="col-md-6">
                                        <div className={styles.leftName}>
                                          <h4>Contractor Details</h4>
                                          <div className={styles.PrileImg}>
                                            <span>
                                              <img
                                                src={
                                                  contract.user.profilePicture
                                                }
                                                alt=""
                                              />
                                            </span>
                                            <div>
                                              <h5>{contract.user?.name}</h5>
                                              <span>
                                                {contract.user.occupation?.name}
                                              </span>
                                              <p>
                                                <img src={loc} alt="" />{" "}
                                                {contract.worksInfo.address}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className={styles.rightName}>
                                          <p>
                                            Start Date:{" "}
                                            {
                                              <span>
                                                {moment(
                                                  contract.worksInfo.times
                                                    .startDate
                                                ).format("MMM DD, YYYY")}
                                              </span>
                                            }
                                          </p>
                                          <p>
                                            End Date:{" "}
                                            {
                                              <span>
                                                {moment(
                                                  contract.worksInfo.times
                                                    .startDate
                                                ).format("MMM DD, YYYY")}
                                              </span>
                                            }
                                          </p>
                                          <p>
                                            Rate Per Hour : $
                                            {contract.worksInfo.rates.baseRate}
                                          </p>
                                          <p>
                                            Expense Terms : net$
                                            {
                                              contract.expensePayment
                                                .paymentTerm
                                            }
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={styles.VideoCallBtn}>
                                    <button
                                      onClick={() =>
                                        handleStatusChange(
                                          contract._id,
                                          WORK_APPLY_TYPE.SELECTED
                                        )
                                      }
                                      className={styles.videBtn}
                                    >
                                      <i className="far fa-check-circle"></i>{" "}
                                      Select
                                    </button>

                                    <button
                                      onClick={() =>
                                        handleStatusChange(
                                          contract._id,
                                          WORK_APPLY_TYPE.REJECTED
                                        )
                                      }
                                    >
                                      <i className="far fa-times-circle"></i>{" "}
                                      Cancel Request
                                    </button>
                                    <button className="videBtn">
                                      <i className="fas fa-video"></i> Request
                                      for Video Call
                                    </button>
                                  </div>
                                  <div className={styles.Infor}>
                                    <p>
                                      <img src={rdinfo} alt="" /> If you cancel
                                      a project that you committed to, your
                                      account will be subject to cancelation.
                                    </p>
                                  </div>
                                </>
                              );
                            } else if (
                              activeSelectedContractType ===
                              WORK_APPLY_TYPE.SELECTED
                            ) {
                              return (
                                <>
                                  <div className={styles.ContarctHead}>
                                    <div className={styles.leftContract}>
                                      <h5>
                                        Contract ID : #
                                        {contract?.worksInfo?.workNumber}
                                      </h5>
                                    </div>
                                    <div className={styles.leftContract}>
                                      <button className={styles.shedule}>
                                        {contract.status === "selected" ? "Selected" : ""}
                                      </button>
                                    </div>
                                  </div>
                                  <div className={styles.outerNameDetail}>
                                    <div className="row align-items-center">
                                      <div className="col-md-6">
                                        <div className={styles.leftName}>
                                          <h4>Contractor Details</h4>
                                          <div className={styles.PrileImg}>
                                            <span>
                                              <img
                                                src={
                                                  contract.user.profilePicture
                                                }
                                                alt=""
                                              />
                                            </span>
                                            <div>
                                              <h5>{contract.user?.name}</h5>
                                              <span>
                                                {contract.user.occupation?.name}
                                              </span>
                                              <p>
                                                <img src={loc} alt="" />{" "}
                                                {contract.worksInfo.address}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className={styles.rightName}>
                                          <p>
                                            Start Date:{" "}
                                            {
                                              <span>
                                                {moment(
                                                  contract.worksInfo.times
                                                    .startDate
                                                ).format("MMM DD, YYYY")}
                                              </span>
                                            }
                                          </p>
                                          <p>
                                            End Date:{" "}
                                            {
                                              <span>
                                                {moment(
                                                  contract.worksInfo.times
                                                    .startDate
                                                ).format("MMM DD, YYYY")}
                                              </span>
                                            }
                                          </p>
                                          <p>
                                            Rate Per Hour : $
                                            {contract.worksInfo.rates.baseRate}
                                          </p>
                                          <p>
                                            Expense Terms : net$
                                            {
                                              contract.expensePayment
                                                .paymentTerm
                                            }
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={styles.VideoCallBtn}>
                                    <button
                                      onClick={() =>
                                        handleViewUserProfile(contract)
                                      }
                                    >
                                      View Full Profile{" "}
                                    </button>
                                  </div>
                                  <div className={styles.Infor}>
                                    <p>
                                      <img src={rdinfo} alt="" /> If you cancel
                                      a project that you committed to, your
                                      account will be subject to cancelation.
                                    </p>
                                  </div>
                                </>
                              );
                            } else {
                              return (
                                <div
                                  key={contract._id}
                                  className={styles.contractPast}
                                >
                                  <div className={styles.ContarctHead}>
                                    <div className={styles.leftContract}>
                                      <h5>
                                        Contract ID : #
                                        {contract?.worksInfo?.workNumber}
                                      </h5>
                                    </div>
                                    <div className={styles.leftContract}>
                                      <button className={styles.shedule}>
                                        {contract.status}
                                      </button>
                                    </div>
                                  </div>
                                  <div className={styles.outerNameDetail}>
                                    <div className="row align-items-center">
                                      <div className="col-md-6">
                                        <div className={styles.leftName}>
                                          <h4>Contractor Details</h4>
                                          <div className={styles.PrileImg}>
                                            <span>
                                              <img
                                                src={
                                                  contract.user.profilePicture
                                                }
                                                alt=""
                                              />
                                            </span>
                                            <div>
                                              <h5>{contract.user?.name}</h5>
                                              <span>
                                                {contract.user.occupation?.name}
                                              </span>
                                              <p>
                                                <img src={loc} alt="" />{" "}
                                                {contract.worksInfo.address}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className={styles.rightName}>
                                          <p>
                                            Start Date:{" "}
                                            {
                                              <span>
                                                {moment(
                                                  contract.worksInfo.times
                                                    .startDate
                                                ).format("MMM DD, YYYY")}
                                              </span>
                                            }
                                          </p>
                                          <p>
                                            End Date:{" "}
                                            {
                                              <span>
                                                {moment(
                                                  contract.worksInfo.times
                                                    .startDate
                                                ).format("MMM DD, YYYY")}
                                              </span>
                                            }
                                          </p>
                                          <p>
                                            Rate Per Hour : $
                                            {contract.worksInfo.rates.baseRate}
                                          </p>
                                          <p>
                                            Expense Terms : net$
                                            {
                                              contract.expensePayment
                                                .paymentTerm
                                            }
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={styles.VideoCallBtn}>
                                    {/* <button><i className="far fa-times-circle"></i> Cancel Contract</button> */}
                                    <button className="videBtn">
                                      <i className="fas fa-video"></i> Remove
                                    </button>
                                    <button
                                      onClick={() =>
                                        handleStatusChange(
                                          contract._id,
                                          WORK_APPLY_TYPE.APPLIED
                                        )
                                      }
                                      className={styles.videBtn}
                                    >
                                      <i className="fas fa-undo"></i> Unreject{" "}
                                    </button>
                                    {/* <button><i className="far fa-times-circle"></i> Remove</button> */}
                                  </div>
                                  <div className={styles.Infor}>
                                    <p>
                                      <img src={rdinfo} alt="" /> If you cancel
                                      a project that you committed to, your
                                      account will be subject to cancelation.
                                    </p>
                                  </div>
                                </div>
                              );
                            }
                          })
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className={`text-center`}>
                        <div
                          className="spinner-border text-blue"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </>
  );
};
export default DetailOpenContract;
