import React, { FC, useEffect, useState } from "react";
import hand from "../../../assets/images/hand.png";
import thdots from "../../../assets/images/thdots.png";
import ylbtn from "../../../assets/images/ylbtn.png";
import topTip from "./styles/style.module.css";
// import styles from "../../work/styles/style.module.css"
// @ts-ignore
import { useSpeechSynthesis, SpeakOptions } from 'react-speech-kit';

import { ToltipProps } from "./interface";

import { getHelpTextByFilter } from "./api";

const Toltip: FC<ToltipProps> = ({
  slug,
  labelId
}) => {
  const { speak,cancel } = useSpeechSynthesis()
  const [toolTipText, setToolTipText] = useState<string>("");
  useEffect(() => {
    // console.log('slug', slug, labelId)
    const fetchTooltipData = async () => {
      if (slug && labelId) {
        const response = await getHelpTextByFilter(slug, labelId);

        if (response.data){
          // console.log("Tooltip API response:--->", response.data.text);

          setToolTipText(response.data.text);
        }
        else {
          console.error("Tooltip API response data is undefined.");
          // Handle the case where data is undefined
        }
      };

    }
    fetchTooltipData();

  }, [slug, labelId]);
  const handleSpeak = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const options: SpeakOptions = { text: toolTipText };
    speak(options);
  };
  const handleStop = () => {
    cancel(); // Stop the speech synthesis when the button is hovered out
  };
  return (
    <div className="cuatomeTop">
      <div className={`MobOTP ${topTip.OuterToptip}`}>
        <div className={topTip.Toltip}>
          <div className={topTip.topTiphead}>
            {/* <h4>
              Hi Johns! <img src={hand} alt="" />
            </h4>
            <h5>How can we help?</h5> */}
            {/* <span  ><i className="fas fa-times"></i></span> */}
          </div>
          <div className={topTip.whtTol}>
            <p>{toolTipText}</p>
            {/* <p>
              You can quickly choose what to post according to your preference.
            </p> */}
            {/* <p>
              You can quickly select what to post based on your preferences.
              This allows you to tailor your content to suit your style and
              interests.
            </p> */}
          </div>
          <div className={topTip.Listen}>
            <span>
              <img src={thdots} alt="" width={"40px"} height={"40px"} />
            </span>
            <button className="cmbtn" onClick={handleSpeak} onMouseLeave={handleStop}>Start Listening</button>
            {/* <button className={topTip.cntTol}>
              Contact Us <img src={ylbtn} alt="" />
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Toltip;
