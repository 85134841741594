import React from "react";
import styles from "../../components/work/styles/style.module.css"
import { useDispatch, useSelector } from "react-redux";
import group from "../../assets/images/Group1686558045.png";
import whitearrow from "../../assets/images/whitearrow.svg";
import { RootState } from "../../redux/store";
import { setLegalDetails } from "../../redux/reducers/addContract";


const Post = (props: any) => {
  const dispatch = useDispatch()
  const { legalDetails } = useSelector((state: RootState) => state.addContract);
  const { isAutomateAmerica, isAnonymous } = legalDetails;

  const onInputChange = (isAutomateAmerica: boolean, isAnonymous: boolean) => {
    dispatch(setLegalDetails({ ...legalDetails, isAutomateAmerica, isAnonymous }));
  };

  const submitContract = () => {
    props.publishContract()
  }

  return (
    <div className="py-3">
      <div className="text-center">
        <img src={group} alt="" />
      </div>

      <div className="row justify-content-center mt-4 mx-0">

        <div className={styles.ratedRadio}>
          <div className={styles.rateradio} style={{display: "flex", alignItems: "center" , justifyContent: "center" , flexDirection: "column"}}>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="postingOption"
                checked={isAutomateAmerica && !isAnonymous}
                onChange={() => onInputChange(true, false)}
              />
              <label htmlFor="isAutomateAmerica" className="font-28 text-center text-black">
                Post As Own company
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="postingOption"
                id="isAnonymous"
                checked={!isAutomateAmerica && isAnonymous}
                onChange={() => onInputChange(false, true)}
              />
              <label htmlFor="isAnonymous" className="font-28 text-center text-black">
                Post As Anonymous
              </label>
            </div>
          </div>

        </div>

        <div className="col-6 my-3 btn-hover-effect text-center">
          <button
            type="button"
            onClick={() => submitContract()}
            className="btn bg-white arrowblue border-black border-1 rounded-pill position-relative py-lg-3 py-2 pe-5"
          >
            <span className="px-lg-4 px-2 text-12 fw-semibold">Submit</span>
            <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
              <img src={whitearrow} alt="" />
            </i>
          </button>
        </div>
      </div>
    </div>
  );

};
export default Post;
