import React, { useEffect, useRef, RefObject } from 'react';
import { Props } from 'react-select';

export const VideoPlayer = ({ user }: any) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        user.videoTrack.play(ref.current);
    }, []);

    return (
        <div>
            Uid: {user.uid}
            <div
                ref={ref}
                style={{ width: '200px', height: '200px' }}
            ></div>
        </div>
    );
};