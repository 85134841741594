import React, { useEffect, useState } from "react";
import styles from "../../styles/styles.module.css";
import { useFormik } from "formik";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import storage from "../../../../../utils/storage";
import Occupations from "../../../../../assets/images/occupations.png";
import whitearrow from "../../../../../assets/images/whitearrow.svg";
import blackarrow from "../../../../../assets/images/blackarrow.svg";
import Delete from "../../../../../assets/images/delete.svg";
import { RootState } from "../../../../../redux/store";
import {
  setActiveStep,
  setTechnicalSkills,
} from "../../../../../redux/reducers/addContract";
import { ADD_CONTRACT_STEPS } from "../../../../../shared/enums";
import { getMasterTypes } from "../../../../../feature/auth/signup/api";
import { MASTER_TYPE } from "../../../../../utils/enums/masterTypes.enum";
import { getManufacturerModelList } from "../../../api";
import HeaderContent from "../../../../../shared/Components/HeaderContent/headerContent";
import { Dropdown, FormControl } from "react-bootstrap";
import { IOption } from "../../../../../Interfaces";
import { SaveAsDraftButton } from "../../Components";

const AddTechnicalSkills: React.FC = () => {
  const dispatch = useDispatch();
  const token = storage.getToken();
  const { technicalSkillsData } = useSelector(
    (state: RootState) => state.addContract
  );

  const [searchTerm, setSearchTerm] = useState<string>("");

  const formIk: any = useFormik({
    initialValues: {
      skills: technicalSkillsData.skills,
    },

    onSubmit: (values) => {
      // dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_ELIGIBILITY_QUESTION));
    },
  });

  useEffect(() => {
    const getManufacturerData = async () => {
      const response = await getMasterTypes(MASTER_TYPE.MANUFACTURERS);
      response.data.forEach((x) => {
        x.label = x.name;
        x.value = x._id;
      });

      dispatch(
        setTechnicalSkills({
          ...technicalSkillsData,
          skillTypes: response.data,
        })
      );
    };

    getManufacturerData();
  }, []);

  const addNewManufacture = (): void => {
    const updatedSkills = [...technicalSkillsData.skills];
    updatedSkills.push({
      isRequired: false,
      subCategoriesList: [],
      category: { label: "", value: "" },
      subCategory: [],
    });

    dispatch(
      setTechnicalSkills({ ...technicalSkillsData, skills: updatedSkills })
    );
  };

  const onValueChange = (
    value: string | boolean | IOption[],
    key: string,
    index: number
  ): void => {
    const updatedSkills = [...technicalSkillsData.skills];
    const updatedSkill = { ...updatedSkills[index], [key]: value };
    updatedSkills[index] = updatedSkill;

    dispatch(
      setTechnicalSkills({ ...technicalSkillsData, skills: updatedSkills })
    );
    formIk.setFieldValue("skills", updatedSkills);
  };

  const handleGoBack = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_BASIC_INFO));
  };
  const handleGoToNextStep = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_ELIGIBILITY_QUESTION));
  };

  const handleSkip = () => {
    dispatch(setActiveStep(ADD_CONTRACT_STEPS.ADD_ELIGIBILITY_QUESTION));
  };

  const handleSearch = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const handleChangeManufacturer = async (
    option: any,
    index: number,
    key: string
  ) => {
    const updatedSkills = [...technicalSkillsData.skills];
    const updatedSkill = { ...updatedSkills[index] };

    if (key === "category") {
      const response = await getManufacturerModelList(
        MASTER_TYPE.MANUFACTURERS_MODELS,
        option.value
      );
      response.data.forEach((x) => {
        x.label = x.name;
        x.value = x._id;
      });

      updatedSkill.subCategoriesList = response.data ?? [];
      updatedSkill.category = option;
      updatedSkill.subCategory = [];
    }

    if (key === "subCategory") {
      updatedSkill.subCategory = option;
    }

    (updatedSkill as any)[key] = option;
    updatedSkills[index] = updatedSkill;

    formIk.setFieldValue("skills", updatedSkills);
    dispatch(
      setTechnicalSkills({ ...technicalSkillsData, skills: updatedSkills })
    );
  };

  const deleteSkill = (i: number) => {
    const updatedSkills = [...technicalSkillsData.skills];
    updatedSkills.splice(i, 1);

    dispatch(
      setTechnicalSkills({ ...technicalSkillsData, skills: updatedSkills })
    );
  };

  const filteredCategories = technicalSkillsData.skillTypes.filter((option) =>
    option?.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <HeaderContent />
      <div className="py-4 heightautoscroll">
        <div className={styles.signuppage}>
          <div className="contractpages">
            <form className="h-100" onSubmit={formIk.handleSubmit}>
              <div
                className={styles.registerOuter}
                style={{ position: "relative" }}
              >
                <div className="container">
                  <div className={styles.registerBord}>
                    <div className="row">
                      <div className="col-md-6">
                        <div
                          className={styles.registerLeft}
                          style={{
                            position: "absolute",
                            left: "0",
                            width: "50%",
                          }}
                        >
                          {/* <p>Add a Legal information of the work that you need</p> */}
                          <figure>
                            <img
                              src={Occupations}
                              alt=""
                              className="img-fluid"
                            />
                          </figure>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className={styles.signUPContainers}>
                          <div className={styles.registerRight}>
                            <div className={styles.registerLeft}>
                              <h4 className="text-black text-38 fw-semibold">
                                Programming / Technical Skills
                              </h4>
                              <p className="text-16 text-white opacity-75">
                                Select manufacturer of the hardware or software
                                that you need help with. Examples: AutoCAD,
                                FANUC, Siemens, Allen Bradley, Kuka, ABB
                              </p>
                            </div>
                            <div className="technicalskill">
                              <div className={styles.ScrollDiv}>
                                {technicalSkillsData?.skills?.map((x, i) => (
                                  <>
                                    <>
                                      {technicalSkillsData.skills.length > 1 && (
                                        <div className="mb-4 text-end">
                                          <button
                                            type="button"
                                            className="btn"
                                            onClick={() => deleteSkill(i)}
                                          >
                                            {/* <span className=" px-lg-4 px-2">Delete</span> */}
                                            <i className="bg-white rounded-circle p-2">
                                              <img
                                                src={Delete}
                                                alt=""
                                                width={25}
                                                height={25}
                                              />
                                            </i>
                                          </button>
                                        </div>
                                      )}
                                    </>
                                    <div
                                      className="p-3 rounded-4 mb-3"
                                      style={{
                                        background: "rgba(255, 255, 255, 0.2)",
                                      }}
                                    >
                                      <div
                                        className={styles.fromGroup}
                                        style={{ marginBottom: "30px" }}
                                      >
                                        <label >     
                                        Manufacturer
                                        </label>

                                        <Dropdown>
                                          <Dropdown.Toggle
                                            variant="primary"
                                            id="dropdown-basic"
                                          >
                                            {x.category.label ??
                                              "Please select an option"}
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu>
                                            <FormControl
                                              autoFocus
                                              className="mx-3 my-2 w-auto"
                                              placeholder="Search..."
                                              onChange={handleSearch}
                                              value={searchTerm}
                                            />
                                            {filteredCategories.length === 0 ? (
                                              <Dropdown.Item disabled>
                                                No options found
                                              </Dropdown.Item>
                                            ) : (
                                              filteredCategories?.map(
                                                (option, index: number) => (
                                                  <Dropdown.Item
                                                    key={index}
                                                    onClick={() =>
                                                      handleChangeManufacturer(
                                                        option,
                                                        i,
                                                        "category"
                                                      )
                                                    }
                                                  >
                                                    {option.label}
                                                  </Dropdown.Item>
                                                )
                                              )
                                            )}
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                      <div
                                        className={styles.fromGroup}
                                        style={{ marginBottom: "30px" }}
                                      >
                                        <label>
                                          Model / Controller
                                        </label>

                                        <Select
                                          isMulti
                                          name="colors"
                                          options={x.subCategoriesList}
                                          className="basic-multi-select"
                                          classNamePrefix="select"
                                          onChange={(val) =>
                                            onValueChange(
                                              val as any,
                                              "subCategory",
                                              i
                                            )
                                          }
                                          value={x.subCategory}
                                        />
                                      </div>

                                      <div className={styles.fromGroup}>
                                        <label>
                                          Skills
                                        </label>
                                        <ul
                                          className={`${styles.radioBTn} ${styles.radStepOne}`}
                                          style={{ flexDirection: "column" }}
                                        >
                                          <li style={{ background: "transparent" }}>
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name={`flexRadioDefault${i}`}
                                                id={`flexRadioDefault1${i}`}
                                                checked={x.isRequired}
                                                onChange={(e) =>
                                                  onValueChange(
                                                    true,
                                                    "isRequired",
                                                    i
                                                  )
                                                }
                                              />
                                              <label className="form-check-label">
                                                Mandatory
                                              </label>
                                            </div>
                                          </li>
                                          <li
                                            className=""
                                            style={{ background: "transparent" }}
                                          >
                                            <div className="form-check">
                                              <input
                                                className="form-check-input"
                                                type="radio"
                                                name={`flexRadioDefault2${i}`}
                                                id={`flexRadioDefault2${i}`}
                                                checked={!x.isRequired}
                                                onChange={(e) =>
                                                  onValueChange(
                                                    false,
                                                    "isRequired",
                                                    i
                                                  )
                                                }
                                              />
                                              <label className="form-check-label">
                                                Good to have
                                              </label>
                                            </div>
                                          </li>
                                        </ul>
                                        {formIk.touched.flexRadioDefault &&
                                        formIk.errors.flexRadioDefault ? (
                                          <div className={styles.error}>
                                            {formIk.errors.flexRadioDefault}
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row align-items-center justify-content-between">
                          <div className="col-4 text-start my-3 btn-hover-effect">
                            <h6
                              onClick={() => handleGoBack()}
                              className="btn bg-white arrowyellow border-white border-1 rounded-pill position-relative py-lg-3 py-2 ps-5 backbutton"
                            >
                              <span className="px-lg-4 px-2 text-12 fw-semibold">
                                Back
                              </span>
                              <i className="bg-yellow rounded-pill arroweffect position-absolute start-0 h-100 top-0 d-flex align-items-center justify-content-center me-3">
                                <img src={blackarrow} alt="" />
                              </i>
                            </h6>
                          </div>
                          <div className="col-8">
                            <div className="row align-items-center jusify-content-end">
                              <div className="col-6">
                                <button
                                  type="button"
                                  onClick={addNewManufacture}
                                  className="btn bg-white arrowblue border-white border-1 rounded-pill position-relative py-lg-3 py-2 px-3 text-14"
                                >
                                  <span className=" text-12 fw-semibold">
                                    Add Another +
                                  </span>
                                </button>
                              </div>
                              <div className="col-6 text-end my-3 btn-hover-effect">
                                <button
                                  onClick={handleSkip}
                                  className="btn bg-secondary arrowyellow border-grey border-1 rounded-pill position-relative py-lg-3 py-2"
                                >
                                  <span className="px-lg-4 px-2 text-12 fw-semibold text-black">
                                    Skip
                                  </span>
                                </button>
                              </div>
                              <div className="col-4 text-end my-3 btn-hover-effect">
                                <h6
                                  onClick={handleGoToNextStep}
                                  className="btn bg-white arrowblue border-white border-1 rounded-pill position-relative py-lg-3 py-2 pe-5"
                                >
                                  <span className="px-lg-4 px-2 text-12 fw-semibold">
                                    Next
                                  </span>
                                  <i className="bg-blue rounded-pill arroweffect position-absolute end-0 h-100 top-0 d-flex align-items-center justify-content-center ms-3">
                                    <img src={whitearrow} alt="" />
                                  </i>
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddTechnicalSkills;
