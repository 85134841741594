import React, { useEffect, useState } from "react";
import Header from "../../header/routes";
import { getAllUserProfiles } from "../api";
import location from "../../../assets/images/icon_location.svg";
import dummy from "../../../assets/images/dummy.jpeg";
import styles from "../../Feeds/styles/style.module.css";
import { APP_ROUTES } from "../../../utils/routes/AppRoutes";
import { useNavigate } from "react-router-dom";
import blsearch from "../../../assets/images/blsearch.png";
import shareprofile from "../../../assets/images/share_profile.svg";
import flag from "../../../assets/images/flag.svg";
import loc from "../../../assets/images/loc.png";
import star from "../../../assets/images/icon_star.svg";
import selfrating from "../../../assets/images/self_rating.svg";
import gprs from "../../../assets/images/gprs.png";
import profilePic from "../../../assets/images/profilepicture.svg";
import profilePicBlue from "../../../assets/images/profilepicture-blue.svg";
import { userProfileResponse } from "../interface";

const FindProfessionals = () => {
  const [professional, setProfessional] = useState<userProfileResponse[]>([]);
  console.log(professional,'professional');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const FindProfessionals = async () => {
    const response = await getAllUserProfiles();
    setProfessional(response?.data);
    setLoading(false);
  };

  useEffect(() => {
    FindProfessionals();
  }, []);

  const handleViewFullProfile = (value: string) => {
    navigate(`${APP_ROUTES.USER_PROFILE}/${value}`);
  };

  return (
    <>
      <Header />
      <div className="container">
        <div className="row align-items-center justify-content-center my-3 mx-0">
          <div className="bg-white row align-items-center py-3 mt-4">
            <div className="col-lg-5 col-12">
              <div className="d-flex align-items-center justify-content-between px-3">
                <span className="SeachIc">
                  <img src={blsearch} alt="" />
                </span>
                <input
                  type=" text"
                  placeholder="Search by: Job tittle, Position, Keyword or People..."
                  className="border-0 px-3 py-2 w-100"
                />
              </div>
            </div>
            <div className="col-lg-5 col-12">
              <div className="d-flex align-items-center justify-content-between border-start px-3">
                <span className="SeachIc">
                  <img src={loc} alt="" />
                </span>
                <input
                  type=" text"
                  placeholder="City, state or zip code"
                  className="border-0 px-3 py-2 w-100"
                />
                <span className={styles.SeachIcgps}>
                  <img src={gprs} alt="" />
                </span>
              </div>
            </div>
            <div className="col-lg-2 col-12">
              <div className={styles.FormGroup}>
                <input
                  type="submit"
                  value="Search"
                  className="btn btn-primary"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="recommendedjobs">
        {loading ? (
          <div className="spinner-grow text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        ) : (
          <div className="container">
            <div className="row align-items-center justify-content-between">
              {/* return ( */}
              {professional?.map((data, idx) => {
                return (
                  <div key={idx} className="col-lg-6">
                    <div className="border rounded-3 mb-3">
                      <div className="row flex-column align-items-center justify-content-between mb-2 bg-lightblue py-3 mx-0">
                        <div className="row align-items-center justify-content-between gx-2">
                          <div className="col-3 profile_icon">
                            <h3 className="mb-0 text-16 clamp clamp1">
                              {data?.occupationIds?.name}
                            </h3>
                          </div>
                          <div className="col-9 ps-3 text-end">
                            <div className="row align-items-start justify-content-end">
                              <div className="col-12">
                                <p className="mb-0 bg-white border border-success text-success rounded-pill text-center text-13 d-inline-block px-2 py-1">
                                  {data?.isAvailable === true
                                    ? "Available"
                                    : "UnAvailable"}
                                </p>
                                <i className="mx-3">
                                  <img src={flag} alt="" />
                                </i>
                                <span>
                                  <img src={shareprofile} alt="" />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="p-3">
                        <div className="skills">
                          <div className="row align-items-center">
                            <div className="col-md-2 col-12 text-center mb-md-0 mb-3">
                            <figure
                                    className={styles.profileCircle}
                                    style={{ marginBottom: "0" }}
                                  >
                                    <img
                                      src={
                                        data?.profilePicture
                                          ? data?.profilePicture
                                          : dummy
                                      }
                                      alt=""
                                      className="img-fluid w-100"
                                      style={{minHeight: "auto", minWidth: "auto"}}
                                    />
                                  </figure>
                            </div>
                            <div className="col-md-5 col-6 border-end">
                                <div className="newheadingstyle ps-2">
                                  <h3 className="mb-0 text-16 clamp clamp1">
                                    {data?.name}
                                    {/* Bradely Innes */}
                                  </h3>
                                  <p className="text-12 my-1">
                                    {data?.occupationIds?.name}
                                    {/* Software Developer */}
                                  </p>
                                  <p className="text-12 d-flex align-items-center mb-2 text-black">
                                    <i className="me-1">
                                      <img
                                        src={location}
                                        alt=""
                                        width={15}
                                        height={14}
                                      />
                                    </i>
                                    {data?.address}
                                  </p>
                                  <p className="text-12 mb-0">
                                    Reviews : 20{" "}
                                    <span className="border-start ps-2 ms-2">
                                      4.5{" "}
                                      <i className="ms-1">
                                        <img src={star} alt="" />
                                      </i>
                                    </span>
                                  </p>
                                </div>
                            </div>
                            <div className="col-md-5 col-6">
                              <div className="d-flex align-items-center justify-content-center">
                                <div className="col-12">
                                  <div className="newheadingstyle ps-2">
                                    <h4 className="text-12">
                                      Company Accounts Payable Contact
                                    </h4>
                                    <p className="text-12 mb-0">
                                      Top Skill : PHP Developer
                                    </p>
                                    <p className="text-12 mb-0">
                                      Second Skill : Python{" "}
                                    </p>
                                    <p className="text-12 mb-0">
                                      Member Since : 2018
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className=" mt-3 border-top pt-3">
                            <div className="newheadingstyle">
                              <h3 className="text-14">Programming Skills</h3>
                              <ul className="ps-0 d-flex flex-wrap align-items-center justify-content-start gap-2 mx-0">
                                <li className="badge bg-blue text-white rounded-pill fw-normal text-12 d-inline-block">
                                  Figma
                                </li>
                              </ul>
                              <h4 className="text-14">
                                Self Rating :{" "}
                                <i>
                                  <img src={selfrating} alt="" />
                                </i>
                              </h4>
                            </div>
                            <div className="">
                              <h4 className="text-16 fw-semibold mb-2">
                                About
                              </h4>
                              <p className="text-12 mb-0">
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Sed luctus, augue eget
                                scelerisque efficitur.Lorem ipsum dolor Lorem
                                ipsum dolor sit amet, consectetur adipiscing
                                elit. Sed luctus, augue eget scelerisque
                                efficitur.Lorem ipsum dolor ..
                              </p>
                            </div>
                            <ul className="ps-0 d-flex flex-wrap align-items-center justify-content-start gap-2 mx-0">
                              {data?.skilledTradeIds?.map((data:any, idx:any) => {
                                return (
                                  <li
                                    key={idx}
                                    className="col badge text-bg-light text-grey fw-normal text-12"
                                  >
                                    {data?.name}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                        <div className={styles.payrates}>
                          <h3 className="fw-semibold mt-4 text-14">
                            Pay Rates
                          </h3>
                          <ul className="row g-3 ps-0 mb-0 mt-2">
                            <li className="col mt-0">
                              <div className="">
                                <h3 className="text-13 fw-medium mb-2">Base</h3>
                                <p className="text-primary text-16 mb-2 fw-semibold">
                                  ${data?.rates?.baseRate}
                                </p>
                              </div>
                            </li>
                            <li className="col mt-0">
                              <div className="">
                                <h3 className="text-13 fw-medium mb-2">
                                  Overtime
                                </h3>
                                <p className="text-primary text-16 mb-2 fw-semibold">
                                  ${data?.rates?.overTimeRate}
                                </p>
                              </div>
                            </li>
                            <li className="col mt-0">
                              <div className="">
                                <h3 className="text-13 fw-medium mb-2">
                                  Sun/Holidays
                                </h3>
                                <p className="text-primary text-16 mb-2 fw-semibold">
                                  ${data?.rates?.doubleTimeRate}
                                </p>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className={styles.workExp}>
                          <h3 className="text-14 fw-semibold">
                            Working Experience
                          </h3>
                          <div className={styles.workExpSkill}>
                            <img src={profilePic} alt="" />
                            <ul>
                              <li className="ps-2">
                                <h6>Freelance UX/UI designer</h6>
                                <span>Lorem ipsum</span>
                                <p>
                                  lorem ipsum{" "}
                                  <span className="text-blue">
                                    1 yrs 02 mos
                                  </span>
                                </p>
                              </li>
                            </ul>
                          </div>
                          <div className={styles.workExpSkill}>
                            <img src={profilePicBlue} alt="" />
                            <ul>
                              <li className="ps-2">
                                <h6>UX/UI designer</h6>
                                <span>Lorem ipsum</span>
                                <p>
                                  lorem ipsum
                                  <span className="text-blue">
                                    1 yrs 02 mos
                                  </span>
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="mt-3">
                          <div className="row align-items-center g-2">
                            {/* )} */}
                            <div className="col">
                              <button
                                type="button"
                                className="btn btn-white w-100 text-center border text-12 text-primary border-primary"
                              >
                                Request to Connect
                              </button>
                            </div>
                            <div className="col">
                              <button
                                onClick={() => handleViewFullProfile(data._id)}
                                type="button"
                                className="btn btn-primary w-100 text-12"
                              >
                                View Full Profile
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              {/* ); */}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default FindProfessionals;
