import React, { useEffect, useState } from "react";
import styles from "../styles/style.module.css";
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import { addEvents, getEvents } from "../../../Calendar/api";
import moment from "moment";
import { MESSAGES } from '../../../../utils/messages';
import { EVENT_TYPE, INTERVIEW_TYPE } from "../../../../utils/enums/types";
import axios from "axios";
import { API_ROUTES } from '../../../../utils/routes/apiRoutes';
import { createRtmToken } from '../../../VideoCall/api/index'
import { setVideoJoinConfig } from "../../../../redux/reducers/addVideoData";
import { useDispatch } from "react-redux";
import storage from "../../../../utils/storage";

const createTokenUrl = 'https://dev-api.automateamerica.com/api/common/rtc-token';

interface ModalProps {
  isOpen: boolean | string;
  onClose: () => void;
  professional_id
  : string;
  work_id: string;
  occupation_name: string;
  professional_name: string;
  interviewScheduled: (data: boolean) => void;
}

const InterviewScheduleModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  professional_id,
  work_id,
  occupation_name,
  professional_name,
  interviewScheduled,
}) => {
  if (!isOpen) return null;
  const [date, setDate] = useState<Date | null>(null);
  const [time, setTime] = useState("");
  const [notes, setNotes] = useState('');
  const [title] = useState(`Interview for ${occupation_name}`);
  const [videoDetailsValue, setVideoDetails] = useState({
    channelName: '',
    uid: 0,
    token: '',
    link: '',
  })
  const generateChannelName = () => {
    return parseInt(Math.floor(100000 + Math.random() * 900000).toString());
  }
  let channelName = professional_id + generateChannelName() + +new Date();
  let uid: number = generateChannelName();
  let tokenData: any;

  const getToken = async () => {
    let token = await createRtmToken({ channelName, uid, role: 'publisher', });
    setVideoDetails({
      channelName,
      uid,
      token: token?.data?.token,
      link: window.location.href + `?token=${token?.data?.token}&channelName=${channelName}`
    });
  }
  useEffect(() => { tokenData = getToken() }, []);

  const handleDateChange = (selectedDate: Date | null) => {
    setDate(selectedDate);
  };

  const handleSubmit = async () => {
    if (work_id && professional_id
      && date && time) {
      const combinedDateTime = moment(date).set({
        hour: moment(time, 'HH:mm').hour(),
        minute: moment(time, 'HH:mm').minute(),
        second: 0,
        millisecond: 0
      }).toDate();

      const payload = {
        workId: work_id,
        userId: professional_id,
        title,
        // eventDate: combinedDateTime.valueOf(),
        eventDate: moment(combinedDateTime).local().valueOf(),
        // eventTime: combinedDateTime.valueOf(),
        eventTime: moment(combinedDateTime).local().valueOf(),
        eventType: EVENT_TYPE.INTERVIEW,
        interviewType: INTERVIEW_TYPE.VIDEO,
        videoDetails: videoDetailsValue,
        additionalNotes: notes,
      };
      try {
        await toast.promise(addEvents(payload), {
          pending: MESSAGES.CALENDAR.PENDING,
          success: MESSAGES.CALENDAR.SUCCESS,
          error: MESSAGES.CALENDAR.ERROR,
        });

        interviewScheduled(true);

        storage.setInterviewData({
          day: moment(payload.eventDate).format('YYYY-MM-DD'),
          workId: work_id,
          userId: professional_id,
          uid: videoDetailsValue?.uid,
          channelName: videoDetailsValue?.channelName,
          token: videoDetailsValue?.token,
          link: videoDetailsValue?.link,
          time: payload.eventTime
        });

        storage.setInterviewUserId({ userId: professional_id });

        onClose();
      } catch (err) {
        console.log(err);
      }
    } else {
      alert("Please fill in all fields");
    }
  };

  return (
    <>
      <div
        className="modal test-modal"
        style={{ display: isOpen ? "block" : "none" }}
      >
        <div className="modal-dialog  modal-dialog-centered ">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              ></button>
            </div>
            <div className="modal-body">
              <div className={styles.videoModalItem}>
                <div className="text-center">
                  <h3>Request for Interview</h3>
                  <p>
                    A notification will be sent to the professional, and it will
                    be added to both your and the professional's calendars.
                  </p>
                </div>
                <div className={styles.formGroup}>
                  <input
                    type="text"
                    disabled={true}
                    placeholder={title}
                  />
                </div>
                <div className={styles.formGroup}>
                  <input
                    type="text"
                    disabled={true}
                    placeholder={`${professional_name}`}
                  />
                </div>
                <DatePicker
                  className={styles.formGroup}
                  selected={date}
                  onChange={handleDateChange}
                  placeholderText="Start Date"
                  minDate={new Date()}
                  dateFormat="MM-dd-yyyy"
                />
                <div className={styles.formGroup}>
                  <input type="time"
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                    name="time"
                    placeholder="Johns Yahi" />
                </div>
                <div className={styles.formGroup}>
                  <select name="interviewType" >
                    <option value="Interview Type">Interview Type</option>
                    <option value="VideoMeeting">Video meeting</option>
                    <option value="PhoneCall">Phone call</option>
                    <option value="In-person">In-person</option>
                  </select>
                </div>
                <div className={styles.formGroup}>
                  <textarea
                    name="notes"
                    placeholder="Additional Notes"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                  ></textarea>
                </div>
                <div className={styles.formCta}>
                  <input
                    type="submit"
                    value="Save Date and Send Request"
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InterviewScheduleModal;


// TODO hide schedule interview button if the professional is not available