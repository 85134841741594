import React from "react";
import logo from "../../../assets/images/logo.png";
import storage from "../../../utils/storage";
import { Link } from "react-router-dom";

const HeaderContent = () => {
  const token = storage.getToken();
  return (
    <div className="container-fluid">
      <div className="row align-items-center justify-content-between py-3">
        <div className="col-12 col-xl-5">
          <img src={logo} alt="" className="img-fluid" />
        </div>
        {!token ? (
          <div className="col-12 col-xl-7">
            <div className="row align-items-center justify-content-end">
              <div className="col-12 d-flex align-items-center justify-content-end mob-justify">
                <p className="mb-0 text-14 fw-normal text-black pe-lg-4 pe-2">
                  {/* Don’t have an account?{" "} */}
                  Already have an account?{" "}
                  {/* <a
                    href="/personal-info"
                    className="text-14 text-blue fw-bold text-decoration-none"
                  >
                    Sign up
                  </a>
                  / */}
                  <Link
                    to="/login"
                    className="text-14 text-blue fw-bold text-decoration-none"
                  >
                    Sign In
                  </Link>
                </p>
                <button className="btn btn-white needhelp">Need Help?</button>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-12 col-xl-7">
            <div className="row align-items-center justify-content-end">
              <div className="col-12 d-flex align-items-center justify-content-end mob-justify">
                <p className="mb-0 pe-4">
                  <a
                    href="/home"
                    className="text-14 text-blue fw-bold text-decoration-none"
                  >
                    Dashboard
                  </a>
                </p>
                <button className="btn btn-white text-14 needhelp d-block mt-0">
                  Need Help?
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default HeaderContent;
