import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserData {
  firstName: string;
  lastName: string;
}
interface Contact {
  phoneNum: string;
  countryCode: string;
  phoneNumVerifyId: string;
  number: string;
}
interface Otp {
  verificationCode: number | string;
  isVerified: boolean;
}

interface verificationToken {
  userName: number | string;
  verificationToken: string;
}
interface Email {
  email: string;
}
interface Password {
  password: string;
}
interface Occupation {
  occupation: string[];
}
interface Citizenship {
  citizenship: string;
}
interface Aboutus {
  selectedOption: string;
  About: string;
}
interface PrivacyPolicy {
  isPrivacyPolicy: boolean
}
interface UserDataState {
  data: UserData;
  contact: Contact;
  verificationCode: Otp;
  emailData: Email;
  password: Password;
  occupationData: Occupation;
  citizenshipData: Citizenship;
  aboutData: Aboutus;
  PrivacyPolicy: PrivacyPolicy;
  verificationToken: verificationToken;
}

const initialState: UserDataState = {
  data: {
    firstName: "",
    lastName: "",
  },
  contact: {
    phoneNum: "",
    phoneNumVerifyId: "",
    countryCode: "",
    number: "",
  },
  verificationCode: {
    verificationCode: "",
    isVerified: false,
  },
  verificationToken: {
    verificationToken: "",
    userName: "",
  },
  emailData: {
    email: "",
  },
  password: {
    password: "",
  },
  PrivacyPolicy: {
    isPrivacyPolicy: false,
  },
  occupationData: {
    occupation: [],
  },
  citizenshipData: {
    citizenship: "",
  },
  aboutData: {
    About: "",
    selectedOption:""
  },
};

const userDataSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<UserData>) => {
      state.data = action.payload;
    },
    setContact: (state, action: PayloadAction<Contact>) => {
      state.contact = { ...action.payload };
    },
    setOtp: (state, action: PayloadAction<Otp>) => {
      state.verificationCode = { ...action.payload };
    },
    setEmail: (state, action: PayloadAction<Email>) => {
      state.emailData = { ...action.payload };
    },
    setPassword: (state, action: PayloadAction<Password>) => {
      state.password = action.payload;
    },
    setOccupation: (state, action: PayloadAction<Occupation>) => {
      state.occupationData = action.payload;
    },
    setCitizenship: (state, action: PayloadAction<Citizenship>) => {
      state.citizenshipData = action.payload;
    },
    setAboutData: (state, action: PayloadAction<Aboutus>) => {
      state.aboutData = action.payload;
    },
    setPrivacyPolicies: (state, action: PayloadAction<PrivacyPolicy>) => {
      state.PrivacyPolicy = action.payload;
    },
    setVerificationToken: (state, action: PayloadAction<verificationToken>) => {
      state.verificationToken = action.payload;
    },
  },
});

export const {
  setUserData,
  setContact,
  setOtp,
  setEmail,
  setOccupation,
  setCitizenship,
  setAboutData,
  setPassword,
  setVerificationToken,
  setPrivacyPolicies
} = userDataSlice.actions;
export default userDataSlice.reducer;
