import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SignupResponseData {
  _id: string;
  name: string;
  email: string;
  phoneNumber: string;
  countryCode: string;
  password: string;
  status: boolean;
  isVerified: boolean;
  isAvailable: boolean;
  workStatus: boolean;
  // Add other fields as needed
}

interface SignupResponse {
  data: SignupResponseData;
}

const initialState: SignupResponse | null = {
  data: {
    _id: "",
    name: "",
    email: "",
    phoneNumber: "",
    countryCode: "",
    password: "",
    status: false,
    isVerified: false,
    isAvailable: false,
    workStatus: false,
  },
};

const signupResponseSlice = createSlice({
  name: "signupResponse",
  initialState,
  reducers: {
    saveSignupResponse: (state, action: PayloadAction<SignupResponseData>) => {
      state.data = action.payload;
    },
  },
});

export const { saveSignupResponse } = signupResponseSlice.actions;
export default signupResponseSlice.reducer;
