import { axios } from "../../../lib/axios";
import { API_ROUTES } from "../../../utils/routes/apiRoutes";
import { ContractResponse } from "../../common/Contracts/interface";
import { ApplyWork, GetRecommendedResponse, ReccommendedWork, WorkType, Inquiry, User } from "../interface";

export const getRecommendedWork = async (workType: WorkType): Promise<GetRecommendedResponse> => {
    return axios.get(API_ROUTES.FEED.GET_RECCOMENDED, { params: workType });
};

export const applyWork = async (id: string, payload: ApplyWork): Promise<any> => {
    return axios.post(`${API_ROUTES.WORK.APPLY}/${id}`, payload);
};

export const getSpecificWork = async (id: string): Promise<ContractResponse> => {
    return axios.get(`${API_ROUTES.CONTRACTS.CREATE_CONTRACT}/${id}`)
};

export const getAdvertise = async (id:string): Promise<any> => {
    return axios.get(`${API_ROUTES.FEED.GET_FEED}`)
};

export const postQuery = async (payload:Inquiry): Promise<any> => {
    return axios.post(`${API_ROUTES.INQUIRY.INQUIRY}`, payload)
}
export const getLoggedInUser = async (): Promise<User> => {
    return axios.get(API_ROUTES.AUTH.GET_USER);
}