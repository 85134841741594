import React, { useState } from "react";
import styles from "../styles/style.module.css";
import penbl from "../../../assets/images/penbl.png";
import bin from "../../../assets/images/dlt.png"
import EducationModal from "../../common/modals/education/page";
import { Link } from "react-router-dom";
import { EducationDetails } from "../interface";

interface EducationCertiProps {
  education: EducationDetails[];
  refresh: () => void;
}

const EducationCerti: React.FC<EducationCertiProps> = ({
  education = [],
  refresh,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [editData, setEditData] = useState<EducationDetails | null>(null);
 const [indexNumber,setIndexNumber]= useState<number|null>(null)
 
  const openModal = (data?: EducationDetails,index?:number) => {
    setEditData(data || null); // Set data to edit or null for new entry
    setIsModalOpen(true);
    console.log("index---", index)
    if(index !== null &&index !== undefined  && index>=0 ){
      console.log("index-if",index)
      setIndexNumber(index)
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setEditData(null); // Clear data when closing modal
  };

  const label = [
    "School name",
    "Degree/Certificate Name",
    "Year of Completion",
    "Specialization",
  ];

  const handleEdit = (updatedEducation: EducationDetails) => {
    // Logic to update the specific education record
    console.log("Updated Education Data:", updatedEducation);
    // Implement the update logic here (e.g., make an API call)
    refresh(); // Refresh the education list
  };

  return (
    <div className={styles.AboutEdit}>
      <div className="row align-items-center justify-content-between mb-3">
        <div className="col-6">
          <h5 className="text-20 fw-semibold mb-0"> Education and Certification{" "} </h5>
        </div>
        <div className="col-6 text-end">
        <Link to="#" onClick={() => openModal()} className="btn btn-primary text-white w-auto fw-normal">
          +Add Education or Certification
        </Link>
        </div>
      </div>
      <div className={styles.addPop}>
        {education?.length > 0 ? (
          education?.map((data, index) => (
            <div
              key={index}
              style={{
                border: "1px solid grey",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
                padding: "5px",
              }}
            >
              <div>
                <p>
                  <strong>School:</strong> {data?.schoolDetails?.name}
                </p>
                <p>
                  <strong>Degree/Certificate:</strong>{" "}
                  {data?.degreeDetails?.name}
                </p>
                <p>
                  <strong>Year of Completion:</strong> {data?.completionYear}
                </p>
                <p>
                  <strong>Specialization:</strong>{" "}
                  {data?.specializationDetails?.name}
                </p>
                <p>
                  <strong>Description:</strong> {data?.comment}
                </p>
              </div>
              <span>
                {/* <div>
                  <img src={penbl} alt=""  onClick={
                    // () => handleEdit(data)
                    () => openModal(data,index)
                  } />
                  <img src={bin} alt="" />
                </div> */}
              </span>
            </div>
          ))
        ) : (
          <p>You don’t have any certifications yet.</p>
        )}

        {/* <button type="button" onClick={openModal}>
          {" "}
        </button> */}
        
        <EducationModal
          isOpen={isModalOpen}
          onClose={closeModal}
          title="Education and certification"
          educationDetails={education}
          refresh={refresh}
          handleEdit={handleEdit}
          initialData={editData}
          indexNumber={indexNumber}
        />
      </div>
    </div>
  );
};

export default EducationCerti;
