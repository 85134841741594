import React, { useEffect, useState } from "react";

import Header from "../../header/routes";
import { useNavigate } from "react-router-dom";
import Img1 from "../../../assets/images/img_1.png";
import styles from "../styles/style.module.css";
import Slider from "react-slick";

function NewFeeds() {
  const navigate = useNavigate();
  const handleGoToNext = () => {
    navigate("/feeds");
  };
  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 9.2,
    slidesToScroll: 4,
    initialSlide: 0,
    infinite: false,
    responsive: [
      {
        breakpoint: 1520,
        settings: {
          slidesToShow: 8.2,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 6.2,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5.2,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4.2,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3.2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 525,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings}>
        <div>
          <div className={styles.card_view}>
            <div className={styles.card_img}>
              <figure>
                <img src={Img1} alt="" />
              </figure>
            </div>
            <div className={styles.card_status}>
              <h4>Available</h4>
            </div>
            <div className={styles.card_detail}>
              <h3>Johns Yami.</h3>
              <p>Robort Programer</p>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.card_view}>
            <div className={styles.card_img}>
              <figure>
                <img src={Img1} alt="" />
              </figure>
            </div>
            <div className={styles.card_status}>
              <h4>Available</h4>
            </div>
            <div className={styles.card_detail}>
              <h3>Johns Yami.</h3>
              <p>Robort Programer</p>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.card_view}>
            <div className={styles.card_img}>
              <figure>
                <img src={Img1} alt="" />
              </figure>
            </div>
            <div className={styles.card_status}>
              <h4>Available</h4>
            </div>
            <div className={styles.card_detail}>
              <h3>Johns Yami.</h3>
              <p>Robort Programer</p>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.card_view}>
            <div className={styles.card_img}>
              <figure>
                <img src={Img1} alt="" />
              </figure>
            </div>
            <div className={styles.card_status}>
              <h4>Available</h4>
            </div>
            <div className={styles.card_detail}>
              <h3>Johns Yami.</h3>
              <p>Robort Programer</p>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.card_view}>
            <div className={styles.card_img}>
              <figure>
                <img src={Img1} alt="" />
              </figure>
            </div>
            <div className={styles.card_status}>
              <h4>Available</h4>
            </div>
            <div className={styles.card_detail}>
              <h3>Johns Yami.</h3>
              <p>Robort Programer</p>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.card_view}>
            <div className={styles.card_img}>
              <figure>
                <img src={Img1} alt="" />
              </figure>
            </div>
            <div className={styles.card_status}>
              <h4>Available</h4>
            </div>
            <div className={styles.card_detail}>
              <h3>Johns Yami.</h3>
              <p>Robort Programer</p>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.card_view}>
            <div className={styles.card_img}>
              <figure>
                <img src={Img1} alt="" />
              </figure>
            </div>
            <div className={styles.card_status}>
              <h4>Available</h4>
            </div>
            <div className={styles.card_detail}>
              <h3>Johns Yami.</h3>
              <p>Robort Programer</p>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.card_view}>
            <div className={styles.card_img}>
              <figure>
                <img src={Img1} alt="" />
              </figure>
            </div>
            <div className={styles.card_status}>
              <h4>Available</h4>
            </div>
            <div className={styles.card_detail}>
              <h3>Johns Yami.</h3>
              <p>Robort Programer</p>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.card_view}>
            <div className={styles.card_img}>
              <figure>
                <img src={Img1} alt="" />
              </figure>
            </div>
            <div className={styles.card_status}>
              <h4>Available</h4>
            </div>
            <div className={styles.card_detail}>
              <h3>Johns Yami.</h3>
              <p>Robort Programer</p>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.card_view}>
            <div className={styles.card_img}>
              <figure>
                <img src={Img1} alt="" />
              </figure>
            </div>
            <div className={styles.card_status}>
              <h4>Available</h4>
            </div>
            <div className={styles.card_detail}>
              <h3>Johns Yami.</h3>
              <p>Robort Programer</p>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.card_view}>
            <div className={styles.card_img}>
              <figure>
                <img src={Img1} alt="" />
              </figure>
            </div>
            <div className={styles.card_status}>
              <h4>Available</h4>
            </div>
            <div className={styles.card_detail}>
              <h3>Johns Yami.</h3>
              <p>Robort Programer</p>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.card_view}>
            <div className={styles.card_img}>
              <figure>
                <img src={Img1} alt="" />
              </figure>
            </div>
            <div className={styles.card_status}>
              <h4>Available</h4>
            </div>
            <div className={styles.card_detail}>
              <h3>Johns Yami.</h3>
              <p>Robort Programer</p>
            </div>
          </div>
        </div>
      </Slider>
    </>
  );
}

export default NewFeeds;
