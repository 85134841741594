import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../styles/style.module.css";
import { getLoggedInUser, postQuery } from "../../Feeds/api";
import { toast } from "react-toastify";
import { MESSAGES } from "../../../utils/messages";
import Swal from 'sweetalert2';

function Sendmessageform() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");

  const handleStates = () => {
    setMsg('');
  }



  const handlePostQuest = async () => {
    if (msg) {
      const payload = {
        question: msg,
      };

      try {
        await toast.promise(postQuery(payload), {
          pending: MESSAGES.INQUIRY.PENDING,
          error: MESSAGES.INQUIRY.ERROR,
        });

        handleStates();

        // Show SweetAlert after successful response
        await Swal.fire({
          title: 'Message Sent',
          text: 'Your message has been sent successfully.',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      console.log("Error");
    }
  };


  useEffect(() => {
    userData();
  }, []);

  const userData = async () => {
    const response: any = await getLoggedInUser();
    setName(response?.data?.name);
    setEmail(response.data?.email)
  };


  return (
    <>
      <div className={styles.sendmessage}>
        <div className="mb-3">
          <label htmlFor="" className="form-label">
            Full Name
          </label>
          <input
            value={name}
            disabled
            type="text"
            className="form-control"
            id=""

          />
        </div>
        <div className="mb-3">
          <label htmlFor="" className="form-label">
            Email Address
          </label>
          <input
            value={email}
            disabled
            type="email"
            className="form-control"
            id=""

          />
        </div>
        <div className="mb-3">
          <label htmlFor="exampleFormControlTextarea1" className="form-label">
            Messages
          </label>
          <textarea
            value={msg}
            onChange={(e) => setMsg(e.target.value)}
            className="form-control"
            id="exampleFormControlTextarea1"
            placeholder="Write your messages in here"
            style={{ height: "150px" }}
          ></textarea>
        </div>
        <div className="text-end">
          <div className="col-xl-5 col-7 ms-auto">
            <button
              type="button"
              className="btn btn-primary h-40 text-14"
              onClick={handlePostQuest}
            >
              Send Messages
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sendmessageform;
